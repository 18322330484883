import React, {
  useContext,
  useState
} from 'react';
import {
  Box,
  Image,
  Text
} from '@chakra-ui/react';
import {
  Link,
  useNavigate
} from 'react-router-dom';
import { useForm } from 'react-hook-form';

import ContainerWrapper from '../../components/ContainerWrapper';
import {
  CallistoRoutes,
  PrivateRoutes,
  PublicRoutes
} from '../../config/routes';
import CampusRecord from '../../components/CampusRecord';
import Button from '../../components/Button';
import { useLogin } from '../../service/hooks/auth';
import { wait } from '../../service/backend';
import { AppContext } from '../../contexts/AppContext';
import IconInput from '../../components/IconInput';
import Notification from '../../components/Notification';
import { useNotification } from '../../components/Notification/useNotification';

import UserIcon from '../../../assets/icons/user.svg';
import LockIcon from '../../../assets/icons/lock_new.svg';
import CallistoVaultLogo from '../../../assets/CallistoVault_logo_stacked.png';
import GiveFeedbackLink from '../../components/GiveFeedbackLink';

interface ILoginFormProps {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const { setLoggedIn } = useContext(AppContext);
  const { register, handleSubmit } = useForm<ILoginFormProps>();
  const { notification, setNotification } = useNotification();
  const navigate = useNavigate();
  const [signRequesting, setSignRequesting] = useState<boolean>(false);
  const { mutateAsync: requestLogin } = useLogin();

  const onSubmit = async ({ username, password }: ILoginFormProps) => {
    setSignRequesting(true);
    try {
      await wait(100);
      await requestLogin({ username, password });
      setLoggedIn(true);
      setSignRequesting(false);
      navigate(PrivateRoutes.DASHBOARD);
    } catch (e) {
      setNotification({
        type: 'error',
        message:
          'The username or password you entered is incorrect. Please try again.',
      });
      setSignRequesting(false);
    }
  };

  return (
    <ContainerWrapper
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box maxWidth="850px" width="100%">
        <Notification notification={notification} />
        <Box
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          width="100%"
          backgroundColor="brand.white"
          padding={['20px 20px', '40px 20px', '60px 120px']}
        >
          <Image
            src={CallistoVaultLogo as string}
            alt="Callisto Vault Logo" htmlWidth="200px"
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
          />
          <Text mt="30px" fontSize="xl" as="h1" fontFamily="Avenir">
            Log In
          </Text>
          <br />
          <IconInput
            inputGroupProps={{ mb: '30px' }}
            icon={UserIcon as string}
            placeholder="Enter your username"
            {...register('username')}
            isRequired
          />
          <IconInput
            placeholder="Enter your password"
            inputGroupProps={{
              mb: '45px',
            }}
            icon={LockIcon as string}
            {...register('password')}
            type="password"
            isRequired
          />
          <Button
            type="submit"
            buttonColor="brand.primary"
            isLoading={signRequesting}
          >
            Log in to my account
          </Button>
        </Box>
        <CampusRecord />
        <Link to={PublicRoutes.RESET_PASSWORD}>
          <Text
            fontSize="sm"
            color="brand.link"
            fontWeight="bold"
            textDecor="underline"
            display="inline"
          >
            Forgot password?
          </Text>
        </Link>
        <br />
        <a
          href={CallistoRoutes.TERMS_OF_SERVICE}
          target="_blank"
          rel="noreferrer"
          style={hyperLinkStyles}
        >
          <Box>Terms of Service</Box>
        </a>
        <a
          href={CallistoRoutes.PRIVACY_POLICY}
          target="_blank"
          rel="noreferrer"
          style={hyperLinkStyles}
        >
          <Box>Privacy Policy</Box>
        </a>
        <GiveFeedbackLink />
      </Box>
    </ContainerWrapper>
  );
};

export default Login;

const hyperLinkStyles = {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginBottom: '3px',
  fontFamily: 'Avenir',
  fontWeight: 'bold' as const,
  fontSize: '16px',
  textDecoration: 'underline',
  padding: '0px 15px',
  overflow: 'hidden',
};
