import React from 'react';
import {
  Navigate,
  Outlet
} from 'react-router-dom';
import { PublicRoutes } from '../../config/routes';
import { survivorClient } from '../../service/backend';

const PrivateRoute: React.FC = () => {
  if (!survivorClient.loggedIn) {
    return <Navigate to={PublicRoutes.LOGIN} />;
  }
  return <Outlet />;
};

export default PrivateRoute;
