import * as React from 'react';

const FingerprintIcon: React.FC = () => {
  const path1 = 'M44.1228 65.1744C45.7281 68.5944 47.7838 71.4676 49.8093 ' +
    '73.2451C51.0546 74.338 51.1782 76.2335 50.0854 77.4788C48.9925 78.7241 47.0971 78.8477 45.8517 ' +
    '77.7549C43.0394 75.2869 40.5401 71.6623 38.6914 67.7239C36.8356 63.7702 35.534 59.2852 35.2914 ' +
    '54.9849C35.0504 50.7145 35.8439 46.2945 38.5884 42.8914C41.41 39.3929 45.8585 37.5 51.7727 37.5C55.3922 ' +
    '37.5 58.3327 38.8377 60.5784 41.0794C62.7456 43.2427 64.1474 46.1222 65.0842 49.107C66.9489 55.0475 ' +
    '67.2504 62.345 67.2504 68.0805C67.2504 69.7373 65.9072 71.0805 64.2504 71.0805C62.5935 71.0805 61.2504 ' +
    '69.7373 61.2504 68.0805C61.2504 62.3899 60.9269 55.8972 59.3596 50.9039C58.5806 48.4219 57.5646 46.5487 ' +
    '56.3396 45.3259C55.1933 44.1816 53.7671 43.5 51.7727 43.5C47.1714 43.5 44.6599 44.9208 43.2588 ' +
    '46.658C41.7807 48.4907 41.0889 51.2287 41.2818 54.6469C41.4731 58.0352 42.5246 61.7696 44.1228 65.1744Z';

  const path2 = 'M23.797 45.9729C26.2269 32.3587 41.3714 19.9057 59.6404 ' +
    '27.3195C61.1756 27.9425 61.9151 29.6922 61.2921 31.2274C60.6691 32.7627 58.9195 33.5022 57.3842 ' +
    '32.8792C43.1292 27.0943 31.5573 36.6413 29.7037 47.0271C28.5572 53.4507 27.6838 61.5281 33.9214 ' +
    '70.8291C34.8443 72.2052 34.4769 74.0688 33.1008 74.9916C31.7248 75.9145 29.8611 75.5471 28.9383 ' +
    '74.171C21.4052 62.9384 22.5527 52.9443 23.797 45.9729ZM64.1432 33.8035C65.2363 32.5584 67.1318 32.4352 ' +
    '68.3769 33.5283C71.6729 36.4219 73.9428 39.6727 75.3545 43.6046C76.7452 47.4778 77.2503 51.8666 77.2503 ' +
    '56.9664C77.2503 58.6233 75.9072 59.9664 74.2503 59.9664C72.5934 59.9664 71.2503 58.6233 71.2503 56.9664C71.2503 ' +
    '52.2161 70.7718 48.5965 69.7075 45.6321C68.6642 42.7263 67.0078 40.3104 64.4185 38.0372C63.1733 36.9442 63.0501 ' +
    '35.0487 64.1432 33.8035Z';

  const path3 = 'M17.8202 35.9435C19.2321 36.8105 19.6739 38.6579 18.8069 ' +
    '40.0698C15.5674 45.3456 15.8487 53.2871 17.2258 58.7691C17.6295 60.376 16.654 62.0059 15.0471 62.4096C13.4402 ' +
    '62.8133 11.8103 61.8378 11.4066 60.2309C9.88233 54.1629 9.19662 44.2544 13.6939 36.9302C14.5609 35.5183 16.4083 ' +
    '35.0765 17.8202 35.9435Z';

  const path4 = 'M62.5837 21.2064C55.0414 17.4644 46.2569 18.0386 40.2991 ' +
    '20.2978C34.1739 22.6204 27.2695 27.5431 23.7104 32.6421C22.762 34.0007 20.8919 34.3333 19.5333 33.3849C18.1747 ' +
    '32.4366 17.8421 30.5664 18.7904 29.2078C23.1692 22.9347 31.1486 17.3507 38.1718 14.6876C45.3623 11.961 55.9244 ' +
    '11.2046 65.2504 15.8316C76.3761 21.3516 86.0947 30.9083 88.2276 48.1313C88.4313 49.7756 87.2634 51.2736 85.6191 ' +
    '51.4772C83.9748 51.6809 82.4768 50.513 82.2731 48.8687C80.4371 34.0431 72.2952 26.0248 62.5837 21.2064Z';

  const path5 = 'M47.3637 50.1674C48.652 49.1255 50.5409 49.3251 51.5829 ' +
    '50.6134C54.5697 54.3062 55.6901 58.6744 56.1018 61.9433C56.3106 63.6009 56.3455 65.0418 56.328 66.0763C56.3193 ' +
    '66.5952 56.2972 67.0164 56.2763 67.3168C56.2659 67.4671 56.2557 67.5876 56.2476 67.6753C56.2436 67.7192 56.2401 ' +
    '67.7549 56.2372 67.7821L56.2336 67.8166L56.2323 67.8289L56.2315 67.8361C56.2313 67.8371 56.2312 67.838 53.2503 ' +
    '67.5L56.2312 67.838C56.0445 69.4843 54.5586 70.6676 52.9123 70.4809C51.2686 70.2945 50.0865 68.813 50.2686 ' +
    '67.1698C50.2686 67.1691 50.2687 67.1681 50.2689 67.1669L50.2731 67.1236C50.2772 67.0791 50.2836 67.0041 50.2908 ' +
    '66.9012C50.3051 66.6952 50.3221 66.3792 50.3289 65.9748C50.3426 65.1627 50.3151 64.0127 50.1489 62.6931C49.8106 ' +
    '60.0074 48.931 56.8756 46.9178 54.3866C45.8759 53.0984 46.0755 51.2094 47.3637 50.1674Z';

  const path6 = 'M45.2503 3C45.2503 1.34314 46.5935 -4.76837e-07 48.2503 0C59.4217 ' +
    '3.57628e-06 68.1259 3.22787 75.7027 9.72223C76.9607 10.8005 77.1064 12.6944 76.0281 13.9524C74.9498 15.2104 ' +
    '3.0559 15.356 71.798 14.2778C65.3747 8.77213 58.0789 6 48.2503 6C46.5935 6 45.2503 4.65685 45.2503 3ZM41.3229 ' +
    '3.44187C41.6312 5.0698 40.5614 6.63938 38.9335 6.94762C19.34 10.6576 7.59735 28.892 5.97632 41.6288C5.76713 ' +
    '43.2724 4.26516 44.4352 2.62156 44.226C0.977966 44.0168 -0.184854 42.5148 0.0243306 40.8712C1.90329 26.1079 ' +
    '15.1606 5.34237 37.8172 1.05238C39.4451 0.744129 41.0147 1.81394 41.3229 3.44187Z';

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path1}
        fill="currentColor"
      />
      <path
        d={path2}
        fill="currentColor"
      />
      <path
        d={path3}
        fill="currentColor"
      />
      <path
        d={path4}
        fill="currentColor"
      />
      <path
        d={path5}
        fill="currentColor"
      />
      <path
        d={path6}
        fill="currentColor"
      />
    </svg>
  );
};

export default FingerprintIcon;
