import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState
} from 'react';
import {
  Flex,
  IconButton,
  Image,
  Modal,
  ModalContent,
  ModalProps,
  Text
} from '@chakra-ui/react';

import SocialInput from './SocialInput';
import Button from './Button';
import { validateSocialAccount } from '../containers/CreateWho/validation';
import CloseIcon from './icons/CloseIcon';

interface Props extends ModalProps {
  icon: string;
  description: string;
  label: string;
  placeholder: string;
  id: string;
  account: boolean;
  data: string[];
  prevValues: string[];
  update: (id: string, _identifiers: string[]) => void;
}

const SocialAccountModal: React.FC<Props> = ({
  icon,
  description,
  label,
  placeholder,
  children = '',
  id,
  account,
  data,
  prevValues,
  update,
  ...restProps
}: Props) => {
  const maxAccounts = 10;
  const [inputArray, setInputArray] = useState<string[]>([]);
  const [valid, setValid] = useState<boolean>(true);

  useEffect(() => {
    trim(data);
    if (inputArray.length === 0 || data.length === 0) {
      setInputArray(['']);
    } else {
      setInputArray(data);
    }
    setValid(true);
  }, [id, restProps.isOpen]);

  const handleSubmit = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    trim(inputArray);
    const isValid = validateSocialAccount(id, inputArray);
    if (isValid) {
      update(id, inputArray);
      restProps.onClose();
    } else {
      setValid(isValid);
    }
  };

  const handleAddClick = () => {
    trim(inputArray);
    const isValid = validateSocialAccount(id, inputArray);
    if (isValid) {
      setValid(true);
      setInputArray([...inputArray, '']);
    } else {
      setValid(false);
    }
  };

  const handleRemoveClick = (idx: number) => {
    const localInputArray = [...inputArray];
    localInputArray.splice(idx, 1);
    if (localInputArray.length === 0) {
      update(id, localInputArray);
      restProps.onClose();
    } else {
      setInputArray(localInputArray);
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const localInputArray = [...inputArray];
    localInputArray[idx] = e.currentTarget.value;
    setInputArray(localInputArray);
  };

  const handlePhoneNumberChange = (value: string | undefined, idx: number) => {
    const localInputArray = [...inputArray];
    localInputArray[idx] = value ? value : '';
    setInputArray(localInputArray);
  };

  const trim = (array: string[]) => {
    for(let i = 0; i < array.length; i++){
      array[i] = array[i].trim();
    }
  };

  return (
    <Modal isCentered {...restProps}>
      {children}
      <ModalContent maxWidth="534px" margin="15px" p="40px">
        <Flex
          as="form"
          mt="-120px"
          flexDir="column"
          justifyContent="flex-start"
          alignItems="center"
          onSubmit={handleSubmit}
        >
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            width="140px"
            height="140px"
            bgColor="brand.white"
            boxShadow="0px 8px 20px rgb(0 0 0 / 5%)"
            borderRadius="0.5em"
          >
            <Image src={icon} alt="Social Icon" maxWidth="50px" />
            <Text mt="20px" fontWeight="700" color="brand.primary">
              {label}
            </Text>
          </Flex>
          <IconButton
            aria-label="Close modal"
            icon={<CloseIcon />}
            minWidth="auto"
            width="25px"
            height="25px"
            backgroundColor="transparent !important"
            position="absolute"
            right="25px"
            top="25px"
            onClick={restProps.onClose}
          />
          <Text as="h2" fontSize="sm2" m="30px 0px 15px 0px" fontWeight="800" color="brand.primary">
            What&apos;s their {description}?
          </Text>
          { account && (
            <Text mb="40px" color="brand.primary">
            Add up to {maxAccounts} {label} accounts for this offender.
            </Text>
          )}
          { !account && (
            <Text mb="40px" color="brand.primary">
              Add up to {maxAccounts} {description}s for this offender.
              { id === 'phone' && (
                <span>
                  &nbsp;We recommend only entering personal phone numbers for the offender, such as cell or home phone.
                  It is best not to use office or shared phone numbers.
                </span>
              )}
            </Text>
          )}

          {inputArray.map((str, idx) => (
            <SocialInput
              key={idx}
              placeholder={placeholder}
              value={str}
              idx={idx}
              onChange={(e) => handleInputChange(e, idx)}
              onPhoneNumberChange={handlePhoneNumberChange}
              onClose={handleRemoveClick}
              mb="5px"
              isDisabled={prevValues.length > idx}
              isRequired
              isPhoneNumber={id === 'phone'}
            />
          ))}
          {!valid && (
            <Text as="p" color="brand.red">
              Please enter a valid {description}
            </Text>
          )}
          <Flex
            flexDir={['column-reverse', 'column-reverse', 'row']}
            justifyContent="space-between"
            width="100%"
            mt="35px"
          >
            <Button
              buttonColor="brand.white"
              color="brand.primary"
              borderRadius="10px"
              borderColor="brand.primary"
              border="1px solid"
              mt={['15px', '15px', '0px']}
              minWidth="auto"
              type="submit"
            >
              I&apos;m done adding
            </Button>
            {inputArray.length < maxAccounts && <Button
              buttonColor="brand.primary"
              borderRadius="10px"
              p="15px 15px"
              onClick={handleAddClick}
            >
              Enter another {description}
            </Button>}
            {inputArray.length >= maxAccounts && <Button
              buttonColor="#D1E7E7"
              borderRadius="10px"
              p="15px 15px"
            >
              Max entries reached
            </Button>}
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default SocialAccountModal;
