import React from 'react';
import { Box, Container, ContainerProps } from '@chakra-ui/react';

const ContainerWrapper: React.FC<ContainerProps> = ({
  children,
  ...props
}: ContainerProps) => (
  <Box height="100%" backgroundColor="transparent">
    <Container maxW="1280px" height="100%" {...props}>
      {children}
    </Container>
  </Box>
);

export default ContainerWrapper;
