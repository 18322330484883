import React, { ChangeEvent } from 'react';
import {
  MultiselectInput,
  MultiselectOption,
  NumberInput,
  RadioInput,
  RadioOption,
  SubMultiselectInput,
  SubRadioInput,
  TextAreaInput,
  TextInput
} from './FormElements';
import {
  getMultiselectValue,
  getRadioValue,
  getTextValue,
  resultContainsKey
} from '../../../../../../lib/questionnaire/formFunctions';
import { resultType } from '../../../../../../lib/data';
import {
  updateMultiselectValue,
  updateRadioValue,
  updateTextValue
} from './FormFunctions';
import { IncidentLogQuestionFieldData } from '../../containers/IncidentLog/incidentLogQuestions';

export const getElement = (
  data: IncidentLogQuestionFieldData,
  path: string[],
  results: Map<string, resultType>,
  setResults: (newResults: Map<string, resultType>) => void,
  disabled: boolean = false,
  pdf: boolean = false
): React.JSX.Element => {
  switch (data.type) {
    case 'text':
      return (
        <TextInput
          path={path}
          key={data.key}
          qKey={data.key}
          label={data.label}
          value={getTextValue(path, results)}
          onChange={(e) => { updateTextValue(data, path, e, results, setResults); }}
          insertBreak={data.layout?.insertBreak}
          className={`${data.layout?.className}${pdf ? ' pdf' : ''}`}
          disabled={!pdf && (disabled || (path.length !== 1 && !resultContainsKey(path, path[path.length - 1], results)))}
          required={data.required}
          results={results}
          setResults={setResults}
          newQuestion={data.newQuestion}
        />
      );
    case 'textWithOptions':
      return (
        <TextInput
          path={path}
          key={data.key}
          qKey={data.key}
          label={data.label}
          value={getTextValue(path, results)}
          onChange={(e) => { updateTextValue(data, path, e, results, setResults); }}
          insertBreak={data.layout?.insertBreak}
          className={`${data.layout?.className}${pdf ? ' pdf' : ''}`}
          disabled={!pdf && (disabled || !resultContainsKey(path, path[path.length - 1], results))}
          options={data.options}
          getElement={getElement}
          results={results}
          setResults={setResults}
          name={data.key}
          required={data.required}
          newQuestion={data.newQuestion}
        />
      );
    case 'textarea':
      return (
        <TextAreaInput
          path={path}
          key={data.key}
          qKey={data.key}
          label={data.label}
          additionalInfo={data.additionalInfo}
          value={getTextValue(path, results)}
          onChange={(e) => { updateTextValue(data, path, e, results, setResults); }}
          insertBreak={data.layout?.insertBreak}
          className={`${data.layout?.className}${pdf ? ' pdf' : ''}`}
          disabled={!pdf && (disabled || (path.length !== 1 && !resultContainsKey(path, path[path.length - 1], results)))}
          required={data.required}
          results={results}
          setResults={setResults}
          newQuestion={data.newQuestion}
        />
      );
    case 'number':
      return (
        <NumberInput
          path={path}
          key={data.key}
          qKey={data.key}
          label={data.label}
          value=""
          onChange={(e) => { updateTextValue(data, path, e, results, setResults); }}
          insertBreak={data.layout?.insertBreak}
          className={`${data.layout?.className}${pdf ? ' pdf' : ''}`}
          disabled={!pdf && disabled}
          required={data.required}
          results={results}
          setResults={setResults}
        />
      );
    case 'radio':
      return (
        <RadioInput
          path={path}
          key={data.key}
          qKey={data.key}
          label={data.label}
          value={getRadioValue(path, results) ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateRadioValue(data.key, path, e, results, setResults);
          }}
          options={data.options}
          getElement={getElement}
          setResults={setResults}
          results={results}
          className={`${data.layout?.className}${pdf ? ' pdf' : ''}`}
          disabled={!pdf && disabled}
          name={data.key}
          required={data.required}
          newQuestion={data.newQuestion}
          pdf={pdf}
        />
      );
    case 'subradio':
      return (
        <SubRadioInput
          qKey={data.key}
          path={path}
          label={data.label}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateRadioValue(data.key, path, e, results, setResults, false, data.value as string); }}
          value={getRadioValue(path, results) ?? ''}
          resultContainsKey={resultContainsKey(path, data.key, results)}
          name={data.name}
          required={data.required}
          results={results}
          setResults={setResults}
        />
      );
    case 'radioOption':
    case 'textRadioOption':
      return (
        <RadioOption
          key={data.key}
          okey={data.key}
          value={data.value ?? ''}
          label={data.label}
          checked={getRadioValue(path, results) === data.value}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateRadioValue(data.key, path, e, results, setResults, data.type === 'textRadioOption');
          }}
          className={`${data.layout?.className}${pdf ? ' pdf' : ''}`}
          disabled={!pdf && !resultContainsKey(path, path[path.length - 2], results)}
          name={data.name}
          required={data.required && resultContainsKey(path, path[path.length - 2], results)}
        />
      );
    case 'multiselect':
      return (
        <MultiselectInput
          key={data.key}
          path={path}
          qKey={data.key}
          label={data.label}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateMultiselectValue(path, e, results, setResults);
          }}
          value={getMultiselectValue(path, results)}
          options={data.options}
          getElement={getElement}
          results={results}
          setResults={setResults}
          className={`${data.layout?.className}${pdf ? ' pdf' : ''}`}
          disabled={!pdf && disabled}
          newQuestion={data.newQuestion}
          pdf={pdf}
        />
      );
    case 'submultiselect':
      return (
        <SubMultiselectInput
          qKey={data.key}
          path={path}
          label={data.label}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateMultiselectValue(path, e, results, setResults, false, data.exclusiveKeys);
          }}
          value={data.value as string}
          resultContainsKey={resultContainsKey(path, data.key, results)}
          results={results}
          setResults={setResults}
        />
      );
    case 'multiselectOption':
    case 'textMultiselectOption':
      return (
        <MultiselectOption
          key={data.key}
          okey={data.key}
          value={data.value ?? ''}
          label={data.label}
          checked={getMultiselectValue(path, results).includes(data.value as string)}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            updateMultiselectValue(path, e, results, setResults, data.type === 'textMultiselectOption', data.exclusiveKeys); }}
          className={`${data.layout?.className}${pdf ? ' pdf' : ''}`}
          disabled={!pdf && !resultContainsKey(path, path[path.length - 2], results, data.type === 'textMultiselectOption')}
        />
      );
    default:
      return (
        <div key={data.key}>No such element</div>
      );
  }
};
