import React from 'react';
import { Text, TextProps } from '@chakra-ui/layout';

const ReviewSubValue: React.FC<TextProps> = ({ children, ...restProps }) => (
  <Text as="p" mt="14px" fontSize={['14px', '14px', '16px']} {...restProps}>
    {children}
  </Text>
);

export default ReviewSubValue;
