import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Paragraph } from '../../components/Paragraph';
import CheckboxRadio from '../../components/CheckboxRadio';
import Checkbox from '../../components/CheckBox';
import WhiteButton from '../../components/layout/WhiteButton';
import Button from '../../components/Button';
import {
  IncidentLogPdfContext,
  possibleReasons
} from './IncidentLogPdfContext';

interface ReasonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const IncidentLogPdfReasonModal: React.FC<ReasonModalProps> = ({ isOpen, onClose, onSubmit }: ReasonModalProps) => {
  const { state, setReason } = useContext(IncidentLogPdfContext);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="800px" color="brand.primary">
        <ModalCloseButton />
        <Paragraph props={{ fontWeight: 'bold', fontSize: 'sm', padding: '15px' }}>
          Please select the reason for your download.
        </Paragraph>
        <Box padding="15px">
          <CheckboxRadio
            value={state.reason}
            setValue={(v) => setReason(v as possibleReasons | undefined)}
          >
            <Checkbox value="record preservation">
              {`I want to create a PDF and export this Incident Log for the purpose of record preservation.
              I understand this document will be discoverable which means I may have to provide a copy of it in
              a court proceeding if ordered by a subpoena or court order.`}
            </Checkbox>
            <Checkbox value="share with attorney" paddingTop="30px">
              {`I want to create a PDF and export this Incident Log for the purpose of sharing it with an attorney
              to seek and receive legal advice. I want this Incident Log to be confidential and protected by
              attorney client privilege and so I will not share it with anyone except my attorney.`}
            </Checkbox>
          </CheckboxRadio>
        </Box>
        <Box textAlign="center" mt="20px" pt="20px">
          <WhiteButton mb="5px" mx="10px" px="20px" minWidth="auto" onClick={onClose}>
            Cancel
          </WhiteButton>
          <Button
            mb="5px"
            buttonColor="brand.primary"
            isDisabled={!state.reason}
            onClick={onSubmit}>Download PDF</Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default IncidentLogPdfReasonModal;
