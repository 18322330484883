import React, { useState, createContext } from 'react';
import { survivorClient } from '../service/backend';

interface IState {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
}

interface IProps {
  children?: React.ReactNode | React.ReactNode[];
}

const initialState: IState = {
  loggedIn: survivorClient.loggedIn,
  setLoggedIn: () => null,
};

export const AppContext = createContext<IState>(initialState);

export const AppContextProvider: React.FC = ({ children }: IProps) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(survivorClient.loggedIn);

  return (
    <AppContext.Provider value={{ loggedIn, setLoggedIn }}>
      {children}
    </AppContext.Provider>
  );
};
