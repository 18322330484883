import { ActionMap, Step } from '../type';

export const STEP_WHERE_TEXT = 'Where did it happen?';

export enum StepWhereActionTypes {
  SET_SELECTED_STATE = 'SET_SELECTED_STATE',
}

type StepWherePayload = {
  [StepWhereActionTypes.SET_SELECTED_STATE]: string;
};

export type StepWhereActions = ActionMap<StepWherePayload>[keyof ActionMap<StepWherePayload>];

export interface StepWhereState extends Step {
  selectedState: string;
  changesMade: boolean;
}

const stepWhereReducer = (state: StepWhereState, action: StepWhereActions): StepWhereState => {
  switch (action.type) {
    case StepWhereActionTypes.SET_SELECTED_STATE:
      return {
        ...state,
        completed: true,
        changesMade: (action.payload !== state.selectedState),
        selectedState: action.payload,
      };
    default:
      return state;
  }
};

export default stepWhereReducer;
