import React, {
  FormEvent,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  Link,
  useNavigate
} from 'react-router-dom';
import {
  Box,
  Image,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react';

import HeaderText from '../../components/HeaderText';
import SubHeader from '../../components/SubHeader';
import { PrivateRoutes } from '../../config/routes';
import {
  EntryContext,
  EntryGlobalActionTypes
} from '../../contexts/EntryContext';
import { StepWhoActionTypes } from '../../contexts/EntryContext/stepWho';
import { checkIdentifiersEmpty } from './validation';
import Button from '../../components/Button';
import SocialCategories from '../../components/SocialCategories';
import MainCard from '../../components/MainCard';
import { survivorClient } from '../../service/backend';

import QuestionMark from '../../../assets/icons/question-mark.svg';
import WhiteButton from '../../components/layout/WhiteButton';
import { entryData } from '../../../../../../lib/data';
import { usStates } from '../CreateWhere/constant';

const SelectSocialMedia: React.FC = () => {
  const {
    state: {
      steps: {
        stepWho: { identifiers, nextStepRoute },
        stepWhere
      },
      editEntryID
    },
    dispatch,
  } = useContext(EntryContext);
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState<boolean>(false);
  const toast = useToast();

  useEffect(() => () => {
    if (checkIdentifiersEmpty(identifiers)) {
      submitAction();
    } else {
      dispatch({
        type: StepWhoActionTypes.MARK_AS_IN_COMPLETED,
      });
    }
  }, [isValid]);

  useEffect(() => {
    setIsValid(checkIdentifiersEmpty(identifiers));
    if (!checkIdentifiersEmpty(identifiers)) {
      dispatch({
        type: StepWhoActionTypes.MARK_AS_IN_COMPLETED,
      });
    }
  }, [identifiers]);

  const onSubmit = async (e: FormEvent<any>) => {
    e.preventDefault();
    await handleSaveDraftEntry(e);
    navigate(PrivateRoutes.MATCHING_SYSTEM);
  };

  const handleSaveDraftEntry = async (e: FormEvent<any>) => {
    e.preventDefault();
    const draftEntry: entryData = {
      id: editEntryID ?? undefined,
      userID: survivorClient.userID,
      incidentState: usStates[stepWhere.selectedState],
      perpIDs: identifiers
    };

    const draftId = await survivorClient.saveDraftEntry(draftEntry);
    dispatch({
      type: EntryGlobalActionTypes.SET_EDIT_ENTRY_ID,
      payload: draftId,
    });

    toast({
      title: 'Your entry was saved as a draft.',
      status: 'success',
      position: 'top',
    });
  };

  const handleClickNext = (e: FormEvent<any>) => {
    e.preventDefault();

    submitAction();
    navigate(nextStepRoute);
  };

  const submitAction = () => {
    dispatch({
      type: StepWhoActionTypes.MARK_AS_COMPLETED,
    });
  };

  const sendEvent = () => {
    survivorClient.submitEvent('Click don\'t know identifiers', {}).catch(() => {});
  };

  const footer = (
    <>
      {!isValid && (
        <span>
          <Text color='brand.link' textDecor='underline' fontWeight='600'>
            <Link to={PrivateRoutes.CREATE_WHO_SOCIAL} onClick={sendEvent}>
              I don&apos;t know my offender&apos;s contact or social media information
            </Link>
            <Tooltip width='100%' label={<TooltipText />} placement='top' bg='brand.primary' color='brand.lightBackground'>
              <Image
                src={QuestionMark as string}
                maxWidth='30px'
                height='20px'
                display='inline-block'
                ml='1ex'
              />
            </Tooltip>
          </Text>
        </span>
      )}
      {isValid && (
        <Button
          buttonColor="brand.primary"
          width="100%"
          maxWidth="420px"
          mt="15px"
          type="button"
          onClick={handleClickNext}
        >
          I am done adding identifiers
        </Button>
      )}
      <WhiteButton
        width="100%"
        margin="20px 20px 20px 0px"
        type="button"
        onClick={handleSaveDraftEntry}
      >Save My Entry as a Draft</WhiteButton>
    </>
  );

  return (
    <Box as="form" onSubmit={onSubmit} id="entry_form">
      <MainCard>
        <HeaderText>Enter the offender&apos;s unique identifier(s)</HeaderText>
        <SubHeader>
          This is often the most difficult part. Take a deep breath.
          Looking up the perpetrator on social media can be triggering.
          If needed, get the help of a trusted support person.
        </SubHeader>
        <SubHeader>
          Please only enter identifiers for one perpetrator. If there is
          more than one perpetrator, please create a different Matching
          Entry for each.
        </SubHeader>
        <SubHeader>
          If you know the offender’s social media or contact information, select
          from the options below. The more personal identifiers you add, the
          better chance you have to match. After looking up the offender, you
          may wish to block them from all of your socials.
        </SubHeader>
        <SubHeader>
          IMPORTANT NOTE: If you access LinkedIn, the offender may see that you
          have looked at their profile. Proceed with caution and consider
          blocking the account.
        </SubHeader>
        <SocialCategories />
        <Box display={['none', 'none', 'block']}>{footer}</Box>
      </MainCard>
      <Box display={['block', 'block', 'none']} mt="30px" textAlign="center">
        {footer}
      </Box>
    </Box>
  );
};

const TooltipText = () => (
  <span>
    <p>If you find out that your offender changes their handle in the future, you
      can always log back into your account and add new identifiers.</p>
    <br />
    <p>If you don&apos;t know the offender&apos;s name or unique identifiers,
      you may be able to find it by accessing the following:</p>
    <br />
    <ul style={{ marginLeft: '1em' }}>
      <li>Campus directory</li>
      <li>Yearbooks</li>
      <li>Classroom rosters</li>
      <li>Sports rosters (academic or community-based)</li>
      <li>Student organization rosters</li>
      <li>Work-study rosters</li>
      <li>Facebook event invites</li>
      <li>Facebook group member lists</li>
    </ul>
  </span>
);

export default SelectSocialMedia;
