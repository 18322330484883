import React, {
  useEffect,
  useState
} from 'react';
import {
  IncidentLogQuestionFieldData,
  incidentLogSections,
  IncidentLogStep,
  POSTSTEPS,
  PRESTEPS
} from './incidentLogQuestions';
import { resultType } from '../../../../../../lib/data';
import {
  FieldData,
  getMultiselectValue,
  getRadioValue,
  getTextValue
} from '../../../../../../lib/questionnaire/formFunctions';
import HeaderText from '../../components/HeaderText';
import { getElement } from '../../components/forms/GetElement';
import { Box } from '@chakra-ui/react';

interface IncidentLogPageContentProps {
  stepNumber?: number;
  stepName?: string;
  step: IncidentLogStep;
  results: Map<string, resultType> | null;
  setResults: (results: Map<string, resultType>, initial: boolean) => void;
  textWidth?: string;
  questions?: IncidentLogQuestionFieldData[];
  pdf?: boolean;
  updated?: Date;
}

export const IncidentLogPageContent: React.FC<IncidentLogPageContentProps> = ({
  stepNumber,
  stepName,
  step,
  results,
  setResults,
  textWidth,
  questions,
  pdf = false,
  updated
}) => {
  const [questionData, setQuestionData] = useState<IncidentLogQuestionFieldData[]>([]);

  const initialState: Map<string, resultType> = new Map<string, resultType>();

  useEffect(() => {
    setQuestionData(step.questions);

    results?.forEach((value, key) => {
      initialState.set(key, value);
    });

    return () => {
      for (const question of questionData) {
        if (!results) {
          results = new Map<string, resultType>();
        }

        let response: string | string[] | undefined;
        if (question.type === 'multiselect') {
          response = getMultiselectValue([question.key], results);
          if (response.length > 0) {
            response = response[0];
          } else {
            response = undefined;
          }
        } else if (question.type === 'radio') {
          response = getRadioValue([question.key], results);
        } else {
          response = getTextValue([question.key], results);
        }
        if (response) {
          step.completed = true;
          return;
        }
      }

      step.completed = false;
    };
  });

  useEffect(() => {
    if (questions) {
      for (const qData of questions) {
        addToInitialState(qData);
      }
    } else {
      for (const qData of questionData) {
        addToInitialState(qData);
      }
    }

    setResults(initialState, true);
  }, [questionData]);

  const setResultsAndSectionUpdated = (responses: Map<string, resultType>) => {
    setResults(responses, false);
  };

  const addToInitialState = (qData: FieldData) => {
    if (!initialState.get(qData.key)) {
      if (qData.type === 'multiselect') {
        initialState.set(qData.key, {
          key: qData.key,
          type: 'question',
          label: qData.label,
          value: []
        });
      } else {
        initialState.set(qData.key, {
          key: qData.key,
          type: 'question',
          label: qData.label,
          value: ''
        });
      }
    }
  };

  return (
    <div style={{ width: textWidth }}>
      <HeaderText>{step.text}</HeaderText>
      {stepNumber && <p className="step">(Step {stepNumber} of&nbsp;
        {Object.keys(incidentLogSections).length - [...PRESTEPS, ...POSTSTEPS].length})</p>}
      {pdf && stepName && !PRESTEPS.includes(stepName) && !POSTSTEPS.includes(stepName) && (
        <p>Section last updated (UTC/GMT): {updated ? updated.toISOString() : 'Never'}</p>
      )}
      { step.intro && (
        <Box mb="1em" dangerouslySetInnerHTML={{__html: step.intro}} />
      )}
      { results && questions && questions.map((field) =>
        <div id={`${field.key}Div`} key={field.key}>
          {getElement(field, [field.key], results ?? new Map<string, resultType>(), setResultsAndSectionUpdated, false, pdf)}
        </div>
      )}
      { results && !questions && questionData.map((field) =>
        <div id={`${field.key}Div`} key={field.key}>
          {getElement(field, [field.key], results ?? new Map<string, resultType>(), setResultsAndSectionUpdated)}
        </div>)}
    </div>
  );
};
