import React from 'react';
import { Text, TextProps } from '@chakra-ui/layout';

const ReviewSubHeader: React.FC<TextProps> = ({
  children,
  ...restProps
}: TextProps) => (
  <Text as="h2" fontSize={['18px', '18px', '1.5em']} mt="20px" {...restProps}>
    <b>{children}</b>
  </Text>
);

export default ReviewSubHeader;
