import * as React from 'react';
import { Fragment, useCallback, useEffect } from 'react';
import { survivorClient } from '../service/backend';
import { Tooltip, Button } from '@chakra-ui/react';

const BUTTON_COLOR = '#3C828B';

export const EmergencyExitButton: React.FC = ({ children }) => {
  const escape = async () => {
    // Don't display a prompt for unsaved datea
    window.onbeforeunload = () => {};
    // Remove auth key so the back button doesn't log user back in
    window.sessionStorage.removeItem('__callisto_auth');
    await survivorClient.logout();
    window.open('https://weather.com', '_blank');
    window.open('https://www.google.com', '_self');
  };

  const escapeKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      void escape();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escapeKeyPress, false);
    return () => {
      document.removeEventListener('keydown', escapeKeyPress, false);
    };
  }, [escapeKeyPress]);

  return (
    <Fragment>
      <Tooltip
        width='100%'
        label='Sometimes for privacy, you  may need to exit the site quickly.
        Clicking this button or hitting esc will open a new tab on weather.com.
        You will be logged out and all of your unsubmitted data will be lost.
        Remember to clear your history if needed!'
        placement='bottom'
        bg='brand.primary'
        color='brand.lightBackground'
        hasArrow
      >
        <span className={'emergencyExit'}>
          <Button
            backgroundColor={BUTTON_COLOR}
            color="brand.white"
            _hover={{
              background: BUTTON_COLOR,
            }}
            _active={{
              background: BUTTON_COLOR,
            }}
            height="auto"
            fontSize={{base: 'xxs', md: 'sm'}}
            minWidth="100px"
            borderRadius="100px"
            fontFamily="AvenirBold"
            onClick={escape}
          >
            Quick Exit
          </Button>
        </span>
      </Tooltip>
      {children}
    </Fragment>
  );
};
