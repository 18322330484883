import React, {
  useContext,
  useState
} from 'react';
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import ContainerWrapper from '../../components/ContainerWrapper';
import {
  Box,
  Flex,
  Text,
  useToast
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../contexts/AppContext';
import { useNotification } from '../../components/Notification/useNotification';
import { useLogin } from '../../service/hooks/auth';
import { survivorClient } from '../../service/backend';
import { PrivateRoutes } from '../../config/routes';
import IconInput from '../../components/IconInput';
import UserIcon from '../../../assets/icons/user.svg';
import LockIcon from '../../../assets/icons/lock_new.svg';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import GiveFeedbackLink from '../../components/GiveFeedbackLink';

interface LoginProps {
  username: string;
  password: string;
}

export const VerifyRecoveryEmail: React.FC = () => {
  const { state: { encryptedData, legacySetup } }: {
    state: {
      encryptedData: Uint8Array;
      legacySetup: boolean;
    };
  } = useLocation() as unknown as {
    state: { encryptedData: Uint8Array; legacySetup: boolean };
  };
  const toast = useToast();
  const { setLoggedIn } = useContext(AppContext);
  const { register, handleSubmit } = useForm<LoginProps>();
  const { notification, setNotification } = useNotification();
  const navigate = useNavigate();
  const [signRequesting, setSignRequesting] = useState<boolean>(false);
  const { mutateAsync: requestLogin } = useLogin();

  const onSubmit = async ({ username, password }: LoginProps) => {
    setSignRequesting(true);
    try {
      try {
        await requestLogin({ username, password });
        setLoggedIn(true);
      } catch (e) {
        try {
          await survivorClient.submitEvent('Verify account recovery email', {
            error: (e as Error).message
          });
        } catch {
          // Swallow this error
        }
        setNotification({
          type: 'error',
          message:
            'The username or password you entered is incorrect. Please try again.'
        });
        return;
      }

      try {
        const { email, phone } = survivorClient.decryptProfileData(encryptedData);
        if (legacySetup) {
          navigate(PrivateRoutes.RECOVERY_SETUP, { state: { email } });
        } else {
          await survivorClient.resetAccountRecovery(email, phone);

          toast({
            title: 'Recovery email account verified. Your profile information has been updated.',
            isClosable: true,
            position: 'top',
            status: 'success'
          });
          navigate(PrivateRoutes.ACCOUNT);
        }
      } catch (e) {
        try {
          await survivorClient.submitEvent('Verify account recovery email', {
            error: (e as Error).message
          });
        } catch {
          // Swallow this error
        }
        setNotification({
          type: 'error',
          message:
            'There was an error verifying your email account. Please try again.'
        });
      }
    } finally {
      setSignRequesting(false);
    }
  };

  return (
    <ContainerWrapper>
      <Flex alignItems="center" justifyContent="center" margin="0 auto" padding="0 0 25px 0" flexDir="column">
        <Box maxWidth="850px" width="100%">
          <Notification notification={notification} />
          <Box
            width="100%"
            backgroundColor="brand.white"
            padding={['20px 20px', '40px 20px', '60px 120px']}
            as="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Flex
              maxWidth="650px"
              flexDir="column"
              textAlign="left"
              justifyContent="center"
              alignItems="center"
              height="100%"
              margin="0 auto"
            >
              <Text as="h1" fontSize="xl">
                Verify Recovery Email Address
              </Text>
              <Text mt="16px">
                To verify your recovery email address, please enter your username and password below.
              </Text>
              <br />
              <IconInput
                inputGroupProps={{ mb: '30px' }}
                icon={UserIcon as string}
                placeholder="Enter your username"
                {...register('username')}
                isRequired
              />
              <IconInput
                placeholder="Enter your password"
                inputGroupProps={{
                  mb: '45px',
                }}
                icon={LockIcon as string}
                {...register('password')}
                type="password"
                isRequired
              />
              <Button
                type="submit"
                buttonColor="brand.primary"
                isLoading={signRequesting}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Box>
        <GiveFeedbackLink />
      </Flex>
    </ContainerWrapper>
  );
};
