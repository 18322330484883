import {
  Box,
  Text,
  useToast
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ContainerWrapper from '../../components/ContainerWrapper';
import { survivorClient } from '../../service/backend';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';
import * as emailValidator from 'email-validator';
import { useNavigate } from 'react-router-dom';

interface FeedbackProps {
  emailAddress?: string;
  message: string;
}

const Feedback: React.FC = () => {
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm<FeedbackProps>();
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async ({ emailAddress, message }: FeedbackProps) => {
    try {
      await survivorClient.sendFeedback(message, emailAddress);
      reset();
      toast({
        title: 'Your feedback has been submitted. Thank you!',
        status: 'success',
        isClosable: true,
        position: 'top'
      });
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    } catch (error) {
      toast({
        title: (error as Error).message,
        status: 'error',
        isClosable: true,
        position: 'top'
      });
    }
  };

  return <ContainerWrapper
    textAlign='center'
    display='flex'
    justifyContent='center'
    alignItems='center'
  >
    <Box maxWidth="850px" width='100%'>
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        width="100%"
        backgroundColor="brand.white"
        padding="15px"
      >
        <Text fontSize="xl" as="h1">Leave Feedback/Report an Issue</Text>
        <Text textAlign="left" fontWeight="bold">Email address (optional) </Text>
        <Text textAlign="left" fontSize="xs">By providing your email address, you are consenting to be contacted by Callisto
        technical staff for the purposes of obtaining more information concerning your feedback or issue. Your email address
        provided here will be used for no other purpose and is not stored in our database.</Text>
        <Input
          type="email"
          placeholder="Enter your email address (optional)"
          {...register('emailAddress', {
            validate: () => {
              const isValid = !getValues().emailAddress || emailValidator.validate(getValues().emailAddress ?? '');
              setValidEmail(isValid);
              return isValid;
            }
          })}
          width="75%"
          minWidth="100px"
        />

        <Text textAlign="left" fontWeight="bold" mt="15px">Message (required)</Text>
        <Textarea
          placeholder="Please provide your feedback or report an issue by entering it here"
          {...register('message', {
            required: { value: true, message: 'Please enter a message for our technical staff before submitting'}
          })}
          width="75%"
          minWidth="100px"
          height="400px"
        />

        {!validEmail &&
          <Text color="red" mt="5px" mb="5px">
            Please enter a valid email address or leave it blank
          </Text>
        }

        <Text color="red" mt="5px" mb="5px">
          {errors?.message?.message}
        </Text>

        <Box>
          <Button type="submit"
            buttonColor="brand.primary"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  </ContainerWrapper>;
};

export default Feedback;
