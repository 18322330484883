import React, {
  ChangeEvent,
  useContext
} from 'react';
import { IWhenFormProps } from './index';
import HeaderText from '../../components/HeaderText';
import SubHeader from '../../components/SubHeader';
import CheckboxRadio from '../../components/CheckboxRadio';
import Checkbox from '../../components/CheckBox';
import {
  MONTH_VALUES,
  OrfContext,
  TIME_CONSTANTS
} from '../../contexts/OrfContext';
import LineInput from '../../components/LineInput';
import Textarea from '../../components/Textarea';
import {
  Flex,
  HStack,
  Text,
  VStack
} from '@chakra-ui/layout';
import {
  Box,
  Input,
  Select
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { PdfTextarea } from '../../components/PdfTextarea';
import { OrfQuestion } from '../../components/OrfQuestion';
import { onlineCheckboxProps } from '../Orfs';
import { UseFormRegister } from 'react-hook-form';

import '../OrfPdf/styles.scss';

type valueName = 'memory' | 'season' | 'hasExactDate' | 'time' | 'exactTime' | 'year' | 'month' | 'day';

const NUM_OF_DAYS = 31;

interface WhenPageContentProps {
  values: IWhenFormProps;
  pdf?: boolean;
  setValue?: (name: valueName,
    value: string | Date | null | undefined,
    config?: (Partial<{shouldValidate: boolean; shouldDirty: boolean}> | undefined)) => void;
  register: UseFormRegister<IWhenFormProps>;
  textWidth?: string;
}

export const OrfWhenPageContent: React.FC<WhenPageContentProps> =
  ({
    values,
    setValue = () => {},
    register,
    pdf = false,
    textWidth
  }: WhenPageContentProps) => {
    const { setModified } = useContext(OrfContext);
    const checkboxProps = onlineCheckboxProps;
    const { onChange: onChangeExactTime } = register('exactTime');
    const { onChange: onChangeMemory } = register('memory');


    return (
      <div style={{ width: textWidth }}>
        <HeaderText pdf={pdf}>When</HeaderText>
        <SubHeader mb="5px" mt="30px" pdf={pdf}>
          <OrfQuestion pdf={pdf}>Do you know any details about the date?</OrfQuestion>
        </SubHeader>
        <CheckboxRadio
          value={values.hasExactDate}
          setValue={(v) => setValue('hasExactDate', v)}
        >
          <VStack alignItems="flex-start">
            {pdf && (
              <Box as="div" fontSize="sm">
                <label><input
                  type="checkbox"
                  checked={values.hasExactDate === 'Yes'}
                  readOnly={true}
                  className="pdf"
                /> Yes, it was</label>
              </Box>
            )}
            {!pdf && (
              <Checkbox value="Yes" {...checkboxProps}
                onChange={setModified}
                marginBottom={ pdf && values.memory ? '10px' : ''}
              >
                Yes, it was
              </Checkbox>
            )}
            <HStack marginLeft="10px" paddingTop="2em" alignItems="flex-start">
              <VStack alignItems="flex-start">
                <Text as="label">Month</Text>
                {!pdf && <Select
                  bgColor="brand.white"
                  fontSize="sm"
                  {...register('month')}
                  minWidth="100px"
                  disabled={values.hasExactDate !== 'Yes'}
                  onChange={setModified}
                >
                  <option value="" />
                  {MONTH_VALUES.map((month) => (
                    <option value={month.value} key={month.key}>
                      {month.key}
                    </option>
                  ))}
                </Select>
                }
                {pdf &&
                  <PdfTextarea
                    maxHeight="3em"
                    minHeight="2em"
                    minWidth="12em"
                    border="1px"
                  >{values.month ? MONTH_VALUES.find(
                      (v) => v.value.toString() === values.month
                    )?.key : ''}
                  </PdfTextarea>
                }
              </VStack>
              <VStack alignItems="flex-start">
                <Text as="label">Day</Text>
                {!pdf &&
                  <Select
                    bgColor="brand.white"
                    fontSize="sm"
                    {...register('day')}
                    disabled={values.hasExactDate !== 'Yes'}
                    minWidth="100px"
                    onChange={setModified}
                  >
                    <option value="" />
                    {Array(NUM_OF_DAYS)
                      .fill(0)
                      .map((_day, idx) => (
                        <option value={idx + 1} key={idx}>
                          {idx + 1}
                        </option>
                      ))}
                  </Select>
                }
                {pdf &&
                  <PdfTextarea
                    maxHeight="3em"
                    minHeight="2em"
                    paddingTop="0"
                    minWidth="2em"
                    border="1px"
                  >
                    {values.day}
                  </PdfTextarea>
                }
              </VStack>
              <VStack alignItems="flex-start">
                <Text as="label">Year</Text>
                {!pdf &&
                  <DatePicker
                    selected={values.year}
                    onChange={(date: Date) => {
                      setValue('year', date);
                      setModified();
                    }}
                    showYearPicker
                    dateFormat="yyyy"
                    maxDate={new Date()}
                    disabled={values.hasExactDate !== 'Yes'}
                  />
                }
                {pdf &&
                  <PdfTextarea
                    maxHeight="3em"
                    minHeight="2em"
                    paddingTop="0"
                    minWidth="4em"
                    border="1px"
                  >
                    {values.year?.getFullYear()}
                  </PdfTextarea>
                }
              </VStack>
            </HStack>
          </VStack>
          {!pdf && (
            <Checkbox value="No"
              onChange={setModified}
              {...checkboxProps}>
              I&apos;m not sure
            </Checkbox>
          )}
          {pdf && (
            <Box as="div" fontSize="sm">
              <label><input
                type="checkbox"
                checked={values.hasExactDate === 'No'}
                readOnly={true}
                className="pdf"
              /> I&apos;m not sure</label>
            </Box>
          )}
        </CheckboxRadio>

        <SubHeader mb="5px" mt={pdf ? '15px' : '30px'} pdf={pdf}>
          <OrfQuestion pdf={pdf}>What season was it?</OrfQuestion>
        </SubHeader>
        <CheckboxRadio
          value={values.season}
          setValue={(v) => setValue('season', v)}
        >
          <VStack alignItems="flex-start">
            {!pdf && (
              <>
                <Checkbox value="spring"
                  onChange={setModified}
                  {...checkboxProps}>
                  Spring
                </Checkbox>
                <Checkbox value="summer"
                  onChange={setModified}
                  {...checkboxProps}>
                  Summer
                </Checkbox>
                <Checkbox value="fall"
                  onChange={setModified}
                  {...checkboxProps}>
                  Fall
                </Checkbox>
                <Checkbox value="winter"
                  onChange={setModified}
                  {...checkboxProps}>
                  Winter
                </Checkbox>
              </>
            )}
            {pdf && (
              <>
                <Box as="div" fontSize="sm">
                  <label>
                    <input
                      type="checkbox"
                      checked={values.season === 'spring'}
                      readOnly={true}
                      className="pdf"
                    /> Spring</label>
                </Box>
                <Box as="div" fontSize="sm">
                  <label>
                    <input
                      type="checkbox"
                      checked={values.season === 'summer'}
                      readOnly={true}
                      className="pdf"
                    /> Summer</label>
                </Box>
                <Box as="div" fontSize="sm">
                  <label>
                    <input
                      type="checkbox"
                      checked={values.season === 'fall'}
                      readOnly={true}
                      className="pdf"
                    /> Fall</label>
                </Box>
                <Box as="div" fontSize="sm">
                  <label>
                    <input
                      type="checkbox"
                      checked={values.season === 'winter'}
                      readOnly={true}
                      className="pdf"
                    /> Winter</label>
                </Box>
              </>
            )}

          </VStack>
        </CheckboxRadio>

        <SubHeader mb="5px" mt={pdf ? '15px' : '30px'} pdf={pdf}>
          <OrfQuestion pdf={pdf}>What time of day was it?</OrfQuestion>
        </SubHeader>
        <CheckboxRadio
          value={values.time}
          setValue={(v) => setValue('time', v)}
        >
          <VStack alignItems="flex-start">
            {!pdf &&
              TIME_CONSTANTS.map((v) => (
                <Checkbox
                  value={v}
                  key={v}
                  textTransform="capitalize"
                  onChange={setModified}
                  {...checkboxProps}
                >
                  {v}
                </Checkbox>
              ))
            }
            {pdf &&
              TIME_CONSTANTS.map((v) => (
                <Box as="div" fontSize="sm" key={`${v}-div`}>
                  <label key={`${v}-label`}>
                    <input
                      key={v}
                      type="checkbox"
                      checked={values.time === v}
                      readOnly={true}
                      className="pdf"
                    /> {v.replace(v.charAt(0), v.charAt(0).toUpperCase())}
                  </label>
                </Box>
              ))
            }
            <Flex>
              {!pdf &&
                <>
                  <Checkbox value="exact time"
                    onChange={setModified}
                    {...checkboxProps}>
                    {'I know the exact time. It was '}
                  </Checkbox>

                  <LineInput
                    isDisabled={values.time !== 'exact time'}
                    {...register('exactTime')}
                    isRequired={values.time === 'exact time'}
                    onChange={async (e: Event) => {
                      await onChangeExactTime(e);
                      setModified();
                    }}
                  />
                </>
              }
              {pdf &&
                <>
                  <Box as="div" fontSize="sm">
                    <label>
                      <input
                        type="checkbox"
                        checked={values.time === 'exact time'}
                        readOnly={true}
                        className="pdf"
                      /> I know the exact time. It was</label>
                  </Box>
                  <Input
                    {...register('exactTime')}
                    defaultValue={values.exactTime}
                    readOnly
                    size="sm"
                    width="auto"
                    borderColor="black"
                    ml="5px"
                  />
                </>
              }
            </Flex>
          </VStack>
        </CheckboxRadio>

        <SubHeader mb={pdf ? '15px' : '5px'} mt={pdf ? '15px' : '30px'} pdf={pdf}>
          <OrfQuestion pdf={pdf}>Is there anything else you remember about the date?</OrfQuestion>
        </SubHeader>
        {(!pdf || !values.memory) && <Textarea
          placeholder="Examples: holidays, day of the week, games, school or social events around that time, etc."
          {...register('memory')}
          onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
            await onChangeMemory(e);
            setModified();
          }}
        />}
        {pdf && values.memory && <PdfTextarea paddingTop="15px">{values.memory}</PdfTextarea>}
      </div>
    );

  };
