import React from 'react';
import {
  Box,
  Flex
} from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import { PublicRoutes } from '../../config/routes';
import { CALLISTO_STATIC_URL } from '../../config/env';
import Logo from '../../../assets/Callisto_logo.png';
import ContainerWrapper from '../ContainerWrapper';
import Button from '../Button';
import {
  Link,
  Outlet
} from 'react-router-dom';

const PublicLayout: React.FC = () => (
  <Flex height="100%" flexDir="column">
    <Box>
      <ContainerWrapper>
        <Flex
          padding={['0px 0px', '20px 10px', '40px 30px']}
          minHeight="36px"
          alignItems="center"
          justifyContent="space-between"
        >
          <a href={CALLISTO_STATIC_URL} target="_blank" rel="noreferrer">
            <Image src={Logo as string} alt="Logo" w="200px" />
          </a>
          <Flex flexDir={['column', 'column', 'row']}>
            <Link to={PublicRoutes.LOGIN}>
              <Button buttonColor="brand.primary" fontSize="xs" padding="15px 30px" minWidth="100px" margin="5px">
                  LOG IN
              </Button>
            </Link>
            <Link to={PublicRoutes.SIGN_UP}>
              <Button
                buttonColor="brand.appBackground"
                color="brand.primary"
                borderColor="brand.primary"
                borderStyle="solid"
                borderWidth="thin"
                fontSize="xs"
                minWidth="100px"
                margin="5px"
              >
                  SIGN UP
              </Button>
            </Link>
          </Flex>
        </Flex>
      </ContainerWrapper>
    </Box>
    <Box flex="1">
      <Outlet />
    </Box>
  </Flex>
);

export default PublicLayout;
