import {
  crypto_hash_sha256,
  crypto_sign,
  crypto_sign_keypair,
  KeyPair,
  randombytes_buf,
  ready,
  to_hex
} from 'libsodium-wrappers-sumo';

import {
  Box,
  Matching,
  OPAQUE,
  OPRF,
  Secretbox
} from '../../../../lib/crypto';
import {
  assignmentData,
  contactInfo,
  entryData,
  incidentLogData,
  profileData,
  recordData,
  resultType,
  userData,
  userEntry,
  userIncidentLog,
  userRecord
} from '../../../../lib/data';
import {
  RPCClient,
  ServerError
} from '../../../../lib/rpc';
import { endpoints as v1 } from '../../endpoints';
import { v4 } from 'uuid';
import { computeShares } from '../../../../lib/util/shares';
import {
  tAssignmentData,
  tKey,
  tProfileData,
  tSodiumBytes,
  tUserData,
  tVersionedKeyBox
} from '../../../server/util/iots';
import { IOrfSteps } from '../../web2/src/contexts/OrfContext';
import { Step } from '../../web2/src/contexts/type';
import sha1 from 'js-sha1';
import * as t from 'io-ts';
import { PublicRoutes } from '../../web2/src/config/routes';
import { RecoveryClient } from '../../../recoveryserver/client';

export class SurvivorClient extends RPCClient {
  // TODO: make it possible to set these in a serviceworker.
  public userData: userData = {};
  public contactInfo: contactInfo = {};
  public entries: userEntry[] = [];
  public draftEntries: userEntry[] = [];
  public records: userRecord[] = [];
  public incidentLogs: userIncidentLog[] = [];
  public demographicsComplete = false;
  public acceptedPrivacyPolicy = false;
  public consents: Record<string, boolean> = {};
  public envVars: Record<string, string> = {};
  public signupCampusName = '';
  public currentCampusName = '';
  private recoveryClient: RecoveryClient;

  constructor(baseURL?: string) {
    super();

    // TODO: move this into the base class!
    this.baseURL = baseURL !== undefined ? baseURL : '';
  }

  public async getEnvironmentVariables(): Promise<Record<string, string>> {
    this.envVars = await this.call(v1.GetFrontEndEnvironmentVariables)({});
    return this.envVars;
  }

  public async getRecoveryClient(): Promise<RecoveryClient> {
    if (this.recoveryClient !== undefined) {
      return this.recoveryClient;
    }

    const env = await this.getEnvironmentVariables();
    this.recoveryClient = new RecoveryClient(
      env.UI_ENV_RECOVERY_SERVER_1_URL,
      env.UI_ENV_RECOVERY_SERVER_2_URL,
    );

    return this.recoveryClient;
  }

  public async saveDemographicData(
    age: number | null,
    studentType: string | null,
    yearInSchool: string | null,
    raceEthnicity: string[] | null,
    genderIdentity: string | null,
    sexualOrientation: string | null,
    disability: string[] | null,
    howLearned: string | null,
    extracurricularActivity: string[] | null
  ): Promise<void> {
    this.demographicsComplete = await this.encryptedCall(v1.SaveDemographicData)({
      age,
      studentType,
      yearInSchool,
      raceEthnicity,
      genderIdentity,
      sexualOrientation,
      disability,
      howLearned,
      extracurricularActivity
    });
  }

  public async acceptPrivacyPolicy(): Promise<void> {
    await this.encryptedCall(v1.AcceptPrivacyPolicy)({ privacyPolicyAccepted: true });
    this.acceptedPrivacyPolicy = true;
  }

  // Login =====================================================================
  public async login(
    username: string,
    password: string,
    badgeSalt: string
  ): Promise<void> {
    await ready;

    const lowercaseUsername = username.toLowerCase();
    // Try using just the username as the index first.
    // This modification was made to make our instance of OPAQUE more secure
    // against dictionary attacks than the original was.
    const usernameHash = OPAQUE.makeUsername(lowercaseUsername);
    const passwordHash = OPAQUE.makePassword(password, badgeSalt);
    const alpha = OPAQUE.mask(passwordHash);

    try {
      await this.doLogin(lowercaseUsername, usernameHash, alpha);
    } catch {
      try {
        // Try to use a lowercase username first, since that will be the case
        // for the majority of users.
        const identity = OPAQUE.makeIdentity(
          lowercaseUsername,
          password,
          badgeSalt
        );
        const alpha1 = OPAQUE.mask(identity.key);
        await this.doLogin(lowercaseUsername, identity.index, alpha1);
      } catch (err) {
        if (lowercaseUsername === username) {
          throw err;
        }
        // Failing that, try the original provided username.
        const identity = OPAQUE.makeIdentity(username, password, badgeSalt);
        const alpha1 = OPAQUE.mask(identity.key);
        await this.doLogin(username, identity.index, alpha1);
      }

      // We need to upgrade the index to match the new method so we can skip
      // these old index styles.
      const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
        alpha: alpha.point,
      });
      const unmasked = OPAQUE.unmask(beta, alpha.mask);
      const encrypted = OPAQUE.encrypt(unmasked, {
        pk: this.publicKey,
        sk: this.privateKey,
      });

      await this.encryptedCall(v1.UpdatePassword)({
        newIndex: usernameHash,
        newEnvelope: encrypted,
      });
    }

    // Get the user data and contact info for the user at login, if any is set.
    // This allows us to verify that the login actually worked.
    await this.bootstrap();

    // Generate a new marker for anyone who didn't have one.
    if (!this.userData.entryMarker) {
      this.userData.entryMarker = randombytes_buf(64);
      await this.saveUserData();
    }
  }

  // logout destroys all the information and keys that a client is holding
  // for the user.
  public async logout() {
    await super.logout();

    this.contactInfo = {};
    this.userData = {};
    this.entries = [];
    this.records = [];
    this.acceptedPrivacyPolicy = false;
    this.demographicsComplete = false;
    this.consents = {};
  }

  public async sendFeedback(message: string, emailAddress?: string): Promise<void> {
    await this.call(v1.SendFeedback)({
      emailAddress: emailAddress ? emailAddress : null,
      message
    });
  }

  // Account Setup =============================================================
  public async verifyEmail(email: string): Promise<{ success: boolean; foreignEdu: boolean }> {
    return (await this.call(v1.VerifyEmail)({ email }));
  }

  public async verifyRecoveryEmail(
    email: string,
    token: string
  ): Promise<{ success: boolean }> {
    return (await this.call(v1.VerifyRecoveryEmail)({ email, token, path: PublicRoutes.SIGN_UP_CREDENTIAL_TOKEN.replace(':token', '') }));
  }

  public async verifyToken(token: string): Promise<{ success: boolean; campusIdentified: boolean; emailDomain?: string }> {
    return (await this.call(v1.VerifyToken)({ token }));
  }

  public async verifySignupCredentialsToken(token: string): Promise<{
    success: boolean;
    campusIdentified: boolean;
    emailDomain?: string;
    email: string;
    signupToken: string;
  }> {
    const keys = Box.keygen();
    const { success, campusIdentified, emailDomain, encryptedEmail, signupToken } = await this.call(v1.VerifySignupCredentialsToken)({
      token,
      emailPublicKey: keys.publicKey
    });

    const email: string = Box.tsDecrypt(encryptedEmail, keys.publicKey, keys.privateKey, t.string);
    return {
      success,
      campusIdentified,
      emailDomain,
      email,
      signupToken
    };
  }

  public async checkPasswordStrength(password: string): Promise<void> {
    if (password.length < 8) {
      return Promise.reject();
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const hash = sha1(password) as string;
    const prefix = hash.substr(0, 5);
    const { hashes } = await this.call(v1.CheckPasswordStrength)({ prefix });

    if (hashes.some((v) => v === hash)) {
      return Promise.reject();
    }

    return Promise.resolve();
  }

  public async createAccount(
    username: string,
    password: string,
    token: string,
    privacyPolicyAccepted: boolean,
    badgeSalt: string,
    campusName?: string,
    emailDomain?: string
  ): Promise<void> {
    await ready;

    // Create an "entry marker" that allows us to tag entries as coming from
    // the same user, but without knowing their user ID.
    this.userData.entryMarker = randombytes_buf(64);

    // Make the username lowercase always, to standardize on something.
    username = username.toLowerCase();

    const usernameHash = OPAQUE.makeUsername(username);
    const passwordHash = OPAQUE.makePassword(password, badgeSalt);

    const keys = OPAQUE.generateKeys();
    const alpha = OPAQUE.mask(passwordHash);

    const oprfData = await this.call(v1.CreateAccount_Step1_OPRF)({
      token,
      index: usernameHash,
      alpha: alpha.point,
      userPublicKey: keys.pk,
      privacyPolicyAccepted,
      campusName,
      emailDomain
    });

    const unmasked = OPAQUE.unmask(oprfData.beta, alpha.mask);
    const encrypted = OPAQUE.encrypt(unmasked, keys);

    // Set up the necessary keys and data for user accounts.
    this.username = username;
    this.userID = oprfData.userID;
    this.serverKey = oprfData.serverPublicKey;
    this.publicKey = keys.pk;
    this.privateKey = keys.sk;
    this.demographicsComplete = false;
    this.acceptedPrivacyPolicy = true;

    try {
      await this.encryptedCall(v1.CreateAccount_Step2_Finalize)({
        envelope: encrypted
      });
      await this.saveUserData(); // Save the entryMarker we generated.
    } catch (error) {
      try {
        await this.submitEvent('Create account step 2', { error: (error as Error).message });
      } catch {
        // fail silently
      }
      try {
        await this.encryptedCall(v1.UndoCreateAccountStep1)({});
      } catch (err) {
        try {
          await this.submitEvent('Undo create account step 1', { error: (err as Error).message });
        } catch {
          // fail silently
        }
        throw new Error('Error creating account. Please start over by verifying your email address');
      } finally {
        await this.logout();
      }
      throw new Error('Error creating account. Please try submitting again.');
    }
  }

  // Account recovery/profile updates
  public async verifyRecoveryEmailChange(
    email: string,
    phone: string,
    legacySetup?: boolean
  ): Promise<{ success: boolean }> {
    const encryptedData = Box.tsEncrypt(this.publicKey, { email, phone }, tProfileData);
    return this.encryptedCall(v1.VerifyRecoveryEmailUpdate)({
      email,
      encryptedData,
      path: PublicRoutes.VERIFY_RECOVERY_EMAIL_TOKEN.replace(':token', ''),
      legacySetup: legacySetup ?? false
    });
  }

  public async verifyRecoveryEmailToken(token: string): Promise<{
    encryptedData: Uint8Array;
    legacySetup: boolean;
  }> {
    const { encryptedData, legacySetup } = await this.call(v1.VerifyRecoveryEmailToken)({ token });
    return {
      encryptedData,
      legacySetup
    };
  }

  public decryptProfileData(encryptedData: Uint8Array): {
    email: string;
    phone: string;
  } {
    const {
      email,
      phone
    }: profileData = Box.tsDecrypt(encryptedData, this.publicKey, this.privateKey, tProfileData);
    return {
      email,
      phone
    };
  }

  public async setUpAccountRecovery(
    consentsToFeedbackEmails: boolean,
    email: string,
    phoneNumber: string,
    securityQuestions: string[],
    answers: string[]
  ): Promise<{ success: boolean }> {
    const questions = new Map<string, string>();
    for (let i = 0; i < securityQuestions.length; i++) {
      questions.set(securityQuestions[i], answers[i]);
    }

    // Create an ownership key for this entry so that we can delete it later
    // if we need to.
    const keys = Matching.makeOwnershipKey();
    this.userData.recoveryOwnershipKey = keys.privateKey;
    await this.saveUserData();

    const recoveryClient = await this.getRecoveryClient();
    await recoveryClient.setup(
      email,
      phoneNumber,
      questions,
      this.userID,
      this.username,
      this.userData.entryMarker,
      {
        pk: this.publicKey,
        sk: this.privateKey
      },
      keys.publicKey,
    );
    await this.updateAccountRecoveryData(email, phoneNumber, securityQuestions, answers);
    await this.updateConsent('feedbackEmail', consentsToFeedbackEmails);
    return { success: true };
  }

  public async resetAccountRecovery(
    email: string,
    phoneNumber: string,
    securityQuestions: string[] = this.userData.securityQuestions,
    answers: string[] = this.userData.securityAnswers
  ): Promise<{ success: boolean }> {
    const questions = new Map<string, string>();
    for (let i = 0; i < securityQuestions.length; i++) {
      questions.set(securityQuestions[i], answers[i]);
    }
    const client = await this.getRecoveryClient();
    try {
      await client.update(
        this.userData.callistoContactEmail,
        this.userData.phoneNumber,
        email,
        phoneNumber,
        questions,
        this.userID,
        this.username,
        this.userData.entryMarker,
        {
          pk: this.publicKey,
          sk: this.privateKey
        },
        this.userData.recoveryOwnershipKey
      );
      await this.updateAccountRecoveryData(email, phoneNumber, securityQuestions, answers);
    } catch (e) {
      try {
        await this.submitEvent('reset account recovery', { error: (e as Error).message });
      } catch {
        // swallow this error
      }
      throw new Error('There was an error updating your data. Please try again.');
    }
    return { success: true };
  }

  public async submitAccountRecoveryRequest(email: string, phoneNumber: string): Promise<{ success: boolean }> {
    try {
      const client = await this.getRecoveryClient();

      await client.request(email, phoneNumber);
    } catch (error) {
      try {
        await this.submitEventUnencrypted('Submit recovery request', { error: (error as Error).message });
      } catch {
        // Swallow this error
      }
      return { success: true };
    }

    return { success: true };
  }

  public async verifyAccountRecoveryToken(token: string): Promise<{
    success: boolean;
    validation: {
      questions: string[];
    };
  }> {
    const client = await this.getRecoveryClient();
    const questions = await client.recovery_step1_get_questions(token);

    return Promise.resolve({
      success: true,
      validation: {
        questions
      }
    });
  }

  public async validateSecurityQuestionAnswers(
    answers: string[],
    token: string
  ): Promise<boolean> {
    try {
      const client = await this.getRecoveryClient();
      const envelope = await client.recovery_step2_complete(token, answers);

      const { serverKey } = await this.call(v1.GetServerPublicKey)({});
      this.userID = envelope.userID;
      this.username = envelope.username;
      this.publicKey = envelope.envelope.pk;
      this.privateKey = envelope.envelope.sk;
      this.serverKey = serverKey;
      return true;
    } catch (e) {
      try {
        await this.submitEventUnencrypted('Validate security question answers', { error: (e as Error).message });
      } catch (error) {
        // Swallow this error
      }
      return false;
    }
  }

  // Backup Codes ==============================================================
  public async createBackups(n: number, salt: string): Promise<string[]> {
    const encryptedEnvelopes: { [index: string]: Uint8Array } = {};
    const codes: string[] = [];

    for (let i = 0; i < n; i++) {
      const backup = OPAQUE.createBackup(
        this.username,
        {
          pk: this.publicKey,
          sk: this.privateKey,
        },
        salt
      );

      const index = to_hex(crypto_hash_sha256(backup.code));
      encryptedEnvelopes[index] = backup.encrypted;
      codes.push(backup.code);
    }

    await this.encryptedCall(v1.SaveBackupCodes)({ codes: encryptedEnvelopes });
    return codes;
  }

  public async emailBackupCodes(email: string, codes: string[]): Promise<void> {
    await this.encryptedCall(v1.MailBackupCodes)({
      email,
      codes,
    });
  }

  public async useBackupCode(code: string, salt: string): Promise<void> {
    const index = to_hex(crypto_hash_sha256(code));

    // TODO: error handling.
    const resp = await this.call(v1.UseBackupCode_Step1_Find)({ code: index });

    this.userID = resp.userID;
    this.serverKey = resp.serverPublicKey;

    // Stage all the information needed for finalizing the request.
    const decrypted = OPAQUE.decryptBackup(resp.encryptedEnvelope, code, salt);
    this.username = decrypted.username;
    this.publicKey = decrypted.keys.pk;
    this.privateKey = decrypted.keys.sk;
  }

  public async burnBackupCode(
    code: string,
    newPassword: string,
    badgeSalt: string
  ): Promise<void> {
    const codeIndex = code ? to_hex(crypto_hash_sha256(code)) : '';

    const passwordHash = OPAQUE.makePassword(newPassword, badgeSalt);

    const alpha = OPAQUE.mask(passwordHash);
    const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
      alpha: alpha.point,
    });

    const unmasked = OPAQUE.unmask(beta, alpha.mask);
    const encrypted = OPAQUE.encrypt(unmasked, {
      pk: this.publicKey,
      sk: this.privateKey,
    });

    await this.encryptedCall(v1.UseBackupCode_Step3_Finalize)({
      encryptedEnvelope: encrypted,
      codeIndex,
    });

    await this.bootstrap();
  }

  // Account Management ========================================================
  public async updateContactInfo(): Promise<void> {
    let key = this.userData.contactInfoKey;
    let update = true;
    if (key === undefined) {
      key = Secretbox.keygen();
      update = false;
      this.userData.contactInfoKey = key;
    }
    const encrypted = Secretbox.encrypt(key, this.contactInfo);
    let encryptedContactInfoKey: Uint8Array = null;

    const { dlocKey, dlocKeyVersion, locKey, locKeyVersion } = await this.encryptedCall(v1.GetLocAndDlocKeys)({});

    // We only re-encrypt the key here if this is brand-new contact info.
    // Otherwise, we'll destroy access for an LOC that might have a case from
    // us assigned.
    if (!update) {
      const encryptedKey = Box.tsEncrypt(locKey, key, tKey);
      const versionedKeyBox = {
        version: locKeyVersion,
        key: encryptedKey
      };

      encryptedContactInfoKey = Box.tsEncrypt(dlocKey, versionedKeyBox, tVersionedKeyBox);
    }

    await this.encryptedCall(v1.SaveContactInformation)({
      encryptedContactInfo: encrypted,
      encryptedUserData: Box.tsEncrypt(
        this.publicKey,
        this.userData,
        tUserData
      ),
      encryptedKey: encryptedContactInfoKey,
      dlocKeyVersion: encryptedContactInfoKey ? dlocKeyVersion : null
    });
  }

  /**
   * saveUserData encrypts and transmits the current userData to the server.
   */
  public async saveUserData(): Promise<unknown> {
    return this.encryptedCall(v1.SaveUserData)({
      data: Box.tsEncrypt(this.publicKey, this.userData, tUserData),
    });
  }

  /**
   * updatePassword creates a new envelope and badge for the user and saves
   * them.
   */
  public async updatePassword(oldPassword: string, newPassword: string, badgeSalt: string): Promise<void> {
    // Essentially perform a login to verify that the user has entered
    // their correct password to confirm their intent.
    const usernameHash = OPAQUE.makeUsername(this.username);
    const passwordHash = OPAQUE.makePassword(oldPassword, badgeSalt);
    const oldAlpha = OPAQUE.mask(passwordHash);

    const loginData = await this.call(v1.Login)({
      index: usernameHash,
      alpha: oldAlpha.point,
      passwordCheck: true
    });

    const oldUnmasked = OPAQUE.unmask(loginData.beta, oldAlpha.mask);
    OPAQUE.decrypt(oldUnmasked, loginData.encryptedEnvelope);

    // If the decryption succeeded, the user entered their correct old password
    // and we should carry out the password change operation.

    const newPasswordHash = OPAQUE.makePassword(newPassword, badgeSalt);
    const newAlpha = OPAQUE.mask(newPasswordHash);

    const { output: beta } = await this.encryptedCall(v1.OPRF_Me)({
      alpha: newAlpha.point,
    });

    const newUnmasked = OPAQUE.unmask(beta, newAlpha.mask);
    const encrypted = OPAQUE.encrypt(newUnmasked, {
      pk: this.publicKey,
      sk: this.privateKey,
    });

    await this.encryptedCall(v1.UpdatePassword)({
      newIndex: OPAQUE.makeUsername(this.username),
      newEnvelope: encrypted,
    });
  }

  public async updateAccountRecoveryData(
    email: string,
    phone: string,
    securityQuestions: string[] = this.userData.securityQuestions,
    answers: string[] = this.userData.securityAnswers
  ): Promise<void> {
    try {
      const { key, keyVersion } = await this.call(v1.GetLatestSharedAdminPublicKey)({});
      if (email !== this.userData.callistoContactEmail) {
        if (!this.serverKey) {
          const { serverKey } = await this.call(v1.GetServerPublicKey)({});
          this.serverKey = serverKey;
        }
        const encryptedEmail = Box.tsEncrypt(
          key,
          Box.tsEncrypt(this.serverKey, email, t.string),
          tSodiumBytes
        );

        await this.encryptedCall(v1.SurvivorUpdateEmail)({
          encryptedEmail,
          adminKeyVersion: keyVersion,
          survivorMarker: to_hex(this.userData.entryMarker)
        });

        try {
          await this.submitEvent('Update email', { success: true });
        } catch {
          // Swallow this error
        }
      }

      this.userData.securityQuestions = securityQuestions;
      this.userData.securityAnswers = answers;
      this.userData.callistoContactEmail = email;
      this.userData.phoneNumber = phone;
      await this.saveUserData();
    } catch (error) {
      try {
        await this.submitEvent('Update account recovery data', { success: false, error: (error as Error).message });
      } catch {
        // Swallow this error
      }
      throw new Error('There was an error updating your account information; please try again');
    }
  }

  public async updateConsent(whatFor: string, consented: boolean): Promise<void> {
    try {
      const { consentId } = await this.encryptedCall(v1.SurvivorUpdateConsent)({
        consentGiven: consented,
        whatFor,
        survivorMarker: to_hex(this.userData.entryMarker)
      });

      try {
        await this.submitEvent('Update consent', { success: true });
      } catch {
        // Swallow this error
      }

      if (!this.userData.consentIds) {
        this.userData.consentIds = [];
      }

      if (!this.userData.consentIds.includes(consentId)) {
        this.userData.consentIds.push(consentId);
      }
      await this.saveUserData();

      if (!this.consents) {
        this.consents = {};
      }

      this.consents[whatFor] = consented;
    } catch (error) {
      try {
        await this.submitEvent('Update consent', { success: false, error: (error as Error).message });
      } catch {
        // Swallow this error
      }
      throw new Error('There was an error updating your consent settings; please try again');
    }
  }

  public async setCurrentCampus(
    campusId: string | null | undefined,
    campusName: string | null | undefined,
    emailDomain: string | null | undefined
  ) {
    const campusData = await this.encryptedCall(v1.SetCurrentCampus)({ campusId, campusName, emailDomain });
    if (!campusData.success) {
      throw new Error('Error setting campus. Please try again.');
    }

    this.currentCampusName = campusData.campusName;
  }

  public async deleteAccount(password: string, badgeSalt: string): Promise<void> {
    // Essentially perform a login to verify that the user has entered
    // their correct password to confirm their intent.
    const usernameHash = OPAQUE.makeUsername(this.username);
    const passwordHash = OPAQUE.makePassword(password, badgeSalt);
    const alpha = OPAQUE.mask(passwordHash);

    const loginData = await this.call(v1.Login)({
      index: usernameHash,
      alpha: alpha.point,
      passwordCheck: true
    });

    const unmasked = OPAQUE.unmask(loginData.beta, alpha.mask);
    OPAQUE.decrypt(unmasked, loginData.encryptedEnvelope);

    // If the decryption succeeded, the user entered their correct password
    // and we should carry out the deletion.

    // Sign requests to delete all entries created by the survivor, if there
    // are any.
    const entrySigns: { [entryID: string]: Uint8Array } = {};
    for (const i in this.userData.entries) {
      if (this.userData.entries.hasOwnProperty(i)) {
        const key = this.userData.entries[i].ownershipKey;
        entrySigns[i] = crypto_sign('delete', key);
      }
    }

    const draftEntrySignatures: { [entryId: string]: Uint8Array } = {};
    for (const id in this.userData.draftEntries) {
      if (this.userData.draftEntries.hasOwnProperty(id)) {
        const key = this.userData.draftEntries[id].ownershipKey;
        draftEntrySignatures[id] = crypto_sign('delete', key);
      }
    }

    const recordSignatures: { [recordId: string]: Uint8Array } = {};
    for (const id in this.userData.records) {
      if (this.userData.records.hasOwnProperty(id)) {
        const key = this.userData.records[id].ownershipKey;
        recordSignatures[id] = crypto_sign('delete', key);
      }
    }

    const incidentLogSignatures: { [logId: string]: Uint8Array } = {};
    for (const id in this.userData.incidentLogs) {
      if (this.userData.incidentLogs.hasOwnProperty(id)) {
        const key = this.userData.incidentLogs[id].ownershipKey;
        incidentLogSignatures[id] = crypto_sign('delete', key);
      }
    }

    await this.encryptedCall(v1.DeleteAccount)({
      entry: entrySigns,
      draftEntrySignatures,
      recordFormSignatures: recordSignatures,
      incidentLogSignatures,
      consentIds: this.userData.consentIds ?? []
    });

    // Also delete the recovery info for this survivor.
    try {
      const client = await this.getRecoveryClient();
      await client.delete(
        this.userData.callistoContactEmail,
        this.userData.phoneNumber,
        this.userData.recoveryOwnershipKey);
    } finally {
      // The account is deleted, so we want to log them out even if deleting recovery data failed
      await this.logout();
    }
  }

  // Draft Matching Entry Management
  public async saveDraftEntry(draft: entryData): Promise<string> {
    let entryKey: Uint8Array;
    let ownerKey: KeyPair;
    if (draft.id && this.userData.draftEntries && this.userData.draftEntries[draft.id]) {
      entryKey = this.userData.draftEntries[draft.id].dek;
      ownerKey = {
        privateKey: this.userData.draftEntries[draft.id].ownershipKey,
        publicKey: undefined,
        keyType: 'ed25519'
      };
    } else {
      entryKey = Matching.makeEntryKey();
      ownerKey = Matching.makeOwnershipKey();
    }

    const draftId = draft.id && draft.id !== '' ? draft.id : v4();

    const encrypted = Secretbox.encrypt(entryKey, draft);

    if (this.userData.draftEntries === undefined) {
      this.userData.draftEntries = {};
    }

    if (!this.userData.draftEntries[draftId]) {
      this.userData.draftEntries[draftId] = {
        dek: entryKey,
        ownershipKey: ownerKey.privateKey
      };
    }

    await this.saveUserData();

    try {
      await this.encryptedCall(v1.SaveDraftEntry)({
        id: draftId,
        publicKey: ownerKey.publicKey,
        encrypted
      });
    } catch (err) {
      // Attempt to recover userData's previous state
      delete this.userData.draftEntries[draftId];
      await this.saveUserData();
      throw err;
    }

    // Update the entry in this.draftEntries
    this.draftEntries = this.draftEntries.filter((draftEntry) => draftEntry.id !== draftId);

    const { draftEntries } = await this.encryptedCall(v1.GetEntries)({
      ids: [],
      draftIds: [draftId]
    });
    for (const draftEntryId in draftEntries) {
      if (draftEntries.hasOwnProperty(draftEntryId)) {
        const draftEntry = draftEntries[draftEntryId];
        const dek = this.userData.draftEntries[draftEntryId].dek;

        const decrypted = Secretbox.decrypt(Uint8Array.from(draftEntry.encrypted), dek) as entryData;
        decrypted.id = draftEntryId;
        this.draftEntries.push({
          id: draftEntryId,
          data: decrypted,
          created: draftEntry.created,
          status: draftEntry.status,
          edited: draftEntry.edited,
        });
      }
    }

    return draftId;
  }

  public async deleteDraftEntry(draftId: string): Promise<void> {
    const signature = crypto_sign('delete', this.userData.draftEntries[draftId].ownershipKey);

    await this.encryptedCall(v1.DeleteDraftEntry)({
      id: draftId,
      signature
    });

    delete this.userData.draftEntries[draftId];
    await this.saveUserData();
    this.draftEntries = this.draftEntries.filter((draft) => draft.id !== draftId);
  }

  // Matching Entry Management =================================================
  public async createMatchingEntry(entry: entryData): Promise<string> {
    const entryKey = Matching.makeEntryKey();
    const ownerKey = Matching.makeOwnershipKey();

    const entryID = entry.id && entry.id !== '' && !this.userData.entries[entry.id] ? entry.id : v4();
    if (this.userData.entries && this.userData.entries[entry.id]) {
      await this.submitEvent('Create entry', { error: `Entry with ID ${entry.id} already exists`, newEntryId: entryID });
    }

    const encryptedEntry = Secretbox.encrypt(entryKey, entry);

    const { dlocKey, dlocKeyVersion, locKey, locKeyVersion } = await this.encryptedCall(v1.GetLocAndDlocKeys)({});
    const { apiShares } = await computeShares(
      entry,
      this.userData.entryMarker,
      this
    );

    // Encrypt information that only the DLOC can see.
    const encryptedAssignmentData = Box.tsEncrypt(
      dlocKey,
      {
        incidentState: entry.incidentState,
        userID: this.userID,
        preferredLanguage: this.contactInfo.preferredLanguage,
        accommodationsNeeded: this.contactInfo.accommodationsNeeded
      } as assignmentData,
      tAssignmentData
    );

    // We save the keys FIRST, to ensure that we preserve access to the potential
    // entry in case something goes wrong.
    if (this.userData.entries === undefined) {
      this.userData.entries = {};
    }

    this.userData.entries[entryID] = {
      dek: entryKey,
      ownershipKey: ownerKey.privateKey,
    };

    let draftKeys: {
      dek: Uint8Array;
      ownershipKey: Uint8Array;
    };
    if (this.userData.draftEntries && this.userData.draftEntries[entryID]) {
      draftKeys = this.userData.draftEntries[entryID];
      delete this.userData.draftEntries[entryID];
    }

    await this.saveUserData();

    const encryptedDek = Box.tsEncrypt(locKey, entryKey, tKey);
    const versionedKeyBox = {
      key: encryptedDek,
      version: locKeyVersion
    };

    try {
      const encryptedKey = Box.tsEncrypt(dlocKey, versionedKeyBox, tVersionedKeyBox);
      await this.encryptedCall(v1.CreateMatchingEntry)({
        id: entryID,
        entryPublicKey: ownerKey.publicKey,
        encrypted: encryptedEntry,
        encryptedAssignmentData,
        dlocKeyVersion,
        shares: apiShares,
        encryptedKey
      });
    } catch (err) {
      // Attempt to recover userData's previous state
      if (draftKeys) {
        this.userData.draftEntries[entryID] = draftKeys;
        delete this.userData.entries[entryID];

        await this.saveUserData();
      }
      throw err;
    }

    // Now, fetch that entry from the DB to make sure the process works.
    const { entries } = await this.encryptedCall(v1.GetEntries)({
      ids: [entryID],
      draftIds: undefined
    });
    for (const entryId in entries) {
      if (entries.hasOwnProperty(entryId)) {
        const e = entries[entryId];
        const dek = this.userData.entries[entryId].dek;

        const decrypted = Secretbox.decrypt(Uint8Array.from(e.encrypted), dek) as entryData;
        this.entries.push({
          id: entryId,
          data: decrypted,
          created: e.created,
          status: e.status,
          edited: e.edited,
        });
      }
    }

    // Finally, remove the entry from the list of drafts (if there is one)
    this.draftEntries = this.draftEntries.filter((draft) => draft.id !== entryID);

    return entryID;
  }

  public async deleteMatchingEntry(entryID: string): Promise<void> {
    // Sign the request to delete with the private key generated at creation.
    const key = this.userData.entries[entryID].ownershipKey;
    const entrySign = crypto_sign('delete', key);

    await this.encryptedCall(v1.DeleteEntry)({
      entryID,
      signature: entrySign,
    });

    delete this.userData.entries[entryID];
    this.entries = this.entries.filter((val) => val.id !== entryID);
  }

  public async editMatchingEntry(entryID: string, entry: entryData): Promise<string> {
    const entryKey = this.userData.entries[entryID].dek;
    const ownershipKey = this.userData.entries[entryID].ownershipKey;
    const entrySign = crypto_sign('delete', ownershipKey);
    const encrypted = Secretbox.encrypt(entryKey, entry);

    const { dlocKey, dlocKeyVersion } = await this.encryptedCall(v1.GetLocAndDlocKeys)({});
    const { apiShares } = await computeShares(
      entry,
      this.userData.entryMarker,
      this
    );

    // Encrypt information that only the DLOC can see.
    const encryptedAssignmentData = Box.tsEncrypt(
      dlocKey,
      {
        incidentState: entry.incidentState,
        userID: this.userID,
        preferredLanguage: entry.preferredLanguage,
        accommodationsNeeded: entry.accommodationsNeeded
      } as assignmentData,
      tAssignmentData
    );

    await this.encryptedCall(v1.EditEntry)({
      entryID,
      signature: entrySign,
      encrypted,
      shares: apiShares,
      encryptedAssignmentData,
      dlocKeyVersion
    });

    // Now, fetch that entry from the DB to make sure the process works.
    this.entries = this.entries.filter((val) => val.id !== entryID);

    const { entries } = await this.encryptedCall(v1.GetEntries)({
      ids: [entryID],
      draftIds: undefined
    });

    for (const entryId in entries) {
      if (entries.hasOwnProperty(entryId)) {
        const e = entries[entryId];
        const dek = this.userData.entries[entryId].dek;

        const decrypted = Secretbox.decrypt(e.encrypted, dek) as entryData;
        this.entries.push({
          id: entryId,
          data: decrypted,
          created: e.created,
          status: e.status,
          edited: e.edited,
        });
      }
    }

    return entryID;
  }

  public async updateLanguageAndAccommodationsOnAllEntries() {
    for (const entry of this.entries) {
      const updatedEntryData = {
        ...entry.data
      };
      let updateNeeded = false;

      if (this.contactInfo?.preferredLanguage !== entry.data.preferredLanguage) {
        updatedEntryData.preferredLanguage = this.contactInfo?.preferredLanguage;
        updateNeeded = true;
      }

      if (this.contactInfo?.accommodationsNeeded !== entry.data.accommodationsNeeded) {
        updatedEntryData.accommodationsNeeded = this.contactInfo?.accommodationsNeeded;
        updateNeeded = true;
      }
      if (updateNeeded) {
        await this.editMatchingEntry(entry.id, updatedEntryData);
      }
    }

    for (const draft of this.draftEntries) {
      const updatedEntryData = {
        ...draft.data
      };
      let updateNeeded = false;

      if (this.contactInfo?.preferredLanguage !== draft.data.preferredLanguage) {
        updatedEntryData.preferredLanguage = this.contactInfo?.preferredLanguage;
        updateNeeded = true;
      }

      if (this.contactInfo?.accommodationsNeeded !== draft.data.accommodationsNeeded) {
        updatedEntryData.accommodationsNeeded = this.contactInfo?.accommodationsNeeded;
        updateNeeded = true;
      }
      if (updateNeeded) {
        await this.saveDraftEntry(updatedEntryData);
      }
    }
  }

  // Encrypted Record Form Management
  public async deleteRecordForm(recordFormId: string): Promise<void> {
    // Sign the request to prove ownership
    const key = this.userData.records[recordFormId].ownershipKey;
    const signature = crypto_sign('delete', key);

    if (
      !(await this.encryptedCall(v1.DeleteRecordForm)({
        signature,
        id: recordFormId,
      }))
    ) {
      throw new ServerError('Error deleting record form. Please try again.');
    }

    delete this.userData.records[recordFormId];
    this.records = this.records.filter((form) => form.id !== recordFormId);
  }

  public async updateRecordForm(
    recordId: string,
    record: recordData
  ): Promise<string> {
    const recordKey = this.userData.records[recordId].dek;
    const ownershipKey = this.userData.records[recordId].ownershipKey;
    const signature = crypto_sign('update', ownershipKey);
    const encryptedRecord = Secretbox.encrypt(recordKey, record);

    const { recordForm } = await this.encryptedCall(v1.UpdateRecordForm)({
      id: recordId,
      signature,
      encryptedData: encryptedRecord,
    });

    // Remove the old version of the record form from the list and add in the new version
    this.records = this.records.filter((form) => form.id !== recordId);

    this.records.push({
      id: recordForm.id,
      data: Secretbox.decrypt(recordForm.encryptedData, recordKey) as recordData,
      created: recordForm.created,
      edited: recordForm.lastEdited,
    });

    return recordForm.id;
  }

  // Incident Log Management
  public async createIncidentLog(log: incidentLogData): Promise<string> {
    const logKey = Secretbox.keygen();
    const ownerKey = crypto_sign_keypair();
    const logId = v4();

    const encryptedLog = Secretbox.encrypt(logKey, this.convertIncidentLogDataFromMap(log));

    if (this.userData.incidentLogs === undefined) {
      this.userData.incidentLogs = {};
    }

    this.userData.incidentLogs[logId] = {
      dek: logKey,
      ownershipKey: ownerKey.privateKey
    };
    await this.saveUserData();

    const { incidentLog } = await this.encryptedCall(v1.CreateIncidentLog)({
      id: logId,
      publicKey: ownerKey.publicKey,
      encrypted: encryptedLog,
      survivorMarker: to_hex(this.userData.entryMarker)
    });

    this.incidentLogs.push({
      id: incidentLog.id,
      data: this.convertIncidentLogDataToMap(Secretbox.decrypt(Uint8Array.from(incidentLog.encrypted), logKey) as incidentLogData),
      created: incidentLog.created,
      updated: incidentLog.updated
    });
    return incidentLog.id;
  }

  public async updateIncidentLog(logId: string, log: incidentLogData): Promise<string> {
    const logKey = this.userData.incidentLogs[logId].dek;
    const ownershipKey = this.userData.incidentLogs[logId].ownershipKey;
    const signature = crypto_sign('update', ownershipKey);
    const encryptedLog = Secretbox.encrypt(logKey, this.convertIncidentLogDataFromMap(log));

    const { incidentLog } = await this.encryptedCall(v1.UpdateIncidentLog)({
      id: logId,
      signature,
      encrypted: encryptedLog
    });

    // Remove old version of the incident log from the list and add in the new version
    this.incidentLogs = this.incidentLogs.filter((userLog) => userLog.id !== logId);

    this.incidentLogs.push({
      id: incidentLog.id,
      data: this.convertIncidentLogDataToMap(Secretbox.decrypt(incidentLog.encrypted, logKey) as incidentLogData),
      created: incidentLog.created,
      updated: incidentLog.updated
    });

    return incidentLog.id;
  }

  public async deleteIncidentLog(logId: string): Promise<void> {
    // Sign the request to prove ownership
    const key = this.userData.incidentLogs[logId].ownershipKey;
    const signature = crypto_sign('delete', key);

    const { success } = await this.encryptedCall(v1.DeleteIncidentLog)({
      signature,
      id: logId
    });

    if (!success) {
      throw new ServerError('Error deleting incident log. Please try again.');
    }

    delete this.userData.incidentLogs[logId];
    this.incidentLogs = this.incidentLogs.filter((log) => log.id !== logId);
  }

  public async incrementCount(measure: string): Promise<void> {
    await this.encryptedCall(v1.IncrementCount)({ measure });
  }

  public async incrementCountUnencrypted(measure: string, token: string): Promise<void> {
    await this.call(v1.IncrementCountUnencrypted)({ measure, token });
  }

  public async writeWeakPasswordUnusedToken(token: string): Promise<void> {
    await this.call(v1.WriteWeakPasswordUnusedToken)({ token });
  }

  public async submitEvent(action: string, data: Record<string, unknown>): Promise<void> {
    const event = {
      action,
      ...data,
      name: action,
      service_name: 'survivor'
    };
    await this.encryptedCall(v1.SurvivorHoneycombEvent)({ event });
  }

  public async submitEventUnencrypted(action: string, data: Record<string, unknown>): Promise<void> {
    const event = {
      action,
      ...data,
      name: action,
      service_name: 'survivor'
    };
    await this.call(v1.HoneycombEvent)({ event });
  }

  public extractCompletedSteps(steps: IOrfSteps): string[] {
    const completedSteps: string[] = [];
    for (const entry in steps) {
      if (entry !== 'intro' && entry !== 'review') {
        const step = steps[entry] as Step;
        if (step.completed) {
          completedSteps.push(entry);
        }
      }
    }

    return completedSteps;
  }

  public async lookUpCampus(emailDomain: string) {
    return await this.encryptedCall(v1.LookUpCampus)({ emailDomain });
  }

  public async bootstrap(): Promise<void> {
    const contactData = await this.encryptedCall(v1.Bootstrap)({});
    this.demographicsComplete = contactData.demographicsComplete;
    this.acceptedPrivacyPolicy = contactData.acceptedPrivacyPolicy;
    this.signupCampusName = contactData.signupCampusName;
    this.currentCampusName = contactData.currentCampusName;

    if (contactData.encryptedUserData instanceof Uint8Array) {
      this.userData = Box.tsDecrypt(
        contactData.encryptedUserData,
        this.publicKey,
        this.privateKey,
        tUserData
      );

      if (contactData.encryptedContactInfo instanceof Uint8Array) {
        this.contactInfo = Secretbox.decrypt(
          contactData.encryptedContactInfo,
          this.userData.contactInfoKey
        ) as contactInfo;
      }

      // Convert old-style contact info phone numbers to new style
      if (this.contactInfo?.phone && !this.contactInfo.phone.startsWith('+')) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.contactInfo.phone.replaceAll(/[- ()]/g, '');
        this.contactInfo.phone = `+1${this.contactInfo.phone}`;
      }


      if (!this.userData.consentIds) {
        this.userData.consentIds = [];
      }

      const { consentStatuses } = await this.encryptedCall(v1.SurvivorGetConsents)({
        consentIds: this.userData.consentIds
      });

      this.consents = consentStatuses;

      await this.getAndDecryptEntries();
      await this.getAndDecryptRecordForms();
      await this.getAndDecryptIncidentLogs();
    }
  }

  // This function is necessary because incident log data requires a Map and encryption/decryption
  // converts it to a plain object. This makes sure the UI will still be able to read the
  // responses properly.
  private convertIncidentLogDataToMap(data: incidentLogData) {
    const convertedData: incidentLogData = {};
    const keys = Object.keys(data);
    for (const key of keys) {
      const responses = new Map<string, resultType>();
      const responseData = (data[key]).responses;
      for (const responseKey of Object.keys(responseData)) {
        responses.set(responseKey, responseData[responseKey] as resultType);
      }
      convertedData[key] = {
        responses,
        updated: (data[key]).updated
      };
    }

    return convertedData;
  }

  // This function is necessary because incident log data requires a Map and encryption
  // doesn't save any map data. This makes sure the data will still be saved properly.
  private convertIncidentLogDataFromMap(data: incidentLogData) {
    const convertedData = {};
    const keys = Object.keys(data);
    for (const key of keys) {
      const responses = {};
      const responseData = (data[key]).responses;
      responseData.forEach((value, responseKey) => {
        responses[responseKey] = value;
      });
      convertedData[key] = {
        responses,
        updated: (data[key]).updated
      };
    }
    return convertedData;
  }

  private async getAndDecryptEntries(): Promise<void> {
    // Download and decrypt entries that the user owns, if they own any.
    this.entries = [];
    if (this.userData.entries === undefined) {
      this.userData.entries = {};
    }

    this.draftEntries = [];
    if (this.userData.draftEntries === undefined) {
      this.userData.draftEntries = {};
    }

    const entryIds = Object.keys(this.userData.entries);
    const draftEntryIds = Object.keys(this.userData.draftEntries);
    const { entries, draftEntries } = await this.encryptedCall(v1.GetEntries)({
      ids: entryIds,
      draftIds: draftEntryIds
    });

    for (const entryId in entries) {
      if (entries.hasOwnProperty(entryId)) {
        const e = entries[entryId];
        const dek = this.userData.entries[entryId].dek;

        const decrypted = Secretbox.decrypt(e.encrypted, dek) as entryData;
        decrypted.id = entryId;
        this.entries.push({
          id: entryId,
          data: decrypted,
          created: e.created,
          status: e.status,
          edited: e.edited,
        });
      }
    }

    for (const draftEntryId in draftEntries) {
      if (draftEntries.hasOwnProperty(draftEntryId)) {
        const draft = draftEntries[draftEntryId];
        const dek = this.userData.draftEntries[draftEntryId].dek;

        const decrypted = Secretbox.decrypt(draft.encrypted, dek) as entryData;
        decrypted.id = draftEntryId;
        this.draftEntries.push({
          id: draftEntryId,
          data: decrypted,
          created: draft.created,
          status: draft.status,
          edited: draft.edited,
        });
      }
    }
  }

  private async getAndDecryptRecordForms(): Promise<void> {
    // Download and decrypt record forms that the user owns, if they own any.
    this.records = [];
    if (this.userData.records === undefined) {
      this.userData.records = {};
      return;
    }

    const recordIDs = Object.keys(this.userData.records);
    const { recordForms: recordFormData } = await this.encryptedCall(
      v1.GetRecordForms
    )({
      ids: recordIDs,
    });
    for (const recordForm of recordFormData) {
      const dek = this.userData.records[recordForm.id].dek;

      const decrypted = Secretbox.decrypt(recordForm.encryptedData, dek) as recordData;
      this.records.push({
        id: recordForm.id,
        data: decrypted,
        created: recordForm.created,
        edited: recordForm.lastEdited,
      });
    }
  }

  private async getAndDecryptIncidentLogs(): Promise<void> {
    // Download and decrypt the incident logs that the user owns, if any.
    this.incidentLogs = [];
    if (this.userData.incidentLogs === undefined) {
      this.userData.incidentLogs = {};
      return;
    }

    const incidentLogIds = Object.keys(this.userData.incidentLogs);
    const { incidentLogs } = await this.encryptedCall(v1.GetIncidentLogs)({
      ids: incidentLogIds
    });

    for (const incidentLog of incidentLogs) {
      const dek = this.userData.incidentLogs[incidentLog.id].dek;

      const decrypted = Secretbox.decrypt(incidentLog.encrypted, dek) as incidentLogData;

      this.incidentLogs.push({
        id: incidentLog.id,
        data: this.convertIncidentLogDataToMap(decrypted),
        created: incidentLog.created,
        updated: incidentLog.updated
      });
    }
  }

  private async doLogin(username: string, index: string, alpha: OPRF.Alpha) {
    const oprfData = await this.call(v1.Login)({
      index,
      alpha: alpha.point,
      passwordCheck: false
    });

    const unmasked = OPAQUE.unmask(oprfData.beta, alpha.mask);
    const decrypted = OPAQUE.decrypt(unmasked, oprfData.encryptedEnvelope);

    this.username = username;
    this.userID = oprfData.userID;
    this.serverKey = oprfData.serverPublicKey;
    this.publicKey = decrypted.pk;
    this.privateKey = decrypted.sk;
    this.demographicsComplete = oprfData.demographicsComplete;
    this.acceptedPrivacyPolicy = oprfData.acceptedPrivacyPolicy;
  }
}
