import {
  Flex,
  Modal,
  ModalContent,
  ModalProps,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { survivorClient } from '../service/backend';
import { PublicRoutes } from '../config/routes';
import { useNavigate } from 'react-router-dom';
import WhiteButton from './layout/WhiteButton';
import Button from './Button';

const DeleteAccountRequestModal: React.FC<ModalProps> = ({
  onClose,
  ...props
}: ModalProps) => {
  const navigate = useNavigate();

  const handleDoNotDelete = async () => {
    await survivorClient.logout();
    onClose();
    navigate(PublicRoutes.LOGIN);
  };

  const handleDelete = () => {
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={onClose}
      {...props}
    >
      <ModalContent p="100px" maxW="625px" borderRadius="5px" borderColor="brand.primary" borderWidth="5px" color="brand.primary">
        <Text mt="40px" mb="18px" as="h1" fontSize="md">
          Since you do not accept the privacy policy or terms of service, would you like to delete your account?
        </Text>
        <Text mb="27px" mt="16px" fontSize="sm">
          Deleting your account is permanent and cannot be undone.
        </Text>

        <Flex justifyContent="center" mt="20px">
          <WhiteButton
            minWidth="auto"
            mx="10px"
            onClick={handleDoNotDelete}
          >
            Do Not Delete My Account
          </WhiteButton>
          <Button
            minWidth="auto"
            buttonColor="brand.primary"
            mx="10px"
            onClick={handleDelete}
          >
            Yes, Delete My Account
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default DeleteAccountRequestModal;
