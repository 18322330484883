import {
  extendTheme,
  StyleFunctionProps,
  theme as origTheme
} from '@chakra-ui/react';

const errorColor = '#F56565';
const successColor = '#3C828B';

const theme = extendTheme({
  colors: {
    brand: {
      primary: '#00375B',
      link: '#00375B',
      appBackground: '#D1E7E7',
      lightBackground: '#D1E7E7',
      green: successColor,
      mapColor: '#00375B',
      red: '#e74c3c',
      info: '#0DA1C1',
      error: errorColor,
      white: '#F5F7F7',
      brightWhite: '#FFFFFF'
    },
    input: {
      border: '#C6C5C5',
    },
  },
  fontSizes: {
    xxs: '12px',
    xs: '14px',
    sm: '16px',
    sm2: '18px',
    md: '24px',
    lg: '32px',
    lg2: '36px',
    xl: '40px',
  },
  components: {
    Alert: {
      variants: {
        // eslint-disable-next-line max-len
        solid: (props: StyleFunctionProps) => {
          const { colorScheme: c } = props;
          if (c === 'green') { // success toast color
            return {
              container: {
                bg: successColor
              }
            };
          }
          if (c === 'red') { // error toast color
            return {
              container: {
                bg: errorColor
              }
            };
          }

          return origTheme.components.Alert.variants?.subtle(props);
        }
      }
    }
  }
});

export default theme;
