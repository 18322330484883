import React, { SyntheticEvent } from 'react';
import Card from '../../components/Card';
import {
  Box,
  Flex,
  Image,
  Spacer,
  Text
} from '@chakra-ui/react';
import Button from '../../components/Button';
import theme from '../../styles/theme';
import WhiteButton from '../../components/layout/WhiteButton';

interface DashboardCardProps {
  title: string;
  text: string;
  icon: string;
  buttonText: string;
  isPrimary: boolean;
  onButtonClick: (e: SyntheticEvent) => void;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  text,
  icon,
  buttonText,
  isPrimary,
  onButtonClick
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const primaryButtonColor = theme.colors.brand.primary as string;

  return (
    <Box p='0.5em 0.5em' width={{base: '100%', md: '50%'}}>
      <Card width='100%' p='10px'>
        <Flex width='100%' flexDir='row' verticalAlign='top'>
          <Flex flexDir='column'>
            <Text as='h1' fontSize={{base: 'md', md: 'lg'}} p='15px 15px 0px 15px'>{title}</Text>
            <Text p='0px 15px'>{text}</Text>
            <Box p='15px'>
              {isPrimary && (
                <Button buttonColor={primaryButtonColor} onClick={onButtonClick}>{buttonText}</Button>
              )}
              {!isPrimary && (
                <WhiteButton onClick={onButtonClick}>{buttonText}</WhiteButton>
              )}
            </Box>
          </Flex>
          <Spacer />
          <Box>
            <Image src={icon} p='15px' width='75px' float='right' />
          </Box>
        </Flex>
      </Card>
    </Box>
  );
};
