// These monstrous functions automatically encode/decode object data.
// Their primary purpose is to find Uint8Arrays in arbitrarily-deep objects
// and transform them to/from hex-encoded versions for transit between
// client/server processes.
import sodium from 'libsodium-wrappers-sumo';
import bigInt = require('big-integer');

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const autoencode = (o: any): any => {
  if (o === null) {
    return null;
  }

  if (o instanceof Uint8Array) {
    return `hex$${sodium.to_hex(o)}`;
  }

  if (typeof o === 'bigint') {
    return `bigint$${o.toString(16)}`;
  }

  if (Array.isArray(o)) {
    const t: any[] = [];
    for (const p of o) {
      t.push(autoencode(p));
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return t;
  }

  if (o instanceof Date) {
    return `date$${o.toUTCString()}`;
  }

  if (typeof o === 'object') {
    const t: Record<string, unknown> = {};
    for (const b in o) {
      if ((o as Record<string, unknown>).hasOwnProperty(b)) {
        t[b] = autoencode((o as Record<string, unknown>)[b]);
      }
    }

    return t;
  }

  return o;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const autodecode = (e: any): any => {
  if (e === null) {
    return null;
  }

  if (typeof e === 'string') {
    if (e.startsWith('hex$')) {
      return sodium.from_hex(e.replace('hex$', ''));
    }

    if (e.startsWith('bigint$')) {
      return bigInt(e.replace('bigint$', ''), 16);
    }

    if (e.startsWith('date$')) {
      return new Date(e.replace('date$', ''));
    }
  }

  if (Array.isArray(e)) {
    const o: any[] = [];
    for (const k of e) {
      o.push(autodecode(k));
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return o;
  }

  if (typeof e === 'object') {
    const o: Record<string, unknown> = {};
    for (const k in e) {
      if ((e as Record<string, unknown>).hasOwnProperty(k)) {
        o[k] = autodecode((e as Record<string, unknown>)[k]);
      }
    }

    return o;
  }

  return e;
};
