import React from 'react';

const EmailIcon: React.FC = () => {
  const path1 = 'M0 19.7674C0 15.482 0 11.1911 0 6.90576C0.0601563 6.9386 0.13125 6.96597 0.185938 7.00975C3.99219 ' +
    '9.54923 7.79297 12.0832 11.5992 14.6227C12.4469 15.1864 13.3711 15.4491 14.3938 15.3506C15.1758 15.2795 15.8703 ' +
    '14.9839 16.5156 14.5516C20.2508 12.0559 23.9859 9.56565 27.7211 7.07543C27.8086 7.01522 27.907 6.96596 28 ' +
    '6.91123C28 11.1966 28 15.4875 28 19.7728C27.9836 19.833 27.9562 19.8932 27.9453 19.9534C27.8469 20.3858 ' +
    '27.6609 20.7799 27.3656 21.1137C26.8023 21.7541 26.0859 22.0113 25.2492 22.0113C18.3148 22.0058 11.3805 22.0113 ' +
    '4.44609 22.0113C3.83359 22.0113 3.22656 22.0168 2.61406 22.0113C1.44922 21.9949 0.486719 21.3053 ' +
    '0.136719 20.249C0.0820312 20.0793 0.04375 19.9206 0 19.7674Z';

  const path2 = 'M28 3.23944C28 3.47478 28 3.71559 28 3.95093C27.9891 3.96735 27.9727 3.97829 27.9672 3.99471C27.825 ' +
    '4.79377 27.3656 5.3575 26.693 5.78439C25.9055 6.28791 25.1289 6.82427 24.3523 7.3442C21.4047 9.30902 18.457 ' +
    '11.2793 15.5094 13.2441C14.4977 13.9173 13.4805 13.9009 12.4688 13.2332C12.2609 13.0964 12.0586 12.9595 11.8562 ' +
    '12.8227C8.30156 10.4529 4.75234 8.07759 1.19219 5.71324C0.639844 5.3356 0.251563 4.85398 0.0765625 ' +
    '4.20816C0.0546875 4.12059 0.0273438 4.03302 0 3.95093C0 3.71559 0 3.47478 0 3.23944C0.103906 2.94937 0.169531 ' +
    '2.6374 0.317188 2.3747C0.842188 1.42239 1.67891 1.00097 2.75078 1.00097C9.72344 1.00097 16.6906 1.00097 23.6633 ' +
    '1.00097C24.2375 1.00097 24.8117 0.995495 25.3859 1.00097C26.6984 1.02286 27.6883 1.84929 27.9563 3.13545C27.9617 ' +
    '3.17376 27.9836 3.2066 28 3.23944Z';

  return (
    <svg
      width="28"
      height="23"
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path1}
        fill="currentColor"
      />
      <path
        d={path2}
        fill="currentColor"
      />
    </svg>
  );
};

export default EmailIcon;
