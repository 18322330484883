import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useRef
} from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@chakra-ui/layout';
import AnimatedBox from '../../components/layout/AnimatedBox';
import OrfActionButtons from '../../components/OrfActionButtons';
import { PrivateRoutes } from '../../config/routes';
import {
  ITimeCheck,
  OrfContext
} from '../../contexts/OrfContext';
import MainCard from '../../components/MainCard';
import { wait } from '../../service/backend';
import {
  IFormProps,
  usePersistData
} from '../../service/hooks/usePersistData';
import { handleORFSubmit } from '../../components/layout/sidebar/CreateEditSidebar';
import { OrfWhenPageContent } from './OrfWhenPageContent';
import { useNavigate } from 'react-router-dom';

export type ISeasonCheck = 'spring' | 'summer' | 'fall' | 'winter';

export interface IWhenFormProps {
  memory: string;
  season: ISeasonCheck;
  hasExactDate: 'Yes' | 'No' | null;
  time: ITimeCheck;
  exactTime: string;
  year: Date | undefined;
  month: string;
  day: string;
}

const OrfWhen: React.FC = () => {
  const {
    state: { steps, currentRecordId, mode, changesMade },
    completeAction,
    updateORF,
    setSavingScreenVisibility
  } = useContext(OrfContext);
  const navigate = useNavigate();
  const { when } = steps;
  const formRef = useRef<HTMLFormElement>(null);

  const { handleSubmit, register, setValue, watch } = useForm<IWhenFormProps>({
    defaultValues: {
      season: when.season as ISeasonCheck,
      hasExactDate: when.hasExactDate,
      time: when.time.key as ITimeCheck,
      year: when.year ? new Date(Date.UTC(parseInt(when.year, 10), 1)) : undefined,
      month: when.month,
      day: when.day,
      exactTime:
        ((when.time.key as ITimeCheck) === 'exact time' && when.time.value) ||
        '',
      memory: when.memory,
    },
  });

  useEffect(() => {
    register('season');
    register('hasExactDate');
    register('time');
    register('year');

    const keypressListener = (e: KeyboardEvent) => {
      if (document.activeElement &&
        Array.from(document.getElementsByTagName('textarea'))
          .includes(document.activeElement as HTMLTextAreaElement)) {
        return;
      }

      if (e.key === 'Enter') {
        e.preventDefault();
      }
    };

    if (formRef.current) {
      formRef.current.addEventListener('keypress', keypressListener);
    }

    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener('keypress', keypressListener);
      }
    };

  }, []);

  const generatePayload = ({
    season,
    hasExactDate,
    time,
    exactTime,
    memory,
    year,
    month,
    day,
  }: IWhenFormProps) => ({
    season,
    time: {
      key: time,
      value: time === 'exact time' ? exactTime : time,
    },
    memory,
    hasExactDate,
    year: hasExactDate === 'Yes' && year ? year.getFullYear().toString() : '',
    month: hasExactDate === 'Yes' && month ? month : '',
    day: hasExactDate === 'Yes' && day ? day : '',
  });

  const saveForm = (formProps: IWhenFormProps) => {
    if (changesMade) {
      completeAction('when', generatePayload(formProps));
    }
  };

  const setWatchValuesRef = usePersistData(saveForm);

  useEffect(() => {
    setWatchValuesRef(watch() as IFormProps);
  });

  const onContinue = () => {
    navigate(when.nextStepRoute);
  };

  const handleSaveAndExit = async (e: SyntheticEvent) => {
    e.preventDefault();

    setSavingScreenVisibility(true);
    await wait(1000);
    const updatedWhen = generatePayload(watch());
    const updatedSteps = {
      ...steps,
      when: {
        ...when,
        ...updatedWhen,
        completed: !!updatedWhen.hasExactDate ||
          !!updatedWhen.season ||
          !!updatedWhen.time.value ||
          !!updatedWhen.memory
      }
    };

    await handleORFSubmit(
      mode,
      updatedSteps,
      updateORF,
      currentRecordId
    );
    setSavingScreenVisibility(false);
  };

  return (
    <AnimatedBox>
      <MainCard>
        <Box as="form" onSubmit={handleSaveAndExit} id="orf_form" ref={formRef}>
          <OrfWhenPageContent
            register={register}
            values={watch()}
            setValue={setValue}
          />
          <OrfActionButtons backRoute={PrivateRoutes.ORF_INTRO} handleClick={handleSubmit(onContinue)} />
        </Box>
      </MainCard>
    </AnimatedBox>
  );
};

export default OrfWhen;
