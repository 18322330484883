/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { Fragment } from 'react';
import { FieldData } from '../../../../../../lib/questionnaire/formFunctions';
import { resultType } from '../../../../../../lib/data';
import { PdfTextarea } from '../PdfTextarea';

interface ElementProps {
  path: string[];
  qKey: string;
  label: string;
  additionalInfo?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string | string[];
  required?: boolean;
  options?: {
    value: string;
    label?: string;
    question?: FieldData;
    type?: 'textRadioOption' | 'textMultiselectOption';
    className?: string;
    exclusive?: boolean;
  }[];

  getElement?: (
    data: FieldData,
    path: string[],
    results: Map<string, resultType>,
    setResults: (newResults: Map<string, resultType>) => void,
    disabled?: boolean,
    pdf?: boolean
  ) => React.JSX.Element;

  results: Map<string, resultType>;

  setResults: (newResults: Map<string, resultType>) => void;

  insertBreak?: boolean;

  className?: string;

  newQuestion?: boolean;
  disabled?: boolean;
  resultContainsKey?: boolean;
  name?: string;
  pdf?: boolean;
}

interface SubOptionProps extends ElementProps {
  qKey: string;
}

interface OptionProps {
  okey: string;
  value: string | string[];
  label: string;
  name?: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

export const TextInput: React.FC<ElementProps> = (props: ElementProps) => {
  const {
    qKey,
    label,
    additionalInfo,
    onChange,
    insertBreak,
    className,
    disabled,
    options,
    getElement,
    results,
    setResults,
    path,
    value,
    name,
    required,
    newQuestion
  } = props;

  const exclusiveKeys: string[] = [];
  if (options) {
    options.forEach((opt) => {
      if (opt.exclusive) {
        exclusiveKeys.push(qKey + opt.value);
      }
    });
  }

  return (
    <span>
      {newQuestion && <div className="new"></div>}
      <label htmlFor={qKey} className={newQuestion ? 'question' : ''}>{label}</label>
      {additionalInfo && (<span><br /><i>{additionalInfo}</i></span>)}
      {insertBreak && <br />}{!insertBreak && ' '}
      {className?.includes('pdf') && value && (
        <PdfTextarea mt='1em'>{value}</PdfTextarea>
      )}
      {!(className?.includes('pdf') && value) && (
        <input id={qKey} type='text' className={className} onChange={onChange}
          disabled={disabled} value={value} required={required} />
      )}
      <br />
      {(options ?? []).map((opt) => {
        if (getElement && opt.label) {
          return getElement({
            key: qKey + opt.value,
            label: opt.label,
            value: opt.value,
            type: opt.type,
            exclusiveKeys,
            name,
            required,
            layout: {
              className: opt.className
            }
          }, [...path, qKey + opt.value], results, setResults, disabled);
        }
        return <div key={qKey + opt.value}></div>;
      })}
    </span>
  );
};

export const TextAreaInput: React.FC<ElementProps> = (props: ElementProps) => {

  const {
    qKey,
    label,
    additionalInfo,
    onChange,
    insertBreak,
    className,
    disabled,
    value,
    required,
    newQuestion
  } = props;

  return (
    <span>
      {newQuestion && <div className="new"></div>}
      <label htmlFor={qKey} className={newQuestion ? 'question' : ''}>{label}</label>
      {additionalInfo && (<span><br /><i>{additionalInfo}</i></span>)}
      {insertBreak && <br />}{!insertBreak && ' '}
      {className?.includes('pdf') && value && (
        <PdfTextarea
          mt="1.5em"
          dangerouslySetInnerHTML={{ __html: (value as string).replaceAll('\n', '<br />') as string}}
        />
      )}
      {className?.includes('pdf') && !value && (
        <p style={{ marginTop: '1em', width: '100%', height: '9em' }} />
      )}
      {!className?.includes('pdf') && (
        <textarea id={qKey} className={className} onChange={onChange} disabled={disabled}
          value={value} required={required} />
      )}
    </span>
  );
};

export const NumberInput: React.FC<ElementProps> = (props: ElementProps) => {

  const {
    qKey,
    label,
    value,
    additionalInfo,
    onChange,
    insertBreak,
    className,
    disabled,
    required,
    newQuestion
  } = props;

  return (
    <span>
      {newQuestion && <div className="new"></div>}
      <label htmlFor={qKey} className={newQuestion ? 'question' : ''}>{label}</label>
      {additionalInfo && (<span><br /><i>{additionalInfo}</i></span>)}
      {insertBreak && <br />}{!insertBreak && ' '}
      {className?.includes('pdf') && value && (
        <PdfTextarea mt='1em'>{value}</PdfTextarea>
      )}
      {!(className?.includes('pdf') && value) && (
        <input id={qKey} type='number' className={className} onChange={onChange}
          disabled={disabled} required={required} value={value} />
      )}
    </span>
  );
};

export const RadioInput: React.FC<ElementProps> = (props: ElementProps) => {
  const {
    qKey,
    label,
    additionalInfo,
    options,
    getElement,
    results,
    setResults,
    disabled,
    path,
    name,
    required,
    newQuestion,
    pdf
  } = props;

  return (
    <span>
      {newQuestion && <div className="new"></div>}
      <Fragment>
        <label htmlFor={qKey}>{label}</label><br />
        {additionalInfo && (<span><br /><i>{additionalInfo}</i></span>)}
        {(options ?? []).map((opt) => {
          if (getElement && opt.label) {
            return getElement({
              key: qKey + opt.value,
              label: opt.label,
              value: opt.value,
              type: 'radioOption',
              name,
              required,
              layout: {
                className: opt.className
              }
            }, [...path, qKey + opt.value], results, setResults, disabled, pdf);
          } else if (getElement && opt.question) {
            return <div key={qKey + opt.value + 'div'}>
              {getElement({
                key: qKey + opt.value,
                value: '',
                label: opt.question.label,
                type: 'subradio',
                name,
                required
              }, [...path, qKey + opt.value], results, setResults)}
              {getElement(
                { ...opt.question, key: qKey + opt.value, required },
                [...path, qKey + opt.value],
                results,
                setResults,
                false,
                pdf)}
            </div>;
          }

          return <div key={qKey + opt.value}></div>;
        })}
      </Fragment>
    </span>
  );
};

export const MultiselectInput: React.FC<ElementProps> = (props: ElementProps) => {
  const {
    qKey,
    label,
    additionalInfo,
    options,
    getElement,
    results,
    setResults,
    path,
    disabled,
    newQuestion,
    pdf
  } = props;

  const exclusiveKeys: string[] = [];
  if (options) {
    options.forEach((opt) => {
      if (opt.exclusive) {
        exclusiveKeys.push(qKey + opt.value);
      }
    });
  }

  return (
    <span>
      {newQuestion && <div className="new"></div>}
      <label htmlFor={qKey} className={newQuestion ? 'question' : ''}>{label}</label><br />
      {additionalInfo && (<span><br /><i>{additionalInfo}</i></span>)}
      {(options ?? []).map((opt) => {
        if (getElement && opt.label) {
          return getElement({
            key: qKey + opt.value,
            label: opt.label,
            value: opt.value,
            type: 'multiselectOption',
            exclusiveKeys,
            layout: {
              className: opt.className
            }
          }, [...path, qKey + opt.value], results, setResults, disabled, pdf);
        } else if (getElement && opt.question) {
          return <div key={qKey + opt.value + 'div'}>
            {getElement({
              key: qKey + opt.value,
              label: opt.question.label,
              value: '',
              type: 'submultiselect',
              exclusiveKeys
            }, [...path, qKey + opt.value], results, setResults, disabled, pdf)}
            {getElement(
              { ...opt.question, key: qKey + opt.value, exclusiveKeys },
              [...path, qKey + opt.value],
              results,
              setResults,
              false,
              pdf
            )}
          </div>;
        }

        return <div key={qKey + opt.value}></div>;
      })}
    </span>
  );
};

export const SubRadioInput: React.FC<ElementProps> = (props: SubOptionProps) => {
  const {
    qKey,
    value,
    disabled,
    resultContainsKey,
    onChange,
    className,
    name,
    required
  } = props;
  return (
    <input type='radio' id={qKey} key={value as string} value={value}
      checked={!disabled && resultContainsKey} onChange={onChange} required={required}
      className={`radioButton ${className}`} disabled={disabled} name={name} />
  );
};

export const SubMultiselectInput: React.FC<ElementProps> = (props: SubOptionProps) => {
  const {
    qKey,
    value,
    disabled,
    resultContainsKey,
    onChange,
    className
  } = props;
  return (
    <input type='checkbox' id={qKey} key={value as string} value={value}
      checked={!disabled && resultContainsKey}
      onChange={onChange} className={`checkbox ${className}`}
      disabled={disabled}
    />
  );
};

export const RadioOption: React.FC<OptionProps> = (props: OptionProps) => {
  const {
    okey,
    value,
    label,
    checked,
    onChange,
    className,
    disabled,
    name,
    required
  } = props;
  return (
    <div key={okey + 'div'}>
      <input type='radio' id={okey} key={okey} value={value} checked={!disabled && checked}
        onChange={onChange} className={`radioButton ${className}`} disabled={disabled}
        name={name} required={required}
      />
      <label htmlFor={okey}>{label}</label>
    </div>
  );
};

export const MultiselectOption: React.FC<OptionProps> = (props: OptionProps) => {
  const {
    value,
    disabled,
    onChange,
    className,
    label,
    checked,
    okey
  } = props;

  return (
    <div key={okey + 'div'}>
      <input type='checkbox' id={okey} key={okey} value={value}
        checked={!disabled && checked}
        onChange={onChange} className={`checkbox ${className}`}
        disabled={disabled}
      />
      <label htmlFor={okey}>{label}</label>
    </div>
  );
};
