import React from 'react';
import { CheckboxGroup } from '@chakra-ui/react';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  value: any;
  setValue: (value: string) => void;
}

const CheckboxRadio: React.FC<Props> = (
  ({
    children,
    value,
    setValue,
  }: Props) => {
    const handleChange = (values: string[]) => {
      if (values.length > 1) {
        setValue(values.filter((v) => v !== value)[0]);
      } else {
        setValue('');
      }
    };

    return (
      <CheckboxGroup value={[value]} onChange={handleChange}>
        {children}
      </CheckboxGroup>
    );
  });

export default CheckboxRadio;
