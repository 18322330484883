import { KeyPair } from 'libsodium-wrappers-sumo';
import { Box } from './box';
import { tKey } from '../../src/server/util/iots';

export class KeyGenerationHelper {

  public static generateSharedKeys(): KeyPair {
    return Box.keygen();
  }

  public static encryptSharedKeys(
    publicKeys: Record<string, Uint8Array>,
    sharedKey: Uint8Array
  ): { userId: string; encryptedKey: Uint8Array }[] {
    const encryptedKeys: {  userId: string; encryptedKey: Uint8Array }[] = [];
    for (const userId in publicKeys) {
      if (publicKeys.hasOwnProperty(userId)) {
        encryptedKeys.push({
          userId,
          encryptedKey: Box.tsEncrypt(publicKeys[userId], sharedKey, tKey)
        });
      }
    }

    return encryptedKeys;
  }
}
