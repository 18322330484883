import React, { MouseEventHandler } from 'react';

interface HyperlinkProps {
  to: string;
  text?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  target?: string;
}

export const Hyperlink: React.FC<HyperlinkProps> = ({ to, text, onClick, target }) => (
  <a href={to} onClick={onClick} target={target}>{text ?? to}</a>
);
