import { CALLISTO_STATIC_URL } from './env';

const INCIDENT_LOG_ROUTE_BASE = '/incident-log';

export const PublicRoutes = {
  LOGIN: '/log-in',
  SIGN_UP: '/create-account',
  SIGN_UP_FOREIGN_EDU: '/create-account/not-us-based-edu',
  SIGN_UP_CREDENTIAL_TOKEN: '/create-account/credentials/token/:token',
  SIGN_UP_CREDENTIAL: '/create-account/credentials',
  SIGN_UP_TOKEN: '/create-account/token/:token',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_NEW: '/reset-password/new',
  REQUEST_ACCOUNT_RECOVERY: '/recover-account',
  FEEDBACK: '/feedback',
  RECOVERY_EMAIL_ADDRESS: '/create-account/recovery-email',
  RECOVER_ACCOUNT_TOKEN: '/recover-account/token/:token',
  ANSWER_SECURITY_QUESTIONS: '/recover-account/security-questions',
  VERIFY_RECOVERY_EMAIL_TOKEN: '/verify-recovery-email/token/:token',
  VERIFY_RECOVERY_EMAIL: '/verify-recovery-email'
};

export const PrivateRoutes = {
  DASHBOARD: '/dashboard',
  CONSENT_SETTINGS: '/consent-settings',
  DEMOGRAPHICS: '/demographics',
  MATCHING_SYSTEM: '/matching-system',
  ENTRIES_EDIT: '/entries/edit',
  ACCOUNT: '/account-settings',
  CREATE_WHERE: '/create/where',
  CREATE_WHO: '/create/who',
  CREATE_WHO_SOCIAL: '/create/who/social-accounts',
  CREATE_CONTACT: '/create/contact',
  CREATE_SUBMIT: '/create/submit',
  ORF_INTRO: '/orf/intro',
  ORF_WHEN: '/orf/when',
  ORF_WHERE: '/orf/where',
  ORF_KNOW: '/orf/know',
  ORF_CONSENT: '/orf/consent',
  ORF_INCIDENTS: '/orf/incidents',
  ORF_OFFENDERS: '/orf/offenders',
  ORF_EVIDENCE: '/orf/evidence',
  ORF_REVIEW: '/orf/review',
  ORF_PDF_DOWNLOAD: '/printpdf',
  CONTACT_US: '/contact-us',
  RECOVERY_SETUP: '/create-account/setup-recovery',
  ILOG: `${INCIDENT_LOG_ROUTE_BASE}`,
  ILOG_WHO_WHAT: `${INCIDENT_LOG_ROUTE_BASE}/whowhat`,
  ILOG_WHEN: `${INCIDENT_LOG_ROUTE_BASE}/when`,
  ILOG_WHERE: `${INCIDENT_LOG_ROUTE_BASE}/where`,
  ILOG_PEOPLE: `${INCIDENT_LOG_ROUTE_BASE}/people`,
  ILOG_CONSENT: `${INCIDENT_LOG_ROUTE_BASE}/consent`,
  ILOG_INCIDENT: `${INCIDENT_LOG_ROUTE_BASE}/incident`,
  ILOG_OFFENDERS: `${INCIDENT_LOG_ROUTE_BASE}/offenders`,
  ILOG_EVIDENCE: `${INCIDENT_LOG_ROUTE_BASE}/evidence`,
  ILOG_NOTES: `${INCIDENT_LOG_ROUTE_BASE}/notes`,
  ILOG_SUMMARY: `${INCIDENT_LOG_ROUTE_BASE}/summary`,
  ILOG_PDF_DOWNLOAD: `${INCIDENT_LOG_ROUTE_BASE}/printpdf`,
  RESOURCES: '/resources'
};

export const CallistoRoutes = {
  DETECT_REPEAT_OFFENDERS: `${CALLISTO_STATIC_URL}/callistovault`,
  NAVIGATE_YOUR_OPTIONS: `${CALLISTO_STATIC_URL}/should-i-report`,
  ABOUT_US: `${CALLISTO_STATIC_URL}/about`,
  CONTACT_US: `${CALLISTO_STATIC_URL}/contact-us`,
  FIND_RESOURCES: `${CALLISTO_STATIC_URL}/talk-to-someone`,
  UNDERSTAND_LANGUAGE: `${CALLISTO_STATIC_URL}/definitions`,
  CALLISTO_HOME: `${CALLISTO_STATIC_URL}`,
  GET_INVOLVED: `https://give.projectcallisto.org/campaign/connect-survivors-of-repeat-perpetrators-across-the-nation/c449415`,
  TERMS_OF_SERVICE: `${CALLISTO_STATIC_URL}/terms-of-service`,
  PRIVACY_POLICY: `${CALLISTO_STATIC_URL}/callisto-vault-privacy-policy`,
  404: `${CALLISTO_STATIC_URL}/404`,
};
