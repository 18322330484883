import React from 'react';
import { useForm } from 'react-hook-form';
import { useVerifyRecoveryEmail } from '../../service/hooks/auth';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Text,
  useToast
} from '@chakra-ui/react';
import * as emailValidator from 'email-validator';
import ContainerWrapper from '../../components/ContainerWrapper';
import IconInput from '../../components/IconInput';
import EmailIcon from '../../../assets/email_navy.svg';
import Button from '../../components/Button';
import { CallistoRoutes } from '../../config/routes';
import GiveFeedbackLink from '../../components/GiveFeedbackLink';
import { ResendVerifyRecoveryEmail } from './ResendVerifyRecoveryEmail';

interface RecoveryEmailForm {
  email: string;
  confirmEmail: string;
}

export const RecoveryEmailEntry: React.FC = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<RecoveryEmailForm>();
  const {
    mutateAsync: verifyRecoveryEmail,
    isLoading,
    data: verificationSent
  } = useVerifyRecoveryEmail();
  const toast = useToast();
  const { state: { token} } = useLocation<{
    token: string;
  }>();

  const onSubmit = async ({ email }: RecoveryEmailForm) => {
    if (!emailValidator.validate(email)) {
      toast({
        position: 'top',
        status: 'error',
        title: 'Please enter a valid email address',
        duration: 9000,
        isClosable: true
      });
      return;
    }

    try {
      const { success } = await verifyRecoveryEmail({ email: email?.toLowerCase().trim(), token });
      if (!success) {
        toast({
          title: 'Unable to send verification email. Please try again.',
          status: 'error',
          isClosable: true,
          duration: 9000,
          position: 'top'
        });
      }
    } catch {
      toast({
        title: 'There was an error validating your email. Please try again.',
        status: 'error',
        position: 'top'
      });
    }
  };

  return (
    <ContainerWrapper
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      {!isLoading && verificationSent?.success && (
        <ResendVerifyRecoveryEmail
          email={getValues().email}
          token={token}
        />

      )}
      <Box
        maxWidth="850px"
        as="form"
        backgroundColor="brand.white"
        padding={['20px 20px', '30px 40px', '60px 80px']}
        onSubmit={handleSubmit(onSubmit)}
        display={verificationSent?.success ? 'none' : 'flex'}
        flexDir="column"
        alignItems="center"
      >
        <Text as="h1" fontSize="xl" fontFamily="Avenir">
          Enter a recovery email address
        </Text>
        <Text as="p" fontSize="sm" py="15px" textAlign="left">
          No one plans on losing access to their account, but if it does happen to you, Callisto is
          committed to making the process of recovering your account as secure as possible. As many
          people lose access to their .edu account when they graduate or are no longer employed by
          the university, we ask that you validate a personal email address with us to be used for
          broadcast communications and account recovery. (Broadcast emails are sent through the
          Callisto Vault system and Callisto employees never have access to your email address.)
        </Text>
        <Text width="100%" marginBottom="0" fontSize="smaller" textAlign="left">
          Recovery Email* (We encourage you to use a personal email address that is not your .edu email.)
        </Text>
        <IconInput
          icon={EmailIcon as string}
          type="email"
          placeholder="Enter your recovery email address"
          {...register('email')}
          isRequired
          minWidth="100%"
        />
        <Text width="100%" marginBottom="0" fontSize="smaller" textAlign="left" mt="15px">
          Confirm Recovery Email*
        </Text>
        <IconInput
          icon={EmailIcon as string}
          type="email"
          placeholder="Reenter your recovery email address"
          {...register('confirmEmail', {
            validate: () =>
              getValues().email === getValues().confirmEmail,
            required: {
              value: true,
              message: 'Please confirm your recovery email address'
            }
          })}
          isRequired
          minWidth="100%"
        />
        <Text color="red" textAlign="left" width="100%" mt="10px">
          {errors.confirmEmail && errors.confirmEmail.type === 'validate' && 'Email addresses do not match.'}
        </Text>
        <Button
          mt="28px"
          type="submit"
          buttonColor="brand.primary"
          isLoading={isLoading}
        >
          Submit
        </Button>
      </Box>
      <a
        href={CallistoRoutes.TERMS_OF_SERVICE}
        target="_blank"
        rel="noreferrer"
        style={hyperLinkStyles}
      >
        <Box mt="10px">Terms of Service</Box>
      </a>
      <a
        href={CallistoRoutes.PRIVACY_POLICY}
        target="_blank"
        rel="noreferrer"
        style={hyperLinkStyles}
      >
        <Box>Privacy Policy</Box>
      </a>
      <GiveFeedbackLink />
    </ContainerWrapper>
  );
};

const hyperLinkStyles = {
  display: 'grid',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginBottom: '3px',
  fontFamily: 'Avenir',
  fontWeight: 'bold' as const,
  fontSize: '16px',
  textDecoration: 'underline',
  padding: '0px 15px',
  overflow: 'hidden',
};

