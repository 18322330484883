import { ActionMap, Step } from '../type';

export const STEP_WHO_TEXT = 'Who was the offender?';

export enum StepWhoActionTypes {
  SET_IDENTIFIERS = 'SET_IDENTIFIERS',
  MARK_AS_COMPLETED = 'MARK_AS_COMPLETED_WHO',
  MARK_AS_IN_COMPLETED = 'MARK_AS_IN_COMPLETED_WHO',
}

type StepWhoPayload = {
  [StepWhoActionTypes.SET_IDENTIFIERS]: IIdentifiers;
  [StepWhoActionTypes.MARK_AS_COMPLETED]: undefined;
  [StepWhoActionTypes.MARK_AS_IN_COMPLETED]: undefined;
};

export type StepWhoActions = ActionMap<StepWhoPayload>[keyof ActionMap<StepWhoPayload>];

export interface IIdentifiers {
  [key: string]: string[];
  twitter: [];
  facebook: [];
  linkedin: [];
  instagram: [];
  snapchat: [];
  tiktok: [];
  whatsapp: [];
  email: [];
  phone: [];
}

export interface StepWhoState extends Step {
  identifiers: IIdentifiers;
  originalIdentifiers: IIdentifiers;
  changesMade: boolean;
}

const stepWhoReducer = (state: StepWhoState, action: StepWhoActions): StepWhoState => {
  switch (action.type) {
    case StepWhoActionTypes.MARK_AS_COMPLETED:
      return {
        ...state,
        completed: true,
      };
    case StepWhoActionTypes.MARK_AS_IN_COMPLETED:
      return {
        ...state,
        completed: false,
      };
    case StepWhoActionTypes.SET_IDENTIFIERS:
      return {
        ...state,
        identifiers: action.payload,
        changesMade: !identifiersEqual(action.payload, state.identifiers),
      };
    default:
      return state;
  }
};

export default stepWhoReducer;

const arrayEquals = (a: string[], b: string[]): boolean =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, index) => val === b[index]);

const identifiersEqual = (a: IIdentifiers, b: IIdentifiers): boolean => {
  for(const k of Object.keys(a)) {
    if(!arrayEquals(a[k], b[k])) {
      return false;
    }
  }
  return true;
};
