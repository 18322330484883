import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useAccountRecoveryToken } from '../../service/hooks/auth';
import { PublicRoutes } from '../../config/routes';

export const RecoverAccountToken: React.FC = () => {
  const { token: input } = useParams<{ token: string }>();
  const toast = useToast();
  const navigate = useNavigate();
  const { mutateAsync: verifyAccountRecoveryToken } = useAccountRecoveryToken();

  useEffect(() => {
    const verify = async () => {
      try {
        // The input might have had dummy query parameters added by the survivor's email system. We strip them off here.
        const inputParts = input.split('&');
        const token = inputParts[0];
        const {
          success,
          validation
        } = await verifyAccountRecoveryToken({ token });
        if (success) {
          navigate(PublicRoutes.ANSWER_SECURITY_QUESTIONS, {
            state: {
              token,
              validation
            }
          });
          toast({
            title: 'Successfully verified. Please answer your security questions',
            status: 'success',
            isClosable: true,
            position: 'top'
          });
        } else {
          toast({
            title: 'Unable to retrieve security questions',
            status: 'error',
            isClosable: true,
            position: 'top'
          });
          navigate(PublicRoutes.LOGIN);
        }
      } catch (e) {
        toast({
          title: 'There was an error retrieving your security questions. Please try again.',
          status: 'error',
          isClosable: true,
          position: 'top',
          duration: 10000
        });
        navigate(PublicRoutes.LOGIN);
      }
    };

    void verify();
  }, []);

  return <LoadingIndicator />;
};
