import React, {
  ChangeEvent,
  useContext
} from 'react';
import { IOffendersFormProps } from './index';
import HeaderText from '../../components/HeaderText';
import SubHeader from '../../components/SubHeader';
import Input from '../../components/Input';
import Textarea from '../../components/Textarea';
import CheckboxRadio from '../../components/CheckboxRadio';
import Checkbox from '../../components/CheckBox';
import {
  OrfContext,
  YesNoSure
} from '../../contexts/OrfContext';
import { OrfQuestion } from '../../components/OrfQuestion';
import { PdfTextarea } from '../../components/PdfTextarea';
import {
  Box,
  Text,
  VStack
} from '@chakra-ui/react';
import { onlineCheckboxProps } from '../Orfs';
import {
  UseFormRegister,
  UseFormSetValue
} from 'react-hook-form';

const YES_NO_SURES: YesNoSure[] = ['Yes', 'No', `I'm not sure`];

interface OffendersPageContentProps {
  values: IOffendersFormProps;
  pdf?: boolean;
  setValue: UseFormSetValue<IOffendersFormProps>;
  register: UseFormRegister<IOffendersFormProps>;
  textWidth?: string;
}

export const OrfOffenderPageContent: React.FC<OffendersPageContentProps> =
  ({
    values,
    setValue,
    register,
    pdf = false,
    textWidth
  }: OffendersPageContentProps) => {
    const { setModified } = useContext(OrfContext);
    const examples = 'Examples: cell phone number, what job they have, if you’ve seen them before, ' +
      'how you know them, any physical characteristics (hair color, identifiable marks, tattoos, ' +
      'clothing, moles or birthmarks), or anything you could not forget about them.';
    const { onChange: onChangeNumber } = register('numberOfOffenders');
    const { onChange: onChangeName } = register('name');
    const { onChange: onChangeOffenderDetails } = register('offenderDetails');
    const { onChange: onChangeCompanionDetails } = register('companionDetails');

    const checkboxProps = onlineCheckboxProps;

    const subHeaderProps = {
      marginTop: '30px',
      marginBottom: '16px',
      pdf
    };

    return (
      <div style={{ width: textWidth }}>
        <HeaderText pdf={pdf}>Offenders</HeaderText>
        <SubHeader {...subHeaderProps}>
          <OrfQuestion pdf={pdf}>
            How many offenders were there?
          </OrfQuestion>
          <br />
          <Text as="span" mt={pdf ? '5px' : ''}>It’s okay if you are not sure, just put what you can remember.</Text>
        </SubHeader>
        { !pdf && <Input
          {...register('numberOfOffenders')}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            await onChangeNumber(e);
            setModified();
          }}
        /> }
        { pdf &&
          <PdfTextarea>{values.numberOfOffenders}</PdfTextarea>
        }
        <SubHeader {...subHeaderProps}>
          <OrfQuestion pdf={pdf}>
            What is their name?/What are their names? (if known)
          </OrfQuestion>
        </SubHeader>
        {!pdf &&
          <Input
            {...register('name')}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeName(e);
              setModified();
            }}
          />
        }
        { pdf &&
          <PdfTextarea>{values.name}</PdfTextarea>
        }
        <SubHeader {...subHeaderProps}>
          <OrfQuestion pdf={pdf}>
            Do you know any other information about the offender(s)?
          </OrfQuestion>
        </SubHeader>
        {(!pdf || !values.offenderDetails) &&
          <Textarea
            placeholder={examples}
            rows={7}
            height="auto"
            {...register('offenderDetails')}
            onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
              await onChangeOffenderDetails(e);
              setModified();
            }}
          />
        }
        {pdf && values.offenderDetails && <PdfTextarea>{values.offenderDetails}</PdfTextarea>}
        <SubHeader {...subHeaderProps}>
          <OrfQuestion pdf={pdf}>
            Was the offender with anyone else either at the time of the
            incident, or directly before or after?
          </OrfQuestion>
        </SubHeader>
        <CheckboxRadio
          value={values.anyoneElse}
          setValue={(v) => setValue('anyoneElse', v as YesNoSure)}
        >
          <VStack alignItems="flex-start">
            {!pdf && YES_NO_SURES.map((v) => (
              <Checkbox value={v as string}
                key={v}
                onChange={setModified}
                {...checkboxProps}>
                {v as string}
              </Checkbox>
            ))}
            {pdf && YES_NO_SURES.map((v) => (
              <Box key={`${v}-div`} as="div" fontSize="sm">
                <label key={`${v}-label`}>
                  <input
                    key={v}
                    type="checkbox"
                    checked={values.anyoneElse === v}
                    readOnly={true}
                    className="pdf"
                  /> {v}
                </label>
              </Box>
            ))}
          </VStack>
        </CheckboxRadio>
        <SubHeader {...subHeaderProps}>
          <OrfQuestion pdf={pdf}>
            What information do you know about these people?
          </OrfQuestion>
        </SubHeader>
        {(!pdf || !values.companionDetails) &&
          <Textarea
            placeholder=""
            rows={7}
            height="auto"
            {...register('companionDetails')}
            onChange={async (e: ChangeEvent<HTMLTextAreaElement>) => {
              await onChangeCompanionDetails(e);
              setModified();
            }}
          />
        }
        {pdf && values.companionDetails &&
          <PdfTextarea>{values.companionDetails}</PdfTextarea>
        }
      </div>
    );
  };
