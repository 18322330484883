import * as t from 'io-ts';
import {
  tSodiumBytes,
  tSodiumDate
} from './util/iots';
import { tShare } from './endpoints';

export const survivorRpcSpec = {
  GetLocAndDlocKeys: {
    input: t.unknown,
    output: t.type({
      locKey: t.union([tSodiumBytes, t.undefined]),
      locKeyVersion: t.union([t.number, t.undefined]),
      dlocKey: tSodiumBytes,
      dlocKeyVersion: t.number
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  CreateMatchingEntry: {
    input: t.type({
      id: t.string,
      entryPublicKey: tSodiumBytes,
      encrypted: tSodiumBytes,
      shares: t.array(tShare),
      encryptedKey: tSodiumBytes,
      encryptedAssignmentData: tSodiumBytes,
      dlocKeyVersion: t.number
    }),
    output: t.unknown
  },

  SaveContactInformation: {
    input: t.type({
      encryptedKey: t.union([tSodiumBytes, t.null]),
      encryptedContactInfo: tSodiumBytes,
      encryptedUserData: tSodiumBytes,
      dlocKeyVersion: t.union([t.number, t.null])
    }),
    output: t.unknown
  },

  SaveDraftEntry: {
    input: t.type({
      id: t.string,
      publicKey: t.union([tSodiumBytes, t.undefined]),
      encrypted: tSodiumBytes
    }),
    output: t.unknown
  },

  DeleteDraftEntry: {
    input: t.type({
      id: t.string,
      signature: tSodiumBytes
    }),
    output: t.unknown
  },

  SendFeedback: {
    input: t.type({
      emailAddress: t.union([t.string, t.null]),
      message: t.string
    }),
    output: t.type({
      success: t.boolean
    })
  },

  CreateIncidentLog: {
    input: t.type({
      id: t.string,
      encrypted: tSodiumBytes,
      publicKey: tSodiumBytes,
      survivorMarker: t.string
    }),
    output: t.type({
      incidentLog: t.type({
        id: t.string,
        created: tSodiumDate,
        updated: tSodiumDate,
        encrypted: tSodiumBytes,
        publicKey: tSodiumBytes
      })
    })
  },

  UpdateIncidentLog: {
    input: t.type({
      id: t.string,
      encrypted: tSodiumBytes,
      signature: tSodiumBytes
    }),
    output: t.type({
      incidentLog: t.type({
        id: t.string,
        created: tSodiumDate,
        updated: tSodiumDate,
        encrypted: tSodiumBytes,
        publicKey: tSodiumBytes
      })
    })
  },

  DeleteIncidentLog: {
    input: t.type({
      id: t.string,
      signature: tSodiumBytes
    }),
    output: t.type({
      success: t.boolean
    })
  },

  GetIncidentLogs: {
    input: t.type({
      ids: t.array(t.string)
    }),
    output: t.type({
      incidentLogs: t.array(t.type({
        id: t.string,
        created: tSodiumDate,
        updated: tSodiumDate,
        encrypted: tSodiumBytes,
        publicKey: tSodiumBytes
      }))
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  SurvivorUpdateConsent: {
    input: t.type({
      consentGiven: t.boolean,
      whatFor: t.string,
      survivorMarker: t.string
    }),
    output: t.type({
      consentId: t.string
    })
  },

  SurvivorUpdateEmail: {
    input: t.type({
      encryptedEmail: tSodiumBytes,
      adminKeyVersion: t.number,
      survivorMarker: t.string
    }),
    output: t.unknown
  },

  SurvivorGetConsents: {
    input: t.type({
      consentIds: t.array(t.string)
    }),
    output: t.type({
      consentStatuses: t.record(t.string, t.boolean)
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  VerifyRecoveryEmail: {
    input: t.type({
      email: t.string,
      token: t.string,
      path: t.string
    }),
    output: t.type({
      success: t.boolean
    })
  },

  VerifyRecoveryEmailUpdate: {
    input: t.type({
      email: t.string,
      encryptedData: tSodiumBytes,
      path: t.string,
      legacySetup: t.boolean
    }),
    output: t.type({
      success: t.boolean
    })
  },

  VerifySignupCredentialsToken: {
    input: t.type({
      token: t.string,
      emailPublicKey: tSodiumBytes
    }),
    output: t.type({
      success: t.boolean,
      campusIdentified: t.boolean,
      emailDomain: t.union([t.string, t.undefined]),
      encryptedEmail: tSodiumBytes,
      signupToken: t.string
    })
  },

  VerifyRecoveryEmailToken: {
    input: t.type({
      token: t.string
    }),
    output: t.type({
      encryptedData: tSodiumBytes,
      legacySetup: t.boolean
    })
  },

  LookUpCampus: {
    input: t.type({
      emailDomain: t.string
    }),
    output: t.type({
      success: t.boolean,
      campusId: t.union([t.string, t.null, t.undefined])
    })
  },

  SetCurrentCampus: {
    input: t.type({
      campusId: t.union([t.string, t.undefined]),
      campusName: t.union([t.string, t.undefined]),
      emailDomain: t.union([t.string, t.undefined])
    }),
    output: t.type({
      success: t.boolean,
      campusName: t.string
    })
  }
};
