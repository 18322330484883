import {
  useMutation,
  UseMutationResult
} from 'react-query';
import { BADGE_SALT } from '../../config/env';

import { survivorClient } from '../backend';

export interface ICreateAccountRequest {
  username: string;
  password: string;
  token: string;
  privacyPolicyAccepted: boolean;
  campusName?: string;
  emailDomain?: string;
}

export const useVerifyEmail = (): UseMutationResult<{ success: boolean; foreignEdu: boolean }, unknown, {email: string}> =>
  useMutation(({ email }: { email: string }) =>
    survivorClient.verifyEmail(email)
  );

export const useToken = (): UseMutationResult<{
  success: boolean;
  campusIdentified: boolean;
  emailDomain?: string;
}, unknown, {token: string}> =>
  useMutation(({ token }: { token: string }) =>
    survivorClient.verifyToken(token)
  );

export const useCheckPasswordStrength = (): UseMutationResult<void, unknown, {password: string}> =>
  useMutation(({ password }: { password: string }) =>
    survivorClient.checkPasswordStrength(password)
  );

export const useCreateAccount = (): UseMutationResult<void, unknown, ICreateAccountRequest> =>
  useMutation((request: ICreateAccountRequest) =>
    survivorClient.createAccount(
      request.username,
      request.password,
      request.token,
      request.privacyPolicyAccepted,
      BADGE_SALT,
      request.campusName,
      request.emailDomain)
  );

export const useGenerateBackupCodes = (): UseMutationResult<string[], unknown, void> =>
  useMutation(() => survivorClient.createBackups(5, BADGE_SALT));

export const useSendBackupCodes = (): UseMutationResult<void, unknown, {
  email: string;
  backupCodes: string[];
}> =>
  useMutation(
    ({ email, backupCodes }: { email: string; backupCodes: string[] }) =>
      survivorClient.emailBackupCodes(email, backupCodes)
  );

export const useLogin = (): UseMutationResult<void, unknown, {
  username: string;
  password: string;
}> =>
  useMutation(
    ({ username, password }: { username: string; password: string }) =>
      survivorClient.login(username, password, BADGE_SALT)
  );

export const useBackupCode = (): UseMutationResult<void, unknown, {code: string}> =>
  useMutation(({ code }: { code: string }) =>
    survivorClient.useBackupCode(code, BADGE_SALT)
  );

export const useBurnBackupCode = (): UseMutationResult<void, unknown, {
  code: string;
  password: string;
}> =>
  useMutation(({ code, password }: { code: string; password: string }) =>
    survivorClient.burnBackupCode(code, password, BADGE_SALT)
  );

export const useAccountRecoveryToken = (): UseMutationResult<{
  success: boolean;
  validation: {
    questions: string[];
  };
}, unknown, { token: string }> =>
  useMutation(({ token }: { token: string }) =>
    survivorClient.verifyAccountRecoveryToken(token)
  );

export const useSignupCredentialsToken = (): UseMutationResult<{
  success: boolean;
  campusIdentified: boolean;
  emailDomain?: string;
  email: string;
  signupToken: string;
}, unknown, {token: string}> =>
  useMutation(({ token }: { token: string }) =>
    survivorClient.verifySignupCredentialsToken(token)
  );

export const useVerifyRecoveryEmail = (): UseMutationResult<{ success: boolean }, unknown, {
  email: string;
  token: string;
}> =>
  useMutation(({ email, token }: {
    email: string;
    token: string;
  }) =>
    survivorClient.verifyRecoveryEmail(email, token)
  );

export const useVerifyRecoveryEmailToken = (): UseMutationResult<{
  encryptedData: Uint8Array;
  legacySetup: boolean;
}, unknown, { token: string }> =>
  useMutation(({ token }: { token: string }) =>
    survivorClient.verifyRecoveryEmailToken(token)
  );

export const useVerifyRecoveryEmailUpdate = (): UseMutationResult<{ success: boolean }, unknown, {
  email: string;
  phone: string;
  legacySetup?: boolean;
}> =>
  useMutation(({ email, phone, legacySetup }: {
    email: string;
    phone: string;
    legacySetup?: boolean;
  }) =>
    survivorClient.verifyRecoveryEmailChange(email, phone, legacySetup)
  );

export const useSetUpAccountRecovery = (): UseMutationResult<{ success: boolean }, unknown, {
  consentsToFeedbackEmails: boolean;
  email: string;
  phoneNumber: string;
  securityQuestions: string[];
  answers: string[];
}> =>
  useMutation(({
    consentsToFeedbackEmails,
    email,
    phoneNumber,
    securityQuestions,
    answers
  }: {
    consentsToFeedbackEmails: boolean;
    email: string;
    phoneNumber: string;
    securityQuestions: string[];
    answers: string[];
  }) =>
    survivorClient.setUpAccountRecovery(consentsToFeedbackEmails, email, phoneNumber, securityQuestions, answers)
  );

export const useRequestAccountRecovery = (): UseMutationResult<{ success: boolean }, unknown, {
  emailAddress: string;
  phoneNumber: string;
}> =>
  useMutation(({ emailAddress, phoneNumber }: {
    emailAddress: string;
    phoneNumber: string;
  }) =>
    survivorClient.submitAccountRecoveryRequest(emailAddress, phoneNumber)
  );

export const useValidateAnswers = (): UseMutationResult<boolean, unknown, {
  answer0: string;
  answer1: string;
  answer2: string;
  token: string;
}> =>
  useMutation(({ answer0, answer1, answer2, token }: {
    answer0: string;
    answer1: string;
    answer2: string;
    token: string;
  }) =>
    survivorClient.validateSecurityQuestionAnswers([answer0, answer1, answer2], token)
  );
