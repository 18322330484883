import React from 'react';
import {
  Flex,
  Text
} from '@chakra-ui/react';

import Button from '../../components/Button';
import { PrivateRoutes } from '../../config/routes';

const NotFoundPage: React.FC = () => (
  <Flex
    flexDir="column"
    justifyContent="center"
    alignItems="center"
    width="100%"
    height="100%"
  >
    <Text as="h1" fontSize="md" m="20px">
      <b>Sorry, this page is not available</b>
    </Text>

    <Button buttonColor="brand.green">
      <a href={PrivateRoutes.DASHBOARD}>Return to dashboard</a>
    </Button>
  </Flex>
);

export default NotFoundPage;
