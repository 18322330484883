import React, { useState } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Spacer,
  Text
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { survivorClient } from '../service/backend';
import Input from './Input';
import WhiteButton from './layout/WhiteButton';
import Button from './Button';
import { useNotification } from './Notification/useNotification';
import Notification from './Notification';

interface EnterCampusNameModalProps extends ModalProps {
  emailDomain: string;
}

interface EnterCampusNameInputForm {
  campusName: string;
}

const EnterCampusNameModal: React.FC<EnterCampusNameModalProps> = ({
  isOpen,
  onClose,
  emailDomain,
  ...props
}: EnterCampusNameModalProps) => {
  const {
    register,
    handleSubmit
  } = useForm<EnterCampusNameInputForm>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const { notification, setNotification } = useNotification();

  const closeAction = () => {
    setNotification({
      type: 'success',
      message: ''
    });
    onClose();
  };

  const onSubmit = async ({ campusName }: EnterCampusNameInputForm) => {
    setIsloading(true);
    try {
      await survivorClient.setCurrentCampus(undefined, campusName, emailDomain);
      onClose();
    } catch {
      setNotification({
        type: 'error',
        message: 'There was an error setting your current campus. Please try again later.'
      });
    } finally {
      setIsloading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAction}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      {...props}
    >
      <ModalOverlay />
      <ModalCloseButton />
      <ModalContent p="100px" maxW="850px" borderRadius="5px" borderColor="brand.primary" borderWidth="2px" color="brand.primary">
        <Flex flexDir="column">
          <Box
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            flexDir="column"
          >
            <Notification notification={notification} />
            <Text as="p" fontSize="sm">
              We were unable to identify your campus based on the email domain ({emailDomain}).
              Please enter the full name of your US based university or college (ex: University of Southern California rather than USC).
            </Text>

            <Input
              type="text"
              {...register('campusName')}
              isRequired
            />
            <Flex flexDir="row" mt="3em">
              <Spacer />
              <WhiteButton onClick={closeAction}>Cancel</WhiteButton>
              <Spacer />
              <Button
                buttonColor="brand.primary"
                type="submit"
                isLoading={isLoading}
              >
                Submit
              </Button>
              <Spacer />
            </Flex>

          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default EnterCampusNameModal;
