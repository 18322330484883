import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect
} from 'react';
import { useNavigate } from 'react-router-dom';
import { survivorClient } from '../../service/backend';
import {
  PrivateRoutes,
  PublicRoutes
} from '../../config/routes';
import { Box } from '@chakra-ui/layout';
import {
  CheckboxGroup,
  Flex,
  NumberInput,
  NumberInputField,
  Text,
  VStack
} from '@chakra-ui/react';
import { Paragraph } from '../../components/Paragraph';
import AnimatedBox from '../../components/layout/AnimatedBox';
import MainCard from '../../components/MainCard';
import HeaderText from '../../components/HeaderText';
import { useForm } from 'react-hook-form';
import Checkbox from '../../components/CheckBox';
import { QuestionLabel } from './QuestionLabel';
import CheckboxRadio from '../../components/CheckboxRadio';
import LineInput from '../../components/LineInput';
import Button from '../../components/Button';

const SELECT_ONE = 'Please select one or select decline to answer';
const SELECT_AT_LEAST_ONE = 'Please select at least one or select decline to answer';

interface DemographicsFormInput {
  declineToAnswerAge: boolean;
  age: string;
  declineToAnswerStudentType: boolean;
  studentType: string;
  declineToAnswerYearInSchool: boolean;
  yearInSchool: string;
  declineToAnswerRaceEthnicity: boolean;
  raceEthnicities: string[];
  declineToAnswerGenderIdentity: boolean;
  genderIdentity: string;
  declineToAnswerSexualOrientation: boolean;
  sexualOrientation: string;
  declineToAnswerDisability: boolean;
  disabilities: string[];
  declineToAnswerHowLearned: boolean;
  howLearned: string;
  howLearnedOther: string;
  declineToAnswerExtracurricularActivity: boolean;
  extracurricularActivities: string[];
  extracurricularActivitiesOther: string;
}

export const Demographics: React.FC = () => {
  const navigate = useNavigate();
  const { handleSubmit, register, watch, setValue, getValues, formState: { errors } } = useForm<DemographicsFormInput>({
    defaultValues: {
      declineToAnswerAge: false,
      age: undefined,
      declineToAnswerStudentType: false,
      studentType: undefined,
      declineToAnswerYearInSchool: false,
      yearInSchool: undefined,
      declineToAnswerRaceEthnicity: false,
      raceEthnicities: [],
      declineToAnswerGenderIdentity: false,
      genderIdentity: undefined,
      declineToAnswerSexualOrientation: false,
      sexualOrientation: undefined,
      declineToAnswerDisability: false,
      disabilities: [],
      declineToAnswerHowLearned: false,
      howLearned: undefined,
      howLearnedOther: '',
      declineToAnswerExtracurricularActivity: false,
      extracurricularActivities: [],
      extracurricularActivitiesOther: ''
    }});
  const formValues = watch();
  const noDisability = 'I do not have a disability or chronic condition';
  const noExtracurricularActivities = 'No extracurricular activities';
  const { onChange: onChangeDeclineAge } = register('declineToAnswerAge');
  const { onChange: onChangeDeclineStudentType } = register('declineToAnswerStudentType');
  const { onChange: onChangeDeclineYearInSchool } = register('declineToAnswerYearInSchool');
  const { onChange: onChangeDeclineRaceEthnicity } = register('declineToAnswerRaceEthnicity');
  const { onChange: onChangeDeclineGenderIdentity } = register('declineToAnswerGenderIdentity');
  const { onChange: onChangeDeclineSexualOrientation } = register('declineToAnswerSexualOrientation');
  const { onChange: onChangeDeclineDisability } = register('declineToAnswerDisability');
  const { onChange: onChangeDeclineHowLearned } = register('declineToAnswerHowLearned');
  const { onChange: onChangeDeclineExtracurricularActivity } = register('declineToAnswerExtracurricularActivity');

  const onSubmit = async () => {
    const data = getValues();
    const raceEthnicities = data.raceEthnicities.length > 0 ? data.raceEthnicities : null;
    const disabilities = data.disabilities.length > 0 ? data.disabilities : null;
    const howLearned = data.howLearned === 'Other' ? data.howLearnedOther : data.howLearned;
    const extracurricularActivities = data.extracurricularActivities.length > 0 ? data.extracurricularActivities : null;
    if (extracurricularActivities && extracurricularActivities.includes('Other')) {
      const indexOfOther = extracurricularActivities.indexOf('Other');
      extracurricularActivities.splice(indexOfOther, indexOfOther);
      extracurricularActivities.push(data.extracurricularActivitiesOther);
    }
    await survivorClient.saveDemographicData(
      data.declineToAnswerAge ? null : parseInt(data.age, 10),
      data.declineToAnswerStudentType ? null : data.studentType,
      data.declineToAnswerYearInSchool ? null : data.yearInSchool,
      data.declineToAnswerRaceEthnicity ? null : raceEthnicities,
      data.declineToAnswerGenderIdentity ? null : data.genderIdentity,
      data.declineToAnswerSexualOrientation ? null : data.sexualOrientation,
      data.declineToAnswerDisability ? null : disabilities,
      data.declineToAnswerHowLearned ? null : howLearned,
      data.declineToAnswerExtracurricularActivity ? null : extracurricularActivities
    );
    navigate(PrivateRoutes.DASHBOARD);
  };

  useEffect(() => {
    if (!survivorClient.loggedIn) {
      navigate(PublicRoutes.LOGIN);
    }
    if (survivorClient.demographicsComplete) {
      navigate(PrivateRoutes.DASHBOARD);
    }
  }, []);

  return (
    <Box mx="16px" my="16px" as="form" onSubmit={handleSubmit(onSubmit)}>
      <AnimatedBox>
        <MainCard>
          <HeaderText>Demographics</HeaderText>
          <Paragraph mt="0">We are honored that you are using Callisto as part of your journey toward healing and
            justice.</Paragraph>
          <Paragraph>In order for Callisto to maintain a free service for survivors, we seek out grant funding
            from foundations, individuals, and governments. In doing so, it is essential that we are able to discuss
            the impact that our work is having, particularly for people in historically underserved and marginalized
            communities. As such, we have begun collecting some basic demographic information. This information will
            NOT be connected with any of your record forms or entries. The demographic data collected  will only be
            shared in aggregate form and therefore, it will not be identifiable.</Paragraph>
          <Paragraph>We appreciate your willingness to provide this information as it will not only help us continue
            this as a free service, but also to help us improve our platform. While your participation in the survey is
            required, each question has an option to “decline to answer” if you do not wish to disclose certain or any
            information.</Paragraph>
          <Paragraph mb="16px">Thank you for helping Callisto to continue to support survivors in their journey towards healing
            and justice.</Paragraph>

          <QuestionLabel>Age in Years</QuestionLabel>
          <NumberInput
            id="age"
            size="sm"
            width="7em"
            isDisabled={formValues.declineToAnswerAge}
            {...register('age', {
              required: {
                value: !formValues.declineToAnswerAge,
                message: 'Please enter your age or select decline to answer'
              }
            })}
            min={10}
            max={99}
            inputMode="numeric"
            precision={0}
            value={formValues.age}
            onChange={(val) => {
              setValue('age', val);
            }}

          >
            <NumberInputField />
          </NumberInput>
          <Checkbox
            py="10px"
            {...register('declineToAnswerAge')}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeDeclineAge(e);
              setValue('age', '');
            }}
          >
            Decline to answer
          </Checkbox>
          {errors.age && (<Text color="red">{errors.age.message}</Text>)}

          <QuestionLabel>What is your affiliation to your campus or institution?</QuestionLabel>
          <CheckboxRadio
            value={formValues.studentType}
            setValue={(v) => setValue('studentType', v)}
            {...register('studentType', {
              required: {
                value: !formValues.declineToAnswerStudentType,
                message: SELECT_ONE
              }
            })}
          >
            <VStack alignItems="flex-start">
              <Checkbox
                value="Undergraduate"
                key="Undergraduate"
                isDisabled={formValues.declineToAnswerStudentType}
              >
                Undergraduate Student
              </Checkbox>
              <Checkbox
                value="Graduate/Professional"
                key="Graduate/Professional"
                isDisabled={formValues.declineToAnswerStudentType}
              >
                Graduate/Professional Student
              </Checkbox>
              <Checkbox
                value="Faculty member"
                key="Faculty"
                isDisabled={formValues.declineToAnswerStudentType}
              >
                Faculty Member
              </Checkbox>
              <Checkbox
                value="Other"
                key="Other"
                isDisabled={formValues.declineToAnswerStudentType}
              >
                Other
              </Checkbox>
            </VStack>
          </CheckboxRadio>
          <Checkbox
            py="10px"
            {...register('declineToAnswerStudentType')}
            onChange = {async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeDeclineStudentType(e);
              setValue('studentType', '');
            }}
          >
            Decline to answer
          </Checkbox>
          {errors.studentType && (<Text color="red">{errors.studentType.message}</Text>)}

          <QuestionLabel>What is your class year in school? Answer on the basis of
            the number of credits you have earned. (Select one.)</QuestionLabel>
          <CheckboxRadio
            value={formValues.yearInSchool}
            setValue={(v) => setValue('yearInSchool', v)}
            {...register('yearInSchool', {
              required: {
                value: !formValues.declineToAnswerYearInSchool,
                message: SELECT_ONE
              }
            })}
          >
            <VStack alignItems="flex-start">
              <Checkbox
                value="1st year"
                key="first year"
                isDisabled={formValues.declineToAnswerYearInSchool}
              >
                1st year
              </Checkbox>
              <Checkbox
                value="2nd year"
                key="second year"
                isDisabled={formValues.declineToAnswerYearInSchool}
              >
                2nd year
              </Checkbox>
              <Checkbox
                value="3rd year"
                key="third year"
                isDisabled={formValues.declineToAnswerYearInSchool}
              >
                3rd year
              </Checkbox>
              <Checkbox
                value="4th year"
                key="fourth year"
                isDisabled={formValues.declineToAnswerYearInSchool}
              >
                4th year
              </Checkbox>
              <Checkbox
                value="5th year or higher"
                key="fifth year or higher"
                isDisabled={formValues.declineToAnswerYearInSchool}
              >
                5th year or higher
              </Checkbox>
              <Checkbox
                value="Other"
                key="Other"
                isDisabled={formValues.declineToAnswerYearInSchool}
              >
                Other
              </Checkbox>
              <Checkbox
                value="Not Applicable"
                key="Not Applicable"
                isDisabled={formValues.declineToAnswerYearInSchool}
              >
                Not Applicable
              </Checkbox>
            </VStack>
          </CheckboxRadio>
          <Checkbox
            py="10px"
            {...register('declineToAnswerYearInSchool')}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeDeclineYearInSchool(e);
              setValue('yearInSchool', '');
            }}
          >
            Decline to answer
          </Checkbox>
          {errors.yearInSchool && (<Text color="red">{errors.yearInSchool.message}</Text>)}

          <QuestionLabel>Select one or more of the following races/ethnicities that best
            describes you. (Mark all that apply.)</QuestionLabel>
          <CheckboxGroup
            value={formValues.raceEthnicities}
            onChange={(values) => setValue('raceEthnicities', values as string[])}
          >
            <VStack
              alignItems="flex-start"
              {...register('raceEthnicities', {
                validate: () => formValues.declineToAnswerRaceEthnicity || formValues.raceEthnicities.length > 0
              })}
            >
              <Checkbox
                value="Native American or Alaska Native"
                key="Native American or Alaska Native"
                isDisabled={formValues.declineToAnswerRaceEthnicity}
              >
                Native American or Alaska Native
              </Checkbox>
              <Checkbox
                value="Asian"
                key="Asian"
                isDisabled={formValues.declineToAnswerRaceEthnicity}
              >
                Asian
              </Checkbox>
              <Checkbox
                value="Black or African American"
                key="Black or African American"
                isDisabled={formValues.declineToAnswerRaceEthnicity}
              >
                Black or African American
              </Checkbox>
              <Checkbox
                value="Hispanic/Latino"
                key="Hispanic/Latino"
                isDisabled={formValues.declineToAnswerRaceEthnicity}
              >
                Hispanic/Latino
              </Checkbox>
              <Checkbox
                value="Native Hawaiian or Other Pacific Islander"
                key="Native Hawaiian or Other Pacific Islander"
                isDisabled={formValues.declineToAnswerRaceEthnicity}
              >
                Native Hawaiian or Other Pacific Islander
              </Checkbox>
              <Checkbox
                value="White"
                key="White"
                isDisabled={formValues.declineToAnswerRaceEthnicity}
              >
                White
              </Checkbox>
              <Checkbox
                value="Other"
                key="Other"
                isDisabled={formValues.declineToAnswerRaceEthnicity}
              >
                Other
              </Checkbox>
            </VStack>
          </CheckboxGroup>
          <Checkbox
            py="10px"
            {...register('declineToAnswerRaceEthnicity')}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeDeclineRaceEthnicity(e);
              setValue('raceEthnicities', []);
            }}
          >
            Decline to answer
          </Checkbox>
          {errors.raceEthnicities && (<Text color="red">{SELECT_AT_LEAST_ONE}.</Text>)}

          <QuestionLabel>Which best describes your gender identity? (Select one.)</QuestionLabel>
          <CheckboxRadio
            value={formValues.genderIdentity}
            setValue={(v) => setValue('genderIdentity', v)}
            {...register('genderIdentity', {
              required: {
                value: !formValues.declineToAnswerGenderIdentity,
                message: SELECT_ONE
              }
            })}
          >
            <VStack alignItems="flex-start">
              <Checkbox
                value="Woman"
                key="Woman"
                isDisabled={formValues.declineToAnswerGenderIdentity}
              >
                Woman
              </Checkbox>
              <Checkbox
                value="Man"
                key="Man"
                isDisabled={formValues.declineToAnswerGenderIdentity}
              >
                Man
              </Checkbox>
              <Checkbox
                value="Trans-woman"
                key="Trans-woman"
                isDisabled={formValues.declineToAnswerGenderIdentity}
              >
                Trans-woman
              </Checkbox>
              <Checkbox
                value="Trans-man"
                key="Trans-man"
                isDisabled={formValues.declineToAnswerGenderIdentity}
              >
                Trans-man
              </Checkbox>
              <Checkbox
                value="Nonbinary/genderqueer/Gender non-conforming"
                key="Nonbinary"
                isDisabled={formValues.declineToAnswerGenderIdentity}
              >
                Nonbinary/genderqueer/Gender non-conforming
              </Checkbox>
              <Checkbox
                value="Questioning or Not Listed"
                key="Questioning"
                isDisabled={formValues.declineToAnswerGenderIdentity}
              >
                Questioning or Not Listed
              </Checkbox>
            </VStack>
          </CheckboxRadio>
          <Checkbox
            py="10px"
            {...register('declineToAnswerGenderIdentity')}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeDeclineGenderIdentity(e);
              setValue('genderIdentity', '');
            }}
          >
            Decline to answer
          </Checkbox>
          {errors.genderIdentity && (<Text color="red">{errors.genderIdentity.message}</Text>)}

          <QuestionLabel>Do you consider yourself to be (select one)</QuestionLabel>
          <CheckboxRadio
            value={formValues.sexualOrientation}
            setValue={(v) => setValue('sexualOrientation', v)}
            {...register('sexualOrientation', {
              required: {
                value: !formValues.declineToAnswerSexualOrientation,
                message: SELECT_ONE
              }
            })}
          >
            <VStack alignItems="flex-start">
              <Checkbox
                value="Heterosexual or straight"
                key="Heterosexual"
                isDisabled={formValues.declineToAnswerSexualOrientation}
              >
                Heterosexual or straight
              </Checkbox>
              <Checkbox
                value="Gay or Lesbian"
                key="Homosexual"
                isDisabled={formValues.declineToAnswerSexualOrientation}
              >
                Gay or Lesbian
              </Checkbox>
              <Checkbox
                value="Bisexual"
                key="Bisexual"
                isDisabled={formValues.declineToAnswerSexualOrientation}
              >
                Bisexual
              </Checkbox>
              <Checkbox
                value="Asexual"
                key="Asexual"
                isDisabled={formValues.declineToAnswerSexualOrientation}
              >
                Asexual
              </Checkbox>
              <Checkbox
                value="Queer"
                key="Queer"
                isDisabled={formValues.declineToAnswerSexualOrientation}
              >
                Queer
              </Checkbox>
              <Checkbox
                value="Questioning"
                key="Questioning"
                isDisabled={formValues.declineToAnswerSexualOrientation}
              >
                Questioning
              </Checkbox>
              <Checkbox
                value="Intersex"
                key="Intersex"
                isDisabled={formValues.declineToAnswerSexualOrientation}
              >
                Intersex
              </Checkbox>
              <Checkbox
                value="Pansexual"
                key="Pansexual"
                isDisabled={formValues.declineToAnswerSexualOrientation}
              >
                Pansexual
              </Checkbox>
              <Checkbox
                value="Not Listed"
                key="Not Listed"
                isDisabled={formValues.declineToAnswerSexualOrientation}
              >
                Not Listed
              </Checkbox>
            </VStack>
          </CheckboxRadio>
          <Checkbox
            py="10px"
            {...register('declineToAnswerSexualOrientation')}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeDeclineSexualOrientation(e);
              setValue('sexualOrientation', '');
            }}
          >
            Decline to answer
          </Checkbox>
          {errors.sexualOrientation && (<Text color="red">{errors.sexualOrientation.message}</Text>)}

          <QuestionLabel>If you identify as a person with any of the following, please mark
            all that apply.</QuestionLabel>
          <CheckboxGroup
            value={formValues.disabilities}
            onChange={(values) => setValue('disabilities', values as string[])}
          >
            <VStack
              alignItems="flex-start"
              {...register('disabilities', {
                validate: () => formValues.declineToAnswerDisability || formValues.disabilities.length > 0
              })}
            >
              <Checkbox
                value={noDisability}
                key="None"
                isDisabled={formValues.declineToAnswerDisability
                  || (formValues.disabilities.length > 0 && !formValues.disabilities.includes(noDisability))}
              >
                I do not have a disability or chronic condition
              </Checkbox>
              <Checkbox
                value="Learning disability"
                key="Learning disability"
                isDisabled={formValues.declineToAnswerDisability  ||
                  formValues.disabilities.includes(noDisability)}
              >
                Learning disability
              </Checkbox>
              <Checkbox
                value="ADHD"
                key="ADHD"
                isDisabled={formValues.declineToAnswerDisability  ||
                  formValues.disabilities.includes(noDisability)}
              >
                ADHD
              </Checkbox>
              <Checkbox
                value="Autism Spectrum Disorder"
                key="Autism"
                isDisabled={formValues.declineToAnswerDisability  ||
                  formValues.disabilities.includes(noDisability)}
              >
                Autism Spectrum Disorder
              </Checkbox>
              <Checkbox
                value="Mobility-related disability"
                key="Mobility"
                isDisabled={formValues.declineToAnswerDisability  ||
                  formValues.disabilities.includes(noDisability)}
              >
                Mobility-related disability (e.g., spinal cord injury, muscular dystrophy, etc.)
              </Checkbox>
              <Checkbox
                value="Sensory Disability"
                key="Sensory"
                isDisabled={formValues.declineToAnswerDisability  ||
                  formValues.disabilities.includes(noDisability)}
              >
                Sensory disability (e.g., hard of hearing, low vision, etc.)
              </Checkbox>
              <Checkbox
                value="Chronic medical condition"
                key="Chronic medical condition"
                isDisabled={formValues.declineToAnswerDisability  ||
                  formValues.disabilities.includes(noDisability)}
              >
                Chronic medical condition (e.g., cystic fibrosis, diabetes, chronic pain, etc.)
              </Checkbox>
              <Checkbox
                value="Other disability or chronic condition"
                key="Other"
                isDisabled={formValues.declineToAnswerDisability  ||
                  formValues.disabilities.includes(noDisability)}
              >
                Other disability or chronic condition
              </Checkbox>
            </VStack>
          </CheckboxGroup>
          <Checkbox
            py="10px"
            {...register('declineToAnswerDisability')}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeDeclineDisability(e);
              setValue('disabilities', []);
            }}
          >
            Decline to answer
          </Checkbox>
          { errors.disabilities && <Text color="red">{SELECT_AT_LEAST_ONE}</Text>}

          <QuestionLabel>Please select all extracurricular activities you are involved in</QuestionLabel>
          <CheckboxGroup
            value={formValues.extracurricularActivities}
            onChange={(values) => setValue('extracurricularActivities', values as string[])}
          >
            <VStack
              alignItems="flex-start"
              {...register('extracurricularActivities', {
                validate: () => formValues.declineToAnswerExtracurricularActivity || formValues.extracurricularActivities.length > 0
              })}
            >
              <Checkbox
                value={noExtracurricularActivities}
                key={noExtracurricularActivities}
                isDisabled={formValues.declineToAnswerExtracurricularActivity
                  || (formValues.extracurricularActivities.length > 0 &&
                    !formValues.extracurricularActivities.includes(noExtracurricularActivities))}
              >
                {noExtracurricularActivities}
              </Checkbox>
              <Checkbox
                value="Student Government"
                key="Student Government"
                isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
              >
                Student Government
              </Checkbox>
              <Checkbox
                value="Sexual Assault Advocacy"
                key="Sexual Assault Advocacy"
                isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
              >
                Sexual Assault Advocacy
              </Checkbox>
              <Checkbox
                value="Multicultural activities and organizations"
                key="Multicultural activities"
                isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
              >
                Multicultural activities and organizations
              </Checkbox>
              <Checkbox
                value="Intercollegiate Athletics"
                key="Intercollegiate Athletics"
                isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
              >
                Intercollegiate Athletics
              </Checkbox>
              <Checkbox
                value="Greek Life"
                key="Greek Life"
                isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
              >
                Greek Life
              </Checkbox>
              <Checkbox
                value="Religious organizations"
                key="Religious organizations"
                isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
              >
                Religious organizations
              </Checkbox>
              <Checkbox
                value="Professional/departmental club"
                key="Professional/departmental club"
                isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
              >
                Professional/departmental club
              </Checkbox>
              <Checkbox
                value="College Production and/or performances"
                key="College Production and/or performances"
                isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
              >
                College Production and/or performances
              </Checkbox>
              <Checkbox
                value="College media"
                key="College media"
                isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
              >
                College media
              </Checkbox>
              <Flex>
                <Checkbox
                  value="Other"
                  key="Other Activity"
                  isDisabled={formValues.declineToAnswerExtracurricularActivity ||
                  formValues.extracurricularActivities.includes(noExtracurricularActivities)}
                >
                Other (List all other activities separated by commas)
                </Checkbox>&nbsp;
                <LineInput
                  width="600px"
                  isDisabled={!formValues.extracurricularActivities.includes('Other')}
                  type="text"
                  {...register('extracurricularActivitiesOther', {
                    required: {
                      value: !formValues.declineToAnswerExtracurricularActivity &&
                      formValues.extracurricularActivities.includes('Other'),
                      message: 'Please enter other extracurricular activities you are involved in'
                    }
                  })}
                  onChange={(evt: SyntheticEvent) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                    setValue('extracurricularActivitiesOther', evt.currentTarget.value as string);
                  }}
                  value={formValues.extracurricularActivitiesOther}
                />
              </Flex>
              { errors.extracurricularActivitiesOther && <Text color="red">{errors.extracurricularActivitiesOther.message}</Text> }
            </VStack>
          </CheckboxGroup>
          <Checkbox
            py="10px"
            {...register('declineToAnswerExtracurricularActivity')}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeDeclineExtracurricularActivity(e);
              setValue('extracurricularActivities', []);
              setValue('extracurricularActivitiesOther', '');
            }}
          >
            Decline to answer
          </Checkbox>
          { errors.extracurricularActivities && <Text color="red">{SELECT_AT_LEAST_ONE}</Text> }

          <QuestionLabel>How did you learn about Callisto? (Select one.)</QuestionLabel>
          <CheckboxRadio
            value={formValues.howLearned}
            setValue={(v) => setValue('howLearned', v)}
            {...register('howLearned', {
              required: {
                value: !formValues.declineToAnswerHowLearned,
                message: SELECT_ONE
              }
            })}
          >
            <VStack alignItems="flex-start">
              <Checkbox
                value="Campus Event"
                key="Campus Event"
                isDisabled={formValues.declineToAnswerHowLearned}
              >
                Campus Event
              </Checkbox>
              <Checkbox
                value="Social Media"
                key="Social Media"
                isDisabled={formValues.declineToAnswerHowLearned}
              >
                Social Media
              </Checkbox>
              <Checkbox
                value="Desi Dance Network Partnership"
                key="DDN"
                isDisabled={formValues.declineToAnswerHowLearned}
              >
                Desi Dance Network Partnership
              </Checkbox>
              <Checkbox
                value="Leda Health"
                key="Leda"
                isDisabled={formValues.declineToAnswerHowLearned}
              >
                Leda Health
              </Checkbox>
              <Checkbox
                value="News/Article/Video"
                key="News"
                isDisabled={formValues.declineToAnswerHowLearned}
              >
                News/Article/Video
              </Checkbox>
              <Checkbox
                value="Web Search"
                key="Web"
                isDisabled={formValues.declineToAnswerHowLearned}
              >
                Web Search
              </Checkbox>
              <Flex>
                <Checkbox
                  value="Other"
                  key="Other"
                  isDisabled={formValues.declineToAnswerHowLearned}
                >
                  Other
                </Checkbox>&nbsp;
                <LineInput
                  isDisabled={formValues.howLearned !== 'Other'}
                  type="text"
                  {...register('howLearnedOther', {
                    required: {
                      value: !formValues.declineToAnswerHowLearned && formValues.howLearned === 'Other',
                      message: 'Please enter how you learned about Callisto'
                    }
                  })}
                  onChange={(evt: SyntheticEvent) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setValue('howLearnedOther', evt.currentTarget.value as string);
                  }}
                  value={formValues.howLearnedOther}
                />
              </Flex>
              { errors.howLearnedOther && <Text color="red">{errors.howLearnedOther.message}</Text>}
            </VStack>
          </CheckboxRadio>
          <Checkbox
            py="10px"
            {...register('declineToAnswerHowLearned')}
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              await onChangeDeclineHowLearned(e);
              setValue('howLearned', '');
              setValue('howLearnedOther', '');
            }}
          >
            Decline to answer
          </Checkbox>
          {errors.howLearned && (<Text color="red">{errors.howLearned.message}</Text>)}

          <Flex justifyContent="center">
            <VStack>
              {Object.keys(errors).length > 0 && <Text color="red">Please fix the indicated errors above.</Text>}
              <Button buttonColor="brand.primary" type="submit">Submit</Button>
            </VStack>
          </Flex>
        </MainCard>
      </AnimatedBox>
    </Box>
  );
};
