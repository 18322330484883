import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Box } from '@chakra-ui/react';
import AnimatedBox from '../../components/layout/AnimatedBox';
import {
  OrfContext,
  YesNoSure
} from '../../contexts/OrfContext';
import { useForm } from 'react-hook-form';
import OrfActionButtons from '../../components/OrfActionButtons';
import { PrivateRoutes } from '../../config/routes';
import MainCard from '../../components/MainCard';
import { wait } from '../../service/backend';
import {
  IFormProps,
  usePersistData
} from '../../service/hooks/usePersistData';
import { handleORFSubmit } from '../../components/layout/sidebar/CreateEditSidebar';
import { OrfKnowPageContent } from './OrfKnowPageContent';
import { useNavigate } from 'react-router-dom';

export interface IKnowFormProps {
  anyoneSaw: YesNoSure;
  anyoneInteract: YesNoSure;
  informationYouDescribed: string;
  toldAnyone: 'Yes' | 'No' | null;
  informationYouTold: string;
  offenderTold: YesNoSure;
}

const OrfKnow: React.FC = () => {
  const {
    state: { steps, mode, currentRecordId, changesMade },
    completeAction,
    setSavingScreenVisibility,
    updateORF
  } = useContext(OrfContext);
  const { know } = steps;
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  const { handleSubmit, watch, setValue, register } = useForm<IKnowFormProps>({
    defaultValues: {
      anyoneSaw: know.anyoneSaw,
      anyoneInteract: know.anyoneInteract,
      informationYouDescribed: know.informationYouDescribed,
      toldAnyone: know.toldAnyone,
      informationYouTold: know.informationYouTold,
      offenderTold: know.offenderTold,
    },
  });

  useEffect(() => {
    register('anyoneSaw');
    register('anyoneInteract');
    register('toldAnyone');
    register('offenderTold');

    const keypressListener = (e: KeyboardEvent) => {
      if (document.activeElement &&
        Array.from(document.getElementsByTagName('textarea'))
          .includes(document.activeElement as HTMLTextAreaElement)) {
        return;
      }

      if (e.key === 'Enter') {
        e.preventDefault();
      }
    };

    if (formRef.current) {
      formRef.current.addEventListener('keypress', keypressListener);
    }

    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener('keypress', keypressListener);
      }
    };
  }, []);

  const generatePayload = ({
    anyoneInteract,
    anyoneSaw,
    toldAnyone,
    informationYouDescribed,
    informationYouTold,
    offenderTold,
  }: IKnowFormProps) => ({
    anyoneSaw,
    anyoneInteract,
    informationYouDescribed,
    toldAnyone,
    informationYouTold,
    offenderTold,
  });

  const saveForm = (formProps: IKnowFormProps) => {
    if (changesMade) {
      completeAction('know', generatePayload(formProps));
    }
  };

  const setWatchValuesRef = usePersistData(saveForm);

  useEffect(() => {
    setWatchValuesRef(watch() as IFormProps);
  });

  const onContinue = () => {
    navigate(know.nextStepRoute);
  };

  const handleSaveAndExit = async (e: SyntheticEvent) => {
    e.preventDefault();

    setSavingScreenVisibility(true);
    await wait(1000);
    const updatedKnow = generatePayload(watch());
    const updatedSteps = {
      ...steps,
      know: {
        ...know,
        ...updatedKnow,
        completed: !!updatedKnow.anyoneSaw ||
          !!updatedKnow.anyoneInteract ||
          !!updatedKnow.informationYouDescribed ||
          !!updatedKnow.toldAnyone ||
          !!updatedKnow.informationYouTold ||
          !!updatedKnow.offenderTold
      }
    };

    await handleORFSubmit(
      mode,
      updatedSteps,
      updateORF,
      currentRecordId
    );
    setSavingScreenVisibility(false);
  };

  return (
    <AnimatedBox>
      <MainCard>
        <Box as="form" onSubmit={handleSaveAndExit} id="orf_form" ref={formRef}>
          <OrfKnowPageContent
            register={register}
            values={watch()}
            setValue={setValue}
          />
          <OrfActionButtons backRoute={PrivateRoutes.ORF_WHERE} handleClick={handleSubmit(onContinue)} />
        </Box>
      </MainCard>
    </AnimatedBox>
  );
};

export default OrfKnow;
