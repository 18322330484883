import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import AnimatedBox from '../../components/layout/AnimatedBox';
import MainCard from '../../components/MainCard';
import { IncidentLogContext } from '../../contexts/IncidentLogContext';
import { resultType } from '../../../../../../lib/data';
import { IncidentLogPageContent } from './IncidentLogPageContent';
import IncidentLogActionButtons from '../../components/IncidentLogActionButtons';
import {
  incidentLogSections,
  POSTSTEPS,
  PRESTEPS
} from './incidentLogQuestions';

import './styles.scss';
import {
  Box,
  useToast
} from '@chakra-ui/react';
import { wait } from '../../service/backend';
import { IncidentLogBanner } from '../../components/IncidentLogBanner';
import {
  formContainsData,
  resultsDeepCopy
} from '../../../../../../lib/questionnaire/formFunctions';

interface IncidentLogPageProps {
  stepName: keyof typeof incidentLogSections;
}

const IncidentLogPage: React.FC<IncidentLogPageProps> = ( { stepName }) => {
  const {
    state: { results, mode, currentLogId },
    submitResponses,
    setSectionUpdated,
    setSavingScreenVisibility,
    saveIncidentLog
  } = useContext(IncidentLogContext);
  const [localResults, setLocalResults] = useState<Map<string, resultType>>(new Map<string, resultType>());
  const formRef = useRef<HTMLFormElement>(null);
  const toast = useToast();

  const stepNumber = [...PRESTEPS, ...POSTSTEPS].includes(stepName) ? undefined :
    Object.keys(incidentLogSections).indexOf(stepName) - PRESTEPS.length + 1;
  const step = incidentLogSections[stepName];

  useEffect(() => {
    setLocalResults(resultsDeepCopy(results));

    const keypressListener = (e: KeyboardEvent) => {
      if (document.activeElement &&
        Array.from(document.getElementsByTagName('textarea'))
          .includes(document.activeElement as HTMLTextAreaElement)) {
        return;
      }

      if (e.key === 'Enter') {
        e.preventDefault();
      }
    };

    if (formRef.current) {
      formRef.current.addEventListener('keypress', keypressListener);
    }

    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener('keypress', keypressListener);
      }
    };
  }, []);

  const handleSubmit = () => {
    // submitResponses(stepName, localResults, false);
    setSectionUpdated(stepName, false);
  };

  const handleSaveAndExit = async (e: FormEvent) => {
    e.preventDefault();

    setSavingScreenVisibility(true);
    try {
      handleSubmit();
      if (formContainsData(results)) {
        await wait(1000);
        await saveIncidentLog(mode, currentLogId);
      } else {
        toast({
          position: 'top',
          status: 'error',
          title: 'Please answer at least one question. We cannot save an empty Incident Log.'
        });
      }
    } finally {
      setSavingScreenVisibility(false);
    }
  };

  const updateLocalResults = (r: Map<string, resultType>, initial = false) => {
    if(r) {
      setSectionUpdated(stepName, true);
      submitResponses(stepName, r, initial);
    }
    setLocalResults(r);
  };

  return (
    <AnimatedBox>
      {!PRESTEPS.includes(stepName) && (<IncidentLogBanner />)}
      <MainCard>
        <Box as="form" onSubmit={handleSaveAndExit} id="incident_log_form" ref={formRef}>
          <IncidentLogPageContent
            stepNumber={stepNumber}
            step={step}
            results={localResults}
            setResults={updateLocalResults}
          />
          <IncidentLogActionButtons
            backRoute={step.previousStepRoute}
            forwardRoute={step.nextStepRoute}
            handleClick={handleSubmit} />
        </Box>
      </MainCard>
    </AnimatedBox>
  );
};

export default IncidentLogPage;
