import {
  crypto_secretbox_keygen,
  randombytes_buf,
  crypto_secretbox_NONCEBYTES,
  crypto_secretbox_easy,
  crypto_secretbox_open_easy,
} from 'libsodium-wrappers-sumo';
import { autoencode, autodecode } from '../format';

/**
 * Secretbox encrypts data with a (usually generated in some fashion) pre-shared
 * key. It is mostly just a wrapper around libsodium's crypto_secretbox()
 * primitive, that gives a greater level of convenience, especially around
 * storing the nonce along with the ciphertext.
 */
export module Secretbox {
  /**
   * Generate a key usable for encrypting a Secretbox payload.
   *
   * @returns random key.
   */
  export const keygen = (): Uint8Array => crypto_secretbox_keygen();

  /**
   * Encrypt data with a known key.
   *
   * @param key  Key for secretbox cipher.
   * @param data Data to encrypt.
   *
   * @returns    Combined nonce+ciphertext.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  export const encrypt = (key: Uint8Array, data: any): Uint8Array => {
    const nonce = randombytes_buf(crypto_secretbox_NONCEBYTES);

    const ciphertext = crypto_secretbox_easy(
      JSON.stringify(autoencode(data)),
      nonce,
      key
    );

    const combined = new Uint8Array(nonce.length + ciphertext.length);
    combined.set(nonce, 0);
    combined.set(ciphertext, nonce.length);

    return combined;
  };

  /**
   * Decrypt a Secretbox payload.
   *
   * @param ciphertext Combined nonce+ciphertext generated by `encrypt()`.
   * @param dek        Pre-shared key to decrypt with.
   *
   * @returns          Original encrypted object.
   */
  export const decrypt = (ciphertext: Uint8Array, dek: Uint8Array): any => {
    const nonce = ciphertext.slice(0, crypto_secretbox_NONCEBYTES);
    const c = ciphertext.slice(crypto_secretbox_NONCEBYTES, ciphertext.length);

    return autodecode(
      JSON.parse(
        Buffer.from(crypto_secretbox_open_easy(c, nonce, dek)).toString('utf-8')
      )
    );
  };
}
