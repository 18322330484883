import React, {
  createContext,
  useReducer,
  useState
} from 'react';
import {
  incidentLogData,
  QResponse,
  resultType
} from '../../../../../lib/data';
import { ActionMap } from './type';
import {
  incidentLogSections,
  IncidentLogStep
} from '../containers/IncidentLog/incidentLogQuestions';
import {
  Flex,
  Text,
  useToast
} from '@chakra-ui/react';
import LoadingIndicator from '../components/LoadingIndicator';
import { survivorClient } from '../service/backend';
import { PrivateRoutes } from '../config/routes';
import { useNavigate } from 'react-router-dom';

export interface IncidentLogState {
  results: Map<string, resultType>;
  steps: Record<string, IncidentLogStep>;
  initialData: incidentLogData;
  sectionUpdatedDates: Record<string, Date>;
  sectionUpdated: Record<string, boolean>;
  mode: 'create' | 'edit';
  currentLogId: string | undefined;
  created: Date | undefined;
  updated: Date | undefined;
  changesMade: boolean;
}

export const initialIncidentLogState: IncidentLogState = {
  results: new Map<string, resultType>(),
  initialData: {},
  sectionUpdatedDates: {},
  sectionUpdated: {},
  steps: incidentLogSections,
  mode: 'create',
  currentLogId: undefined,
  updated: undefined,
  created: undefined,
  changesMade: false
};

export const IncidentLogContext = createContext< {
  state: IncidentLogState;
  resetIncidentLogState: () => void;
  submitResponses: (section: string, responses: Map<string, resultType>, initial: boolean) => void;
  setSectionUpdated: (section: string, updated: boolean) => void;
  setSavingScreenVisibility: (savingScreenVisible: boolean) => void;
  setIncidentLogMode: (mode: 'create' | 'edit') => void;
  setCurrentLogId: (id: string) => void;
  setInitialData: (initialData: incidentLogData, created: Date, updated: Date) => void;
  saveIncidentLog: (mode: 'create' | 'edit', currentLogId?: string) => Promise<void>;
}>({
      state: initialIncidentLogState,
      resetIncidentLogState: () => {},
      submitResponses: () => {},
      setSectionUpdated: () => {},
      setSavingScreenVisibility: () => {},
      setIncidentLogMode: () => {},
      setCurrentLogId: () => {},
      setInitialData: () => {},
      saveIncidentLog: () => Promise.resolve()
    });

type ActionPayload = {
  RESET_STATE: undefined;
  UPDATE_RESULTS: { updated: Map<string, resultType> };
  SET_PAGE_CONTENT: { updated: Map<string, resultType> };
  SET_INITIAL_DATA: { initialData: incidentLogData; created: Date; updated: Date };
  SET_SECTION_UPDATED: { section: string; updated: boolean };
  SET_SECTION_UPDATED_DATE: { section: string; date?: Date };
  SET_MODE: { mode: 'create' | 'edit' };
  SET_LOG_ID: { id: string };
};

const reducer = (state: IncidentLogState, action: ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>]) => {
  switch (action.type) {
    case 'RESET_STATE':
      for (const sectionName in incidentLogSections) {
        if (incidentLogSections.hasOwnProperty(sectionName)) {
          incidentLogSections[sectionName].completed = false;
        }
      }
      initialIncidentLogState.results = new Map<string, resultType>();
      return {  ...initialIncidentLogState };
    case 'UPDATE_RESULTS':
      let changesMade = false;
      action.payload.updated.forEach((value, key) => {
        const currentVal = state.results.get(key);
        if (!compareResultTypes(value, currentVal)) {
          changesMade = true;
          state.results.set(key, value);
        }
      });
      return {
        ...state,
        changesMade,
      };
    case 'SET_PAGE_CONTENT':
      action.payload.updated.forEach((value, key) => {
        state.results.set(key, value);
      });
      return {
        ...state,
      };
    case 'SET_INITIAL_DATA':
      const results = new  Map<string, resultType>();
      for (const section of Object.keys(action.payload.initialData)) {
        state.sectionUpdatedDates[section] = action.payload.initialData[section].updated;
        const responses = action.payload.initialData[section].responses;
        responses.forEach((response, key) => {
          if (response) {
            results.set(key, response);
            state.steps[section].completed = true;
          }
        });
      }

      return {
        ...state,
        results,
        initialData: action.payload.initialData,
        created: action.payload.created,
        updated: action.payload.updated,
        changesMade: false
      };
    case 'SET_SECTION_UPDATED':
      return {
        ...state,
        sectionUpdated: {
          ...state.sectionUpdated,
          [action.payload.section]: action.payload.updated
        }
      };
    case 'SET_SECTION_UPDATED_DATE':
      const updated = action.payload.date ?? new Date();
      return {
        ...state,
        updated,
        sectionUpdatedDates: {
          ...state.sectionUpdatedDates,
          [action.payload.section]: updated
        }
      };
    case 'SET_MODE':
      return {
        ...state,
        mode: action.payload.mode
      };
    case 'SET_LOG_ID':
      return {
        ...state,
        currentLogId: action.payload.id
      };
    default:
      return { ...state };
  }
};

export const IncidentLogContextProvider: React.FC = ({ children}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialIncidentLogState);

  const [savingScreenVisible, setSavingScreenVisibility] = useState<boolean>(false);

  const resetIncidentLogState = () => {
    dispatch({
      type: 'RESET_STATE',
    });
  };

  const submitResponses = (section: string, responses: Map<string, resultType>, initial: boolean) => {
    if (initial) {
      dispatch({
        type: 'SET_PAGE_CONTENT',
        payload: {
          updated: responses
        }
      });
    } else {
      dispatch({
        type: 'UPDATE_RESULTS',
        payload: {
          updated: responses,
        }
      });
    }
    dispatch({
      type: 'SET_SECTION_UPDATED_DATE',
      payload: {
        section
      }
    });
  };

  const setSectionUpdated = (section: string, updated: boolean) => {
    if (state.sectionUpdated[section] !== updated) {
      dispatch({
        type: 'SET_SECTION_UPDATED',
        payload: {
          section,
          updated
        }
      });
    }
  };

  const setIncidentLogMode = (mode: 'create' | 'edit') => {
    dispatch({
      type: 'SET_MODE',
      payload: {
        mode
      }
    });
  };

  const setCurrentLogId = (id: string) => {
    dispatch({
      type: 'SET_LOG_ID',
      payload: {
        id
      }
    });
  };

  const setInitialData = (initialData: incidentLogData, created: Date, updated: Date) => {
    dispatch({
      type: 'SET_INITIAL_DATA',
      payload: {
        initialData,
        created,
        updated
      }
    });
  };

  const saveIncidentLog = async (mode: 'create' | 'edit', currentLogId?: string) => {
    if (state.changesMade) {
      try {
        const finalResults: incidentLogData = {};
        for (const sectionName of Object.keys(incidentLogSections)) {
          const section = incidentLogSections[sectionName];
          const updated = state.sectionUpdatedDates[sectionName] ?? new Date();
          if (section.questions.length > 0 && state.sectionUpdatedDates[sectionName]) {
            const sectionResponses = new Map<string, resultType>();
            for (const question of section.questions) {
              const response = state.results.get(question.key);
              if (response) {
                sectionResponses.set(question.key, response);
              }
            }

            finalResults[sectionName] = {
              responses: sectionResponses,
              updated
            };
          }
        }

        if (mode === 'create') {
          await survivorClient.createIncidentLog(finalResults);
          toast({
            position: 'top',
            status: 'success',
            title: 'Successfully created an Incident Log.',
          });
        } else if (currentLogId) {
          await survivorClient.updateIncidentLog(currentLogId, finalResults);
        }
      } finally {
        resetIncidentLogState();
        navigate(PrivateRoutes.RESOURCES, {
          state: {
            target: PrivateRoutes.ILOG,
            text: 'Incident Log'
          }
        });
      }
    } else {
      resetIncidentLogState();
      navigate(PrivateRoutes.ILOG);
    }
  };

  return (
    <IncidentLogContext.Provider
      value={{
        state,
        resetIncidentLogState,
        submitResponses,
        setSectionUpdated,
        setSavingScreenVisibility,
        setIncidentLogMode,
        setCurrentLogId,
        setInitialData,
        saveIncidentLog
      }}
    >
      {children}
      {savingScreenVisible && (
        <Flex
          position="fixed"
          width="100%"
          height="100%"
          left="0px"
          top="0px"
          zIndex={9999}
          backgroundColor="rgba(0,0,0,0.5)"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <LoadingIndicator width="auto" height="auto" />
          <Text as="p" color="brand.white" fontSize="md" fontWeight="600">
            Saving...
          </Text>
        </Flex>
      )}
    </IncidentLogContext.Provider>
  );
};

const compareResultTypes = (first: resultType | undefined, second: resultType | undefined): boolean => {
  if ((!first && second) || (first && !second)) {
    return false;
  }

  if (!first && !second) {
    return true;
  }

  if ((Array.isArray(first) && !Array.isArray(second)) || (!Array.isArray(first) && Array.isArray(second))) {
    return false;
  }

  if (Array.isArray(first) && Array.isArray(second)) {
    if (first.length !== second.length) {
      return false;
    }

    for (let i = 0; i < first.length; i++) {
      if (!compareResultTypes(first[i], second[i])) {
        return false;
      }
    }
  }

  const firstResp = first as QResponse;
  const secondResp = second as QResponse;

  if ((typeof firstResp.value === 'string' && typeof secondResp.value !== 'string') ||
    (typeof firstResp.value !== 'string' && typeof secondResp.value === 'string')) {
    return false;
  }

  if (typeof firstResp.value === 'string') {
    if ((firstResp.optionValue && !secondResp.optionValue) || (!firstResp.optionValue && secondResp.optionValue)) {
      return false;
    }

    if (firstResp.optionValue) {
      return compareResultTypes(firstResp.optionValue, secondResp.optionValue);
    }

    return firstResp.value === secondResp.value;
  }

  return compareResultTypes(firstResp.value, secondResp.value as resultType);
};
