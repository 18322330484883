import React, { useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/modal';
import {
  Box,
  ModalCloseButton
} from '@chakra-ui/react';
import { Paragraph } from '../../components/Paragraph';
import Checkbox from '../../components/CheckBox';
import WhiteButton from '../../components/layout/WhiteButton';
import Button from '../../components/Button';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const IncidentLogPdfDisclaimerModal: React.FC<Props> = ({ isOpen, onClose, onSubmit }: Props) => {
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="800px" color="brand.primary">
        <ModalCloseButton />
        <Paragraph props={{ fontSize: 'sm', padding: '15px' }}>
          While we know that survivors often remember things in stages and this is typical, we also know how this
          can be used against survivors. As such, before downloading the Incident Log that includes
          information about what happened to you, you should be aware that differences between what you download
          and what you tell other people may cause various legal actors (including police or jury members) to
          question the accuracy of your recollections.
        </Paragraph>
        <Paragraph props={{ fontSize: 'sm', paddingLeft: '15px', paddingRight: '15px' }} mt="0">
          For your own protection, we recommend that you password protect the PDF file after it is downloaded and
          store it in a place that is safe and that only you have access to.
        </Paragraph>
        <Paragraph props={{
          fontSize: 'sm',
          paddingLeft: '15px',
          paddingRight: '15px'
        }}
        mt="15px"
        mb="15px">
          Callisto does not assume any responsibility for information that you record to or download from
          Callisto Vault, and no one shall be entitled to a claim for detrimental reliance on any information
          provided or expressed. Callisto does not endorse, guarantee, or warranty the accuracy, reliability, or
          thoroughness of any referenced information.
        </Paragraph>
        <Checkbox ml="15px" mb="15px" onChange={() => setButtonEnabled(!buttonEnabled)}>
          I understand and accept these conditions
        </Checkbox>
        <Box textAlign="center" mt="20px" pt="20px">
          <WhiteButton mb="5px" mx="10px" px="20px" onClick={onClose}>
            Cancel
          </WhiteButton>
          <Button
            mb="5px"
            buttonColor="brand.primary"
            isDisabled={!buttonEnabled}
            onClick={onSubmit}
          >Download PDF</Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default IncidentLogPdfDisclaimerModal;
