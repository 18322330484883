import sodium from 'libsodium-wrappers-sumo';

import {
  OPRF,
  Secretbox
} from '../../../lib/crypto';

import {
  createPromiseClient,
  PromiseClient
} from '@bufbuild/connect';
import { createGrpcWebTransport } from '@bufbuild/connect-web';
import { RecoveryService } from '../gen/recovery_connect';
import { envelopeContents } from '../../../lib/data';

// loginData instances have all the information required to log a user in,
// and are the basic unit ultimately stored by the recovery system.
export interface loginData {
  userID: string;
  username: string;
  envelope: envelopeContents;
}

// encryptionData instances have the necessary locally-computed components
// of the keys, so that tests can reconstruct the keys and test unlocking
// the encrypted data.
export interface encryptionData {
  e: Uint8Array;
  kOTPE: Uint8Array;
  a: Uint8Array;
  kOTPA: Uint8Array;
  m: Uint8Array;
}

// This is the maximum size of an email address, which the contact information
// will be padded to in order to conceal the real size of the given address.
const EMAIL_ADDRESS_LENGTH = 254;

// ServerMismatchError is thrown when the two servers disagree on the parameters
// given by the user. It should only appear if one server has been compromised.
export class ServerMismatchError extends Error { }

// RecoveryClient abstracts over all the necessary steps for executing the
// account recovery protocol, including communicating with both recovery
// servers.
export class RecoveryClient {
  // client1 and client2 are used to communicate with the two recovery servers.
  client1: PromiseClient<typeof RecoveryService>;
  client2: PromiseClient<typeof RecoveryService>;
  private enc = new TextEncoder();

  // These parameters are set by recovery_step1_get_questions() and are
  // required by recovery_step2_complete().
  private m: Uint8Array;
  private kA1: Uint8Array;
  private kA2: Uint8Array;
  private eA: Uint8Array;

  constructor(server1URL: string, server2URL: string) {
    const transport1 = createGrpcWebTransport({
      baseUrl: server1URL,
      useBinaryFormat: true,
      credentials: 'include',
    });
    this.client1 = createPromiseClient(RecoveryService, transport1);

    const transport2 = createGrpcWebTransport({
      baseUrl: server2URL,
      useBinaryFormat: true,
      credentials: 'include',
    });
    this.client2 = createPromiseClient(RecoveryService, transport2);
  }

  // setup saves the account recovery information for the user.
  public async setup(
    emailAddress: string,
    phoneNumber: string,
    questions: Map<string, string>,
    userID: string,
    username: string,
    userMarker: Uint8Array,
    envelope: envelopeContents,
    publicKey: Uint8Array,
    recoveryKey: string = ''
  ): Promise<encryptionData> {
    await sodium.ready;

    // Step 1: create a client nonce (m).
    const m = sodium.randombytes_buf(32);

    // Step 2: Create kE to lock the contact information.
    let padding = '';
    for (let i = 0; i < (EMAIL_ADDRESS_LENGTH - emailAddress.length); i++) {
      padding += '*';
    }
    const kE = Secretbox.keygen();
    const uE = {
      questions: Array.from(questions.keys()),
      e: emailAddress,
      m: sodium.to_hex(m),
      padding,
    };
    const eE = Secretbox.encrypt(kE, uE);

    // Step 3: Create k.
    const k = await this.computeK(emailAddress, phoneNumber, recoveryKey);

    // Step 4: Extract id from k
    const id = k.slice(0, 32);
    const kOTPE = k.slice(32);

    // Step 5: Construct the secret shares of kE, which will be shared to each
    // recovery server.
    const kE1 = sodium.randombytes_buf(sodium.crypto_secretbox_KEYBYTES);
    const kE2 = new Uint8Array(sodium.crypto_secretbox_KEYBYTES);
    for (let i = 0; i < kE2.length; i++) {
      // eslint-disable-next-line no-bitwise
      kE2[i] = kE[i] ^ kE1[i] ^ kOTPE[i];
    }

    // Step 6: Create kA to encrypt the envelope.
    const kA = Secretbox.keygen();
    const eA = Secretbox.encrypt(kA, {
      user_id: userID,
      username,
      envelope: {
        pk: sodium.to_hex(envelope.pk),
        sk: sodium.to_hex(envelope.sk)
      }
    });

    // Step 7: Create A by concatenating the answers to the security questions,
    // as well as the client nonce (m).
    const aa: Uint8Array[] = [];
    for (const v of questions.values()) {
      aa.push(this.enc.encode(v));
    }
    aa.push(m);
    const As = concat(aa);

    // Step 8: Create A, a pOPRF input, from the security questions.
    const A = sodium.crypto_generichash(sodium.crypto_core_ristretto255_HASHBYTES, As);
    const pointA = OPRF.makePoint(A);

    // Step 9: Execute a pOPRF with both servers.
    const maskedA = OPRF.mask(pointA);

    const a1 = await this.client1.setup_POPRF({ n: userMarker, alpha: maskedA.point });
    const a2 = await this.client2.setup_POPRF({ n: userMarker, alpha: maskedA.point });

    const unmaskedA1 = OPRF.unmask(a1.beta, maskedA.mask);
    const unmaskedA2 = OPRF.unmask(a2.beta, maskedA.mask);

    // Step 10: Hash the pOPRF output to create kOTPA, which is a one-time pad
    // for the secret shares for the envelope key.
    const kOTPA = this.hash(
      [As, unmaskedA1, unmaskedA2],
      sodium.crypto_secretbox_KEYBYTES
    );

    // Step 11: Create the secret shares for the envelope key.
    const kA1 = sodium.randombytes_buf(sodium.crypto_secretbox_KEYBYTES);
    const kA2 = new Uint8Array(sodium.crypto_secretbox_KEYBYTES);
    for (let i = 0; i < kA2.length; i++) {
      // eslint-disable-next-line no-bitwise
      kA2[i] = kA[i] ^ kA1[i] ^ kOTPA[i];
    }

    // Step 12: Save the recovery information to both servers so that recovery
    // is possible in the future.
    await this.client1.setup_Save({ id, kE: kE1, eE, kA: kA1, eA, n: userMarker, pk: publicKey });
    await this.client2.setup_Save({ id, kE: kE2, eE, kA: kA2, eA, n: userMarker, pk: publicKey });

    return {
      e: eE,
      kOTPE,
      a: eA,
      kOTPA,
      m,
    };
  }

  public async request(
    emailAddress: string,
    phoneNumber: string,
    mailDomainKey: string = 'MAIL_CANONICAL_DOMAIN',
    recoveryKey: string = ''
  ): Promise<void> {
    await sodium.ready;

    // Step 1: compute k
    const k = await this.computeK(emailAddress, phoneNumber, recoveryKey);

    // Step 2: extract id from k
    const id = k.slice(0, 32);
    const kOTPE = k.slice(32);

    // Step 3: Send the computed parameters to the server, which will reconstruct
    // the contact information and send a tokenized email.
    await this.client1.request_Start({ id, kOTPE, mailDomainKey });
  }

  public async recovery_step1_get_questions(token: string): Promise<string[]> {
    await sodium.ready;

    // Step 2: Use that token to start a transaction.
    const t = await this.client1.recovery_Start({ token });

    // Step 3: Send the token to the other server and retrieve the questions and
    // client nonce.
    const params = await this.client2.recovery_ValidateToken({ token, transaction: t.transaction });

    // Step 4: Run the transaction again so we can validate that both servers
    // are valid.
    const t2 = await this.client2.recovery_Start({ token });
    const params2 = await this.client1.recovery_ValidateToken({ token, transaction: t2.transaction });

    // kA1 and kA2 will be flipped here since they come from different server
    // perspectives so we have to compare them separately.
    if (
      !equal(params.eA, params2.eA) ||
      !equal(params.kA1, params2.kA2) ||
      !equal(params.kA2, params2.kA1) ||
      !equal(params.m, params2.m)) {
      throw new ServerMismatchError();
    }

    // Step 5: Save the parameters we've validated so that the next steps can
    // complete after the survivor enters the answers to their questions.
    this.eA = params.eA;
    this.m = params.m;
    this.kA1 = params.kA1;
    this.kA2 = params.kA2;

    return params.questions;
  }

  public async recovery_step2_complete(token: string, answers: string[]): Promise<loginData> {
    // Step 4: Create A by concatenating the answers to the security questions,
    // as well as the client nonce (m).
    const aa: Uint8Array[] = [];
    for (const v of answers) {
      aa.push(this.enc.encode(v));
    }
    aa.push(this.m);
    const As = concat(aa);

    // Step 5: Create A, a pOPRF input, from the security questions.
    const A = sodium.crypto_generichash(sodium.crypto_core_ristretto255_HASHBYTES, As);
    const pointA = OPRF.makePoint(A);

    // Step 6: Send A to both servers for pOPRF.
    const maskedA = OPRF.mask(pointA);
    const pOPRF1 = await this.client1.recovery_POPRF({ token, transaction: '', alpha: maskedA.point });
    const unmaskedA1 = OPRF.unmask(pOPRF1.beta, maskedA.mask);

    const pOPRF2 = await this.client2.recovery_POPRF({ token, transaction: '', alpha: maskedA.point });
    const unmaskedA2 = OPRF.unmask(pOPRF2.beta, maskedA.mask);

    // Step 7: Hash the pOPRF output to create kOTPA, which is a one-time pad
    // for the secret shares for the envelope key.
    const kOTPA = this.hash(
      [As, unmaskedA1, unmaskedA2],
      sodium.crypto_secretbox_KEYBYTES
    );

    // Step 8: Reconstruct kA and unlock the envelope.
    const kA = new Uint8Array(sodium.crypto_secretbox_KEYBYTES);
    for (let i = 0; i < kA.length; i++) {
      // eslint-disable-next-line no-bitwise
      kA[i] = this.kA1[i] ^ this.kA2[i] ^ kOTPA[i];
    }

    const contents = Secretbox.decrypt(this.eA, kA) as {
      user_id: string;
      username: string;
      envelope: {
        pk: string;
        sk: string;
      };

    };
    return {
      userID: contents.user_id,
      username: contents.username,
      envelope: {
        pk: sodium.from_hex(contents.envelope.pk),
        sk: sodium.from_hex(contents.envelope.sk),
      }
    };
  }

  public async delete(emailAddress: string, phoneNumber: string, privateKey: Uint8Array, recoveryKey: string = ''): Promise<void> {
    await sodium.ready;

    const k = await this.computeK(emailAddress, phoneNumber, recoveryKey);

    // We only need the first part of k to delete.
    const id = k.slice(0, 32);

    // The signature here allows us to validate that the request came from the
    // user instead of some rando.
    const signature = sodium.crypto_sign('delete', privateKey);

    await this.client1.delete({ id, signature });
    await this.client2.delete({ id, signature });
  }

  public async deleteByProxy(
    emailAddress: string,
    phoneNumber: string,
    privateKey: Uint8Array,
    proxyId: string,
    recoveryKey: string = ''
  ): Promise<void> {
    await sodium.ready;

    const k = await this.computeK(emailAddress, phoneNumber, recoveryKey);
    const id = k.slice(0, 32);

    // The signature here allows us to validate that the request came from an active authorized proxy
    const signature = sodium.crypto_sign('delete', privateKey);

    await this.client1.delete_By_Proxy({ id, userId: proxyId, signature });
    await this.client2.delete_By_Proxy({ id, userId: proxyId, signature });
  }

  public async update(
    oldEmailAddress: string,
    oldPhoneNumber: string,
    emailAddress: string,
    phoneNumber: string,
    questions: Map<string, string>,
    userID: string,
    username: string,
    userMarker: Uint8Array,
    envelope: envelopeContents,
    privateKey: Uint8Array,
    recoveryKey: string = ''
  ): Promise<encryptionData> {
    await sodium.ready;

    // Step 1: create a client nonce (m).
    const m = sodium.randombytes_buf(32);

    // Step 2: Create kE to lock the contact information.
    let padding = '';
    for (let i = 0; i < (EMAIL_ADDRESS_LENGTH - emailAddress.length); i++) {
      padding += '*';
    }
    const kE = Secretbox.keygen();
    const uE = {
      questions: Array.from(questions.keys()),
      e: emailAddress,
      m: sodium.to_hex(m),
      padding,
    };
    const eE = Secretbox.encrypt(kE, uE);

    // Step 3: Compute the old ID to look up the existing contact info.
    const k = await this.computeK(oldEmailAddress, oldPhoneNumber, recoveryKey);

    // We only need the first part of k to update.
    const oldID = k.slice(0, 32);

    // Step 4: Compute the new ID in case the user has updated their email/phone.
    const k_new = await this.computeK(emailAddress, phoneNumber, recoveryKey);
    const newID = k_new.slice(0, 32);
    const kOTPE_new = k_new.slice(32);

    // Step 5: Construct the secret shares of kE, which will be shared to each
    // recovery server.
    const kE1 = sodium.randombytes_buf(sodium.crypto_secretbox_KEYBYTES);
    const kE2 = new Uint8Array(sodium.crypto_secretbox_KEYBYTES);
    for (let i = 0; i < kE2.length; i++) {
      // eslint-disable-next-line no-bitwise
      kE2[i] = kE[i] ^ kE1[i] ^ kOTPE_new[i];
    }

    // Step 6: Create kA to encrypt the envelope.
    const kA = Secretbox.keygen();
    const eA = Secretbox.encrypt(kA, {
      user_id: userID,
      username,
      envelope: {
        pk: sodium.to_hex(envelope.pk),
        sk: sodium.to_hex(envelope.sk)
      }
    });

    // Step 7: Create A by concatenating the answers to the security questions,
    // as well as the client nonce (m).
    const aa: Uint8Array[] = [];
    for (const v of questions.values()) {
      aa.push(this.enc.encode(v));
    }
    aa.push(m);
    const As = concat(aa);

    // Step 8: Create A, a pOPRF input, from the security questions.
    const A = sodium.crypto_generichash(sodium.crypto_core_ristretto255_HASHBYTES, As);
    const pointA = OPRF.makePoint(A);

    // Step 9: Execute a pOPRF with both servers.
    const maskedA = OPRF.mask(pointA);

    const a1 = await this.client1.setup_POPRF({ n: userMarker, alpha: maskedA.point });
    const a2 = await this.client2.setup_POPRF({ n: userMarker, alpha: maskedA.point });

    const unmaskedA1 = OPRF.unmask(a1.beta, maskedA.mask);
    const unmaskedA2 = OPRF.unmask(a2.beta, maskedA.mask);

    // Step 10: Hash the pOPRF output to create kOTPA, which is a one-time pad
    // for the secret shares for the envelope key.
    const kOTPA = this.hash(
      [As, unmaskedA1, unmaskedA2],
      sodium.crypto_secretbox_KEYBYTES
    );

    // Step 11: Create the secret shares for the envelope key.
    const kA1 = sodium.randombytes_buf(sodium.crypto_secretbox_KEYBYTES);
    const kA2 = new Uint8Array(sodium.crypto_secretbox_KEYBYTES);
    for (let i = 0; i < kA2.length; i++) {
      // eslint-disable-next-line no-bitwise
      kA2[i] = kA[i] ^ kA1[i] ^ kOTPA[i];
    }

    // The signature here allows us to validate that the request came from the
    // user instead of some rando.
    const signature = sodium.crypto_sign('update', privateKey);

    await this.client1.update({id: oldID, newId: newID, eE, kE: kE1, eA, kA: kA1, signature});
    await this.client2.update({id: oldID, newId: newID, eE, kE: kE2, eA, kA: kA2, signature});

    return {
      e: eE,
      kOTPE: kOTPE_new,
      a: eA,
      kOTPA,
      m,
    };
  }

  // hash implements a slow hash function designed to make this a
  // computationally-expensive protocol to execute.
  private hash(inputs: Uint8Array[], length: number): Uint8Array {
    return sodium.crypto_pwhash(
      length,
      concat(inputs),
      // TODO: make this a more meaningful const
      sodium.crypto_generichash(sodium.crypto_pwhash_SALTBYTES, 'callisto'),
      sodium.crypto_pwhash_OPSLIMIT_MODERATE,
      sodium.crypto_pwhash_MEMLIMIT_MODERATE,
      sodium.crypto_pwhash_ALG_ARGON2ID13
    );
  }

  private async computeK(emailAddress: string, phoneNumber: string, recoveryKey: string = '') {
    await sodium.ready;

    // Step 1: Create an OPRF input from the email address and phone number.
    const ab: Uint8Array[] = [
      this.enc.encode(emailAddress),
      this.enc.encode(phoneNumber)
    ];

    if (recoveryKey && recoveryKey !== '') {
      ab.push(this.enc.encode(recoveryKey));
    }

    const asBytes = concat(ab);

    const E = sodium.crypto_generichash(sodium.crypto_core_ristretto255_HASHBYTES, asBytes);
    const maskedE = OPRF.mask(OPRF.makePoint(E));

    // Step 2: Gather OPRFs from each recovery server.
    const E1 = await this.client1.oprf({ alpha: maskedE.point });
    const E2 = await this.client2.oprf({ alpha: maskedE.point });

    const unmasked1 = OPRF.unmask(E1.beta, maskedE.mask);
    const unmasked2 = OPRF.unmask(E2.beta, maskedE.mask);

    // Step 3: Concatenate the OPRFed inputs to create k.
    return this.hash(
      [asBytes, unmasked1, unmasked2],
      32 + sodium.crypto_secretbox_KEYBYTES
    );
  }
}

// concat solves the problem of domain separation by making sure that each
// input to a hash function is prepended by 4 NULLs, which is a sequence
// extremely unlikely to occur naturally.
export const concat = (inputs: Uint8Array[]): Uint8Array => {
  let length = 0;
  for (const i of inputs) {
    length += i.length + 4;
  }
  const r = new Uint8Array(length);

  let offset = 0;
  for (const i of inputs) {
    r.set([0x00, 0x00, 0x00, 0x00], offset);
    r.set(i, offset + 4);
    offset += i.length + 4;
  }

  return r;
};

// equal is shamelessly stolen from the array-equal NPM library, used here to
// avoid needing to install a tiny library just for a simple function.
export const equal = (arr1: Uint8Array, arr2: Uint8Array): boolean => {
  const length = arr1.length;
  if (arr1 === arr2) {
    return true;
  }
  if (length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};
