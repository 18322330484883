import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/layout';

import Button from './Button';
import ButtonLink from './ButtonLink';

interface Props {
  backRoute?: string;
  forwardRoute: string;
  handleClick: () => void;
}

const IncidentLogActionButtons: React.FC<Props> = ({
  backRoute,
  forwardRoute,
  handleClick
}: Props) => {
  const navigate = useNavigate();

  const handleForwardClick = () => {
    handleClick();
    navigate(forwardRoute);
  };

  const handleBackClick = () => {
    handleClick();
    if (backRoute) {
      navigate(backRoute);
    }
  };

  return (
    <Flex justifyContent="space-between" mt="60px">
      {backRoute && (
        <ButtonLink textDecor="underline" onClick={handleBackClick}>
          Back
        </ButtonLink>
      )}
      <Button buttonColor="brand.primary" onClick={handleForwardClick}>
        Continue
      </Button>
    </Flex>
  );
};

export default IncidentLogActionButtons;
