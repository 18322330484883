import { Box, TextProps, Text } from '@chakra-ui/react';
import React from 'react';
import { PublicRoutes } from '../config/routes';

const HaveAccount: React.FC<TextProps> = ({ ...props }) => (
  <Box fontSize="sm">
    <Text as="p" display="inline" {...props}>
        Already have a Callisto Vault account?{' '}
    </Text>
    <Text
      as="a"
      href={PublicRoutes.LOGIN}
      color="brand.link"
      textDecor="underline"
      fontFamily="Avenir"
      fontWeight="bold"
    >
        Log in here
    </Text>
  </Box>
);

export default HaveAccount;
