import {
  Box,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutTimerModal from './LogoutTimerModal';
import { AppContext } from '../contexts/AppContext';
import { survivorClient } from '../service/backend';
import { PublicRoutes } from '../config/routes';

interface IdleTimerProps {
  children?: React.ReactNode | React.ReactNode[];
}

const TIMEOUT = 29; // number of minutes of idle time before starting countdown
const COUNTDOWN = 60; // number of seconds to count down before logging out

const WithIdleTimer: React.FC<IdleTimerProps> = ({ children }: IdleTimerProps) => {
  const navigate = useNavigate();
  const { loggedIn, setLoggedIn } = useContext(AppContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ lastActiveTime, setLastActiveTime ] = useState<Date>(new Date());
  const [ countdownStartTime, setCountdownStartTime ] = useState<Date>(new Date(lastActiveTime.getTime() + 1000 * 60 * TIMEOUT));
  const [ countdownEndTime, setCountdownEndTime ] = useState<Date>(new Date(countdownStartTime.getTime() + 1000 * COUNTDOWN));
  const [ countdown, setCountdown ] = useState<number>(-1);
  const [ idleTime, setIdleTime ] = useState<number>(0);
  const idleTimer = useRef<NodeJS.Timeout | null>(null);
  const toast = useToast();

  useEffect(() => {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];

    const resetTimer = () => {
      if (!isOpen) {
        handleReset();
      }
    };

    for (const evnt of events) {
      window.addEventListener(evnt, resetTimer);
    }

    return () => {
      for (const evnt of events) {
        window.removeEventListener(evnt, resetTimer);
      }
    };
  });

  useEffect(() => {
    if (loggedIn) {
      const now = Date.now();
      if (now >= countdownEndTime.getTime()) {
        void logout();
      } else if (now >= countdownStartTime.getTime()) {
        if (countdown < 0) {
          startCountdown();
        } else {
          setCountdownTime(now);
        }
      }
      idleTimer.current = setTimeout(
        () => setIdleTime((prev) => prev + 1),
        1
      );
      return () => clearTimer(idleTimer.current);
    } else {
      clearTimer(idleTimer.current);
    }
  }, [idleTime, loggedIn]);

  useEffect(() => {
    setCountdownStartTime(() => new Date(lastActiveTime.getTime() + 1000 * 60 * TIMEOUT));
  }, [lastActiveTime]);

  useEffect(() => {
    setCountdownEndTime(() =>
      countdownStartTime ? new Date(countdownStartTime.getTime() + 1000 * COUNTDOWN)
        : new Date(lastActiveTime.getTime() + 1000 * 60 * TIMEOUT + 1000 * COUNTDOWN));
  }, [countdownStartTime]);

  useEffect(() => {
    const now = Date.now();
    setCountdownTime(now);
  }, [countdownEndTime]);

  const setCountdownTime = (now: number) => {
    if (countdownStartTime.getTime() > now) {
      setCountdown(-1);
    } else {
      setCountdown(Math.ceil((countdownEndTime.getTime() - now) / 1000));
    }
  };

  const clearTimer = (timer: NodeJS.Timeout | null) => {
    if (timer !== null) {
      clearInterval(timer);
    }
  };

  const logout = async () => {
    onClose();
    await survivorClient.logout();
    setLoggedIn(false);
    clearTimer(idleTimer.current);
    toast({
      title: 'You have been logged out.',
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
    navigate(PublicRoutes.LOGIN);
  };

  const stayLoggedIn = () => {
    setLastActiveTime(() => new Date());
    setIdleTime(0);
    onClose();
  };

  const startCountdown = () => {
    setCountdownTime(Date.now());
    onOpen();
  };

  const handleReset = () => {
    stayLoggedIn();
  };

  return (
    <>
      <Box
        height="100%"
        color="primary"
        fontSize="sm"
      >
        {children}
      </Box>
      {
        <LogoutTimerModal
          isOpen={isOpen}
          onClose={onClose}
          sec={countdown}
          stayLoggedIn={stayLoggedIn}
          logout={logout}
        >
          {}
        </LogoutTimerModal>
      }
    </>
  );
};

export default WithIdleTimer;
