import * as React from 'react';

const ConsentIcon: React.FC = () => {
  const path = 'M256 368C286.4 368 307.2 354.9 319 343C328.4 333.7 343.6 333.7 352.1 343C362.3 352.4 362.3 367.6 352.1 ' +
    '376.1C333.9 396.1 301.4 416 256 416C210.6 416 178.1 396.1 159 376.1C149.7 367.6 149.7 352.4 159 343C168.4 333.7 ' +
    '183.6 333.7 192.1 343C204.8 354.9 225.6 368 256 368zM377 242.3C384.6 234.3 397.2 233.9 405.3 241.4C413.4 248.1 ' +
    '413.8 261.6 406.2 269.7C391.7 285.2 370.1 292 351.6 292C332.3 292 311.6 285.2 297 269.7C289.5 261.6 289.9 248.1 ' +
    '297.9 241.4C306 233.9 318.7 234.3 326.2 242.3C331.7 248.2 340.1 252 351.6 252C362.3 252 371.6 248.2 377 ' +
    '242.3zM134.2 242.3C139.7 248.2 148.1 252 159.6 252C170.3 252 179.6 248.2 185 242.3C192.6 234.3 205.2 233.9 213.3 ' +
    '241.4C221.4 248.1 221.8 261.6 214.2 269.7C199.7 285.2 178.1 292 159.6 292C140.3 292 119.6 285.2 105 269.7C97.48 ' +
    '261.6 97.89 248.1 105.9 241.4C114 233.9 126.7 234.3 134.2 242.3V242.3zM207.2 106.9C209.1 115.3 205.4 124.4 197.1 ' +
    '127.2L190.7 129.3C167.1 137.2 146.8 152.7 132.1 173.4L125.3 184.9C120.4 192.2 110.5 194.2 103.1 189.3C95.77 184.4 ' +
    '93.79 174.5 98.69 167.1L106.3 155.7C124.1 129 150.2 109.1 180.6 98.93L186.9 96.82C195.3 94.03 204.4 98.56 207.2 ' +
    '106.9L207.2 106.9zM314.9 127.2C306.6 124.4 302 115.3 304.8 106.9C307.6 98.56 316.7 94.03 325.1 96.82L331.4 ' +
    '98.93C361.8 109.1 387.9 129 405.7 155.7L413.3 167.1C418.2 174.5 416.2 184.4 408.9 189.3C401.5 194.2 391.6 192.2 ' +
    '386.7 184.9L379 173.4C365.2 152.7 344.9 137.2 321.3 129.3L314.9 127.2zM512 256C512 397.4 397.4 512 256 512C114.6 ' +
    '512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 ' +
    '464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z';

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path}
        fill="currentColor"
      />
    </svg>
  );
};

export default ConsentIcon;
