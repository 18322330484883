import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const headerTextProps: TextProps = {
  as: 'h1',
  fontSize: ['sm2', 'md', 'lg', 'lg2'],
  mb: '25px',
  lineHeight: '1.2',
  marginBlockStart: '0.67em',
  marginBlockEnd: '0.67em'
};

const pdfHeaderTextProps: TextProps = {
  ...headerTextProps,
  lineHeight: '1'
};

const HeaderText: React.FC<TextProps & { pdf?: boolean}> = ({
  children = '',
  pdf = false,
  ...restProps
}: TextProps & { pdf?: boolean }) => {
  const props: TextProps = pdf ? pdfHeaderTextProps : headerTextProps;

  return (
    <Text
      {...props}
      {...restProps}
    >
      {children}
    </Text>
  );
};

export default HeaderText;
