import React, {
  FormEvent,
  useContext,
  useState
} from 'react';
import {
  Box,
  Text,
  useToast
} from '@chakra-ui/react';

import Button from '../../components/Button';
import AnimatedBox from '../../components/layout/AnimatedBox';
import {
  EntryContext,
  EntryGlobalActionTypes
} from '../../contexts/EntryContext';
import { usStates } from '../CreateWhere/constant';
import Checkbox from '../../components/CheckBox';
import { useForm } from 'react-hook-form';
import {
  survivorClient,
  wait
} from '../../service/backend';
import { entryData } from '../../../../../../lib/data';
import MainCard from '../../components/MainCard';
import { STEP_WHERE_TEXT } from '../../contexts/EntryContext/stepWhere';
import { STEP_WHO_TEXT } from '../../contexts/EntryContext/stepWho';
import { STEP_CONTACT_TEXT } from '../../contexts/EntryContext/stepContact';
import WhiteButton from '../../components/layout/WhiteButton';
import ContactInfoChangeConfirmModal from '../../components/ContactInfoChangeConfirmModal';
import { PrivateRoutes } from '../../config/routes';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';

interface ISubmitForm {
  agreed: boolean;
  forMyself: boolean;
}

export const SUBMIT_FORM_TEXT = 'Submit';

const SubmitForm: React.FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(EntryContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, register } = useForm<ISubmitForm>();
  const [error, setError] = useState<string>('');
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const toast = useToast();
  const {
    steps: { stepWhere, stepWho, stepContact, stepSubmit },
    editEntryID
  } = state;

  const onSubmitEntry = async (data: ISubmitForm) => {
    if (!stepWhere.completed) {
      setError(`Please complete step 1, "${STEP_WHERE_TEXT}"`);
      return;
    } else if (!stepWho.completed) {
      setError(`Please complete step 2, "${STEP_WHO_TEXT}"`);
      return;
    } else if (!stepContact.completed) {
      setError(`Please complete step 3, "${STEP_CONTACT_TEXT}"`);
      return;
    } else if (!data.forMyself) {
      setError('Please confirm that you are submitting this entry for yourself');
      return;
    } else if (!data.agreed) {
      setError('Please confirm that you are submitting this entry for review by a Legal Options Counselor in the event of a match');
      return;
    }

    if (
      (!survivorClient.contactInfo?.name || survivorClient.contactInfo.name === stepContact.name) &&
      (!survivorClient.contactInfo?.email || survivorClient.contactInfo.email === stepContact.email) &&
      (!survivorClient.contactInfo?.phone || survivorClient.contactInfo.phone === stepContact.phone) &&
      (!survivorClient.contactInfo?.preferredLanguage || survivorClient.contactInfo.preferredLanguage === stepContact.preferredLanguage)
    ) {
      await saveEntry();
    } else {
      setShowConfirmModal(true);
    }
  };

  const saveEntry = async () => {
    setLoading(true);
    try {
      await wait(100);
      const languageChanged = survivorClient.contactInfo?.preferredLanguage !== stepContact.preferredLanguage;
      const accommodationsChanged = survivorClient.contactInfo?.accommodationsNeeded !== stepContact.accommodationsNeeded;
      survivorClient.contactInfo.name = stepContact.name;
      survivorClient.contactInfo.email = stepContact.email;
      survivorClient.contactInfo.phone = stepContact.phone;
      survivorClient.contactInfo.preferredLanguage = stepContact.preferredLanguage;
      survivorClient.contactInfo.accommodationsNeeded = stepContact.accommodationsNeeded;
      await survivorClient.updateContactInfo();

      if (languageChanged || accommodationsChanged) {
        await survivorClient.updateLanguageAndAccommodationsOnAllEntries();
      }

      const e: entryData = {
        id: editEntryID ?? undefined,
        userID: survivorClient.userID,
        incidentState: usStates[stepWhere.selectedState],
        perpIDs: stepWho.identifiers,
      };

      await survivorClient.createMatchingEntry(e);

      toast({
        title: 'Your entry was submitted successfully.',
        status: 'success',
        position: 'top',
      });
      dispatch({
        type: EntryGlobalActionTypes.INITIALIZE_ENTRY,
      });
      navigate(stepSubmit.nextStepRoute, {
        state: {
          target: PrivateRoutes.MATCHING_SYSTEM,
          text: 'Matching System'
        }
      });
    } catch (e) {
      toast({
        title: 'There was an error submitting your entry.',
        status: 'error',
        position: 'top',
      });
    }
    setLoading(false);
  };

  const onSubmitAsDraft = async (event: FormEvent<any>) => {
    event.preventDefault();
    const draftEntry: entryData = {
      id: editEntryID ?? undefined,
      userID: survivorClient.userID,
      incidentState: usStates[stepWhere.selectedState],
      perpIDs: stepWho.identifiers
    };

    await survivorClient.saveDraftEntry(draftEntry);
    toast({
      title: 'Your entry was saved as a draft.',
      status: 'success',
      position: 'top',
    });
    dispatch({
      type: EntryGlobalActionTypes.INITIALIZE_ENTRY,
    });
    navigate(stepSubmit.nextStepRoute, {
      state: {
        target: PrivateRoutes.MATCHING_SYSTEM,
        text: 'Matching System'
      }
    });
  };

  return (
    <AnimatedBox>
      <MainCard>
        <Box as="form" onSubmit={onSubmitAsDraft} id="entry_form">
          {stepWhere.completed && (
            <>
              <Text as="h2" fontSize={['18px', '18px', '1.5em']} mt="20px">
                <b>Where did it happen?</b>
              </Text>
              <Text as="p" mt="5px" mb="16px">
                {usStates[stepWhere.selectedState]}
              </Text>
            </>
          )}
          {stepWho.completed && (
            <>
              <Text as="h2" fontSize={['18px', '18px', '1.5em']} mt="20px">
                <b>Who was the offender?</b>
              </Text>
              {Object.keys(stepWho.identifiers).map((key: string) => {
                const identifier =
                  stepWho.identifiers[key as keyof typeof stepWho.identifiers];
                if (identifier.length > 0) {
                  return (
                    <React.Fragment key={key}>
                      <Text
                        as="p"
                        mt="5px"
                        mb="16px"
                        textTransform="capitalize"
                      >
                        <b>{key}</b>
                      </Text>
                      {identifier.map((str, idx) => (
                        <Text as="p" mt="5px" mb="16px" key={`${str}-${idx}`}>
                          {str}
                        </Text>
                      ))}
                    </React.Fragment>
                  );
                } else {
                  return <></>;
                }
              })}
            </>
          )}
          {stepContact.completed && (
            <>
              <Text as="h2" fontSize={['18px', '18px', '1.5em']} mt="20px">
                <b>Contact Information</b>
              </Text>
              <Text as="h3" mt="18px" fontSize={['18px', '18px', '1.17em']}>
                <b>Preferred Name</b>
              </Text>
              <Text
                as="p"
                mt="5px"
                mb="16px"
                fontSize={['18px', '18px', '1.17em']}
              >
                {stepContact.name}
              </Text>
              <Text as="h3" mt="18px" fontSize={['18px', '18px', '1.17em']}>
                <b>Preferred Language</b>
              </Text>
              <Text as="p" mt="5px" mb="16px" fontSize={['18px', '18px', '1.17em']}>
                {stepContact.preferredLanguage || 'Not specified'}
              </Text>
              <Text as="h3" mt="18px" fontSize={['18px', '18px', '1.17em']}>
                <b>Accommodations Needed</b>
              </Text>
              <Text
                as="p"
                mt="5px"
                mb="16px"
                fontSize={['18px', '18px', '1.17em']}
              >
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                {stepContact.accommodationsNeeded ? stepContact.accommodationsNeeded.replaceAll('\n', '; ') : 'Not specified'}
              </Text>
              <Text as="h3" mt="18px" fontSize={['18px', '18px', '1.17em']}>
                <b>Preferred method of contact</b>
              </Text>
              <Text
                as="p"
                mt="5px"
                mb="16px"
                fontSize={['18px', '18px', '1.17em']}
              >
                {stepContact.checked.email && 'Email'}
                <br />
                {stepContact.checked.phone && 'Phone'}
              </Text>
              {stepContact.checked.email && (
                <>
                  <Text as="h3" mt="18px" fontSize={['18px', '18px', '1.17em']}>
                    <b>Email address</b>
                  </Text>
                  <Text
                    as="p"
                    mt="5px"
                    mb="16px"
                    fontSize={['18px', '18px', '1.17em']}
                  >
                    {stepContact.email}
                  </Text>
                </>
              )}
              {stepContact.checked.phone && (
                <>
                  <Text as="h3" mt="18px" fontSize={['18px', '18px', '1.17em']}>
                    <b>Phone number</b>
                  </Text>
                  <Text
                    as="p"
                    mt="5px"
                    mb="16px"
                    fontSize={['18px', '18px', '1.17em']}
                  >
                    {formatPhoneNumber(stepContact.phone ?? '')}
                  </Text>
                </>
              )}
            </>
          )}
          <Checkbox py="10px" {...register('forMyself')}>
            I am entering into matching for myself and not for another person.
            The person that I am identifying as a perpetrator harmed me.
          </Checkbox>
          <Checkbox py="10px" {...register('agreed')}>
            I am submitting this Matching entry for review by a Legal Options
            Counselor (an attorney provided at no cost to you) to seek and receive legal advice
            in the event of a Match. I want my
            entry to be confidential and protected by attorney client privilege.
            I understand that the entire entry will be viewable by the Legal
            Options Counselor assigned to me, and that they will use the contact
            information I provide to reach out to me.
          </Checkbox>
          <Text color="brand.red" my="20px">
            {error && <b>{error}</b>}
          </Text>
          <Button
            buttonColor="brand.primary"
            width="100%"
            type="button"
            isLoading={loading}
            onClick={handleSubmit(onSubmitEntry)}
          >
            Submit my Matching entry
          </Button>
          <WhiteButton
            width="100%"
            margin="20px 20px 20px 0px"
            type="button"
            onClick={onSubmitAsDraft}
          >Save My Entry as a Draft</WhiteButton>
        </Box>
      </MainCard>

      <ContactInfoChangeConfirmModal
        onSubmit={saveEntry}
        isOpen={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
      />

    </AnimatedBox>
  );
};

export default SubmitForm;
