import React from 'react';
import { ButtonProps } from '@chakra-ui/react';
import Button from '../Button';

const WhiteButton: React.FC<ButtonProps> = ({
  children,
  ...restProps
}: ButtonProps) => (
  <Button
    buttonColor="brand.white"
    color="brand.primary"
    border="1px solid"
    fontSize="sm"
    fontWeight="bold"
    minWidth="200px"
    {...restProps}
  >
    {children}
  </Button>
);

export default WhiteButton;
