import React from 'react';
import HeaderText from '../../components/HeaderText';
import SubHeader from '../../components/SubHeader';

export const OrfIntroPageContent: React.FC = () => (
  <>
    <HeaderText>Introduction</HeaderText>
    <SubHeader>
      <b>Purpose</b>
      <br />
          This form is a completely optional, timestamped tool. How you use
          this record is up to you. You might want to record what happened as
          a way to process it, or because it may feel less difficult to share
          a written document than recount your story verbally. How you use
          this record is up to you. It may be helpful should you choose to:
          report the incident; seek secondary services, such as medical,
          therapeutic, or legal; or simply save it for yourself.
    </SubHeader>
    <SubHeader>
      <b>Who can see this and what to write</b>
      <br />
          If there is legal action related to your assault, the prosecutor,
          the Title IX investigator, the offender, their attorneys, and others
          involved in an investigation might be able to read what you write
          here by legally compelling you, the people you shared this form
          with, or anyone else with access to this form to provide it. If that
          happens, any inconsistencies between what you write down here and
          later say is true might be used against you. Only write down what
          you are certain of and okay with sharing if they are able to read
          this later. Leave as many questions as you’d like blank.
      <br />
      <br />
          The safest way to document what happened is by talking to an
          attorney. What you write in this record is encrypted - Callisto will
          not have access to it.
    </SubHeader>
    <SubHeader>
      <b>
            Nothing in the form is legal advice. Contact an attorney for legal
            advice and to learn more about your options.
      </b>
    </SubHeader>
  </>
);
