import React, { forwardRef } from 'react';
import {
  Input as ChakraInput,
  InputProps
} from '@chakra-ui/react';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => (
    <ChakraInput
      border="1px solid"
      borderColor="input.border"
      padding="10px 15px"
      backgroundColor="brand.brightWhite"
      fontSize="sm"
      height="50px"
      borderRadius="0px"
      _focus={{ borderColor: 'brand.primary' }}
      ref={ref}
      {...props}
    />
  )
);

Input.displayName = 'Input';

export default Input;
