import React, {
  ChangeEvent,
  useEffect,
  useState
} from 'react';
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import {
  Box,
  Flex
} from '@chakra-ui/layout';
import ContainerWrapper from '../../components/ContainerWrapper';
import { CALLISTO_STATIC_URL } from '../../config/env';
import {
  Image,
  Select,
  Text,
  useToast
} from '@chakra-ui/react';
import Logo from '../../../assets/Callisto_logo.png';
import { useForm } from 'react-hook-form';
import Checkbox from '../../components/CheckBox';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import Button from '../../components/Button';
import Input from '../../components/Input';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useSetUpAccountRecovery } from '../../service/hooks/auth';
import {
  CallistoRoutes,
  PrivateRoutes
} from '../../config/routes';
import { SecurityQuestionTips } from '../../components/SecurityQuestionTips';
import { survivorClient } from '../../service/backend';

interface AccountRecoverySetupForm {
  consentsToFeedbackEmails: boolean;
  phoneNumber: string;
  confirmPhoneNumber: string;
  securityQuestion1: string;
  securityQuestion2: string;
  securityQuestion3: string;
  answer1: string;
  answer2: string;
  answer3: string;
}

export const question1Options = [
  'What was the full name of the first school you attended?',
  'To what city did you go the first time you flew on a plane?',
  'What was the destination of your most memorable childhood vacation?'
];

export const question2Options = [
  "What is your oldest sibling's middle name?",
  'What was the surname of your teacher in your first year of school?',
  'What was the name of your first stuffed animal?'
];

export const question3Options = [
  'What was the surname of your first boss?',
  "What was your childhood best friend's nickname?",
  "What was the name of your childhood best friend's pet?"
];

export const RecoverySetup: React.FC = () => {
  const { state }: { state: { email: string } } = useLocation() as unknown as { state: { email: string } };
  const navigate = useNavigate();
  const toast = useToast();
  const [consentsToFeedbackEmails, setConsentsToFeedbackEmails] = useState<boolean>(true);
  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<AccountRecoverySetupForm>();
  const {
    mutateAsync: setUpAccountRecovery
  } = useSetUpAccountRecovery();
  const [loading, setLoading] = useState<boolean>(false);
  const [q1Selected, setQ1Selected] = useState<boolean>(false);
  const [q2Selected, setQ2Selected] = useState<boolean>(false);
  const [q3Selected, setQ3Selected] = useState<boolean>(false);

  useEffect(() => {
    setValue('consentsToFeedbackEmails', consentsToFeedbackEmails);
  }, [consentsToFeedbackEmails]);

  const onSubmit = async (input: AccountRecoverySetupForm) => {
    setLoading(true);
    try {
      const { success } = await setUpAccountRecovery({
        consentsToFeedbackEmails: input.consentsToFeedbackEmails,
        email: state.email,
        phoneNumber: input.phoneNumber,
        securityQuestions: [input.securityQuestion1, input.securityQuestion2, input.securityQuestion3],
        answers: [input.answer1, input.answer2, input.answer3]
      });
      if (success) {
        toast({
          title: 'Account recovery setup successful',
          status: 'success',
          isClosable: true,
          position: 'top'
        });
        navigate(PrivateRoutes.DEMOGRAPHICS);
      } else {
        toast({
          title: 'Account recovery setup failed. Please try again.',
          status: 'error',
          isClosable: true,
          position: 'top'
        });
      }
    } catch (error) {
      await survivorClient.submitEvent('Account recovery setup', { error: (error as Error).message });
      toast({
        title: 'There was an error setting up account recovery. Please try again.',
        status: 'error',
        isClosable: true,
        position: 'top'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex flexDir="column">
      <Box>
        <ContainerWrapper>
          <Flex
            padding={['0px 0px', '20px 10px', '40px 30px']}
            minHeight="36px"
            alignItems="center"
            justifyContent="space-between"
          >
            <a href={CALLISTO_STATIC_URL} target="_blank" rel="noreferrer">
              <Image src={Logo as string} alt="Logo" w="200px" />
            </a>
          </Flex>
        </ContainerWrapper>
      </Box>
      <ContainerWrapper
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDir="column"
      >
        <Box
          flex="1"
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          width="90%"
          maxWidth="850px"
          backgroundColor="brand.white"
          padding={['20px 20px', '30px 40px', '60px 80px']}
          flexDir="column"
          alignItems="center"
        >
          <Text as="h1" fontSize="xl" fontFamily="Avenir">
            Set Up Account Recovery
          </Text>
          <Text as="p" fontSize="sm" py="15px" textAlign="left">
            The easiest and most secure way to recover your account is using a backup code.
            This set up is so you can recover your account even if you lose your backup codes.
            This should not be considered your primary method of recoverying your account.
            Try not to lose your backup codes.
          </Text>
          <Text as="p" fontSize="sm" py="15px" textAlign="left" fontWeight="bold">Verified email: {state.email}</Text>
          <Text as="p" fontSize="smaller" textAlign="left" mt="-30px" mb="4em">
            We will double encrypt your email address so no employees of Callisto can see your email address.
            Any emails sent by us will be sent through Callisto Vault. We do NOT sell or share your email address.
            For more information on our use of your email, see{' '}
            <Text
              as="a"
              href={CallistoRoutes.PRIVACY_POLICY}
              color="brand.link"
              display="inline"
              fontFamily="Avenir"
              textDecor="underline"
              fontSize="smaller"
            >
              the Callisto Vault Privacy Policy
            </Text>
            .
          </Text>
          <Flex
            width="100%"
            flexDir="row"
          >
            <Checkbox
              {...register('consentsToFeedbackEmails')}
              isChecked={consentsToFeedbackEmails}
              onChange={(e) => {
                e.preventDefault();
                setConsentsToFeedbackEmails(!consentsToFeedbackEmails);
              }}
            >{' '}</Checkbox>
            <Text fontSize="smaller" textAlign="left">
            I consent to receiving emails requesting anonymous responses to surveys or other feedback (approximately 2 per year).
            </Text>
          </Flex>

          <Text fontSize="smaller" width="100%" textAlign="left" paddingTop="15px">Phone number*</Text>
          <PhoneNumberInput
            isDisabled={false}
            value=""
            {...register('phoneNumber', {
              required: {
                value: true,
                message: 'Please enter your phone number'
              }
            })}
            onChange={(value?: string | undefined) => setValue('phoneNumber', value ?? '')}
          />
          <Text color="red" textAlign="left" width="100%" mt="10px">
            {errors.phoneNumber && errors.phoneNumber.message}
          </Text>

          <Text fontSize="smaller" width="100%" textAlign="left">Confirm phone number*</Text>
          <PhoneNumberInput
            isDisabled={false}
            value=""
            {...register('confirmPhoneNumber', {
              validate: () => getValues().phoneNumber === getValues().confirmPhoneNumber,
              required: {
                value: true,
                message: 'Please confirm your phone number'
              }
            })}
            onChange={(value?: string | undefined) => setValue('confirmPhoneNumber', value ?? '')}
          />
          <Text color="red" textAlign="left" width="100%" mt="10px">
            {errors.confirmPhoneNumber && errors.confirmPhoneNumber.message}
          </Text>
          <Text color="red" textAlign="left" width="100%" mt="10px">
            {errors?.confirmPhoneNumber?.type === 'validate' && 'phone numbers do not match'}
          </Text>

          <Text as="h2" fontSize="lg" fontFamily="Avenir" paddingTop="60px">
            Security Questions
          </Text>

          <SecurityQuestionTips baseFontSize="sm" titleFontSize="med" />

          <Box py="30px">
            <Select
              placeholder="Select a question"
              textColor={q1Selected ? 'brand.Primary' : 'Gray'}
              bgColor="brand.brightWhite"
              borderColor="lightgray"
              fontSize="sm"
              mb="60px"
              borderRadius="0px"
              _focus={{ borderColor: 'brand.primary' }}
              {...register('securityQuestion1', {
                required: {
                  value: true,
                  message: 'Please select a question'
                },
                onChange: (e: ChangeEvent<HTMLSelectElement>) => {
                  e.preventDefault();
                  setValue('securityQuestion1', e.currentTarget.value);
                  setQ1Selected(!!getValues().securityQuestion1);
                }
              })}
            >
              {question1Options.map((opt) => (
                <option value={opt} key={opt}>{opt}</option>
              ))}
            </Select>
            <Text color="red" textAlign="left" width="100%" mt="-55px">
              {errors.securityQuestion1 && errors.securityQuestion1.message}
            </Text>

            <Input
              placeholder="Answer*"
              height="2em"
              {...register('answer1', {
                required: {
                  value: true,
                  message: 'Please answer the question'
                }
              })}
            />
            <Text color="red" textAlign="left" width="100%">
              {errors.answer1 && errors.answer1.message}
            </Text>
          </Box>

          <Box py="30px">
            <Select
              placeholder="Select a question"
              textColor={q2Selected ? 'brand.Primary' : 'Gray'}
              borderColor="lightgray"
              bgColor="brand.brightWhite"
              fontSize="sm"
              mb="60px"
              borderRadius="0px"
              _focus={{ borderColor: 'brand.primary' }}
              {...register('securityQuestion2', {
                required: {
                  value: true,
                  message: 'Please select a question'
                },
                onChange: (e: ChangeEvent<HTMLSelectElement>) => {
                  e.preventDefault();
                  setValue('securityQuestion2', e.currentTarget.value);
                  setQ2Selected(!!getValues().securityQuestion2);
                }
              })}
            >
              {question2Options.map((opt) => (
                <option value={opt} key={opt}>{opt}</option>
              ))}
            </Select>
            <Text color="red" textAlign="left" width="100%" mt="-55px">
              {errors.securityQuestion2 && errors.securityQuestion2.message}
            </Text>

            <Input
              placeholder="Answer*"
              height="2em"
              {...register('answer2', {
                required: {
                  value: true,
                  message: 'Please answer the question'
                }
              })}
            />
            <Text color="red" textAlign="left" width="100%">
              {errors.answer2 && errors.answer2.message}
            </Text>
          </Box>

          <Box py="30px">
            <Select
              placeholder="Select a question"
              textColor={q3Selected ? 'brand.Primary' : 'Gray'}
              borderColor="lightgray"
              bgColor="brand.brightWhite"
              fontSize="sm"
              borderRadius="0px"
              _focus={{ borderColor: 'brand.primary' }}
              {...register('securityQuestion3', {
                required: {
                  value: true,
                  message: 'Please select a question'
                },
                onChange: (e: ChangeEvent<HTMLSelectElement>) => {
                  e.preventDefault();
                  setValue('securityQuestion3', e.currentTarget.value);
                  setQ3Selected(!!getValues().securityQuestion3);
                }
              })}
            >
              {question3Options.map((opt) => (
                <option value={opt} key={opt}>{opt}</option>
              ))}
            </Select>
            <Text color="red" textAlign="left" width="100%">
              {errors.securityQuestion3 && errors.securityQuestion3.message}
            </Text>

            <Input
              placeholder="Answer*"
              height="2em"
              {...register('answer3', {
                required: {
                  value: true,
                  message: 'Please answer the question'
                }
              })}
            />
            <Text color="red" textAlign="left" width="100%">
              {errors.answer3 && errors.answer3.message}
            </Text>
          </Box>

          <Box py="15px">
            <Button
              py="15px"
              buttonColor="brand.primary"
              type="submit"
              isLoading={loading}
            >Submit</Button>
          </Box>
        </Box>
      </ContainerWrapper>
      {loading && (
        <Flex
          position="fixed"
          width="100%"
          height="100%"
          left="0px"
          top="0px"
          zIndex={9999}
          backgroundColor="rgba(0,0,0,0.5)"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <LoadingIndicator width="auto" height="auto" />
        </Flex>
      )}
    </Flex>
  );
};
