import * as t from 'io-ts';

// ByteArray is a helper for sending Uint8Array data over the wire, and
// validating it.
export const ByteArray = new t.Type<Uint8Array>(
  'ByteArray',
  (input: unknown): input is Uint8Array => input instanceof Uint8Array,
  // `t.success` and `t.failure` are helpers used to build `Either` instances
  (input, context) =>
    input instanceof Uint8Array ? t.success(input) : t.failure(input, context),
  // `A` and `O` are the same, so `encode` is just the identity function
  t.identity
);

// Date is a helper for sending Javascript dates over the wire.
export const Date = new t.Type<Date>(
  'Date',
  (input: unknown): input is Date => input instanceof Date,
  // `t.success` and `t.failure` are helpers used to build `Either` instances
  (input: Date, context) =>
    input instanceof Date ? t.success(input) : t.failure(input, context),
  // `A` and `O` are the same, so `encode` is just the identity function
  t.identity
);

export interface RPCFunction<
  I extends t.Type<any, any, any>,
  O extends t.Type<any, any, any>
> {
  // eslint-disable-next-line @typescript-eslint/prefer-function-type
  (): {
    name: string;
    i: I;
    o: O;

    // Most handlers will need token handling, as they mutate server state and should not be replayed.
    // Setting this option will allow the handler to be called without a token. Save this for things
    // like Get* endpoints that simply fetch data and don't need protection.
    XXX_THINK_CAREFULLY_no_token?: boolean | unknown;
  };
}

export type RPCReturn<T extends RPCFunction<any, any>> = t.TypeOf<ReturnType<T>['o']
>;

/**
 * ServerError captures information about unexpected errors that the server
 * encounters at runtime, which are both logged to beacon and which are
 * reported to the user.
 */
export class ServerError extends Error {
  constructor(message: string, public httpCode: number = 500) {
    super(message);
    this.name = 'ServerError';
    this.httpCode = httpCode;
    Error.captureStackTrace(this, ServerError);
  }
}

// These monstrous types allow an automatic conversion of server RPC spec types into something usable by the client.
// This allows RPCs to be defined in a single place so client and server do the same token processing, for instance.
type rpcSpec = {[name: string]: rpcFn};
type rpcFn = { input: object; output: object; XXX_THINK_CAREFULLY_no_token?: boolean };

export type entryOf<o> = {
  [k in keyof o]-?: [k, Exclude<o[k], undefined>]
}[o extends readonly unknown[] ? keyof o & number : keyof o] & unknown;

type rpcFnOf<r extends rpcSpec> = {
  [k in keyof r]-?: [k, () => { name: k; i: r[k]['input']; o: r[k]['output']; XXX_THINK_CAREFULLY_no_token?: boolean }]
}[keyof r] & unknown;

type entriesOf<o extends object> = entryOf<o>[] & unknown;

const mkEntriesOf = <o extends object>(obj: o) => Object.entries(obj) as entriesOf<o>;

const objectFromEntries = Object.fromEntries as
<Key extends PropertyKey, Entries extends ReadonlyArray<readonly [Key, unknown]>>(values: Entries) => {
  [K in Extract<Entries[number], readonly [Key, unknown]>[0]]: Extract<Entries[number], readonly [K, unknown]>[1]
};

export const makeClientEndpoints = <T extends rpcSpec>(o: T) => objectFromEntries(mkEntriesOf(o).map(([k, v]) => {
  const fn = () => ({
    name: k,
    i: v.input,
    o: v.output,
    XXX_THINK_CAREFULLY_no_token: v.XXX_THINK_CAREFULLY_no_token,
  });
  // This allows us to override the `name` property which is normally not really defined for arrow functions.
  // Client tests (and especially their mocks) require this in order to properly swap out implementations.
  Object.defineProperty(fn, 'name', {enumerable: false, value: k});

  return [k, fn] as rpcFnOf<T>;
}));
