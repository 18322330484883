import React, {
  useEffect,
  useRef
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  Image,
  Text
} from '@chakra-ui/react';

import Button from '../../Button';
import { Step } from '../../../contexts/type';
import useExitPrompt from '../../../service/hooks/useExitPrompt';
import CircleCheckIcon from '../../../../assets/circle_check.svg';
import { IOrfSteps } from '../../../contexts/OrfContext';
import { survivorClient } from '../../../service/backend';
import { State } from '../../../contexts/EntryContext';
import callistoVaultLogoIcon from '../../../../assets/CallistoVault_logo_stacked.svg';
import { FormSectionsNavLinkWrapper } from './MainSidebar';

interface Props {
  steps: Record<string, Step> | IOrfSteps | State['steps'];
  title: string;
  type: 'orf' | 'create_entry' | 'edit_entry' | 'incidentLog';
  handleBackClick: () => void;
}

export const handleORFSubmit = async (
  mode: string,
  updatedSteps: IOrfSteps,
  updateOrf: (steps: IOrfSteps) => Promise<void>,
  currentRecordId: string | undefined
): Promise<void> => {
  const completedSteps = survivorClient.extractCompletedSteps(updatedSteps);

  try {
    await survivorClient.submitEvent('Filled ORF Data', {
      submitted: true,
      completedSteps });
  } finally {
    if (mode === 'edit' && currentRecordId) {
      await updateOrf(updatedSteps);
    }
  }
};

const CreateEditSidebar: React.FC<Props> = ({
  steps,
  title,
  type,
  handleBackClick,
}: Props) => {
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current !== null) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => () => {
    setShowExitPrompt(false);
  }, []);

  return (
    <Flex
      as="aside"
      width={['100%', '100%', '300px']}
      left="0px"
      top="0px"
      position="fixed"
      height={{ base: 'auto', md: '100%' }}
      backgroundColor={['brand.appBackground', 'brand.appBackground', 'brand.white']}
      padding={{ base: '0px', md: '0px 20px 0px 20px' }}
      flexDir="column"
      zIndex={9}
      overflow="auto"
    >
      <Box
        flexGrow={1}
        display="flex"
        flexDir="column"
        backgroundColor={['transparent', 'transparent', 'brand.white']}
      >
        <Flex
          textAlign="center"
          my={['30px', '30px', '75px']}
          justifyContent="center"
          minHeight="31px"
        >
          <Box display={['none', 'none', 'block']}>
            <Image
              src={callistoVaultLogoIcon as string}
              width="200px"
              alt="Callisto Vault Logo"
              onClick={handleBackClick}
            />
          </Box>
        </Flex>
        <Text
          as="p"
          mb="18px"
          fontSize="xs"
          textAlign={{ base: 'center', md: 'left' }}
        >
          <b>{title}</b>
        </Text>
        <Flex
          flexDir={{ base: 'row', md: 'column' }}
          px={{ base: '30px', md: '0px' }}
          as="ul"
          overflowX={['scroll', 'scroll', 'hidden']}
          listStyleType="none"
          width="120%"
        >
          {Object.keys(steps).map((stepName) => {
            const step = steps[stepName as keyof typeof steps] as Step;
            return (
              <li key={`${step.route}-${step.text}`}>
                <FormSectionsNavLinkWrapper
                  to={step.route}
                  onClick={(e) => {
                    // Don't allow sidebar navigation when editing an entry (since it uses a different UI)
                    if (type === 'edit_entry') {
                      e.preventDefault();
                    }
                  }}
                >
                  {step.completed && (type === 'edit_entry' || type === 'create_entry') && (
                    <Image
                      src={CircleCheckIcon as string}
                      alt="Circle Check"
                      minWidth="16px"
                      mr="6px"
                    />
                  )}
                  {step.text}
                </FormSectionsNavLinkWrapper>
              </li>
            );
          })}
        </Flex>
      </Box>
      { (type === 'orf' || type === 'incidentLog') &&
        (<>
          <Button
            buttonColor="brand.primary"
            width="100%"
            display={{ base: 'none', md: 'block' }}
            mb="8px"
            type="submit"
            form={type === 'orf' ? 'orf_form' : 'incident_log_form'}
          >
            Save & Exit
          </Button>
        </>)
      }
      <Button
        buttonColor="brand.primary"
        width="100%"
        display={{ base: 'none', md: 'block' }}
        onClick={handleBackClick}
        mb="8px"
      >
          Back to Dashboard
      </Button>
    </Flex>
  );
};

export default CreateEditSidebar;
