import { Box } from '@chakra-ui/layout';
import { useRadio, UseRadioProps } from '@chakra-ui/radio';
import React from 'react';

interface Props extends UseRadioProps {
  children: React.ReactNode | React.ReactNode[];
}

const RadioCard: React.FC<Props> = ({ children, ...restProps }: Props) => {
  const { getInputProps, getCheckboxProps } = useRadio(restProps);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="100px"
        borderColor="brand.primary"
        fontWeight="800"
        fontSize="sm"
        _checked={{
          bg: 'brand.primary',
          color: 'brand.white',
        }}
        px={[4, 4, 8]}
        py={2}
      >
        {children}
      </Box>
    </Box>
  );
};

export default RadioCard;
