import React from 'react';
import { useForm } from 'react-hook-form';
import ContainerWrapper from '../../components/ContainerWrapper';
import {
  Box,
  Flex,
  Text,
  useToast
} from '@chakra-ui/react';
import * as emailValidator from 'email-validator';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { useRequestAccountRecovery } from '../../service/hooks/auth';
import { ResendAccountRecoveryRequest } from './ResendAccountRecoveryRequest';
import { PublicRoutes } from '../../config/routes';
import { survivorClient } from '../../service/backend';

interface AccountRecoveryRequest {
  emailAddress: string;
  phoneNumber: string;
}

export const RequestAccountRecovery: React.FC = () => {
  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<AccountRecoveryRequest>();
  const {
    mutateAsync: sendAccountRecoveryRequest,
    isLoading,
    data: requestSent
  } = useRequestAccountRecovery();
  const toast = useToast();


  const onSubmit = async (values: AccountRecoveryRequest) => {
    try {
      if (!emailValidator.validate(values.emailAddress)) {
        toast({
          position: 'top',
          status: 'error',
          title: 'Please enter a valid email address',
          duration: 9000,
          isClosable: true
        });
        return;
      }
      const { success } = await sendAccountRecoveryRequest({
        emailAddress: values.emailAddress.toLowerCase().trim(),
        phoneNumber: values.phoneNumber
      });
      if (!success) {
        toast({
          title: 'Unable to send account recovery request. Please try again.',
          status: 'error',
          isClosable: true,
          duration: 9000,
          position: 'top'
        });
      }
    } catch (error) {
      try {
        await survivorClient.submitEvent('Request account recovery', { error: (error as Error).message });
      } catch {
        // Swallow this error
      }
      toast({
        title: 'There was an error sending the account recovery request. Please try again.',
        status: 'error',
        isClosable: true,
        duration: 9000,
        position: 'top'
      });
    }
  };

  return (
    <ContainerWrapper>
      <Flex alignItems="center" justifyContent="center" margin="0 auto" padding="0 0 25px 0">
        <Box maxWidth="850px" width="100%">
          {!isLoading && requestSent?.success && (
            <ResendAccountRecoveryRequest email={getValues().emailAddress} phone={getValues().phoneNumber} />
          )}
          <Box
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            display={requestSent?.success ? 'none' : 'flex'}
            width="100%"
            backgroundColor='brand.white'
            padding={['20px 20px', '40px 20px', '60px 120px']}
          >
            <Flex
              maxWidth="650px"
              flexDir="column"
              textAlign="left"
              justifyContent="center"
              alignItems="center"
              margin="0 auto"
            >
              <Text as="h1" fontSize="xl">
                Recover Your Account
              </Text>
              <Text mt="16px">
                Entering a backup code is the easiest and most secure way to reset your password.
                You should only use this method for recovering your account if you have lost your backup codes.
              </Text>
              <Text mt="16px">
                Please enter the email address and phone number you provided when setting up account recovery.
                If you did not set up account recovery then you must{' '}
                <Text
                  as="a"
                  href={PublicRoutes.RESET_PASSWORD}
                  color="brand.link"
                  display="inline"
                  fontFamily="AvenirBold"
                  textDecor="underline"
                >
                  use a backup code to reset your password
                </Text>.
                If you have not set up account recovery with
                Callisto Vault and have lost your backup codes then we will not be able to reset your
                password at this time. Please contact{' '}
                <Text
                  as="a"
                  href="mailto:support@projectcallisto.org"
                  color="brand.link"
                  display="inline"
                  fontFamily="AvenirBold"
                  textDecor="underline"
                >
                    support@projectcallisto.org
                </Text>
                  .
              </Text>

              <Text mt="16px" mb="-10px" textAlign="left" width="100%">Email address*</Text>
              <Input
                fontSize="sm"
                mt="10px"
                placeholder="Enter recovery email address"
                {...register('emailAddress', {
                  validate: () => emailValidator.validate(getValues().emailAddress.trim()),
                  required: {
                    value: true,
                    message: 'Please enter an email address'
                  }
                })}
              />

              <Text mt="16px" mb="-3px" textAlign="left" width="100%">Phone number*</Text>
              <PhoneNumberInput
                {...register('phoneNumber', {
                  required: {
                    value: true,
                    message: 'Please enter a phone number'
                  }
                })}
                value=''
                isDisabled={false}
                onChange={(value?: string) => {
                  setValue('phoneNumber', value ?? '');
                }}
              />

              <Text color="red" textAlign="left" width="100%">
                {errors.emailAddress && errors.emailAddress.message}
              </Text>
              <Text color="red" textAlign="left" width="100%">
                {errors.phoneNumber && errors.phoneNumber.message}
              </Text>

              <Button
                buttonColor="brand.primary"
                type="submit"
                mt="16px"
                isLoading={isLoading}
              >Submit</Button>
            </Flex>
          </Box>
        </Box>
      </Flex>
    </ContainerWrapper>
  );
};
