import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Flex,
  Image
} from '@chakra-ui/react';

import MobileIcon from '../../../../assets/CallistoVault_logo_stacked.png';
import Button from '../../Button';
import { PrivateRoutes } from '../../../config/routes';

interface CreateEditHeaderProps {
  handleBackClick: () => void;
}

const CreateEditHeader: React.FC<CreateEditHeaderProps> = ({handleBackClick}: CreateEditHeaderProps) => (
  <Box
    height="50px"
    display={{ base: 'block', md: 'none' }}
    as="header"
    position="fixed"
    left="0px"
    top="0px"
    width="100%"
    backgroundColor="brand.white"
    zIndex={9}
  >
    <Flex float="left" height="50px" alignItems="center" px="24px" py="20px">
      <Link to={PrivateRoutes.DASHBOARD}>
        <Image src={MobileIcon as string} float="left" width="87px" alt="Callisto Vault logo" />
      </Link>
    </Flex>
    <Button
      buttonColor="brand.primary"
      height="34px"
      mt="8px"
      mr="8px"
      fontSize="xxs"
      float="right"
      px="10px !important"
      minWidth="auto"
      onClick={handleBackClick}
    >
        Back to dashboard
    </Button>
  </Box>
);

export default CreateEditHeader;
