import * as t from 'io-ts';
import {
  tCaseStatus,
  tSodiumBytes,
  tSodiumDate
} from './util/iots';

export const tShare = t.type({
  dek: tSodiumBytes,
  x: t.string,
  y: t.string,
  type: t.string,
});

export const rpcSpecification = {
  IncrementCount: {
    input: t.type({
      measure: t.string
    }),
    output: t.unknown,
    XXX_THINK_CAREFULLY_no_token: true,
  },

  HoneycombEvent: {
    input: t.type({
      event: t.type({
        action: t.string
      })
    }),
    output: t.unknown,
    XXX_THINK_CAREFULLY_no_token: true,
  },

  SurvivorHoneycombEvent: {
    input: t.type({
      event: t.type({
        action: t.string
      })
    }),
    output: t.unknown,
    XXX_THINK_CAREFULLY_no_token: true,
  },

  Beacon: {
    input: t.type({}),
    output: t.unknown,
    XXX_THINK_CAREFULLY_no_token: true,
  },

  Login: {
    input: t.type({
      index: t.string,
      alpha: tSodiumBytes,
      passwordCheck: t.boolean
    }),
    output: t.type({
      userID: t.string,
      beta: tSodiumBytes,
      encryptedEnvelope: tSodiumBytes,
      serverPublicKey: tSodiumBytes,
      demographicsComplete: t.union([t.boolean, t.null]),
      acceptedPrivacyPolicy: t.union([t.boolean, t.null])
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  VerifyEmail: {
    input: t.type({
      email: t.string,
    }),
    output: t.type({
      success: t.boolean,
      foreignEdu: t.boolean
    }),
  },

  CheckPasswordStrength: {
    input: t.type({
      prefix: t.string,
    }),
    output: t.type({
      hashes: t.array(t.string),
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  VerifyToken: {
    input: t.type({
      token: t.string,
    }),
    output: t.type({
      success: t.boolean,
      campusIdentified: t.boolean,
      emailDomain: t.union([t.string, t.undefined])
    }),
  },

  CreateAccount_Step1_OPRF: {
    input: t.type({
      token: t.string,
      index: t.string,
      alpha: tSodiumBytes,
      userPublicKey: tSodiumBytes,
      privacyPolicyAccepted: t.union([t.boolean, t.null, t.undefined]),
      campusName: t.union([t.string, t.null, t.undefined]),
      emailDomain: t.union([t.string, t.null, t.undefined])
    }),
    output: t.type({
      userID: t.string,
      beta: tSodiumBytes,
      serverPublicKey: tSodiumBytes,
    }),
  },

  GetLatestSharedAdminPublicKey: {
    input: t.unknown,
    output: t.type({
      key: tSodiumBytes,
      keyVersion: t.number
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetServerPublicKey: {
    input: t.unknown,
    output: t.type({
      serverKey: tSodiumBytes
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  UseBackupCode_Step1_Find: {
    input: t.type({
      code: t.string,
    }),
    output: t.type({
      userID: t.string,
      encryptedEnvelope: tSodiumBytes,
      serverPublicKey: tSodiumBytes,
    }),
  },

  Bootstrap: {
    input: t.unknown,
    output: t.type({
      encryptedContactInfo: t.union([tSodiumBytes, t.null]),
      encryptedUserData: tSodiumBytes,
      demographicsComplete: t.union([t.boolean, t.null]),
      acceptedPrivacyPolicy: t.union([t.boolean, t.null]),
      signupCampusName: t.string,
      currentCampusName: t.string
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  Matching_OPRF_A: {
    input: t.type({
      alpha: tSodiumBytes,
    }),
    output: t.type({
      output: tSodiumBytes,
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  Matching_OPRF_B: {
    input: t.type({
      alpha: tSodiumBytes,
    }),
    output: t.type({
      output: tSodiumBytes,
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  OPRF_Me: {
    input: t.type({
      alpha: tSodiumBytes,
    }),
    output: t.type({
      output: tSodiumBytes,
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  CreateAccount_Step2_Finalize: {
    input: t.type({
      envelope: tSodiumBytes
    }),
    output: t.boolean,
  },

  SaveBackupCodes: {
    input: t.type({
      codes: t.record(t.string, tSodiumBytes),
    }),
    output: t.unknown,
  },

  MailBackupCodes: {
    input: t.type({
      codes: t.array(t.string),
      email: t.string,
    }),
    output: t.unknown,
  },

  UseBackupCode_Step3_Finalize: {
    input: t.type({
      codeIndex: t.string,
      encryptedEnvelope: tSodiumBytes,
    }),
    output: t.unknown,
  },

  SaveUserData: {
    input: t.type({
      data: tSodiumBytes,
    }),
    output: t.unknown,
  },

  GetLOCKeys: {
    input: t.unknown,
    output: t.type({
      locKeys: t.record(t.string, tSodiumBytes),
      dlocKey: tSodiumBytes,
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  UpdatePassword: {
    input: t.type({
      newIndex: t.string,
      newEnvelope: tSodiumBytes,
    }),
    output: t.unknown,
  },

  DeleteAccount: {
    input: t.type({
      entry: t.record(t.string, tSodiumBytes),
      recordFormSignatures: t.record(t.string, tSodiumBytes),
      draftEntrySignatures: t.record(t.string, tSodiumBytes),
      incidentLogSignatures: t.record(t.string, tSodiumBytes),
      consentIds: t.array(t.string)
    }),
    output: t.unknown,
  },

  DeleteEntry: {
    input: t.type({
      entryID: t.string,
      signature: tSodiumBytes,
    }),
    output: t.unknown,
  },

  GetEntries: {
    input: t.type({
      ids: t.array(t.string),
      draftIds: t.union([t.array(t.string), t.undefined])
    }),
    output: t.type({
      entries: t.record(
        t.string,
        t.type({
          encrypted: tSodiumBytes,
          status: t.string,
          created: tSodiumDate,
          edited: tSodiumDate,
        })
      ),
      draftEntries: t.record(
        t.string,
        t.type({
          encrypted: tSodiumBytes,
          status: t.string,
          created: tSodiumDate,
          edited: tSodiumDate,
        })
      )
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  EditEntry: {
    input: t.type({
      entryID: t.string,
      signature: tSodiumBytes,
      shares: t.array(tShare),
      encrypted: tSodiumBytes,
      encryptedAssignmentData: tSodiumBytes,
      dlocKeyVersion: t.number
    }),
    output: t.unknown,
  },

  IncrementCountUnencrypted: {
    input: t.type({
      token: t.string,
      measure: t.string
    }),
    output: t.unknown,
    XXX_THINK_CAREFULLY_no_token: true,
  },

  WriteWeakPasswordUnusedToken: {
    input: t.type({
      token: t.string
    }),
    output: t.unknown,
    XXX_THINK_CAREFULLY_no_token: true,
  },

  SaveDemographicData: {
    input: t.type({
      age: t.union([t.number, t.null]),
      studentType: t.union([t.string, t.null]),
      yearInSchool: t.union([t.string, t.null]),
      raceEthnicity: t.union([t.array(t.string), t.null]),
      genderIdentity: t.union([t.string, t.null]),
      sexualOrientation: t.union([t.string, t.null]),
      disability: t.union([t.array(t.string), t.null]),
      howLearned: t.union([t.string, t.null]),
      extracurricularActivity: t.union([t.array(t.string), t.null])
    }),
    output: t.boolean
  },

  AcceptPrivacyPolicy: {
    input: t.type({
      privacyPolicyAccepted: t.boolean
    }),
    output: t.boolean
  },

  //
  //  DLOC Server
  //
  UpdateDlocContactInfo: {
    input: t.type({
      name: t.string,
      emailAddress: t.string,
    }),
    output: t.unknown,
  },

  GetFrontEndEnvironmentVariables: {
    input: t.unknown,
    output: t.record(t.string, t.string),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetDlocContactInfo: {
    input: t.unknown,
    output: t.type({
      name: t.string,
      emailAddress: t.string,
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetLOCS: {
    input: t.unknown,
    output: t.array(
      t.type({
        id: t.string,
        name: t.string,
        email: t.string,
        practice: t.string,
        state: t.string,
        dateAdded: tSodiumDate,
      })
    ),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetRegisteredUnactivatedLocs: {
    input: t.unknown,
    output: t.array(
      t.type({
        id: t.string,
        name: t.string,
        email: t.string,
        practice: t.string,
        state: t.string,
        dateAdded: tSodiumDate
      })
    ),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetUnregisteredLocs: {
    input: t.unknown,
    output: t.array(
      t.type({
        id: t.string,
        name: t.string,
        email: t.string,
        practice: t.string,
        state: t.string,
        dateAdded: tSodiumDate
      })
    ),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetCasesDloc: {
    input: t.unknown,
    output: t.type({
      cases: t.array(
        t.type({
          id: t.string,
          encryptedAssignmentData: tSodiumBytes,
          assignmentDataDlocKeyVersion: t.number,
          encryptedQuestionnaire: t.union([tSodiumBytes, t.null]),
          questionnaireDlocKeyVersion: t.union([t.number, t.null]),
          notesDlocKeyVersion: t.union([t.number, t.null]),
          encryptedDlocNotes: t.union([tSodiumBytes, t.null]),
          created: tSodiumDate,
          lastEdited: tSodiumDate,
          matchFound: tSodiumDate,
          assigned: t.union([tSodiumDate, t.null]),
          status: t.union([tCaseStatus, t.null]),
          statusChanged: tSodiumDate,
          perpId: t.string,
          userSignupCampus: t.string,
          userCampusOnCreation: t.string,
          assignedLOC: t.union([
            t.type({
              id: t.string,
              name: t.string,
            }),
            t.null,
          ]),
          locAssignmentHistory: t.array(
            t.type({
              locName: t.string,
              dateAssigned: tSodiumDate,
              dateUnassigned: t.union([tSodiumDate, t.null])
            })
          ),
          statusHistory: t.array(t.type({
            status: t.string,
            dateUpdated: tSodiumDate,
          })),
          survivorHasManyEntries: t.boolean
        })
      ),
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  SendLocInvitation: {
    input: t.type({
      locData: t.union([t.type({
        name: t.string,
        email: t.string,
        practice: t.string,
        state: t.string,
      }), t.null]),
      locId: t.union([t.string, t.null])
    }),
    output: t.boolean
  },

  ValidateLocPlusSelectionToken: {
    input: t.type({
      token: t.string
    }),
    output: t.type({
      locId: t.string,
      locName: t.string
    })
  },

  SendLocActivationRequest: {
    input: t.type({
      locId: t.string,
      locPlusId: t.string
    }),
    output: t.type({
      success: t.boolean
    })
  },

  GetLocPlusForLoc: {
    input: t.type({
      locId: t.string
    }),
    output: t.type({
      locPlusId: t.union([t.string, t.undefined])
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  //
  //  Legal Server
  //
  GetLocContactInfo: {
    input: t.unknown,
    output: t.type({
      name: t.string,
      email: t.string,
      practice: t.string,
      state: t.string,
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  UpdateLocContactInfo: {
    input: t.type({
      name: t.string,
      email: t.string,
      practice: t.string,
      state: t.string,
    }),
    output: t.unknown,
  },

  GetLocEntries: {
    input: t.unknown,
    output: t.type({
      entries: t.record(
        t.string,
        t.type({
          encrypted: tSodiumBytes,
          encryptedKey: tSodiumBytes,
          status: tCaseStatus,
          matchFound: tSodiumDate,
          lastEdited: tSodiumDate,
          created: tSodiumDate,
          dateAssigned: tSodiumDate,
          statusChanged: tSodiumDate,
          perpetratorID: t.string,
          userSignupCampus: t.string,
          userCampusOnCreation: t.string,
          numMatchedUsers: t.number,
          notes: t.array(t.type({
            notesId: t.string,
            encrypted: tSodiumBytes
          })),
          closeCaseSurveyCompleted: t.boolean,
          survivorHasManyEntries: t.boolean
        })
      ),
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetContactInfoForUsers: {
    input: t.type({
      ids: t.array(t.string),
    }),
    output: t.type({
      users: t.record(
        t.string,
        t.type({
          encrypted: tSodiumBytes,
          encryptedKey: tSodiumBytes,
          campus: t.string
        })
      ),
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  UpdateStatus: {
    input: t.type({
      entryID: t.string,
      newStatus: tCaseStatus,
      dateOfUpdate: tSodiumDate,
    }),
    output: t.unknown,
  },

  // All for share recalculation
  GetEntryKeys: {
    input: t.unknown,
    output: t.type({
      keys: t.record(
        t.string,
        tSodiumBytes
      )
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetUserMarkers: {
    input: t.type({
      entryIds: t.array(
        t.string
      )
    }),
    output: t.type({
      userMarkers: t.record(
        t.string,
        tSodiumBytes
      ),
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  RewriteShares: {
    input: t.record(
      t.string,
      t.array(
        t.type({
          dek: tSodiumBytes,
          x: t.string,
          y: t.string,
          type: t.string
        })
      )
    ),
    output: t.unknown,
  },

  GetRecordForms: {
    input: t.type({
      ids: t.array(t.string),
    }),
    output: t.type({
      recordForms: t.array(
        t.type({
          id: t.string,
          created: tSodiumDate,
          encryptedData: tSodiumBytes,
          lastEdited: tSodiumDate,
          publicKey: tSodiumBytes,
        })
      ),
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetSingleRecordForm: {
    input: t.type({
      id: t.string,
    }),
    output: t.type({
      recordForm: t.type({
        id: t.string,
        created: tSodiumDate,
        encryptedData: tSodiumBytes,
        lastEdited: tSodiumDate,
        publicKey: tSodiumBytes,
      }),
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  DeleteRecordForm: {
    input: t.type({
      id: t.string,
      signature: tSodiumBytes,
    }),
    output: t.boolean,
  },

  UpdateRecordForm: {
    input: t.type({
      id: t.string,
      encryptedData: tSodiumBytes,
      signature: tSodiumBytes,
    }),
    output: t.type({
      recordForm: t.type({
        id: t.string,
        created: tSodiumDate,
        encryptedData: tSodiumBytes,
        lastEdited: tSodiumDate,
        publicKey: tSodiumBytes,
      }),
    }),
  },

  ValidateLocAccountToken: {
    input: t.type({
      token: t.string
    }),
    output: t.type({
      name: t.string,
      email: t.string,
      practice: t.string,
      state: t.string
    })
  },

  ValidateLocActivationToken: {
    input: t.type({
      token: t.string
    }),
    output: t.type({
      id: t.string,
      name: t.string,
      email: t.string
    })
  },

  GetLocPublicKey: {
    input: t.type({
      locId: t.string
    }),
    output: t.type({
      locPublicKey: tSodiumBytes
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  BootstrapUser: {
    input: t.unknown,
    output: t.type({
      encryptedUserData: t.union([tSodiumBytes, t.null])
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetAllDlocs: {
    input: t.unknown,
    output: t.type({
      dlocs: t.array(t.type({
        id: t.string,
        name: t.string,
        email: t.string,
        isRegistered: t.boolean,
        isActivated: t.boolean,
        isActive: t.boolean,
        dateDeactivated: t.union([tSodiumDate, t.null])
      }))
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  GetPublicKeyForProxy: {
    input: t.type({
      userId: t.string
    }),
    output: t.type({
      publicKey: tSodiumBytes
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  SaveSigningPublicKey: {
    input: t.type({
      publicKey: tSodiumBytes
    }),
    output: t.unknown
  },

  GetCurrentUserActiveStatus: {
    input: t.unknown,
    output: t.type({
      isActive: t.boolean
    }),
    XXX_THINK_CAREFULLY_no_token: true,
  },

  UndoCreateAccountStep1: {
    input: t.unknown,
    output: t.type({
      success: t.boolean
    })
  }
};
