import React from 'react';
import HeaderText from '../../components/HeaderText';

interface IncidentLogPdfIntroPageContentProps {
  textWidth?: string;
}

export const IncidentLogIntroPageContent: React.FC<IncidentLogPdfIntroPageContentProps> = ({ textWidth }) => (
  <div style={{ width: textWidth ?? '8in' }}>
    <HeaderText pdf={true}>Callisto Vault Incident Log</HeaderText>
    <p>
      Thank you for trusting Callisto on your journey towards healing and justice.
    </p>
    <p>
      For your own protection, we recommend that you password protect the PDF file and store it in a place that is
      safe and that only you have access to. Once the Incident Log is downloaded as a PDF or is printed, Callisto can
      no longer guarantee the security of the document.
    </p>
    <p>
      Prior to sharing this document with others, determine what level of confidentiality you will receive.
      Not all people will be required to keep the information you share confidential.
    </p>
    <p style={{ fontWeight: 'bold', lineHeight: 1, letterSpacing: '0.05px' }}>
      Nothing in this form is legal advice. Contact an attorney for legal
      advice and to learn more about your options.
    </p>
  </div>
);
