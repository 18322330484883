/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import {
  Button,
  Image,
  InputGroup,
  InputProps,
  InputRightElement
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import Input from './Input';
import theme from '../styles/theme';
import WhiteCloseIcon from '../../assets/close-white.svg';
import PhoneNumberInput from './PhoneNumberInput';

interface Props extends InputProps {
  idx: number;
  onClose: (index: number) => void;
  isPhoneNumber: boolean;
  onPhoneNumberChange: (value: string | undefined, index: number) => void;
}

const SocialInput: React.FC<Props> = ({
  idx,
  isDisabled = false,
  onClose,
  isPhoneNumber,
  onPhoneNumberChange,
  ...props
}) => {
  const onCloseFunction = onClose as (index: number) => void;
  const handleCloseClick = () => {
    onCloseFunction(idx);
  };

  const onPhoneNumberChangeFunction = onPhoneNumberChange as (value: string | undefined, index: number) => void;
  const handlePhoneNumberChange = (value?: string | undefined)  => {
    onPhoneNumberChangeFunction(value, idx);
  };

  return (
    <InputGroup>
      { !isPhoneNumber &&
      <Input
        paddingRight="40px !important"
        isDisabled={isDisabled}
        {...props}
      /> }
      {isPhoneNumber &&
      <PhoneNumberInput
        isDisabled={isDisabled}
        value={props.value as string}
        onChange={handlePhoneNumberChange}
      />
      }
      {!isDisabled && (
        <InputRightElement height="100%">
          <CancelButton mr="10px" onClick={handleCloseClick}>
            <Image src={WhiteCloseIcon as string} />
          </CancelButton>
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default SocialInput;

const CancelButton = styled(Button)`
  min-width: 0;
  width: 20px;
  height: 20px;
  background-color: ${theme.colors.brand.primary};
  border-radius: 50%;
  padding: 0;
  &:hover {
    background-color: ${theme.colors.brand.red};
    transform: none !important;
  }
`;
