import React, { CSSProperties } from 'react';
import { Text } from '@chakra-ui/react';

interface OrfQuestionProps {
  children: React.ReactNode;
  pdf?: boolean;
}

const pdfStyle = {
  letterSpacing: '0.05px',
  fontWeight: 'bold'
};

export const OrfQuestion: React.FC<OrfQuestionProps> =
  ({ children, pdf = false}: OrfQuestionProps) => {
    const bfStyle = pdf ? { ...pdfStyle } as CSSProperties : { fontWeight: 'bold' } as CSSProperties;

    return (
      <Text as="span" style={bfStyle}>{children}</Text>
    );
  };
