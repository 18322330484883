import React from 'react';
import {
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/modal';
import { ModalCloseButton } from '@chakra-ui/react';
import { Paragraph } from '../../components/Paragraph';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const EffectsOfTraumaModal: React.FC<Props> = ({ isOpen, onClose }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent color="brand.primary">
      <ModalCloseButton />
      <Paragraph props={{fontSize: 'sm', padding: '15px'}}>
          When someone is triggered, their emotions such as anger, anxiety, sadness,
          etc, can become extremely heightened. Or a person can become numb and
          void of emotions. No matter your response, be kind to yourself.
          This process can be difficult and no two survivors respond to trauma
          in the same way. You may come back to this site and respond in a completely
          different way the next time. This is typical.
      </Paragraph>
    </ModalContent>
  </Modal>
);

export default EffectsOfTraumaModal;
