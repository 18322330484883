import React, { useState } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalContent,
  ModalProps,
  Text,
  useToast
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import jsPDF from 'jspdf';

import WhiteButton from './layout/WhiteButton';
import Button from './Button';
import ButtonLink from './ButtonLink';
import LoadingIndicator from './LoadingIndicator';
import LogoPng from '../../assets/Callisto_logo.svg';
import { useSendBackupCodes } from '../service/hooks/auth';
import IconInput from './IconInput';
import EmailIcon from './icons/EmailIcon';
import { callistoLogoBase64 } from '../../assets/Callisto_logo';

interface Props extends ModalProps {
  backupCodes: string[];
  isNewlyGenerated?: boolean;
  loading: boolean;
}

const DownloadCodeModal: React.FC<Props> = ({
  children,
  backupCodes,
  onClose,
  loading,
  isNewlyGenerated = false,
  ...props
}: Props) => {
  const [showEmail, setShowEmail] = useState<boolean>(false);
  const { mutateAsync: requestEmailBackupcodes, isLoading: emailSending } =
    useSendBackupCodes();
  const { register, handleSubmit } = useForm<{ email: string }>();
  const toast = useToast();

  const getBase64Image = (img: HTMLImageElement) => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    // const ctx = canvas.getContext('2d');
    // if (ctx) {
    //   ctx.drawImage(img, 0, 0);
    // }
    // const dataURL = canvas.toDataURL('image/png');
    // return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');

    // Hard-coding the Callisto logo base-64 for now since the above doesn't seem to work anymore.
    return callistoLogoBase64;
  };

  const handleDownload = () => {
    const doc = new jsPDF();
    doc.setProperties({
      title: 'Callisto Vault backup codes',
      subject: 'Keep these in a safe place',
      author: 'Callisto',
      keywords: 'backup, codes, Callisto, account, Vault',
      creator: 'Callisto',
    });

    doc.setFontSize(12);
    const img = new Image();
    img.src = LogoPng as string;
    try {
      doc.addImage(getBase64Image(img), 'png', 72, 5, 58, 40); // img, type, left-offset, top-offset, width, height
    } catch {
      // Do nothing -- logo issues should not stop backup code download from happening
    }

    doc.setDrawColor(205, 205, 205);
    doc.roundedRect(10, 85, 190, 45, 3, 3, 'S'); // left-offset, top-offset, width, height, radiusx, radiusy, style

    doc.text(
      'Keep these backup codes somewhere safe but accessible. You use these backup codes to recover' +
      ' your account in case you forget your password.',
      10,
      50,
      { align: 'left', maxWidth: 185 }
    );
    doc.text(
      'In the event you forget your password, you will not be able to recover your account without a' +
      ' backup code.',
      10,
      65,
      { align: 'left', maxWidth: 185 }
    );

    let line = 95;
    for (const code of backupCodes) {
      doc.text(code, 100, line, { align: 'center' }); // text, left-offset, top-offset, position
      line = line + 7;
    }
    doc.save('callisto-backup-codes.pdf');
  };

  const onEmailSubmit = async ({ email }: { email: string }) => {
    try {
      await requestEmailBackupcodes({ email, backupCodes });
      setShowEmail(false);
    } catch (e) {
      toast({
        title: 'There was a problem emailing your codes.',
        position: 'top',
        status: 'error',
      });
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={onClose}
      {...props}
    >
      {children}
      <ModalContent p="40px" maxW="550px" borderRadius="0px" color="brand.primary">
        <Text my="18px" as="h3" fontSize="md" fontFamily="Avenir">
          {isNewlyGenerated ? 'Keep Backup Codes' : 'Backup Codes'}
        </Text>
        <Text mb="27px" mt="16px">
          Keep these backup codes somewhere safe but accessible.{' '}
          <b>Your previous codes will no longer work.</b> You can only use each
          of these backup codes once.
        </Text>
        <Text mb="27px">
          In the event you forget your password, using a backup code is the easiest
          and most secure way to recover your account.
        </Text>
        <Box
          textAlign="center"
          p="20px"
          border="1px solid"
          borderColor="brand.primary"
          mb="30px"
          minHeight="187px"
        >
          {loading && (
            <Flex
              height="100%"
              alignItems="center"
              justifyContent="center"
              flexDir="column"
              marginTop="30px"
            >
              <LoadingIndicator />
              <Text>This could take a couple minutes</Text>
            </Flex>
          )}
          {!loading &&
            backupCodes.map((code) => (
              <Text key={code} mb="7px">
                {code}
              </Text>
            ))}
        </Box>
        {!showEmail && (
          <Flex justifyContent="center" fontSize="xs">
            <WhiteButton
              minWidth="auto"
              mx="10px"
              onClick={() => setShowEmail(true)}
              borderRadius="100px"
              isDisabled={loading}
            >
              Email
            </WhiteButton>
            <Button
              minWidth="auto"
              buttonColor="brand.primary"
              mx="10px"
              isDisabled={loading}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Flex>
        )}
        {showEmail && (
          <Box as="form" onSubmit={handleSubmit(onEmailSubmit)}>
            <Text>
              <b>Enter your email</b>
            </Text>
            <IconInput
              icon={<EmailIcon />}
              inputGroupProps={{ mt: '5px' }}
              type="email"
              placeholder="Enter your email"
              {...register('email')}
              isRequired
            />
            <Flex justifyContent="center" mt="20px">
              <WhiteButton
                minWidth="auto"
                mx="10px"
                onClick={() => setShowEmail(false)}
                borderRadius="100px"
              >
                Back
              </WhiteButton>
              <Button
                minWidth="auto"
                buttonColor="brand.primary"
                mx="10px"
                type="submit"
                isLoading={emailSending}
              >
                Send email
              </Button>
            </Flex>
          </Box>
        )}
        <ButtonLink
          color="brand.primary"
          textDecor="underline"
          onClick={onClose}
          mt="20px"
        >
          I stored these backup codes somewhere safe.
        </ButtonLink>
      </ModalContent>
    </Modal>
  );
};

export default DownloadCodeModal;
