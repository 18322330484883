import { useContext, useEffect, useRef } from 'react';
import { IWhenFormProps } from '../../containers/OrfWhen';
import { IWhereFormProps } from '../../containers/OrfWhere';
import { IKnowFormProps } from '../../containers/OrfKnow';
import { IConsentFormProps } from '../../containers/OrfConsent';
import { IIncidentsFormProps } from '../../containers/OrfIncidents';
import { IOffendersFormProps } from '../../containers/OrfOffenders';
import { IEvidenceFormProps } from '../../containers/OrfEvidence';
import { OrfContext } from '../../contexts/OrfContext';

export type IFormProps =
  & IWhenFormProps
  & IWhereFormProps
  & IKnowFormProps
  & IConsentFormProps
  & IIncidentsFormProps
  & IOffendersFormProps
  & IEvidenceFormProps;

export const usePersistData = (onFormUnMounting: (props: IFormProps) => void): (values: IFormProps) => void => {
  const { state: { changesMade }} = useContext(OrfContext);
  const getValuesRef = useRef<IFormProps | null>(null);
  useEffect(
    () => () => {
      const values = getValuesRef?.current;
      if (onFormUnMounting && values) {
        void onFormUnMounting(values);
      }
    },
    [changesMade]
  );

  return (values: IFormProps) => {
    getValuesRef.current = values;
  };
};
