import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/layout';

import Button from './Button';
import ButtonLink from './ButtonLink';

interface Props {
  loading?: boolean;
  backRoute: string;
  handleClick: () => void;
}

const OrfActionButtons: React.FC<Props> = ({
  backRoute,
  loading = false,
  handleClick
}: Props) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(backRoute);
  };

  return (
    <Flex justifyContent="space-between" mt="60px">
      <ButtonLink textDecor="underline" onClick={handleBackClick}>
        Back
      </ButtonLink>
      <Button buttonColor="brand.primary" onClick={handleClick} isLoading={loading}>
        Continue
      </Button>
    </Flex>
  );
};

export default OrfActionButtons;
