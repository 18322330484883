import React from 'react';
import {
  Box,
  Text,
  useToast
} from '@chakra-ui/react';
import { useVerifyRecoveryEmail } from '../../service/hooks/auth';
import Notification from '../../components/Notification';
import Button from '../../components/Button';

interface ResendVerifyRecoveryEmailProps {
  email: string;
  token: string;
}

export const ResendVerifyRecoveryEmail: React.FC<ResendVerifyRecoveryEmailProps> =
  ({ email, token }: ResendVerifyRecoveryEmailProps) => {
    const toast = useToast();
    const {
      mutateAsync: verifyRecoveryEmail,
      isLoading
    } = useVerifyRecoveryEmail();

    const handleResend = async () => {
      try {
        await verifyRecoveryEmail({ email, token });
        toast({
          title: `We've resent the verification email to ${email}`,
          status: 'success',
          isClosable: true,
          position: 'top'
        });
      } catch (e) {
        toast({
          title: 'There was a problem resending the verification email. Please try again later.',
          status: 'error',
          position: 'top'
        });
      }
    };

    return (
      <Box textAlign="center" as="form">
        <Notification notification={{type: 'success', message: 'Email has been sent'}} />
        <Box
          maxWidth="850px"
          backgroundColor="brand.white"
          padding={['20px 20px', '30px 30px', '50px 60px']}
        >
          <Text as="h1" fontSize="xl" fontFamily="Avenir">
            Enter a recovery email address
          </Text>
          <Box>
            <Text mt="40px" lineHeight="28px" textAlign="left">
              We sent a verification link to {email}. After clicking that link, you will be
              able to create your username and password.
            </Text>
            <Text my="17px">
              <b>Did not receive the email?</b>
            </Text>
            <Text lineHeight="28px" textAlign="left">
              Your verification email may take up to 5 minutes to arrive in your
              inbox. Be sure to also check your spam folder.
            </Text>
            <Button
              buttonColor="brand.primary"
              onClick={handleResend}
              mt="25px"
              isLoading={isLoading}
            >
              Resend verification email
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
