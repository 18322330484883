import React, {
  ChangeEvent,
  useEffect,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Flex,
  Image,
  ModalOverlay,
  Select,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import * as emailValidator from 'email-validator';

import Card from '../../components/Card';
import Input from '../../components/Input';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import {
  survivorClient,
  wait
} from '../../service/backend';
import DownloadCodeModal from '../../components/DownloadCodeModal';
import ChangeCurrentCampusModal from '../../components/ChangeCurrentCampusModal';
import { useGenerateBackupCodes } from '../../service/hooks/auth';
import DeleteModal from '../../components/DeleteModal';
import { BADGE_SALT } from '../../config/env';
import Notification from '../../components/Notification';
import IconInput from '../../components/IconInput';
import { useNotification } from '../../components/Notification/useNotification';

import LockIcon from '../../../assets/icons/lock_new.svg';
import useExitPrompt from '../../service/hooks/useExitPrompt';
import ContactInfoChangeConfirmModal from '../../components/ContactInfoChangeConfirmModal';
import UserIcon from '../../../assets/icons/user.svg';
import { formatPhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from '../../components/PhoneNumberInput';
import {
  question1Options,
  question2Options,
  question3Options
} from '../RecoverySetup';
import VerifyRecoveryEmailModal from '../../components/VerifyRecoveryEmailModal';
import { SecurityQuestionTips } from '../../components/SecurityQuestionTips';
import WhiteButton from '../../components/layout/WhiteButton';
import Textarea from '../../components/Textarea';
import EnterCampusNameModal from '../../components/EnterCampusNameModal';

interface ContactInfo {
  username: string;
  email: string;
  phone: string;
  fullName: string;
  preferredLanguage: string;
  accommodationsNeeded: string;
}

interface ProfileInfo {
  email: string;
  phoneNumber: string;
}

interface IUpdatePasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface UpdateSecurityQuestionsForm {
  question1: string;
  question2: string;
  question3: string;
  answer1: string;
  answer2: string;
  answer3: string;
}

const AccountSettings: React.FC = () => {
  const [questionEditing, setQuestionEditing] = useState<boolean>(false);
  const [profileEditing, setProfileEditing] = useState<boolean>(false);
  const [contactInfoEditing, setContactInfoEditing] = useState<boolean>(false);
  const [passwordEditing, setPasswordEditing] = useState<boolean>(false);
  const [questionSaving, setQuestionSaving] = useState<boolean>(false);
  const [profileSaving, setProfileSaving] = useState<boolean>(false);
  const [contactSaving, setContactSaving] = useState<boolean>(false);
  const [backupLoading, setBackupLoading] = useState<boolean>(false);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [pwdError, setPwdError] = useState<boolean>(false);
  const [pwdUpdating, setPwdUpdating] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState<boolean>(false);
  const [showContactInfoConfirmModal, setShowContactInfoConfirmModal] = useState<boolean>(false);
  const [showChangeCampusModal, setShowChangeCampusModal] = useState<boolean>(false);
  const [showEnterCampusNameModal, setShowEnterCampusNameModal] = useState<boolean>(false);
  const [currentCampusEmailDomain, setCurrentCampusEmailDomain] = useState<string>('');
  const { notification, setNotification } = useNotification();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true);

  useEffect(() => {
    setShowExitPrompt(contactInfoEditing || passwordEditing || profileEditing);
  }, [contactInfoEditing, passwordEditing, profileEditing]);

  const {
    isOpen: showDeleteModal,
    onOpen: setShowDeleteModal,
    onClose: closeDeleteModal,
  } = useDisclosure();
  const { mutateAsync: requestGenerateBackupCodes } = useGenerateBackupCodes();

  const { username, contactInfo, entries } = survivorClient;
  const {
    register: profileRegister,
    getValues: profileValues,
    setValue: setProfileValue,
    handleSubmit: handleProfileSubmit,
    formState: { errors: profileErrors },
    reset: profileFormReset
  } = useForm<ProfileInfo>();
  const {
    register: contactRegister,
    getValues: contactValues,
    setValue: setContactValue,
    handleSubmit: handleContactSubmit,
    formState: { errors: contactErrors },
    reset: contactFormReset
  } = useForm<ContactInfo>();
  const {
    register: pwdRegister,
    handleSubmit: handlePwdSubmit,
    getValues: pwdValues,
    formState: { errors: pwdErrors },
    reset: pwdFormReset
  } = useForm<IUpdatePasswordForm>();
  const {
    register: questionsRegister,
    setValue: setQuestionsValue,
    handleSubmit: handleQuestionsSubmit,
    formState: { errors: questionsErrors },
  } = useForm<UpdateSecurityQuestionsForm>();

  const { onChange: onChangePassword } = pwdRegister('newPassword');

  const handleProfileEditingCancel = () => {
    profileFormReset();
    setProfileEditing(false);
  };

  const handleProfileEditing = () => {
    setProfileValue('phoneNumber', survivorClient.userData.phoneNumber ?? '');
    setProfileEditing(true);
  };

  const handleContactInfoEditingCancel = () => {
    contactFormReset();
    setContactInfoEditing(false);
  };

  const handleContactInfoEditing = () => {
    setContactInfoEditing(true);
  };

  const handlePasswordEditingCancel = () => {
    pwdFormReset();
    setPasswordEditing(false);
  };

  const handlePasswordEditing = () => {
    setPwdError(false);
    setPasswordEditing(true);
  };

  const handleQuestionEditing = () => {
    setQuestionsValue('question1',
      survivorClient.userData.securityQuestions && survivorClient.userData.securityQuestions.length > 0 ?
        survivorClient.userData.securityQuestions[0] : '');
    setQuestionsValue('question2',
      survivorClient.userData.securityQuestions && survivorClient.userData.securityQuestions.length > 1 ?
        survivorClient.userData.securityQuestions[1] : '');
    setQuestionsValue('question3',
      survivorClient.userData.securityQuestions && survivorClient.userData.securityQuestions.length > 2 ?
        survivorClient.userData.securityQuestions[2] : '');
    setQuestionsValue('answer1',
      survivorClient.userData.securityAnswers && survivorClient.userData.securityAnswers.length > 0 ?
        survivorClient.userData.securityAnswers[0] : '');
    setQuestionsValue('answer2',
      survivorClient.userData.securityAnswers && survivorClient.userData.securityAnswers.length > 1 ?
        survivorClient.userData.securityAnswers[1] : '');
    setQuestionsValue('answer3',
      survivorClient.userData.securityAnswers && survivorClient.userData.securityAnswers.length > 2 ?
        survivorClient.userData.securityAnswers[2] : '');
    setQuestionEditing(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleGenerateNewCodes = async () => {
    setBackupLoading(true);
    setShowModal(true);
    try {
      await wait(500);
      const newBackupCodes = await requestGenerateBackupCodes();
      setBackupCodes(newBackupCodes);
    } catch (e) {
      //
    }
    setBackupLoading(false);
  };

  const handleDeleteClick = () => {
    //
    setShowDeleteModal();
  };

  const onProfileSubmit = async (data: ProfileInfo) => {
    if (!data.email?.trim() || !data.phoneNumber) {
      setNotification({
        type: 'error',
        message: 'You must provide a profile email address and phone number.'
      });
      return;
    }

    if (data.email.toLowerCase().trim() !== survivorClient.userData.callistoContactEmail) {
      setProfileSaving(true);
      try {
        setShowEmailVerificationModal(true);
      } finally {
        setProfileSaving(false);
      }
    } else if(data.phoneNumber !== survivorClient.userData.phoneNumber) {
      await submitProfileInfo(data);
    } else if (currentCampusEmailDomain) {
      setNotification({
        type: 'success',
        message: 'Your profile information has been updated!'
      });
      setProfileEditing(false);
    } else {
      setNotification({
        type: 'success',
        message: 'No changes were made, so profile information was not updated.'
      });
      setProfileEditing(false);
    }
  };

  const submitProfileInfo = async (data: ProfileInfo) => {
    const isDataValid = !!data.email?.trim();
    if (isDataValid) {
      setProfileSaving(true);
      try {
        await survivorClient.resetAccountRecovery(data.email.toLowerCase().trim(), data.phoneNumber);
        setNotification({
          type: 'success',
          message: 'Your profile information has been updated!'
        });
      } catch (e) {
        setNotification({
          type: 'error',
          message: 'Server error. Please try again!'
        });
      } finally {
        setProfileEditing(false);
        setProfileSaving(false);
      }
    }
  };

  const onContactSubmit = async (data: ContactInfo) => {
    if (entries && entries.length > 0 && ((!data.email && !data.phone) || !data.fullName)) {
      setNotification({
        type: 'error',
        message:
          'Since you have submitted a Matching Entry, your full name and either email address or phone number must be provided.',
      });
      return;
    }

    if (
      (!survivorClient.contactInfo?.name || survivorClient.contactInfo.name === data.fullName) &&
      (!survivorClient.contactInfo?.email || survivorClient.contactInfo.email === data.email) &&
      (!survivorClient.contactInfo?.phone || survivorClient.contactInfo.phone === data.phone) &&
      (!survivorClient.contactInfo?.preferredLanguage || survivorClient.contactInfo.preferredLanguage === data.preferredLanguage) &&
      (!survivorClient.contactInfo?.accommodationsNeeded || survivorClient.contactInfo.accommodationsNeeded === data.accommodationsNeeded)
    ) {
      await submitContactInfo(data);
    } else {
      setShowContactInfoConfirmModal(true);
    }
  };

  const submitContactInfo = async (data: ContactInfo) => {
    const isDataValid = !(entries && entries.length > 0) || data.phone || data.email;
    if (isDataValid) {
      setContactSaving(true);
      try {
        await wait(100);
        const languageChanged = survivorClient.contactInfo?.preferredLanguage !== data.preferredLanguage;
        const accommodationsChanged = survivorClient.contactInfo?.accommodationsNeeded !== data.accommodationsNeeded;
        survivorClient.contactInfo.email = data.email;
        survivorClient.contactInfo.phone = data.phone;
        survivorClient.contactInfo.name = data.fullName;
        survivorClient.contactInfo.preferredLanguage = data.preferredLanguage;
        survivorClient.contactInfo.accommodationsNeeded = data.accommodationsNeeded;
        await survivorClient.updateContactInfo();

        if (languageChanged || accommodationsChanged) {
          await survivorClient.updateLanguageAndAccommodationsOnAllEntries();
        }

        setNotification({
          type: 'success',
          message: 'Your contact information has been updated!',
        });
      } catch (e) {
        setNotification({
          type: 'error',
          message: 'Server error. Please try again!',
        });
      } finally {
        setContactInfoEditing(false);
        setContactSaving(false);
        setShowContactInfoConfirmModal(false);
      }
    }
  };

  const onPwdSubmit = async ({ oldPassword, newPassword }: IUpdatePasswordForm) => {
    setPwdUpdating(true);
    if (!pwdError) {
      await wait(500);
      try {
        await survivorClient.checkPasswordStrength(newPassword);
      } catch {
        await survivorClient.incrementCount('Weak Password Detected: Password Change');
        setPwdError(true);
        setPwdUpdating(false);
        return;
      }
    } else {
      await survivorClient.incrementCount('Weak Password Warning Bypassed: Password Change');
    }
    try {
      await wait(500);
      await survivorClient.updatePassword(oldPassword, newPassword, BADGE_SALT);
      setNotification({
        type: 'success',
        message: 'Your information has been updated!',
      });
    } catch (e) {
      setNotification({
        type: 'error',
        message: 'There was a problem updating your password',
      });
    }
    setPwdUpdating(false);
    setPasswordEditing(false);
  };

  const handleChangePassword = async () => {
    if (pwdError) {
      setPwdError(false);
      await survivorClient.incrementCount('Weak Password Overridden: Password Change');
    }
  };

  const onQuestionsSubmit = async (responses: UpdateSecurityQuestionsForm) => {
    if (
      responses.question1 === (survivorClient.userData.securityQuestions ? survivorClient.userData.securityQuestions[0] : '') &&
        responses.question2 === (survivorClient.userData.securityQuestions ? survivorClient.userData.securityQuestions[1] : '') &&
        responses.question3 === (survivorClient.userData.securityQuestions ? survivorClient.userData.securityQuestions[2] : '') &&
        responses.answer1 === (survivorClient.userData.securityAnswers ? survivorClient.userData.securityAnswers[0] : '') &&
        responses.answer2 === (survivorClient.userData.securityAnswers ? survivorClient.userData.securityAnswers[1] : '') &&
        responses.answer3 === (survivorClient.userData.securityAnswers ? survivorClient.userData.securityAnswers[2] : '')
    ) {
      setQuestionEditing(false);
      return;
    }
    setQuestionSaving(true);
    try {
      await survivorClient.resetAccountRecovery(
        survivorClient.userData.callistoContactEmail ?? '',
        survivorClient.userData.phoneNumber ?? '',
        [responses.question1, responses.question2, responses.question3],
        [responses.answer1, responses.answer2, responses.answer3]
      );
      setQuestionEditing(false);
    } finally {
      setQuestionSaving(false);
    }
    return Promise.resolve();
  };

  const handleQuestionEditingCancel = () => {
    setQuestionEditing(false);
  };

  return (
    <Box>
      <Notification notification={notification} />

      <Text my="16px" fontSize="xl" as="h1" fontFamily="Avenir">
        <Image src={UserIcon as string} width="100px" />
        Account Settings
      </Text>
      <Card
        flexDir={['column', 'column', 'row']}
        padding="20px"
        justifyContent="space-between"
        position="relative"
        mb="20px"
        as="form"
        onSubmit={handleProfileSubmit(onProfileSubmit)}
      >
        <Box fontSize="sm2">
          <Text as="h2" mb="16px">
            <b>Profile Information</b>
          </Text>
          <Box mb="16px">
            <b>ID</b>
            <Text as="span" fontSize="xs" ml="15px">
              {survivorClient.userID}
            </Text>
          </Box>
          <Box fontSize="sm">
            <Box mb="16px">
              <b>Username</b>
              <Text as="span" fontSize="xs" ml="15px">
                {username}
              </Text>
            </Box>
            <Box mb="16px">
              <b>Campus When Signed Up For Callisto</b>
              <Text as="span" fontSize="xs" ml="15px">
                {survivorClient.signupCampusName}
              </Text>
            </Box>
            <Box mb="16px">
              <b>Current Campus</b>
              <Text as="span" fontSize="xs" ml="15px">
                {survivorClient.currentCampusName}
              </Text>
              {profileEditing && (
                <Button
                  buttonColor="brand.primary"
                  height="2em"
                  minWidth="100px"
                  ml="16px"
                  onClick={() => {
                    setShowChangeCampusModal(true);
                  }}
                >
                  Change Campus
                </Button>
              )}
            </Box>
            <Box mb="16px">
              <b>Email Address</b> {profileEditing && '(changes will require verification)'}
              {!profileEditing && (
                <Text as="span" fontSize="xs" ml="15px">
                  {survivorClient.userData.callistoContactEmail}
                </Text>
              )}
              {profileEditing && (
                <Input
                  mt="10px"
                  defaultValue={survivorClient.userData.callistoContactEmail}
                  {...profileRegister('email', {
                    validate: () => emailValidator.validate(profileValues().email.trim())
                  })}
                />
              )}
              {profileErrors && (
                <Text color="brand.red" my="5px">
                  {profileErrors.email?.type === 'validate' && 'Please enter a valid email'}
                </Text>
              )}
            </Box>
            <Box mb="16px">
              <b>Phone Number</b>
              {!profileEditing && (
                <Text as="span" fontSize="xs" ml="15px">
                  {formatPhoneNumber(survivorClient.userData.phoneNumber ?? '')}
                </Text>
              )}
              {profileEditing && (
                <PhoneNumberInput
                  value={survivorClient.userData.phoneNumber}
                  isDisabled={false}
                  onChange={(value?: string) => {
                    setProfileValue('phoneNumber', value ?? '');
                  }}
                />
              )}
            </Box>
            <Box mb="16px">
              <Text>
                <em>This is the email address and phone number used for account recovery.
                      Callisto employees do not have access to your profile information.</em>
              </Text>
            </Box>
          </Box>
        </Box>
        <Box textAlign="center">
          {!profileEditing && (
            <Button
              buttonColor="brand.primary"
              onClick={handleProfileEditing}
              display={{ base: 'block', md: 'block' }}
            >
                  Update Profile Information
            </Button>
          )}
          {profileEditing && (
            <>
              <Button
                buttonColor="brand.primary"
                ml="16px"
                type="submit"
                isLoading={profileSaving}
              >
                    Save Changes
              </Button>
              <br />
              <ButtonLink onClick={handleProfileEditingCancel}>
                    Cancel
              </ButtonLink>
            </>
          )}
        </Box>
      </Card>
      <Card
        flexDir={['column', 'column', 'row']}
        padding="20px"
        justifyContent="space-between"
        position="relative"
        as="form"
        onSubmit={handleContactSubmit(onContactSubmit)}
      >
        <Box fontSize="sm2">
          <Text as="h2" mb="16px">
            <b>Contact Information</b>
          </Text>
          <Text as="p" fontSize="sm" mb="15px">
            <em>Callisto employees do not have access to your contact information
                  as it is encrypted prior to being saved on our server. In the
                  event that you match with another survivor of the same perpetrator,
                  your contact information will only be visible to the assigned
                  Legal Options Counselor.</em>
          </Text>
          <Box fontSize="sm">
            <Box mb="16px">
              <b>Full Name</b>
              {!contactInfoEditing && (
                <Text as="span" fontSize="xs" ml="15px">
                  {contactInfo.name}
                </Text>
              )}
              {contactInfoEditing && (
                <Input
                  mt="10px"
                  defaultValue={contactInfo.name}
                  {...contactRegister('fullName')}
                />
              )}
            </Box>
            <Box mb="16px">
              <b>Preferred Language</b>
              {!contactInfoEditing && (
                <Text as="span" fontSize="xs" ml="15px">
                  {contactInfo.preferredLanguage ? contactInfo.preferredLanguage : 'Not specified'}
                </Text>
              )}
              {contactInfoEditing && (
                <Input
                  mt="10px"
                  defaultValue={contactInfo.preferredLanguage}
                  {...contactRegister('preferredLanguage')}
                />
              )}
            </Box>
            <Box mb="16px">
              <b>Accommodations Needed</b>
              {!contactInfoEditing && (
                <Text as="span" fontSize="xs" ml="15px">
                  {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                  {contactInfo.accommodationsNeeded ? contactInfo.accommodationsNeeded.replaceAll('\n', '; ') : 'Not specified'}
                </Text>
              )}
              {contactInfoEditing && (
                <>
                  <Text as='p' fontSize='xs' ml='15px'>
                    Callisto is dedicated to ensuring inclusive services for survivors with disabilities.
                    We are fully committed to providing accommodations tailored to individual needs.
                    Kindly specify any accommodations you require in the space provided below and we will do our best
                    to accommodate them.
                  </Text>
                  <Textarea
                    mt='10px'
                    height='100px'
                    backgroundColor='brand.brightWhite'
                    borderColor='input.border'
                    defaultValue={contactInfo.accommodationsNeeded}
                    {...contactRegister('accommodationsNeeded')}
                  />
                </>
              )}
            </Box>
            <Box mb="16px">
              <b>Contact Email</b>
              {!contactInfoEditing && (
                <Text as="span" fontSize="xs" ml="15px">
                  {contactInfo.email}
                </Text>
              )}
              {contactInfoEditing && (
                <Input
                  mt="10px"
                  type="email"
                  defaultValue={contactInfo.email}
                  {...contactRegister('email', {
                    validate: () => contactValues().email === '' || emailValidator.validate(contactValues().email),
                  })}
                />
              )}
            </Box>
            <Box mb="16px">
              <b>Contact Phone</b>
              {!contactInfoEditing && (
                <Text as="span" fontSize="xs" ml="15px">
                  {formatPhoneNumber(contactInfo.phone ?? '')}
                </Text>
              )}
              {contactInfoEditing && (
                <PhoneNumberInput
                  value={contactInfo.phone}
                  isDisabled={false}
                  onChange={(value?: string) => {
                    setContactValue('phone', value ?? '');
                  }}
                />
              )}
              {contactErrors && (
                <Text color="brand.red" my="5px">
                  {contactErrors.phone?.type === 'validate' &&
                      'Please enter a valid U.S. phone number'}
                  <br />
                  {contactErrors.email?.type === 'validate' &&
                      'Please enter a valid email'}
                  <br />
                  {contactErrors.email?.message}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
        <Box textAlign="center">
          {!contactInfoEditing && (
            <Button
              buttonColor="brand.primary"
              onClick={handleContactInfoEditing}
              display={{ base: 'block', md: 'block' }}
            >
              Update Contact Information
            </Button>
          )}
          {contactInfoEditing && (
            <>
              <Button
                buttonColor="brand.primary"
                mx="16px"
                type="submit"
                isLoading={contactSaving}
              >
                Save Changes
              </Button>
              <ButtonLink onClick={handleContactInfoEditingCancel}>
                Cancel
              </ButtonLink>
            </>
          )}
        </Box>
      </Card>
      <Card
        padding="20px"
        flexDir={{ base: 'column', md: 'row' }}
        mt="20px"
        justifyContent="space-between"
        position="relative"
        as="form"
        onSubmit={handlePwdSubmit(onPwdSubmit)}
      >
        <Box fontSize="sm2">
          {!passwordEditing && (
            <>
              <Text as="h2" mb="16px">
                <b>Password</b>
              </Text>
              <IconInput
                inputGroupProps={{}}
                defaultValue="....."
                icon={LockIcon as string}
                isDisabled
                name="password"
                type="password"
                isRequired
              />
            </>
          )}
          {passwordEditing && (
            <Box fontSize="sm">
              <Box mb="16px">
                <b>Current Password</b>
                <br />
                <Input
                  mt="10px"
                  width="100%"
                  type="password"
                  {...pwdRegister('oldPassword', {
                    required: {
                      value: true,
                      message: 'Please enter your current password.',
                    },
                  })}
                />
              </Box>
              <Box mb="16px">
                <b>New Password</b>
                <br />
                <Input
                  mt="10px"
                  width="100%"
                  type="password"
                  {...pwdRegister('newPassword', {
                    minLength: {
                      value: 8,
                      message:
                        'This password is too short, please use at least 8 characters',
                    },
                    required: {
                      value: true,
                      message: 'Please enter a password.',
                    },
                  })}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    await onChangePassword(e);
                    await handleChangePassword();
                  }}
                />
              </Box>
              <Box mb="16px">
                <b>Re-enter New Password</b>
                <br />
                <Input
                  mt="10px"
                  width="100%"
                  type="password"
                  {...pwdRegister('confirmNewPassword', {
                    validate: () =>
                      pwdValues().newPassword === pwdValues().confirmNewPassword,
                    required: {
                      value: true,
                      message: 'Please confirm your password.',
                    },
                  })}
                />
              </Box>
              <Text color="red" textAlign="left" width="100%">
                {pwdErrors.oldPassword && pwdErrors.oldPassword.message}
              </Text>
              <Text color="red" textAlign="left" width="100%">
                {pwdErrors.newPassword && pwdErrors.newPassword.message}
              </Text>
              <Text color="red" textAlign="left" width="100%">
                {!pwdErrors.newPassword &&
                  pwdErrors.confirmNewPassword &&
                  pwdErrors.confirmNewPassword.message}
              </Text>
              <Text color="red" textAlign="left" width="100%">
                {pwdErrors.confirmNewPassword &&
                  pwdErrors.confirmNewPassword.type === 'validate' &&
                  'Passwords do not match.'}
              </Text>
              {pwdError && (
                <Text color="red" textAlign="left" width="100%">
                  The password you chose has been compromised on another website
                  (
                  <Text
                    as="a"
                    href="https://haveibeenpwned.com/Passwords"
                    target="_blank"
                    textDecor="underline"
                    color="brand.link"
                  >
                    click here for more details
                  </Text>
                  ). If you want to proceed with this password, click Save
                  Changes. If not, please enter a new password.
                </Text>
              )}
            </Box>
          )}
        </Box>
        <Flex flexDir="column">
          {!passwordEditing && (
            <>
              <Button
                buttonColor="brand.primary"
                m="3px 3px 3px 15px"
                onClick={handlePasswordEditing}
                display={{ base: 'block', md: 'block' }}
              >
                Change Password
              </Button>
              <Button
                buttonColor="brand.primary"
                mt={{ base: '10px', md: '0px' }}
                onClick={handleGenerateNewCodes}
              >
                Generate New Backup Codes
              </Button>
            </>
          )}
          {passwordEditing && (
            <Flex justifyContent="center" width="100%">
              <ButtonLink onClick={handlePasswordEditingCancel}>
                Cancel
              </ButtonLink>
              <Button
                buttonColor="brand.primary"
                ml="16px"
                isLoading={pwdUpdating}
                type="submit"
              >
                Save Changes
              </Button>
            </Flex>
          )}
        </Flex>
      </Card>
      <Card
        padding="20px"
        flexDir={{ base: 'column', md: 'row' }}
        mt="20px"
        justifyContent="space-between"
        position="relative"
        as="form"
        onSubmit={handleQuestionsSubmit(onQuestionsSubmit)}
      >
        <Box fontSize="sm2">
          <Text as="h2" mb="16px">
            <b>Security Questions and Answers</b>
          </Text>

          {questionEditing && (
            <SecurityQuestionTips baseFontSize="xs" titleFontSize="sm" />
          )}

          {!questionEditing && (
            <>

              <Text fontSize="sm">
                {survivorClient.userData.securityQuestions?.length
                && survivorClient.userData.securityQuestions.length > 0
                && survivorClient.userData.securityQuestions[0]
                || 'No question selected'}
              </Text>
              <Input
                name="securityAnswer1"
                type="password"
                isDisabled={true}
                defaultValue={String(survivorClient.userData.securityAnswers?.length
                && survivorClient.userData.securityAnswers.length > 0
                && survivorClient.userData.securityAnswers[0])}
                mb="16px"
                mt="-24px"
              />
              <Text fontSize="sm" mt="16px">
                {survivorClient.userData.securityQuestions?.length
                && survivorClient.userData.securityQuestions.length > 1
                && survivorClient.userData.securityQuestions[1]
                || 'No question selected'}
              </Text>
              <Input
                name="securityAnswer2"
                type="password"
                isDisabled={true}
                defaultValue={String(survivorClient.userData.securityAnswers?.length
                && survivorClient.userData.securityAnswers.length > 1
                && survivorClient.userData.securityAnswers[1])}
                mb="16px"
                mt="-24px"
              />
              <Text fontSize="sm" mt="16px">
                {survivorClient.userData.securityQuestions?.length
                && survivorClient.userData.securityQuestions.length > 2
                && survivorClient.userData.securityQuestions[2]
                || 'No question selected'}
              </Text>
              <Input
                name="securityAnswer3"
                type="password"
                isDisabled={true}
                defaultValue={String(survivorClient.userData.securityAnswers?.length
                && survivorClient.userData.securityAnswers.length > 2
                && survivorClient.userData.securityAnswers[2])}
                mb="16px"
                mt="-24px"
              />
            </>
          )}
          {questionEditing && (
            <>
              <Box py="30px">
                <Select
                  placeholder="Select a question"
                  bgColor="brand.brightWhite"
                  borderColor="lightgray"
                  fontSize="sm"
                  mb="60px"
                  borderRadius="0px"
                  _focus={{ borderColor: 'brand.primary' }}
                  {...questionsRegister('question1', {
                    required: {
                      value: true,
                      message: 'Please select a question'
                    }
                  })}
                >
                  {question1Options.map((opt) => (
                    <option value={opt} key={opt}>{opt}</option>
                  ))}
                </Select>
                <Text color="red" textAlign="left" width="100%" mt="-55px">
                  {questionsErrors.question1 && questionsErrors.question1.message}
                </Text>

                <Input
                  placeholder="Answer*"
                  height="2em"
                  {...questionsRegister('answer1', {
                    required: {
                      value: true,
                      message: 'Please answer the question'
                    }
                  })}
                />
                <Text color="red" textAlign="left" width="100%">
                  {questionsErrors.answer1 && questionsErrors.answer1.message}
                </Text>
              </Box>

              <Box py="30px">
                <Select
                  placeholder="Select a question"
                  borderColor="lightgray"
                  bgColor="brand.brightWhite"
                  fontSize="sm"
                  mb="60px"
                  borderRadius="0px"
                  _focus={{ borderColor: 'brand.primary' }}
                  {...questionsRegister('question2', {
                    required: {
                      value: true,
                      message: 'Please select a question'
                    }
                  })}
                  defaultValue="Question 1 option 1"
                >
                  {question2Options.map((opt) => (
                    <option value={opt} key={opt}>{opt}</option>
                  ))}
                </Select>
                <Text color="red" textAlign="left" width="100%" mt="-55px">
                  {questionsErrors.question2 && questionsErrors.question2.message}
                </Text>

                <Input
                  placeholder="Answer*"
                  height="2em"
                  {...questionsRegister('answer2', {
                    required: {
                      value: true,
                      message: 'Please answer the question'
                    }
                  })}
                />
                <Text color="red" textAlign="left" width="100%">
                  {questionsErrors.answer2 && questionsErrors.answer2.message}
                </Text>
              </Box>

              <Box py="30px">
                <Select
                  placeholder="Select a question"
                  borderColor="lightgray"
                  bgColor="brand.brightWhite"
                  fontSize="sm"
                  borderRadius="0px"
                  _focus={{ borderColor: 'brand.primary' }}
                  {...questionsRegister('question3', {
                    required: {
                      value: true,
                      message: 'Please select a question'
                    }
                  })}
                >
                  {question3Options.map((opt) => (
                    <option value={opt} key={opt}>{opt}</option>
                  ))}
                </Select>
                <Text color="red" textAlign="left" width="100%">
                  {questionsErrors.question3 && questionsErrors.question3.message}
                </Text>

                <Input
                  placeholder="Answer*"
                  height="2em"
                  {...questionsRegister('answer3', {
                    required: {
                      value: true,
                      message: 'Please answer the question'
                    }
                  })}
                />
                <Text color="red" textAlign="left" width="100%">
                  {questionsErrors.answer3 && questionsErrors.answer3.message}
                </Text>
              </Box>
            </>
          )}
        </Box>
        <Flex flexDir="column">
          {!questionEditing && (
            <>
              <Button
                buttonColor="brand.primary"
                m="3px 3px 3px 15px"
                onClick={handleQuestionEditing}
                display={{ base: 'block', md: 'block' }}
              >
              Change Questions and Answers
              </Button>
            </>
          )}
          {questionEditing && (
            <Flex justifyContent="center" width="100%">
              <ButtonLink onClick={handleQuestionEditingCancel}>
              Cancel
              </ButtonLink>
              <Button
                buttonColor="brand.primary"
                ml="16px"
                isLoading={questionSaving}
                type="submit"
              >
              Save Changes
              </Button>
            </Flex>
          )}
        </Flex>
      </Card>
      <Box textAlign="center" mt="20px" onClick={handleDeleteClick}>
        <WhiteButton>Delete Account</WhiteButton>
      </Box>
      <DownloadCodeModal
        isOpen={showModal}
        onClose={handleCloseModal}
        backupCodes={backupCodes}
        loading={backupLoading}
        isNewlyGenerated
      >
        <ModalOverlay />
      </DownloadCodeModal>
      <DeleteModal isOpen={showDeleteModal} onClose={closeDeleteModal} deletePath="account settings" />
      <ContactInfoChangeConfirmModal
        onSubmit={handleContactSubmit(submitContactInfo)}
        isOpen={showContactInfoConfirmModal}
        onClose={() => {
          setShowContactInfoConfirmModal(false);
        }}
        onCancel={() => {
          setShowContactInfoConfirmModal(false);
        }}
      />
      <VerifyRecoveryEmailModal
        isOpen={showEmailVerificationModal}
        onClose={() => {
          setShowEmailVerificationModal(false);
        }}
        emailAddress={profileValues().email?.toLowerCase().trim()}
        phoneNumber={profileValues().phoneNumber}
      />
      <ChangeCurrentCampusModal
        isOpen={showChangeCampusModal}
        onClose={() => {
          setShowChangeCampusModal(false);
        }}
        setEmailDomain={setCurrentCampusEmailDomain}
        setCampusNotFound={(campusNotFound: boolean) => {
          setShowEnterCampusNameModal(campusNotFound);
        }}
      >
        <ModalOverlay />
      </ChangeCurrentCampusModal>
      <EnterCampusNameModal
        isOpen={showEnterCampusNameModal}
        onClose={() => {
          setShowEnterCampusNameModal(false);
        }}
        emailDomain={currentCampusEmailDomain}
      >
        <ModalOverlay />
      </EnterCampusNameModal>
    </Box>
  );
};

export default AccountSettings;
