import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  useToast
} from '@chakra-ui/react';
import ContainerWrapper from '../../components/ContainerWrapper';
import { useForm } from 'react-hook-form';
import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import Input from '../../components/Input';
import Button from '../../components/Button';
import GiveFeedbackLink from '../../components/GiveFeedbackLink';
import { useValidateAnswers } from '../../service/hooks/auth';
import { survivorClient } from '../../service/backend';
import { PublicRoutes } from '../../config/routes';

export interface SecurityQuestionsForm {
  answer0: string;
  answer1: string;
  answer2: string;
}

export const AnswerSecurityQuestions: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SecurityQuestionsForm>();
  const {
    state: {
      token,
      validation
    } }: {
    state: {
      token: string;
      validation: { questions: string[] };
    };
  } = useLocation() as unknown as {
    state: {
      token: string;
      validation: { questions: string[] };
    };
  };
  const { mutateAsync: validateAnswers } = useValidateAnswers();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (answers: SecurityQuestionsForm) => {
    setLoading(true);
    try {
      const success = await validateAnswers({ ...answers, token });
      if (success) {
        navigate(PublicRoutes.RESET_PASSWORD_NEW, {
          state: {
            code: '',
            noCode: true
          }
        });
      } else {
        toast({
          title: 'There was an error recovering your account. Please try again.',
          status: 'error',
          isClosable: true,
          position: 'top'
        });
      }
    } catch (e) {
      try {
        await survivorClient.submitEvent('Answer security questions', { error: (e as Error).message });
      } catch {
        // swallow error
      }
      toast({
        title: 'There was an error recovering your account. Please try again.',
        status: 'error',
        isClosable: true,
        position: 'top'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContainerWrapper>
      <Box textAlign="center">
        <Flex alignItems="center" justifyContent="center" margin="0 auto" padding="0 0 25px 0">
          <Box
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            width="850px"
            backgroundColor="brand.white"
            padding={['20px 20px', '40px 20px', '60px 120px']}
          >
            <Flex
              maxWidth="650px"
              flexDir="column"
              textAlign="left"
              justifyContent="center"
              alignItems="center"
              height="100%"
              margin="0 auto"
            >
              <Text as="h1" fontSize="xl">Security Questions</Text>
              <Text mt="16px">Remember that answers are case-sensitive.</Text>
              <Flex
                flexDir="column"
                width="100%"
                textAlign="left"
                justifyContent="left"
                alignItems="left"
              >
                <Text mt="16px">Question 1: {validation.questions[0]}</Text>
                <Input
                  placeholder="Your answer*"
                  {...register('answer0', {
                    required: {
                      value: true,
                      message: 'Please answer all questions'
                    }
                  })}
                  mb="30px"
                />
                <Text color="red" textAlign="left" width="100%">
                  {errors.answer0 && errors.answer0.message}
                </Text>

                <Text mt="16px">Question 2: {validation.questions[1]}</Text>
                <Input
                  placeholder="Your answer*"
                  {...register('answer1', {
                    required: {
                      value: true,
                      message: 'Please answer all questions'
                    }
                  })}
                  mb="30px"
                />
                <Text color="red" textAlign="left" width="100%">
                  {errors.answer1 && errors.answer1.message}
                </Text>

                <Text mt="16px">Question 3: {validation.questions[2]}</Text>
                <Input
                  placeholder="Your answer*"
                  {...register('answer2', {
                    required: {
                      value: true,
                      message: 'Please answer all questions'
                    }
                  })}
                  mb="30px"
                />
                <Text color="red" textAlign="left" width="100%">
                  {errors.answer2 && errors.answer2.message}
                </Text>
              </Flex>
              <Button
                buttonColor="brand.primary"
                type="submit"
                isLoading={loading}
              >Submit</Button>
            </Flex>
          </Box>
        </Flex>
        <GiveFeedbackLink />
      </Box>
    </ContainerWrapper>
  );
};
