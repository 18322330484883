import React, {
  MouseEventHandler,
  useState
} from 'react';
import { Hyperlink } from './Hyperlink';
import { Paragraph } from './Paragraph';
import ChevronsDownIcon from '../../assets/icons/chevrons-down-solid.svg';
import ChevronsRightIcon from '../../assets/icons/chevrons-right-solid.svg';
import { Image } from '@chakra-ui/react';


interface CollapsibleSectionWithLinkProps {
  open?: boolean;
  mb?: string;
  to: string;
  text?: string;
  target?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const CollapsibleSectionWithLink: React.FC<CollapsibleSectionWithLinkProps> = (
  { to, target,  open = false, mb, children, text, onClick }
) => {
  const [isOpen, setOpen] = useState<boolean>(open);

  const handleOpening = () => {
    setOpen((prev) => !prev);
  };

  const iconSize = '12px';

  return (
    <>
      <Paragraph mb={mb ? mb : ''} props={{ onClick: handleOpening, className: 'clickable' }}>
        <button type="button">
          {!isOpen ?
            <Image width={iconSize} height={iconSize} src={ChevronsRightIcon as string} /> :
            <Image width={iconSize} height={iconSize} src={ChevronsDownIcon as string} />}
        </button>
        <em>&nbsp;<Hyperlink to={to} text={text} onClick={onClick} target={target} /></em>
      </Paragraph>
      { isOpen && children}
    </>
  );
};
