import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Box,
  Text,
} from '@chakra-ui/react';
import WhiteButton from './layout/WhiteButton';
import Button from './Button';

interface Props {
  submitText?: string;
  cancelText: string;
  returnText?: string;
  headerText: string;
  isConfirming: boolean;
  isOpen: boolean;
  onReturn?: () => void;
  formId: string;
  onClose(): void;
  onCancel(): void;
}

const SubmitCancelReturnModal: React.FC<Props> = ({
  submitText,
  cancelText,
  headerText,
  isConfirming,
  isOpen,
  returnText,
  onReturn,
  onClose,
  onCancel,
  formId
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered>
    <ModalOverlay />
    <ModalContent
      maxW="650px"
      minHeight="215px"
      padding="40px 40px 36px 40px"
      as="form"
      borderRadius="0px"
      position="relative"
      display="flex"
      flexDir="column"
    >
      <ModalCloseButton />
      <Text
        as="h3"
        fontSize="1.17em"
        my="18px"
        textAlign="center"
        dangerouslySetInnerHTML={{ __html: headerText }}
        flex="1 1"
        color="brand.primary"
      />
      <Box textAlign="center" mt="20px" pt="20px">
        <WhiteButton
          mx="10px"
          padding="15px 10px"
          minWidth="auto"
          onClick={onCancel}
          fontSize={['xs', 'xs', 'sm']}
          width={['100%', '100%', 'auto']}
          mt="5px"
        >
          {cancelText}
        </WhiteButton>
        {returnText && onReturn && (
          <Button
            buttonColor="brand.primary"
            mx="10px"
            padding="15px 10px"
            minWidth="auto"
            fontSize={['xs', 'xs', 'sm']}
            onClick={onReturn}
            width={['100%', '100%', 'auto']}
            mt="5px"
          >
            {returnText}
          </Button>
        )}
        {submitText && (
          <Button
            buttonColor="brand.primary"
            mx="10px"
            padding="15px 10px"
            type="submit"
            fontSize={['xs', 'xs', 'sm']}
            isLoading={isConfirming}
            width={['100%', '100%', 'auto']}
            mt="5px"
            form={formId}
          >
            {submitText}
          </Button>
        )}
      </Box>
    </ModalContent>
  </Modal>
);

export default SubmitCancelReturnModal;
