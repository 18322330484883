import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import {
  Box,
  Flex,
  Image,
  Text
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { PrivateRoutes } from '../../config/routes';
import Button from '../../components/Button';
import Card from '../../components/Card';
import WhiteButton from '../../components/layout/WhiteButton';
import { survivorClient } from '../../service/backend';
import { userEntry } from '../../../../../../lib/data';
import {
  EntryContext,
  EntryGlobalActionTypes
} from '../../contexts/EntryContext';
import { Paragraph } from '../../components/Paragraph';
import { CollapsibleSection } from '../../components/CollapsibleSection';
import EffectsOfTraumaModal from './EffectsOfTraumaModal';
import Gears from '../../../assets/icons/gears.svg';
import MatchingEntryItem from './MatchingEntryItem';

const MAX_ENTRIES = 20;
const MAX_DAILY_ENTRIES = 5;

const MatchingSystemDashboard: React.FC = () => {
  const entries: userEntry[] = survivorClient.entries;
  const draftEntries: userEntry[] = survivorClient.draftEntries;
  const { dispatch } = useContext(EntryContext);
  const [ showPopup, setShowPopup ] = useState<boolean>(false);
  const [ totalEntryLimitReached, setTotalEntryLimitReached ] = useState<boolean>(false);
  const [ dailyEntryLimitReached, setDailyEntryLimitReached ] = useState<boolean>(false);
  const [ displayDraftEntries, setDisplayDraftEntries ] = useState<userEntry[]>([]);
  const [ displayEntries, setDisplayEntries ] = useState<userEntry[]>([]);

  let totalEntries = 0;
  let entriesCreatedToday = 0;

  useEffect(() => {
    const today = new Date().toLocaleDateString();
    totalEntries = draftEntries.length + entries.length;
    draftEntries.forEach((entry) => {
      if (entry.created.toLocaleDateString() === today) {
        entriesCreatedToday++;
      }
    });
    entries.forEach((entry) => {
      if (entry.created.toLocaleDateString() === today) {
        entriesCreatedToday++;
      }
    });

    setDailyEntryLimitReached(entriesCreatedToday >= MAX_DAILY_ENTRIES);
    setTotalEntryLimitReached(totalEntries >= MAX_ENTRIES);
  }, [draftEntries.length, entries.length]);

  useEffect(() => {
    setDisplayEntries(entries.sort((a, b) => a.created.getTime() - b.created.getTime()));
  }, [entries]);

  useEffect(() => {
    setDisplayDraftEntries(draftEntries.sort((a, b) => a.created.getTime() - b.created.getTime()));
  }, [draftEntries]);

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleHidePopup = () => {
    setShowPopup(false);
  };

  const handleAddClick = () => {
    survivorClient.incrementCount('Entry Creation')
      .then(() => survivorClient.submitEvent('Button Click', {
        dashboard: 'Entry',
        button: 'Create Entry'
      }))
      .catch(// swallow the error to allow the survivor to continue
        () => {}
      );
    dispatch({
      type: EntryGlobalActionTypes.INITIALIZE_ENTRY
    });
  };

  return (
    <Flex overflowY='visible' flexDir='column'>
      <Text as='h1' fontSize='xl' my='16px'><Image src={Gears as string} />Matching System</Text>

      <CollapsibleSection open={true} prompt="Before you begin">
        <Paragraph props={{mt: '5px'}}>Before you get started, it’s important to recognize that this process can
          be triggering. If you want more information to understand the effects of trauma,
        { }  <a href="#" onClick={handleShowPopup} className="underlined">please click here</a>.
          We suggest that you have support either during or after you fill this out which could
          be a therapist, friend, or family member.</Paragraph>
      </CollapsibleSection>
      <CollapsibleSection prompt="About our matching system">
        <Paragraph props={{mt: '5px'}}>Our Matching System was created with input from hundreds of survivors,
          like you, as a way to overcome the two biggest barriers survivors faced when considering their
          next steps. The first barrier is the &quot;isolated incident&quot; narrative - survivors shared they
          would feel more confident in choosing their next steps if they knew they were not the only survivor
          of the same perpetrator.  The second barrier is that many people think reporting to authorities as
          the first or only option - survivors shared that they wanted to understand their rights and options
          before deciding what action, if any, to take. </Paragraph>
        <Paragraph>Creating a Matching Entry is simple. It does not require any details about the incident.
          Our system matches based on unique identifiers of the perpetrator, such as a social media handle,
          phone number, or email address. If you do not know at least one unique identifier, you will not
          be able to enter into matching. **Note: If you have created an Incident Log, you may already
          have some of this information saved there.</Paragraph>
      </CollapsibleSection>
      <CollapsibleSection mb="30px" prompt="What happens if I match?">
        <Paragraph props={{mt: '-25px'}}>If another survivor identifies the same perpetrator in the system, each survivor
          will be contacted by a Legal Options Counselor - a free and confidential legal expert who can help
          you understand the various options open to you. Legal Options Counselors (LOCs) can also help
          coordinate action between survivors of the same perpetrator, but only if both survivors agree.</Paragraph>
        <Paragraph mb='30px'>It&apos;s important to know that by meeting with a LOC, you do NOT have to take action.
          The choice is yours to make if and when you&apos;re ready.</Paragraph>
      </CollapsibleSection>
      <CollapsibleSection prompt="Matching Entry Statuses">
        <Paragraph>The status of your Matching Entry has two possible values:
          &quot;In progress - Not submitted&quot; and &quot;Submitted&quot;.</Paragraph>

        <Paragraph><strong>In progress - Not submitted:</strong> this status indicates that the Matching Entry
          is a draft--it has not been submitted to the Matching System and will not generate a match.</Paragraph>
        <Paragraph><strong>Submitted:</strong> this status indicates that the Matching Entry has been submitted to
           the Matching System and could potentially generate a match.</Paragraph>
      </CollapsibleSection>

      <Text as="p" my="16px">
        Please create a separate entry for each offender.
      </Text>

      { showPopup && <EffectsOfTraumaModal isOpen={showPopup} onClose={handleHidePopup} />}

      {entries.length === 0 && draftEntries.length === 0 && (
        <>
          <Card
            padding={{ base: '20px', md: '40px' }}
            mt="30px"
            flexDir="column"
            alignItems="center"
          >
            <Text as="h2" my="16px">
              <b>Currently, you have no Matching entries</b>
            </Text>
            <Link to={PrivateRoutes.CREATE_WHERE}>
              <Button
                buttonColor="brand.primary"
                my="20px"
                fontSize="xs"
                width={{ base: '100%', md: 'auto' }}
                onClick={handleAddClick}
              >
                Start your Matching entry
              </Button>
            </Link>
          </Card>
        </>
      )}
      {(entries.length > 0 || draftEntries.length > 0) && (
        <Box>
          <Link to={PrivateRoutes.CREATE_WHERE}>
            <WhiteButton onClick={handleAddClick} isDisabled={totalEntryLimitReached || dailyEntryLimitReached} mb="10px">
              Add another entry
            </WhiteButton>
          </Link>
          {totalEntryLimitReached && (
            <Text as="p" mt="1em">
              There is a limit of {MAX_ENTRIES} entries (including draft entries). You will not be able to create any
              additional entries unless you delete some entries or draft entries to bring the total below {MAX_ENTRIES}.
            </Text>
          )}
          {dailyEntryLimitReached && (
            <Text as="p" mt="1em">
              You may create a maximum of {MAX_DAILY_ENTRIES} entries per day (including draft entries). If you need to
              create more entries, please log back in tomorrow. You may still complete and submit any draft entries you
              may have if you so desire.
            </Text>
          )}
        </Box>
      )}
      {draftEntries.length > 0 && (
        <Box>
          {displayDraftEntries.map((draft) => (
            <MatchingEntryItem mb="20px" {...draft} key={draft.id} isDraft={true} />
          ))}
        </Box>
      )}
      {entries.length > 0 && (
        <Box>
          {displayEntries.map((entry) => (
            <MatchingEntryItem mb="20px" {...entry} key={entry.id} isDraft={false} />
          ))}
        </Box>
      )}
    </Flex>
  );
};

export default MatchingSystemDashboard;
