import { useToast } from '@chakra-ui/react';
import React, {
  useEffect,
  useState
} from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';

import LoadingIndicator from '../../components/LoadingIndicator';
import { PublicRoutes } from '../../config/routes';
import { useToken } from '../../service/hooks/auth';
import { survivorClient } from '../../service/backend';

const Token: React.FC = () => {
  const { token: input } = useParams<{ token: string }>();
  const toast = useToast();
  const navigate = useNavigate();
  const { mutateAsync: requestVerifyToken } = useToken();
  const [envVars, setEnvVars] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    const loadEnvVars = async () => {
      setEnvVars(await survivorClient.getEnvironmentVariables());
    };
    void loadEnvVars();
  }, []);

  useEffect(() => {
    if (envVars) {
      const verify = async () => {
        try {
          // The input might have had dummy query parameters added by the survivor's email system. We strip them off here.
          const inputParts = input.split('&');
          const token = inputParts[0];
          const { success, campusIdentified, emailDomain } = await requestVerifyToken({ token });
          if (success) {
            navigate(PublicRoutes.RECOVERY_EMAIL_ADDRESS, {
              state: {
                token,
                campusIdentified,
                emailDomain
              },
            });
            toast({
              title: 'Your .edu email has been verified',
              status: 'success',
              isClosable: true,
              position: 'top',
            });
          } else {
            toast({
              title: 'Unable to verify email',
              status: 'error',
              isClosable: true,
              position: 'top'
            });
            navigate(PublicRoutes.LOGIN);
          }
        } catch (e) {
          toast({
            title: (e as Error).message,
            status: 'error',
            isClosable: true,
            position: 'top',
            duration: 10000
          });
          navigate(PublicRoutes.LOGIN);
        }
      };
      void verify();
    }
  }, [envVars]);

  return <LoadingIndicator />;
};

export default Token;
