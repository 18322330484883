import React, {
  useContext,
  useState
} from 'react';
import {
  Box,
  Flex,
  FlexProps,
  Image,
  Text,
  Tooltip,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { userRecord } from '../../../../../../lib/data';
import Card from '../../components/Card';
import Button from '../../components/Button';
import WhiteButton from '../../components/layout/WhiteButton';
import QuestionMark from '../../../assets/icons/question-mark.svg';
import { useNavigate } from 'react-router-dom';
import {
  ITimeCheck,
  OrfContext,
  PLACE_VALUES,
  TIME_CONSTANTS,
  YesNoSure
} from '../../contexts/OrfContext';
import {
  survivorClient,
  wait
} from '../../service/backend';
import { PrivateRoutes } from '../../config/routes';
import ConfirmModal from '../../components/ConfirmModal';

type LegacyEncryptedRecordFormItemProps = FlexProps & userRecord;

export const LegacyEncryptedRecordFormItem: React.FC<LegacyEncryptedRecordFormItemProps> = ({
  id,
  created,
  edited,
  data,
  ...restProps
}: LegacyEncryptedRecordFormItemProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { setORFMode, setRecordId, completeAction, resetOrfState, setCreatedModifiedDates } =
    useContext(OrfContext);
  const {
    isOpen: showDeleteConfirmModal,
    onOpen: setShowDeleteConfirmModal,
    onClose: closeDeleteModal,
  } = useDisclosure();
  const [recordDeleting, setRecordDeleting] = useState<boolean>(false);

  const handleEditClick = () => {
    survivorClient.submitEvent('Button Click', {
      dashboard: 'Incident Log',
      button: 'View/Edit'
    }).finally(() => {
      resetOrfState();
      setORFMode('edit');
      setRecordId(id);
      setCreatedModifiedDates(created, edited);
      completeAction('intro');
      completeAction('when', {
        season: data.season || '',
        time: {
          key: data.time
            ? TIME_CONSTANTS.includes(data.time as ITimeCheck)
              ? data.time
              : 'exact time'
            : '',
          value: data.time || '',
        },
        memory: data.whenDetails || '',
        year: data.year?.toString() || '',
        month: data.month || '',
        day: data.dayOfMonth?.toString() || '',
        hasExactDate:
          !!data.year || !!data.month || !!data.dayOfMonth ? 'Yes' : null,
      });
      completeAction('where', {
        place: {
          key: data.place
            ? PLACE_VALUES.includes(data.place)
              ? data.place
              : 'Other'
            : '',
          value: data.place || '',
        },
        memory: data.placeDetails || '',
      });
      completeAction('know', {
        anyoneSaw: (data.anyoneSaw as YesNoSure) || null,
        anyoneInteract: (data.anyoneInteract as YesNoSure) || null,
        informationYouDescribed: data.witnessDetails || '',
        toldAnyone: (data.toldAnyone as 'Yes' | 'No') || null,
        informationYouTold: data.peopleYouToldDetails || '',
        offenderTold: (data.offenderTold as YesNoSure) || null,
      });
      completeAction('consent', {
        reasons: data.reasonsForLackOfConsent || [],
      });
      completeAction('incidents', {
        whatHappened: data.whatHappened || '',
      });
      completeAction('offenders', {
        numberOfOffenders: data.numberOfOffenders || '',
        name: data.offenderName || '',
        offenderDetails: data.offenderDetails || '',
        anyoneElse: (data.offenderWithAnyoneElse as YesNoSure) || null,
        companionDetails: data.offenderCompanionDetails || '',
      });
      completeAction('evidence', {
        physicalInfo: data.physicalEvidence || '',
        electronicInfo: data.electronicEvidence || '',
      });
      completeAction('review');
      navigate(PrivateRoutes.ORF_REVIEW);
    });
  };

  const handleDeleteClick = async () => {
    try {
      await survivorClient.submitEvent('Button Click', {
        dashboard: 'Incident Log',
        button: 'Delete Record'
      });
    } finally {
      setShowDeleteConfirmModal();
    }
  };

  const handleDeleteAction = async () => {
    setRecordDeleting(true);
    try {
      await wait(100);
      await survivorClient.deleteRecordForm(id);
      toast({
        title: 'Successfully removed an Incident Log.',
        position: 'top',
        status: 'success',
      });
      closeDeleteModal();
      navigate(PrivateRoutes.ILOG);
    } catch (e) {
      toast({
        title:
          'There was an error removing the Incident Log. Please try again later.',
        position: 'top',
        status: 'error',
      });
    }
  };

  return (
    <Card
      flexDir={{ base: 'column', md: 'row' }}
      justifyContent='space-between'
      p={{ base: '20px', md: '40px' }}
      fontSize='sm'
      {...restProps}
    >
      <Box>
        <Text mb='16px' fontWeight='800' fontSize='md'>
          Created on: {`${created.toDateString()} ${created.toLocaleTimeString()} `}
          <Tooltip width='100%' label={<TooltipText />} placement='top' bg='brand.primary'
            color='brand.lightBackground'>
            <Image
              src={QuestionMark as string}
              maxWidth='30px'
              height='20px'
              display='inline-block'
              ml='1ex'
            />
          </Tooltip>

        </Text>
        <Text mb='16px'>
          <b>Last Updated:</b>&nbsp;
          {`${edited.toDateString()} ${edited.toLocaleTimeString()}`}
        </Text>
      </Box>
      <Flex flexDir='column'>
        <Button buttonColor='brand.primary' m='3px' onClick={handleEditClick}>
          View/Edit/Download
        </Button>
        <WhiteButton m='3px' onClick={handleDeleteClick}>
          Delete Incident Log
        </WhiteButton>
      </Flex>
      <ConfirmModal
        headerText="<b>Are you sure you want to delete this Incident Log?<br/>This action cannot be undone</b>"
        submitText="Delete This Incident Log"
        cancelText="Cancel"
        onSubmit={handleDeleteAction}
        isConfirming={recordDeleting}
        isOpen={showDeleteConfirmModal}
        onClose={closeDeleteModal}
        onCancel={closeDeleteModal}
      />
    </Card>
  );
};

const TooltipText = () => (
  <span>
    This form was created using an older version of the Incident Log. You may still edit or delete the form.
    Check out the FAQ section of Callisto&apos;s website for more information about this change.
  </span>
);
