import {
  Flex,
  Modal,
  ModalContent,
  ModalProps,
  Text
} from '@chakra-ui/react';
import React from 'react';
import { survivorClient } from '../service/backend';
import { CallistoRoutes } from '../config/routes';
import WhiteButton from './layout/WhiteButton';
import Button from './Button';

const PrivacyPolicyModal: React.FC<ModalProps> = ({
  onClose,
  ...props
}: ModalProps) => {
  const handleDecline = () => {
    survivorClient.acceptedPrivacyPolicy = false;
    onClose();
  };

  const handleAccept = async () => {
    await survivorClient.acceptPrivacyPolicy();
    onClose();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={onClose}
      {...props}
    >
      <ModalContent p="100px" maxW="625px" borderRadius="5px" borderColor="brand.primary" borderWidth="5px" color="brand.primary">
        <Text mt="40px" mb="18px" as="h1" fontSize="md" fontFamily="Avenir">
          We have updated our privacy policy and/or terms of service
        </Text>
        <Text mb="27px" mt="16px">
          By clicking &quot;I accept&quot; below you are indicating that you have
          read the{' '}
          <Text
            as="a"
            href={CallistoRoutes.PRIVACY_POLICY}
            target="_blank"
            color="brand.link"
            textDecor="underline"
            fontFamily="Avenir"
            fontWeight="bold"
          >
            Callisto Vault Privacy Policy
          </Text>
          {' '}and{' '}
          <Text
            as="a"
            href={CallistoRoutes.TERMS_OF_SERVICE}
            target="_blank"
            color="brand.link"
            textDecor="underline"
            fontFamily="Avenir"
            fontWeight="bold"
          >
            Callisto Vault Terms of Service
          </Text>
          {' '}and you accept their terms
        </Text>
        <Flex justifyContent="center" mt="20px">
          <WhiteButton
            minWidth="auto"
            mx="10px"
            onClick={handleDecline}
          >
            I Do Not Accept
          </WhiteButton>
          <Button
            minWidth="auto"
            buttonColor="brand.primary"
            mx="10px"
            onClick={handleAccept}
          >
            I Accept
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default PrivacyPolicyModal;
