import React, {
  useContext,
  useEffect,
  useRef
} from 'react';
import {
  ITimeCheck,
  OrfContext
} from '../../contexts/OrfContext';
import jsPDF from 'jspdf';
import { Paragraph } from '../../components/Paragraph';
import { OrfPdfIntroPageContent } from '../OrfIntro/OrfPdfIntroPageContent';
import LogoSvg from '../../../assets/Callisto_logo.svg';
import LogoPng from '../../../assets/Callisto_logo.png';
import { OrfWhenPageContent } from '../OrfWhen/OrfWhenPageContent';
import {
  ISeasonCheck,
  IWhenFormProps
} from '../OrfWhen';
import { OrfWherePageContent } from '../OrfWhere/OrfWherePageContent';
import { callistoLogoBase64 } from '../../../assets/Callisto_logo';
import { OrfOffenderPageContent } from '../OrfOffenders/OrfOffendersPageContent';
import { OrfKnowPageContent } from '../OrfKnow/OrfKnowPageContent';
import { OrfConsentPageContent } from '../OrfConsent/OrfConsentPageContent';
import { OrfIncidentsPageContent } from '../OrfIncidents/OrfIncidentsPageContent';
import ElectronicEvidence from '../OrfEvidence/ElectronicEvidence';
import PhysicalEvidence from '../OrfEvidence/PhysicalEvidence';
import { Evidence } from '../OrfEvidence/Evidence';
import { IncidentLogPdfContext } from '../IncidentLogPdf/IncidentLogPdfContext';
import { useForm } from 'react-hook-form';
import { IKnowFormProps } from '../OrfKnow';
import { IWhereFormProps } from '../OrfWhere';
import { IIncidentsFormProps } from '../OrfIncidents';
import { IOffendersFormProps } from '../OrfOffenders';
import { PrivateRoutes } from '../../config/routes';
import { useNavigate } from 'react-router-dom';

export const OrfPdf: React.FC = () => {
  const navigate = useNavigate();
  const docRef = useRef<HTMLDivElement>(null);
  const { state: { createdDate, lastUpdatedDate, steps } } = useContext(OrfContext);
  const { state: { reason } } = useContext(IncidentLogPdfContext);
  const when = steps.when;
  const where = steps.where;
  const know = steps.know;
  const consent = steps.consent;
  const incidents = steps.incidents;
  const offenders = steps.offenders;
  const evidence = steps.evidence;
  const { register: iKnowRegister, setValue: iKnowSetValue } = useForm<IKnowFormProps>();
  const { register: whenRegister } = useForm<IWhenFormProps>();
  const { register: whereRegister, setValue: whereSetValue } = useForm<IWhereFormProps>();
  const { register: incidentsRegister } = useForm<IIncidentsFormProps>();
  const { register: offendersRegister, setValue: offendersSetValue } = useForm<IOffendersFormProps>();

  const textWidth = '8in';

  useEffect(() => {
    const getBase64Image = (img: HTMLImageElement) => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      // const ctx = canvas.getContext('2d');
      // if (ctx) {
      //   ctx.drawImage(img, 0, 0);
      // }
      // const dataURL = canvas.toDataURL('image/png');
      // return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');

      // Hard-coding the Callisto logo base-64 for now since the above doesn't seem to work anymore.
      return callistoLogoBase64;
    };

    const savePdf = async () => {
      const jspdf: jsPDF = new jsPDF({
        unit: 'pt',
        format: 'letter'
      });
      await jspdf.html(docRef.current as unknown as string, {
        margin: [45, 0, 45, 45], // top, right, bottom, left (right seems to be ignored)
        callback: (doc: jsPDF) => {
          const pageCount = jspdf.getNumberOfPages();
          for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            if (reason === 'share with attorney') {
              doc.text('CONFIDENTIAL - ATTORNEY CLIENT PRIVILEGE', 175, 35);
            }
            const pageCurrent = doc.getCurrentPageInfo().pageNumber;
            if (pageCurrent === 1) {
              const img = new Image(175, 121);
              img.src = LogoSvg as string;
              try {
                doc.addImage(getBase64Image(img), 'png', 380, 50, 175, 121); // img, type, left-offset, top-offset, width, height
              } catch (error) {
                // When running localhost, you need to use an actual PNG file--doesn't work on heroku because of security settings
                try {
                  doc.addImage(LogoPng as string, 'png', 380, 50, 175, 121);
                } catch {
                  // Do nothing
                }
              }

            }
            doc.setFontSize(10);
            doc.text(String(pageCurrent), doc.internal.pageSize.width / 2, doc.internal.pageSize.height - 15);
          }

          doc.save(`erf-${new Date().toISOString()}.pdf`);
        },
        html2canvas: {
          scale: .65
        },
        autoPaging: 'text'
      });
    };

    void savePdf();
    navigate(PrivateRoutes.ORF_REVIEW);
  }, []);

  return (
    <div ref={docRef}>
      <Paragraph>Record created (UTC/GMT): { createdDate ? createdDate.toISOString() : new Date().toISOString() }</Paragraph>
      <Paragraph>Record last updated (UTC/GMT): { lastUpdatedDate ? lastUpdatedDate.toISOString() : new Date().toISOString() }</Paragraph>
      <OrfPdfIntroPageContent textWidth={textWidth} />
      <OrfWhenPageContent
        pdf={true}
        textWidth={textWidth}
        values={{
          season: when.season as ISeasonCheck,
          hasExactDate: when.hasExactDate,
          time: when.time.key as ITimeCheck,
          year: when.year ? new Date(Date.UTC(parseInt(when.year, 10), 1)) : undefined,
          month: when.month,
          day: when.day,
          exactTime:
            ((when.time.key as ITimeCheck) === 'exact time' && when.time.value) ||
            '',
          memory: when.memory,
        }}
        register={whenRegister}
      />
      <OrfWherePageContent
        pdf={true}
        textWidth={textWidth}
        values={{
          place: where.place.key,
          exactPlace: (where.place.key === 'Other' && where.place.value) || '',
          memory: where.memory,
        }}
        register={whereRegister}
        setValue={whereSetValue}
      />
      <OrfKnowPageContent
        pdf={true}
        textWidth={textWidth}
        values={{
          anyoneSaw: know.anyoneSaw,
          anyoneInteract: know.anyoneInteract,
          informationYouDescribed: know.informationYouDescribed,
          toldAnyone: know.toldAnyone,
          informationYouTold: know.informationYouTold,
          offenderTold: know.offenderTold
        }}
        register={iKnowRegister}
        setValue={iKnowSetValue}
      />
      <OrfConsentPageContent
        pdf={true}
        textWidth={textWidth}
        values={{
          reasons: consent.reasons
        }}
      />
      <OrfIncidentsPageContent
        pdf={true}
        textWidth={textWidth}
        values={{
          whatHappened: incidents.whatHappened
        }}
        register={incidentsRegister}
      />
      <OrfOffenderPageContent
        pdf={true}
        textWidth={textWidth}
        values={{
          numberOfOffenders: offenders.numberOfOffenders,
          name: offenders.name,
          offenderDetails: offenders.offenderDetails,
          anyoneElse: offenders.anyoneElse,
          companionDetails: offenders.companionDetails
        }}
        setValue={offendersSetValue}
        register={offendersRegister}
      />
      <Evidence pdf={true} textWidth={textWidth} />
      <ElectronicEvidence electronicInfo={evidence.electronicInfo} pdf={true} textWidth={textWidth} />
      <PhysicalEvidence physicalInfo={evidence.physicalInfo} pdf={true} textWidth={textWidth} />
    </div>
  );
};
