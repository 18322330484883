import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useState
} from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Image,
  Select,
  Text,
  useToast
} from '@chakra-ui/react';

import SocialCategories from '../../components/SocialCategories';
import AnimatedBox from '../../components/layout/AnimatedBox';
import { PrivateRoutes } from '../../config/routes';
import {
  EntryContext,
  EntryGlobalActionTypes
} from '../../contexts/EntryContext';
import { usStates } from '../CreateWhere/constant';
import Checkbox from '../../components/CheckBox';
import ButtonLink from '../../components/ButtonLink';
import { StepWhereActionTypes } from '../../contexts/EntryContext/stepWhere';
import Button from '../../components/Button';

import PencilIcon from '../../../assets/icons/pencil.svg';
import { entryData } from '../../../../../../lib/data';
import {
  survivorClient,
  wait
} from '../../service/backend';
import MainCard from '../../components/MainCard';
import {
  Link,
  useNavigate
} from 'react-router-dom';

interface ISubmitForm {
  agreed: boolean;
  forMyself: boolean;
}

const EditEntry: React.FC = () => {
  const { state, dispatch } = useContext(EntryContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [edits, setEdits] = useState<{ where: boolean; who: boolean }>({
    where: false,
    who: false,
  });
  const [error, setError] = useState<string>('');
  const toast = useToast();

  const { register, handleSubmit } = useForm<ISubmitForm>();

  const {
    steps: { stepWhere, stepWho },
    cachedEditEntry,
    editEntryID,
  } = state;

  useEffect(() => {
    if (!editEntryID) {
      navigate(PrivateRoutes.MATCHING_SYSTEM);
    }
  }, []);

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: StepWhereActionTypes.SET_SELECTED_STATE,
      payload: e.currentTarget.value,
    });
  };

  const onSubmit = async ({ agreed, forMyself }: ISubmitForm) => {
    if (!forMyself) {
      setError('Please confirm that you are submitting this entry for yourself');
      return;
    } else if (!agreed) {
      setError('Please confirm that you are submitting this entry for review by a Legal Options Counselor in the event of a match');
      return;
    } else if (!stepWhere.selectedState) {
      setError('Please complete "Where did it happen?"');
      return;
    } else if (!Object.values(stepWho.identifiers).join('')) {
      setError('Please complete "Who was the offender?"');
      return;
    } else if (
      cachedEditEntry.incidentState === usStates[stepWhere.selectedState] &&
      JSON.stringify(cachedEditEntry.perpIDs) ===
        JSON.stringify(stepWho.identifiers)
    ) {
      setError('No changes detected');
      return;
    }

    const e: entryData = {
      userID: survivorClient.userID,
      incidentState: usStates[stepWhere.selectedState],
      perpIDs: stepWho.identifiers,
    };

    setLoading(true);
    try {
      await wait(100);
      await survivorClient.editMatchingEntry(editEntryID, e);
      toast({
        title: 'Your matching entry has been updated',
        status: 'success',
        position: 'top',
      });
      dispatch({
        type: EntryGlobalActionTypes.INITIALIZE_ENTRY,
      });
      navigate(PrivateRoutes.MATCHING_SYSTEM);
    } catch (errorThrown) {
      toast({
        title: 'There was an error editing your entry.',
        status: 'error',
        position: 'top',
      });
    } finally {
      setLoading(false);
    }
  };

  if (!editEntryID) {
    return <></>;
  }

  return (
    <AnimatedBox>
      <MainCard>
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Text as="h2" fontSize={['18px', '18px', '1.5em']} mt="20px">
              <b>Where did it happen?</b>
              {!edits.where && (
                <ButtonLink
                  color="brand.link"
                  onClick={() => setEdits((prev) => ({ ...prev, where: true }))}
                >
                  <Image src={PencilIcon as string} />
                </ButtonLink>
              )}
            </Text>
            {!edits.where && (
              <Text as="p" mt="5px" mb="16px">
                {usStates[stepWhere.selectedState]}
              </Text>
            )}
            {edits.where && (
              <Box>
                <Text mb="16px">Select a state.</Text>
                <Select
                  placeholder="Select state"
                  bgColor="brand.white"
                  fontSize="sm"
                  textTransform="capitalize"
                  value={stepWhere.selectedState}
                  onChange={handleSelectChange}
                  mb="16px"
                >
                  {Object.keys(usStates).map((usState) => (
                    <option value={usState} key={usState}>
                      {usStates[usState]}
                    </option>
                  ))}
                </Select>
              </Box>
            )}
          </Box>
          <Box>
            <Text as="h2" fontSize={['18px', '18px', '1.5em']} mt="20px">
              <b>Who was the offender?</b>
              {!edits.who && (
                <ButtonLink
                  color="brand.link"
                  onClick={() => setEdits((prev) => ({ ...prev, who: true }))}
                >
                  <Image src={PencilIcon as string} />
                </ButtonLink>
              )}
            </Text>
            {edits.who && <SocialCategories />}
            {!edits.who &&
              Object.keys(stepWho.identifiers).map((key: string) => {
                const identifier =
                  stepWho.identifiers[key as keyof typeof stepWho.identifiers];
                return (
                  <React.Fragment key={key}>
                    {identifier.length > 0 && (
                      <>
                        <Text
                          as="p"
                          mt="5px"
                          mb="16px"
                          textTransform="capitalize"
                        >
                          <b>{key}</b>
                        </Text>
                        {identifier.map((str, idx) => (
                          <Text as="p" mt="5px" mb="16px" key={`${str}-${idx}`}>
                            {str}
                          </Text>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
          </Box>
          <Box>
            <Text as="h2" fontSize={['18px', '18px', '1.5em']} mt="20px">
              <b>Contact Information</b>
            </Text>
            <Text as="p" mt="5px" mb="16px">
              You can update your contact information at any time in your{' '}
              <Text as="span" color="brand.link" textDecor="underline">
                <Link to={PrivateRoutes.ACCOUNT}>
                  account settings
                </Link>
              </Text>
              .
            </Text>
          </Box>
          <Checkbox py="10px" {...register('forMyself')}>
            I am entering into matching for myself and not for another person.
            The person that I am identifying as a perpetrator harmed me.
          </Checkbox>
          <Checkbox py="10px" {...register('agreed')}>
            I am submitting this Matching entry for review by a Legal Options
            Counselor (an attorney provided at no cost to you) in the event of a Match. I want my
            entry to be confidential and protected by attorney client privilege.
            I understand that the entire entry will be viewable by the Legal
            Options Counselor assigned to me, and that they will use the contact
            information I provide to reach out to me.
          </Checkbox>
          <Text color="brand.red" my="20px">
            {error && <b>{error}</b>}
          </Text>
          <Button
            buttonColor="brand.primary"
            width="100%"
            type="submit"
            isLoading={loading}
          >
            Save changes
          </Button>
        </Box>
      </MainCard>
    </AnimatedBox>
  );
};

export default EditEntry;
