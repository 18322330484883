import React from 'react';
import {
  Box,
  Text
} from '@chakra-ui/react';
import { PublicRoutes } from '../config/routes';

const GiveFeedbackLink: React.FC = () => (
  <Box fontSize="sm" mt="16px">
    <Text
      as="a"
      href={PublicRoutes.FEEDBACK}
      color="brand.link"
      textDecor="underline"
      fontWeight="bold"
    >
      Leave Feedback or Report an Issue
    </Text>
  </Box>
);

export default GiveFeedbackLink;
