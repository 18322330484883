import React, { useState } from 'react';
import {
  Box,
  Text
} from '@chakra-ui/layout';
import { ModalCloseButton, useToast } from '@chakra-ui/react';
import {
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/modal';
import { useForm } from 'react-hook-form';

import Input from './Input';
import WhiteButton from './layout/WhiteButton';
import Button from './Button';
import {
  survivorClient,
  wait
} from '../service/backend';
import { BADGE_SALT } from '../config/env';
import { CallistoRoutes } from '../config/routes';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  deletePath: string;
}

interface IDeleteForm {
  password: string;
}

const DeleteModal: React.FC<Props> = ({ isOpen, onClose, deletePath }: Props) => {
  const { register, handleSubmit, formState: { errors } } = useForm<IDeleteForm>();
  const [accountDeleting, setAccountDeleting] = useState<boolean>(false);
  const toast = useToast();

  const onSubmit = async ({ password }: IDeleteForm) => {
    //
    setAccountDeleting(true);
    try {
      await wait(100);
      try {
        await survivorClient.submitEvent('delete account', {
          deletePath
        });
      } catch {
        // swallow error so it doesn't prevent survivor from deleting account
      }
      await survivorClient.deleteAccount(password, BADGE_SALT);
      window.location.href = CallistoRoutes.CALLISTO_HOME;
    } catch (e) {
      try {
        await survivorClient.submitEvent('delete account', {
          error: (e as Error).message
        });
      } catch {
        // swallow error so it doesn't prevent toast from being shown.
      }
      toast({
        title: 'There was an error deleting your account. Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
    setAccountDeleting(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="534px"
        minHeight="315px"
        padding="40px 40px 36px 40px"
        borderRadius="0px"
        color="brand.primary"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalCloseButton />
        <Text as="h3" fontSize="1.17em" my="18px">
          <b>Are you sure you want to delete your account?</b>
        </Text>
        <Text fontSize="sm" margin="16px 0px 27px 0px">
          Deleting your account is permanent and cannot be undone.
          Your account and any associated Matching Entries or Incident Logs will be irreversibly deleted.
          If you have an open case with an LOC, please contact them directly to close it.
        </Text>
        <Text as="label" color="brand.primary">
          <b>Enter your password:</b>
        </Text>
        <Input
          type="password"
          {...register('password', {
            required: {
              value: true,
              message: 'Please enter a password',
            },
            minLength: {
              value: 8,
              message: 'Passwords must be 8 characters.',
            },
          })}
        />
        {errors && (
          <Text my="5px" color="brand.red">
            {errors.password?.message}
          </Text>
        )}
        <Box textAlign="center" mt="20px" pt="20px">
          <WhiteButton mx="10px" px="20px" minWidth="auto" onClick={onClose}>
            Cancel
          </WhiteButton>
          <Button
            buttonColor="brand.primary"
            mx="10px"
            type="submit"
            isLoading={accountDeleting}
          >
            Yes, Delete My Account
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
