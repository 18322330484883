import React, {
  useContext,
  useState
} from 'react';
import {
  Box,
  Flex,
  FlexProps,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { userIncidentLog } from '../../../../../../lib/data';
import Card from '../../components/Card';
import Button from '../../components/Button';
import WhiteButton from '../../components/layout/WhiteButton';
import { IncidentLogContext } from '../../contexts/IncidentLogContext';
import { survivorClient } from '../../service/backend';
import { PrivateRoutes } from '../../config/routes';
import ConfirmModal from '../../components/ConfirmModal';
import { useNavigate } from 'react-router-dom';

type IncidentLogItemProps = FlexProps & userIncidentLog;

export const IncidentLogItem: React.FC<IncidentLogItemProps> = ({
  id,
  created,
  updated,
  data,
  ...restProps
}: IncidentLogItemProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const [incidentLogDeleting, setIncidentLogDeleting] = useState<boolean>(false);
  const { setIncidentLogMode, setCurrentLogId, resetIncidentLogState, setInitialData } = useContext(IncidentLogContext);
  const {
    isOpen: showDeleteConfirmModal,
    onOpen: setShowDeleteConfirmModal,
    onClose: closeDeleteConfirmModal
  } = useDisclosure();

  const handleEditClick = () => {
    survivorClient.submitEvent('Button Click', {
      dashboard: 'Incident Log',
      button: 'View/Edit'
    }).finally(() => {
      resetIncidentLogState();
      setIncidentLogMode('edit');
      setCurrentLogId(id);
      setInitialData(data, created, updated);
      navigate(PrivateRoutes.ILOG_SUMMARY);
    });
  };

  const handleDeleteClick = async () => {
    try {
      await survivorClient.submitEvent('Button Click', {
        dashboard: 'Incident Log',
        button: 'Delete Incident Log'
      });
    } finally {
      setShowDeleteConfirmModal();
    }
  };

  const handleDeleteAction = async () => {
    setIncidentLogDeleting(true);
    try {
      await survivorClient.deleteIncidentLog(id);
      toast({
        title: 'Successfully deleted an Incident Log',
        position: 'top',
        status: 'success'
      });
      closeDeleteConfirmModal();
      navigate(PrivateRoutes.ILOG);
    } catch {
      toast({
        title: 'There was a problem deleting the Incident Log. Please try again later',
        position: 'top',
        status: 'error'
      });
    }
  };

  return (
    <Card
      flexDir={{ base: 'column', md: 'row' }}
      justifyContent='space-between'
      p={{ base: '20px', md: '40px' }}
      fontSize='sm'
      {...restProps}
    >
      <Box>
        <Text mb='16px' fontWeight='800' fontSize='md'>
          Created on: {`${created.toDateString()} ${created.toLocaleTimeString()}`}
        </Text>
        <Text mb='16px'>
          <b>Last Updated:</b>&nbsp;
          {`${updated.toDateString()} ${updated.toLocaleTimeString()}`}
        </Text>
      </Box>
      <Flex flexDir='column'>
        <Button buttonColor='brand.primary' m='3px' onClick={handleEditClick}>
          View/Edit/Download
        </Button>
        <WhiteButton m='3px' onClick={handleDeleteClick}>
          Delete Incident Log
        </WhiteButton>
      </Flex>
      <ConfirmModal
        headerText="<b>Are you sure you want to delete this Incident Log?<br />This action cannot be undone</b>"
        submitText="Delete Incident Log"
        cancelText="Cancel"
        onSubmit={handleDeleteAction}
        isConfirming={incidentLogDeleting}
        isOpen={showDeleteConfirmModal}
        onClose={closeDeleteConfirmModal}
        onCancel={closeDeleteConfirmModal}
      />
    </Card>
  );
};
