import React, {
  FormEvent,
  Fragment,
  useContext,
  useState
} from 'react';
import HeaderText from '../../components/HeaderText';
import AnimatedBox from '../../components/layout/AnimatedBox';
import MainCard from '../../components/MainCard';
import { IncidentLogContext } from '../../contexts/IncidentLogContext';
import ReviewSubHeader from '../OrfReview/ReviewSubHeader';
import {
  formContainsData,
  getMultiselectResponse,
  getTextValue
} from '../../../../../../lib/questionnaire/formFunctions';
import {
  Box,
  Flex,
  Image,
  useToast
} from '@chakra-ui/react';
import Button from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import {
  survivorClient,
  wait
} from '../../service/backend';
import PencilIcon from '../../../assets/icons/pencil.svg';
import WhiteButton from '../../components/layout/WhiteButton';
import IncidentLogPdfDisclaimerModal from '../IncidentLogPdf/IncidentLogPdfDisclaimerModal';
import { PrivateRoutes } from '../../config/routes';
import IncidentLogPdfReasonModal from '../IncidentLogPdf/IncidentLogPdfReasonModal';
import { IncidentLogPdfContext } from '../IncidentLogPdf/IncidentLogPdfContext';
import { IncidentLogBanner } from '../../components/IncidentLogBanner';
import {
  Link,
  useNavigate
} from 'react-router-dom';

export const IncidentLogSummaryPage: React.FC = () => {
  const {
    state:
    {
      mode,
      currentLogId,
      steps,
      results
    },
    setSavingScreenVisibility,
    saveIncidentLog
  } = useContext(IncidentLogContext);
  const { state: { reason: purpose } } = useContext(IncidentLogPdfContext);
  const [showExportPdfDisclaimerModal, setShowExportPdfDisclaimerModal] = useState<boolean>(false);
  const [showExportPdfReasonModal, setShowExportPdfReasonModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = async (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();

    setSavingScreenVisibility(true);
    try {
      if (formContainsData(results)) {
        await wait(1000);
        await saveIncidentLog(mode, currentLogId);
      } else {
        toast({
          position: 'top',
          status: 'error',
          title: 'Please answer at least one question. We cannot save an empty Incident Log.'
        });
      }
    } finally {
      setSavingScreenVisibility(false);
    }
  };

  return (
    <>
      <AnimatedBox>
        <IncidentLogBanner />
        <MainCard>
          <Box as="form" onSubmit={handleSubmit} id="incident_log_form">
            <WhiteButton
              m="3px"
              className="rightAligned"
              onClick={() => {
                survivorClient.submitEvent('Button Click', {
                  feature: `Incident Log PDF Download`,
                  button: 'Download Incident Log as PDF'
                }).finally(() => {
                  setShowExportPdfDisclaimerModal(true);
                });
              }}
            >
              Download as PDF
            </WhiteButton>
            <HeaderText>Summary</HeaderText>
            { Object.values(steps).map((step) => {
              if (step.questions.length > 0) {
                return (
                  <Fragment key={`${step.text}mainfragment`}>
                    <ReviewSubHeader key={step.text}>
                      {step.text}
                      {mode === 'edit' && (
                        <Link to={step.route}>
                          <ButtonLink color="brand.link">
                            <Image src={PencilIcon as string} />
                          </ButtonLink>
                        </Link>
                      )}
                    </ReviewSubHeader>
                    {step.questions.map((question) =>
                      <Fragment key={`${question.key}frag`}>
                        <p key={question.key}><b key={`${question.key}b`}>{ question.label }</b></p>
                        {(question.type === 'text' || question.type === 'number' || question.type === 'textarea') &&
                            <p key={`${question.key}answer`} className="answer">{getTextValue([question.key], results)}</p>}
                        {question.type === 'multiselect' && (
                          <div key={`${question.key}answer`} className="answer">
                            <ul className="ul" key={`${question.key}ul`}>
                              {getMultiselectResponse([question.key], results).map((resp) => {
                                if (resp.label.toLowerCase() === 'other') {
                                  return <li key={resp.key}>Other: {resp.value}</li>;
                                }
                                return <li key={resp.key}>{resp.label}</li>;
                              })}
                            </ul>
                          </div>
                        )}
                      </Fragment>)}
                  </Fragment>
                );
              }
            }) }
            <Flex flexDir="column" mt="60px">
              <Button
                buttonColor="brand.primary"
                type="submit"
                marginBottom="20px"
              >
              Save & Exit
              </Button>
              <ButtonLink onClick={() => {
                if (steps.summary.previousStepRoute) {
                  navigate(steps.summary.previousStepRoute);
                }
              }}>Back</ButtonLink>
            </Flex>
          </Box>
        </MainCard>
        { showExportPdfDisclaimerModal &&
          <IncidentLogPdfDisclaimerModal
            isOpen={true}
            onClose={() => {
              survivorClient.submitEvent('Button Click', {
                button: 'Cancel (without accepting disclaimer)',
                feature: `Incident Log PDF Download`
              }).finally(() => {
                setShowExportPdfDisclaimerModal(false);
              });
            }}
            onSubmit={() => {
              survivorClient.submitEvent('Button Click', {
                button: 'Accept disclaimer',
                feature: `Incident Log PDF Download`
              }).finally(() => {
                setShowExportPdfDisclaimerModal(false);
                setShowExportPdfReasonModal(true);
              });
            }}
          />
        }
        { showExportPdfReasonModal &&
          <IncidentLogPdfReasonModal
            isOpen={true}
            onClose={() => {
              survivorClient.submitEvent('Button Click', {
                button: 'Cancel (after accepting disclaimer)',
                feature: `Incident Log PDF Download`
              }).finally(() => {
                setShowExportPdfReasonModal(false);
              });
            }}
            onSubmit={() => {
              survivorClient.submitEvent('Button Click', {
                button: 'Download PDF',
                purpose,
                feature: `Incident Log PDF Download`
              }).finally(() => {
                navigate(PrivateRoutes.ILOG_PDF_DOWNLOAD);
              });
            }}
          />
        }
      </AnimatedBox>
    </>
  );
};
