import React from 'react';
import {
  Box,
  Text
} from '@chakra-ui/react';

interface SecurityQuestionTipsProps {
  baseFontSize: string;
  titleFontSize: string;
}

export const SecurityQuestionTips: React.FC<SecurityQuestionTipsProps> = ({
  baseFontSize,
  titleFontSize
}: SecurityQuestionTipsProps) => (
  <Box textAlign="left" border="1px solid" borderColor="brand.primary" padding="1em" fontSize={baseFontSize}>
    <Text as="h3" fontSize={titleFontSize} fontFamily="Avenir" fontWeight="bold" textAlign="center">
      Tips for Making Strong Answers to Security Questions
    </Text>

    <Text as="p" mt="1em">The best secret question answers have four characteristics. They are:</Text>
    <ol style={{ marginLeft: '2em' }}>
      <li><b>Safe</b> (The answers cannot be easily researched or guessed.)</li>
      <li><b>Stable</b> (The answer will not change over time.)</li>
      <li><b>Memorable</b> (If you forget the answer, the question does not work as it should.)</li>
      <li><b>Simple and specific</b> (Something complex or vague may be too difficult to remember.)</li>
    </ol>

    <Text as="p" mt="1em">It is not always easy to meet all 4 characteristics, but at least
    keep the following in mind:</Text>
    <ol style={{ marginLeft: '2em' }}>
      <li>Avoid picking answers that are well known to your friends, shared on social media, or common knowledge.</li>
      <li>Avoid picking answers that can be easily found using a search engine such as Google.</li>
      <li>Avoid picking answers that you may not be able to remember later.</li>
      <li>Avoid really short answers if possible. (A good minimum limit is 8 characters.)</li>
    </ol>

    <Text as="p" mt="1em">The very best answers have no obvious relationship to the question
    but that you will be able to remember. If you are not sure if you will remember your answers, use a
    password manager to store them.</Text>

    <Text as="h3" mt="1em" textAlign="center" fontSize={titleFontSize} fontWeight="bold">
      Answers to security questions are case sensitive!
    </Text>
  </Box>
);
