import React, {
  useContext,
  useRef
} from 'react';
import { Outlet } from 'react-router-dom';

import AnimatedBox from '../../components/layout/AnimatedBox';
import { EntryContext } from '../../contexts/EntryContext';
import Notification from '../../components/Notification';

const CreateWho: React.FC = () => {
  const {
    state: {
      steps: {
        stepWho: { identifiers },
      },
    },
  } = useContext(EntryContext);
  const boxRef = useRef<HTMLDivElement>(null);

  const totalIdentifiersLength = Object.values(identifiers).reduce(
    (accumulator, currentValue) => accumulator + currentValue.length,
    0
  );

  const getCountMessage = (): string => {
    switch (totalIdentifiersLength) {
      case 1:
        return 'great';
      case 2:
        return 'amazing';
      default:
        return 'spectacular';
    }
  };

  const isPlural = totalIdentifiersLength > 1;

  return (
    <AnimatedBox>
      <div ref={boxRef}>
        {totalIdentifiersLength > 0 && (
          <Notification
            width={boxRef.current?.offsetWidth}
            position="fixed"
            top="50px"
            zIndex={9}
            notification={{
              type: 'success',
              message: `${totalIdentifiersLength} identifier ${
                isPlural ? 's' : ''
              } is ${getCountMessage()}! If possible, add another to increase your chances of a match.`,
            }}
          />
        )}
        <Outlet />
      </div>
    </AnimatedBox>
  );
};

export default CreateWho;
