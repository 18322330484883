import React from 'react';
import HeaderText from '../../components/HeaderText';
import SubHeader from '../../components/SubHeader';

interface EvidenceProps {
  pdf?: boolean;
  textWidth?: string;
}

export const Evidence: React.FC<EvidenceProps> = ({
  pdf = false,
  textWidth
}: EvidenceProps) => (
  <div style={{ width: textWidth }}>
    <HeaderText pdf={pdf}>Evidence</HeaderText>
    <SubHeader pdf={pdf}>
      If you choose to report what happened to your school, the police,
      the press, or in a lawsuit, they may ask you for electronic and/or
      physical evidence of what happened. If you don&apos;t have these things,
      it doesn&apos;t mean that what happened is any less real.
    </SubHeader>
  </div>
);
