"use strict";
// Copyright 2021-2023 Buf Technologies, Inc.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRegistry = void 0;
/**
 * Create a new registry from the given types.
 */
function createRegistry(...types) {
    const messages = {};
    const enums = {};
    const services = {};
    const registry = {
        /**
         * Add a type to the registry. For messages, the types used in message
         * fields are added recursively. For services, the message types used
         * for requests and responses are added recursively.
         */
        add(type) {
            if ("fields" in type) {
                if (!this.findMessage(type.typeName)) {
                    messages[type.typeName] = type;
                    for (const field of type.fields.list()) {
                        if (field.kind == "message") {
                            this.add(field.T);
                        }
                        else if (field.kind == "map" && field.V.kind == "message") {
                            this.add(field.V.T);
                        }
                        else if (field.kind == "enum") {
                            this.add(field.T);
                        }
                    }
                }
            }
            else if ("methods" in type) {
                if (!this.findService(type.typeName)) {
                    services[type.typeName] = type;
                    for (const method of Object.values(type.methods)) {
                        this.add(method.I);
                        this.add(method.O);
                    }
                }
            }
            else {
                enums[type.typeName] = type;
            }
        },
        findMessage(typeName) {
            return messages[typeName];
        },
        findEnum(typeName) {
            return enums[typeName];
        },
        findService(typeName) {
            return services[typeName];
        },
    };
    for (const type of types) {
        registry.add(type);
    }
    return registry;
}
exports.createRegistry = createRegistry;
