import React, {
  createContext,
  Dispatch,
  useReducer,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Text,
  useToast
} from '@chakra-ui/react';
import { PrivateRoutes } from '../config/routes';
import { survivorClient } from '../service/backend';
import {
  ActionMap,
  Step
} from './type';
import LoadingIndicator from '../components/LoadingIndicator';

export const MONTH_VALUES = [
  { key: 'Jan', value: 1 },
  { key: 'Feb', value: 2 },
  { key: 'Mar', value: 3 },
  { key: 'Apr', value: 4 },
  { key: 'May', value: 5 },
  { key: 'Jun', value: 6 },
  { key: 'Jul', value: 7 },
  { key: 'Aug', value: 8 },
  { key: 'Sep', value: 9 },
  { key: 'Oct', value: 10 },
  { key: 'Nov', value: 11 },
  { key: 'Dec', value: 12 },
];

export type ITimeCheck =
  | 'morning'
  | 'afternoon'
  | 'evening'
  | 'late night (after 10pm)'
  | 'exact time';

export const TIME_CONSTANTS: ITimeCheck[] = [
  'morning',
  'afternoon',
  'evening',
  'late night (after 10pm)',
];

export const PLACE_VALUES = [
  'On campus',
  'Off campus, locally (local bar, local housing comple, etc)',
  'Away from school (out of city/state/country)',
  `I'm not sure`,
  `I'd rather not say`,
];

interface KeyValue {
  key: string;
  value: string;
}

type ORFMode = 'create' | 'edit';

interface StepWhenPayload {
  season: string;
  time: KeyValue;
  memory: string;
  year: string;
  month: string;
  day: string;
  hasExactDate: 'Yes' | 'No' | null;
}

interface StepWherePayload {
  place: {
    key: string;
    value: string;
  };
  memory: string;
}

export type YesNoSure = 'Yes' | 'No' | `I'm not sure` | null;

interface StepKnowPayload {
  anyoneSaw: YesNoSure;
  anyoneInteract: YesNoSure;
  informationYouDescribed: string;
  toldAnyone: 'Yes' | 'No' | null;
  informationYouTold: string;
  offenderTold: YesNoSure;
}

interface StepConsentPayload {
  reasons: string[];
}

interface StepIncidentsPayload {
  whatHappened: string;
}

interface StepOffendersPayload {
  numberOfOffenders: string;
  name: string;
  offenderDetails: string;
  anyoneElse: YesNoSure;
  companionDetails: string;
}

interface StepEvidencePayload {
  physicalInfo: string;
  electronicInfo: string;
}

type StepIntro = Step;
type StepWhen = Step & StepWhenPayload;
type StepWhere = Step & StepWherePayload;
type StepKnow = Step & StepKnowPayload;
type StepConsent = Step & StepConsentPayload;
type StepIncidents = Step & StepIncidentsPayload;
type StepOffenders = Step & StepOffendersPayload;
type StepEvidence = Step & StepEvidencePayload;
type StepReview = Step;

export interface IOrfSteps {
  intro: StepIntro;
  when: StepWhen;
  where: StepWhere;
  know: StepKnow;
  consent: StepConsent;
  incidents: StepIncidents;
  offenders: StepOffenders;
  evidence: StepEvidence;
  review: StepReview;
}

interface IState {
  steps: IOrfSteps;
  mode: ORFMode;
  currentRecordId: string | undefined;
  isDiscarding: boolean;
  saveCurrentForm: boolean;
  changesMade: boolean;
  createdDate: Date | undefined;
  lastUpdatedDate: Date | undefined;
}

interface IProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const initialState: IState = {
  steps: {
    intro: {
      text: 'Introduction',
      route: PrivateRoutes.ORF_INTRO,
      nextStepRoute: PrivateRoutes.ORF_WHEN,
      completed: false,
    },
    when: {
      text: 'When',
      route: PrivateRoutes.ORF_WHEN,
      nextStepRoute: PrivateRoutes.ORF_WHERE,
      completed: false,
      season: '',
      time: {
        key: '',
        value: '',
      },
      memory: '',
      year: '',
      month: '',
      day: '',
      hasExactDate: null,
    },
    where: {
      text: 'Where',
      route: PrivateRoutes.ORF_WHERE,
      nextStepRoute: PrivateRoutes.ORF_KNOW,
      completed: false,
      place: {
        key: '',
        value: '',
      },
      memory: '',
    },
    know: {
      text: 'People who know or might know',
      route: PrivateRoutes.ORF_KNOW,
      nextStepRoute: PrivateRoutes.ORF_CONSENT,
      completed: false,
      anyoneSaw: null,
      anyoneInteract: null,
      informationYouDescribed: '',
      toldAnyone: null,
      informationYouTold: '',
      offenderTold: null,
    },
    consent: {
      text: 'Consent',
      route: PrivateRoutes.ORF_CONSENT,
      nextStepRoute: PrivateRoutes.ORF_INCIDENTS,
      completed: false,
      reasons: [],
    },
    incidents: {
      text: 'Incidents',
      route: PrivateRoutes.ORF_INCIDENTS,
      nextStepRoute: PrivateRoutes.ORF_OFFENDERS,
      completed: false,
      whatHappened: '',
    },
    offenders: {
      text: 'Offenders',
      route: PrivateRoutes.ORF_OFFENDERS,
      nextStepRoute: PrivateRoutes.ORF_EVIDENCE,
      completed: false,
      numberOfOffenders: '',
      name: '',
      offenderDetails: '',
      anyoneElse: null,
      companionDetails: '',
    },
    evidence: {
      text: 'Evidence',
      route: PrivateRoutes.ORF_EVIDENCE,
      nextStepRoute: PrivateRoutes.ORF_REVIEW,
      completed: false,
      physicalInfo: '',
      electronicInfo: '',
    },
    review: {
      text: 'Review',
      route: PrivateRoutes.ORF_REVIEW,
      nextStepRoute: PrivateRoutes.ILOG,
      completed: false,
    },
  },
  mode: 'create',
  currentRecordId: undefined,
  isDiscarding: false,
  saveCurrentForm: false,
  changesMade: false,
  createdDate: undefined,
  lastUpdatedDate: undefined
};

type CompleteActionPayload =
  | StepWhenPayload
  | StepWherePayload
  | StepKnowPayload
  | StepConsentPayload
  | StepIncidentsPayload
  | StepOffendersPayload
  | StepEvidencePayload;

type ActionPayload = {
  COMPLETE_ACTION: {
    type: keyof IOrfSteps;
    payload?: CompleteActionPayload;
    completed: boolean;
  };
  RESET_STATE: undefined;
  SET_MODE: { mode: ORFMode };
  SET_RECORD_ID: { id: string };
  SET_DISCARDING: { isDiscarding: boolean };
  SET_SAVE_CURRENT_FORM: { saveCurrentForm: boolean };
  SET_CHANGES_MADE: { changesMade: boolean };
  SET_CREATED_MODIFIED_DATES: { created: Date; modified: Date };
};

type OrfActions = ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>];

export const OrfContext = createContext<{
  state: IState;
  dispatch: Dispatch<OrfActions>;
  completeAction: (stepType: keyof IOrfSteps, payload?: CompleteActionPayload) => void;
  resetOrfState: () => void;
  setORFMode: (mode: ORFMode) => void;
  setRecordId: (id: string) => void;
  isAnyDataFilled: () => boolean;
  updateORF: (_steps?: IOrfSteps) => Promise<void>;
  setSavingScreenVisibility: (savingScreenVisible: boolean) => void;
  setCreatedModifiedDates: (created: Date, modified: Date) => void;
  setModified: () => void;
}>({
      state: initialState,
      dispatch: () => null,
      completeAction: () => null,
      resetOrfState: () => null,
      setORFMode: () => null,
      setRecordId: () => null,
      isAnyDataFilled: () => false,
      updateORF: async () => {},
      setSavingScreenVisibility: () => null,
      setCreatedModifiedDates: () => null,
      setModified: () => null
    });

const reducer = (state: IState, action: OrfActions) => {
  switch (action.type) {
    case 'COMPLETE_ACTION':
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.payload.type]: {
            ...state.steps[action.payload.type],
            ...action.payload.payload,
            completed: action.payload.completed,
          },
        }
      };
    case 'SET_CHANGES_MADE':
      return {
        ...state,
        changesMade: action.payload.changesMade
      };
    case 'RESET_STATE':
      return { ...initialState };
    case 'SET_MODE':
      return {
        ...state,
        mode: action.payload.mode,
      };
    case 'SET_RECORD_ID':
      return {
        ...state,
        currentRecordId: action.payload.id,
      };
    case 'SET_DISCARDING':
      return {
        ...state,
        isDiscarding: action.payload.isDiscarding,
        changesMade: !action.payload.isDiscarding
      };
    case 'SET_SAVE_CURRENT_FORM':
      return {
        ...state,
        saveCurrentForm: action.payload.saveCurrentForm,
      };
    case 'SET_CREATED_MODIFIED_DATES':
      return {
        ...state,
        createdDate: action.payload.created,
        lastUpdatedDate: action.payload.modified
      };
    default:
      return { ...state };
  }
};

const getRecordData = (steps: IOrfSteps) => {
  const { when, where, know, consent, incidents, offenders, evidence } = steps;

  return {
    year: when.year ? parseInt(when.year, 10) : undefined,
    dayOfMonth: when.day ? parseInt(when.day, 10) : undefined,
    month: when.month,
    time: when.time.value,
    season: when.season,
    whenDetails: when.memory,
    place: where.place.value,
    placeDetails: where.memory,
    anyoneSaw: know.anyoneSaw || undefined,
    anyoneInteract: know.anyoneInteract || undefined,
    witnessDetails: know.informationYouDescribed,
    toldAnyone: know.toldAnyone || undefined,
    peopleYouToldDetails: know.informationYouTold,
    offenderTold: know.offenderTold || undefined,
    reasonsForLackOfConsent: consent.reasons,
    whatHappened: incidents.whatHappened,
    numberOfOffenders: offenders.numberOfOffenders,
    offenderName: offenders.name,
    offenderDetails: offenders.offenderDetails,
    offenderWithAnyoneElse: offenders.anyoneElse || undefined,
    offenderCompanionDetails: offenders.companionDetails,
    electronicEvidence: evidence.electronicInfo,
    physicalEvidence: evidence.physicalInfo,
  };
};

const isWhenDataFilled = (payload: StepWhenPayload) =>
  !!payload.hasExactDate ||
  !!payload.season ||
  !!payload.time.value ||
  !!payload.memory;

const isWhereDataFilled = (payload: StepWherePayload) =>
  !!payload.memory || !!payload.place.value;
const isKnowDataFilled = (payload: StepKnowPayload) =>
  !!payload.anyoneSaw ||
  !!payload.anyoneInteract ||
  !!payload.informationYouDescribed ||
  !!payload.toldAnyone ||
  !!payload.informationYouTold ||
  !!payload.offenderTold;
const isConsentDataFilled = (payload: StepConsentPayload) =>
  payload.reasons.length > 0;
const isIncidentsDataFilled = (payload: StepIncidentsPayload) =>
  !!payload.whatHappened;
const isOffendersDataFilled = (payload: StepOffendersPayload) =>
  !!payload.numberOfOffenders ||
  !!payload.name ||
  !!payload.anyoneElse ||
  !!payload.offenderDetails ||
  !!payload.companionDetails;
const isEvidenceDataFilled = (payload: StepEvidencePayload) =>
  !!payload.physicalInfo || !!payload.electronicInfo;

const checkStepFilled: {
  // eslint-disable-next-line no-unused-vars
  [key in keyof IOrfSteps]: (payload: any) => boolean;
} = {
  when: isWhenDataFilled,
  where: isWhereDataFilled,
  know: isKnowDataFilled,
  consent: isConsentDataFilled,
  offenders: isOffendersDataFilled,
  incidents: isIncidentsDataFilled,
  evidence: isEvidenceDataFilled,
  intro: () => true,
  review: () => true,
};

export const OrfContextProvider: React.FC  = ({ children }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [savingScreenVisible, setSavingScreenVisibility] =
    useState<boolean>(false);
  const toast = useToast();
  const navigate = useNavigate();

  const completeAction = (
    type: keyof IOrfSteps,
    payload?: CompleteActionPayload
  ) => {
    dispatch({
      type: 'COMPLETE_ACTION',
      payload: {
        type,
        payload,
        completed: checkStepFilled[type](payload),
      },
    });
  };

  const setChangesMade = (
    changesMade: boolean
  ) => {
    dispatch({
      type: 'SET_CHANGES_MADE',
      payload: {
        changesMade
      }
    });
  };

  const resetOrfState = () => {
    dispatch({
      type: 'RESET_STATE',
    });
  };

  const setORFMode = (mode: ORFMode) => {
    dispatch({
      type: 'SET_MODE',
      payload: {
        mode,
      },
    });
  };

  const setRecordId = (id: string) => {
    dispatch({
      type: 'SET_RECORD_ID',
      payload: {
        id,
      },
    });
  };

  const setCreatedModifiedDates = (created: Date, modified: Date) => {
    dispatch({
      type: 'SET_CREATED_MODIFIED_DATES',
      payload: {
        created,
        modified
      }
    });
  };

  const setModified = () => {
    setChangesMade(true);
    setCreatedModifiedDates(state.createdDate ?? new Date(), new Date());
  };

  const isAnyDataFilled = (_steps?: IOrfSteps) => {
    const steps = _steps || state.steps;

    const { when, where, know, consent, incidents, offenders, evidence } =
      steps;

    return (
      isWhenDataFilled(when) ||
      isWhereDataFilled(where) ||
      isKnowDataFilled(know) ||
      isConsentDataFilled(consent) ||
      isIncidentsDataFilled(incidents) ||
      isOffendersDataFilled(offenders) ||
      isEvidenceDataFilled(evidence)
    );
  };

  const checkIfDataFilled = async (
    callback: () => Promise<void>,
    _steps?: IOrfSteps
  ) => {
    if (!isAnyDataFilled(_steps)) {
      toast({
        position: 'top',
        status: 'error',
        title: 'Form is empty and cannot be saved.',
      });
      dispatch({
        type: 'SET_SAVE_CURRENT_FORM',
        payload: {
          saveCurrentForm: false,
        },
      });
    } else {
      await callback();
    }
  };

  const updateORF = async (_steps?: IOrfSteps) => {
    if (state.changesMade) {
      await checkIfDataFilled(async () => {
        const { currentRecordId } = state;
        const recordData = getRecordData(_steps || state.steps);
        if (currentRecordId) {
          try {
            await survivorClient.updateRecordForm(currentRecordId, recordData);
            toast({
              position: 'top',
              status: 'success',
              title: 'Successfully updated the record form.',
            });
            resetOrfState();
            navigate(PrivateRoutes.ILOG);
          } catch (e) {
            toast({
              position: 'top',
              status: 'error',
              title:
                'There was a problem updating the record form. Please try again in a bit.',
            });
          }
        }
      }, _steps);
    } else {
      resetOrfState();
      navigate(PrivateRoutes.ILOG);
    }
  };

  return (
    <OrfContext.Provider
      value={{
        state,
        dispatch,
        completeAction,
        resetOrfState,
        setORFMode,
        setRecordId,
        isAnyDataFilled,
        updateORF,
        setSavingScreenVisibility,
        setCreatedModifiedDates,
        setModified
      }}
    >
      {children}
      {savingScreenVisible && (
        <Flex
          position="fixed"
          width="100%"
          height="100%"
          left="0px"
          top="0px"
          zIndex={9999}
          backgroundColor="rgba(0,0,0,0.5)"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
        >
          <LoadingIndicator width="auto" height="auto" />
          <Text as="p" color="brand.white" fontSize="md" fontWeight="600">
            {state.isDiscarding ? 'Discarding changes' : 'Saving...'}
          </Text>
        </Flex>
      )}
    </OrfContext.Provider>
  );
};
