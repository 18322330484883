import React, { useState } from 'react';
import {
  Box,
  Image,
  Text,
  useToast
} from '@chakra-ui/react';
import { CallistoRoutes } from '../../config/routes';
import { survivorClient } from '../../service/backend';
import Button from '../../components/Button';
import ConsentIcon from '../../../assets/icons/consent.svg';

import './styles.scss';
import Checkbox from '../../components/CheckBox';

const hyperLinkStyles = {
  textDecoration: 'underline',
};

const ConsentDashboard: React.FC = () => {
  const [consentsToFeedbackEmails, setConsentsToFeedbackEmails]
    = useState<boolean>(survivorClient.consents.feedbackEmail ?? false);
  const toast = useToast();

  const onSubmitEmailConsent = async () => {
    try {
      await survivorClient.updateConsent('feedbackEmail', consentsToFeedbackEmails);
      toast({
        title: 'Email consent successfully updated',
        status: 'success',
        position: 'top',
        isClosable: true,
        duration: 9000
      });
    } catch (error) {
      try {
        await survivorClient.submitEvent('Update email consent', { error: (error as Error).message });
      } catch {
        // Swallow error
      }
      const message = 'There was an error updating email consent. Please try again';
      toast({
        title: message,
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 9000
      });

    }
  };

  return (
    <Box>
      <Text as="h1" fontSize="xl" my="16px">
        <Image src={ConsentIcon as string} width="100px" />
        Consent Settings
      </Text>

      <Text>
        By continuing to use Callisto Vault, you are indicating your acceptance of and consent to the following:
      </Text>
      <ul style={{ marginLeft: '3em', marginBottom: '30px' }}>
        <li><a href={CallistoRoutes.PRIVACY_POLICY} target="_blank" rel="noreferrer" style={hyperLinkStyles}>
          The Callisto Vault privacy policy</a></li>
        <li><a href={CallistoRoutes.TERMS_OF_SERVICE} target="_blank" rel="noreferrer" style={hyperLinkStyles}>
          The Callisto Vault terms of service</a></li>
      </ul>

      <hr />

      <Text as="h2" fontSize="lg" my="16px">Email Consent</Text>
      <Checkbox value="feedbackEmail" key="feedbackEmail"
        onChange={() => {
          setConsentsToFeedbackEmails(!consentsToFeedbackEmails);
        }}
        isChecked={consentsToFeedbackEmails}
        my="10px !important"
      >
        I consent to receiving emails requesting anonymous responses to surveys or other feedback. (approximately 2 per year)
      </Checkbox>

      <Box textAlign="center">
        <Button buttonColor="brand.primary" onClick={onSubmitEmailConsent}>Update email consent</Button>
      </Box>
      <hr />
    </Box>
  );
};

export default ConsentDashboard;
