import React from 'react';
import { Text, TextProps } from '@chakra-ui/layout';

const ReviewSubText: React.FC<TextProps> = ({ children, ...restProps }) => (
  <Text as="h3" mt="18px" fontSize={['14px', '14px', '16px']} {...restProps}>
    <b>{children}</b>
  </Text>
);

export default ReviewSubText;
