import * as React from 'react';

const EncryptedDocumentIcon: React.FC = () => {
  const path1 = 'm196,47.83c.84,0,1.66.07,2.47.17h.53v.08c3.19.48,6.14,1.72,8.65,3.53-.66-3.22-2.24-6.2-4.6-8.56L160.01,0h-.01v47.83h36Z';
  // eslint-disable-next-line max-len
  const path2 = 'm160,220c0-11.25,4.33-22.06,12-30.19v-13.81c0-17.65,14.35-32,32-32,1.36,0,2.69.09,4,.26v-76.26c0-6.63-5.37-12-12-12h-40c-2.21,0-4-1.79-4-4V0H40c-4.42,0-8,3.58-8,8v224c0,4.42,3.58,8,8,8h124.83c-3.08-6.01-4.83-12.8-4.83-20Zm-72.25-93.41l4.11,5.88-4.47,3.53-4.11-5.84c-1.6-2.27-4.96-2.26-6.55,0l-3.96,5.66-4.47-3.53,4.13-5.75c1.55-2.16.6-5.2-1.9-6.1l-6.54-2.34,1.71-5.59,6.09,2.2c2.61.94,5.36-.99,5.36-3.76v-6.95h5.85v6.96c0,2.77,2.74,4.7,5.35,3.77l5.94-2.13,1.71,5.77-6.27,2.15c-2.52.86-3.51,3.89-1.98,6.07Zm40,0l4.11,5.88-4.47,3.53-4.11-5.84c-1.6-2.27-4.96-2.26-6.55,0l-3.96,5.66-4.47-3.53,4.13-5.75c1.55-2.16.6-5.2-1.9-6.1l-6.54-2.34,1.71-5.59,6.09,2.2c2.61.94,5.36-.99,5.36-3.76v-6.95h5.85v6.96c0,2.77,2.74,4.7,5.35,3.77l5.94-2.13,1.71,5.77-6.27,2.15c-2.52.86-3.51,3.89-1.98,6.07Zm28.98,3.58l-3.96,5.66-4.47-3.53,4.13-5.75c1.55-2.16.6-5.2-1.9-6.1l-6.54-2.34,1.71-5.59,6.09,2.2c2.61.94,5.36-.99,5.36-3.76v-6.95h5.85v6.96c0,2.77,2.74,4.7,5.35,3.77l5.94-2.13,1.71,5.77-6.27,2.15c-2.52.86-3.51,3.89-1.98,6.07l4.11,5.88-4.47,3.53-4.11-5.84c-1.6-2.27-4.96-2.26-6.55,0Z';
  // eslint-disable-next-line max-len
  const path3 = 'm228,193.17v-17.17c0-13.25-10.75-24-24-24s-24,10.75-24,24v17.17c-7.36,6.59-12,16.17-12,26.83,0,19.88,16.12,36,36,36s36-16.12,36-36c0-10.66-4.64-20.23-12-26.83Zm-36-17.17c0-6.62,5.38-12,12-12s12,5.38,12,12v10.06c-3.75-1.33-7.79-2.06-12-2.06s-8.25.73-12,2.06v-10.06Zm12,64c-11.03,0-20-8.97-20-20s8.97-20,20-20,20,8.97,20,20-8.97,20-20,20Z';

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={path1}
        fill="currentColor"
      />
      <path
        d={path2}
        fill="currentColor"
      />
      <path
        d={path3}
        fill="currentColor"
      />
    </svg>
  );
};

export default EncryptedDocumentIcon;
