import React from 'react';
import {
  Box,
  Text,
  useToast
} from '@chakra-ui/react';
import { useRequestAccountRecovery } from '../../service/hooks/auth';
import Notification from '../../components/Notification';
import Button from '../../components/Button';

interface ResendAccountRecoveryRequestProps {
  email: string;
  phone: string;
}

export const ResendAccountRecoveryRequest: React.FC<ResendAccountRecoveryRequestProps> =
  ({ email, phone }: ResendAccountRecoveryRequestProps) => {
    const toast = useToast();
    const {
      mutateAsync: sendAccountRecoveryRequest,
      isLoading
    } = useRequestAccountRecovery();

    const handleResend = async () => {
      try {
        await sendAccountRecoveryRequest({ emailAddress: email, phoneNumber: phone });
        toast({
          title: `We've attempted to resend the account recovery request to ${email}. Please check your inbox.`,
          status: 'success',
          isClosable: true,
          position: 'top'
        });
      } catch (e) {
        toast({
          title: 'There was en error resending the account recovery request. Please try again later.',
          status: 'error',
          position: 'top'
        });
      }
    };

    return (
      <Box textAlign="center" as="form">
        <Notification notification={{type: 'success', message: 'We have attempted to send you an account recovery email'}} />
        <Box
          maxWidth="850px"
          backgroundColor="brand.white"
          padding={['20px 20px', '30px 30px', '50px 60px']}
        >
          <Text as="h1" fontSize="xl" fontFamily="Avenir">
            Recover Your Account
          </Text>
          <Box>
            <Text mt="40px" lineHeight="28px" textAlign="left">
              If we were able to identify your account in our system then we sent an account recovery link to {email}.
              After clicking that link, you will be need to answer your security questions in order to reset your password.
            </Text>
            <Text my="17px">
              <b>Did not receive the email?</b>
            </Text>
            <Text lineHeight="28px" textAlign="left">
              Your verification email may take up to 5 minutes to arrive in your
              inbox. Be sure to also check your spam folder.
            </Text>
            <Text lineHeight="28px" textAlign="left">
              If you do not receive an email within 5 minutes, it is possible that we were unable to identify
              your account in our system, perhaps due to a typo in your email address or phone number. Please reload
              this page and try again.
            </Text>
            <Button
              buttonColor="brand.primary"
              onClick={handleResend}
              mt="25px"
              isLoading={isLoading}
            >
              Resend verification email
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };
