import { Matching, OPRF } from '../crypto';
import { entryData, shareData } from '../data';
import { RPCClient } from '../rpc';
import { endpoints as v1 } from '../../src/survivor/endpoints';

export const computeShares = async (
  entry: entryData,
  entryMarker: Uint8Array,
  client: Pick<RPCClient, 'encryptedCall'>
): Promise<{
  shares: shareData[];
  apiShares: {
    dek: Uint8Array;
    type: string;
    x: string;
    y: string;
  }[];
}> => {
  const shareKey = Matching.makeEntryKey();
  const shares: shareData[] = [];
  const normalizedUPIDs = Matching.normalizeUPIDs(entry.perpIDs);

  for (const type in normalizedUPIDs) {
    if (normalizedUPIDs.hasOwnProperty(type)) {
      const ids = normalizedUPIDs[type];

      for (const upid of ids.normalized) {
        // Transform the UPID into a higher-entropy form.
        const alpha = Matching.makeAlpha(upid);

        const { output: outputA } = await client.encryptedCall(
          v1.Matching_OPRF_A
        )({ alpha: alpha.point });
        const betaA = OPRF.unmask(outputA, alpha.mask);

        const { output: outputB } = await client.encryptedCall(
          v1.Matching_OPRF_B
        )({ alpha: alpha.point });
        const betaB = OPRF.unmask(outputB, alpha.mask);

        const pHat = Matching.makePHat(betaA, betaB);
        const share = Matching.makeShare(shareKey, pHat, entryMarker, type);
        shares.push(share);
      }
    }
  }

  // Transform the shares into a format that the API can understand.
  // e.g. convert x/y (bigInteger) to hexidecimal
  const apiShares = shares.map(({ dek, type, x, y }) => ({
    dek,
    type,
    x: x.toString(16),
    y: y.toString(16),
  }));

  return { shares, apiShares };
};
