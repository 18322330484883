import React, { useEffect } from 'react';
import {
  useNavigate,
  useParams
} from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useSignupCredentialsToken } from '../../service/hooks/auth';
import { PublicRoutes } from '../../config/routes';
import LoadingIndicator from '../../components/LoadingIndicator';

export const SignupCredentialsToken: React.FC = () => {
  const { token: input } = useParams<{ token: string }>();
  const toast = useToast();
  const navigate = useNavigate();
  const { mutateAsync: verifySignupCredentialsToken } = useSignupCredentialsToken();

  useEffect(() => {
    const verify = async () => {
      try {
        // The input might have had dummy query parameters added by the survivor's email system. We strip them off here.
        const inputParts = input.split('&');
        const token = inputParts[0];
        const { success, campusIdentified, emailDomain, email, signupToken } = await verifySignupCredentialsToken({ token });
        if (success) {
          navigate(PublicRoutes.SIGN_UP_CREDENTIAL, {
            state: {
              token: signupToken,
              campusIdentified,
              emailDomain,
              email
            }
          });
          toast({
            title: 'Your recovery email has been verified',
            status: 'success',
            isClosable: true,
            position: 'top'
          });
        } else {
          toast({
            title: 'Unable to verify email',
            status: 'error',
            isClosable: true,
            position: 'top'
          });
          navigate(PublicRoutes.LOGIN);
        }
      } catch (e) {
        toast({
          title: (e as Error).message,
          status: 'error',
          isClosable: true,
          position: 'top',
          duration: 10000
        });
        navigate(PublicRoutes.LOGIN);
      }
    };
    void verify();
  }, []);
  return (
    <LoadingIndicator />
  );
};
